export const OFFICE_HOURS_TYPE = {
  "birth-plan-workshop": {
    title: "Birth Plan Workshop",
    description:
      "When you are around 30 weeks, it’s time to start talking about your birth plan! This is an opportunity to learn more about Oula’s approach to birth, demystify upcoming decisions in labor, and review the differences between medicated and unmedicated labor. You’ll leave this workshop ready to build your birth plan in the Oula portal. Partners welcome.",
    image: "/images/birth-plan-workshop.jpg",
    link: "birth-plan-workshop",
    iframeUrl: "https://bookwhen.com/oulabirthplan/iframe",
  },
  "final-stretch": {
    title: "Final Stretch Office Hours",
    description:
      "Once you hit 36 weeks, you’re in the final stretch! Join an Oula midwife to check in on what you need to know, get tips for the final weeks, and celebrate how far you’ve come! Bring any questions about the upcoming labor and birth adventure and build community along the way. Partners welcome.",
    image: "/images/final-stretch.jpg",
    link: "final-stretch",
    iframeUrl: "https://bookwhen.com/oulafinalstretch/iframe",
  },
  postpartum: {
    title: "Newborn Feeding Group",
    description:
      "The Newborn Feeding Group is a supportive, non-judgmental space where patients can drop in for support and guidance on all aspects of infant feeding. A Certified Lactation Counselor will answer all your questions about breast/chestfeeding, pumping, bottle feeding and formula. You’ll leave the session feeling confident and empowered in your feeding journey.",
    image: "/images/postpartum-office.jpg",
    link: "postpartum",
    iframeUrl: "https://bookwhen.com/oula/iframe",
  },
};
