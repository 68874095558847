export const overflowedWeekToScheduleMsg =
  "Oh no! Looks like you'll be too late in your pregnancy for us to see you at Oula at the moment. You can email us to discuss your circumstances.";
export const notPregnancyCareTypeMsg = `This appointment type is for pregnant patients only. If you are pregnant, please update your status in <a class='confirmation__errorMessageLink' href="/my_profile">My Profile</a> and try again.`;
export const completeFirstAppointmentMsg =
  "Oops! Looks like you are trying to book a follow-up visit before your new patient visit. Please book this after you've had your first appointment.";
export const cancellationSameDayMsg =
  "This appointment is less than 24 hours away. Modifications and no-shows are subject to a $50 fee.";
export const otherInsuranceMsg =
  "Please provide your insurance information in the space provided and a member of our team will be in touch to discuss your options.";
export const notLaterPregnacyMsg = `Looks like you are very early in your pregnancy! We recommend starting with a virtual visit instead. Please reach out to us if you\'d like to request an early in-person visit.`;
export const notInWeek36Msg = `Looks like you won't be 36 weeks pregnant! Please select a different date, or choose a regular prenatal visit.`;
export const InWeek36Msg = `Looks like you'll be around 36 weeks pregnant on that date! Please book the "Week 36 OB Check" instead.`;
export const transferringPrenatalCareMsg = `Looks like you are transferring your prenatal care to us! Please book at least one week out so we have time to review your records.`;
export const veryEarlyPregnancyVirtualApptMsg = `Looks like you are very early in your pregnancy! Please book your first appointment for when you will be 8 weeks or later.`;
export const veryEarlyPregnancyPersonApptMsg = `Looks like you are very early in your pregnancy! We recommend starting with a virtual visit instead. Please reach out to us if you'd like to request an early in-person visit.`;
