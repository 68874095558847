import classnames from "classnames";

import { HtmlTooltip } from "../../materialDesignShared";

import "./Tooltip.scss";

const Tooltip = ({
  question,
  description,
  iconURI,
  containerClassname,
  descriptionClassname,
  iconClassname,
  questionClassname,
  placement,
}) => {
  const containerClassnames = classnames("tooltip", {
    [containerClassname]: containerClassname,
  });
  const descriptionClassnames = classnames("tooltip__description", {
    [descriptionClassname]: descriptionClassname,
  });
  const iconClassnames = classnames("tooltip__icon", {
    [iconClassname]: iconClassname,
  });
  const questionClassnames = classnames("tooltip__question", {
    [questionClassname]: questionClassname,
  });

  return (
    <HtmlTooltip
      title={<span className={descriptionClassnames}>{description}</span>}
      arrow={true}
      enterTouchDelay={-100}
      leaveTouchDelay={6000}
      placement={placement}
    >
      <div className={containerClassnames}>
        <img className={iconClassnames} src={iconURI} />

        <span className={questionClassnames}>{question}</span>
      </div>
    </HtmlTooltip>
  );
};

export default Tooltip;
