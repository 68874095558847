import { makeObservable, observable, computed, configure } from "mobx";
import { getIntakeFormInfo } from "../api/intakeForm";
import { authenticationStore } from "../stores/authentication-store";

configure({
  enforceActions: "never",
});

class IntakeFormStore {
  intakeFormInfo = undefined;
  loading = undefined;

  constructor() {
    makeObservable(this, {
      intakeForm: computed,
      intakeFormInfo: observable,
      loading: observable,
    });

    try {
      this.refreshUserInfo();
    } catch (e) {}
  }

  @computed
  get intakeForm() {
    if (this.intakeFormInfo === undefined) {
      this.refreshUserInfo();
      return;
    }

    return this.intakeFormInfo;
  }

  refreshUserInfo = async () => {
    if (this.loading || !authenticationStore.userId) {
      return;
    }
    try {
      this.loading = true;
      const { data } = await getIntakeFormInfo(authenticationStore.userId);
      this.intakeFormInfo = data.intake_form;
      this.setIntakeFormData(data.intake_form);
    } catch (e) {
      this.intakeFormInfo = undefined;
    } finally {
      this.loading = false;
    }
  };

  setIntakeFormData = (intakeFormInfo) => {
    this.getRememberMe
      ? localStorage.setItem("intakeForm", JSON.stringify(intakeFormInfo))
      : sessionStorage.setItem("intakeForm", JSON.stringify(intakeFormInfo));
  };

  getIntakeFormData = () => {
    let value =
      sessionStorage.getItem("intakeForm") ||
      localStorage.getItem("intakeForm");
    return value && JSON.parse(value);
  };
}

export const intakeFormStore = new IntakeFormStore();
