import { retryRequest } from "../services/requestHelpers";

export const getLabResults = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}/lab_results`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getPatient = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const resetUnseenResults = (id) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/lab_results/mark_labs_seen`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: {},
    },
    true
  );
};

export const revealSex = (id, value) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: {
        patient: {
          dont_reveal_sex: value,
        },
      },
    },
    true
  );
};

export const getLabResultImage = (userId, lab_result_id, pageid) => {
  return retryRequest(
    {
      method: "post",
      url: `/api/v1/patients/${userId}/lab_results/fetch_image`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: {
        lab_result_id,
        pageid,
      },
    },
    true
  );
};
