import { useState } from "react";
import { withResizeDetector } from "react-resize-detector";
import { Footer } from "../Footer/Footer";
import { MobileHeader } from "../Header/MobileHeader";
import { maxMobileScreenWidth } from "../../services/constants";
import { DesktopHeader } from "../Header/DesktopHeader";
import "./Wrapper.scss";
import { HeaderTopBar } from "../Header/HeaderTopBar";
import useAsyncEffect from "use-async-effect";
import { Box, Grid } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import ErrorFallback from "../App/ErrorFallback";

const WrapperComponent = (props) => {
  const [isMobile, setIsMobile] = useState(null);

  useAsyncEffect(async () => {
    window.innerWidth < maxMobileScreenWidth
      ? setIsMobile(true)
      : setIsMobile(false);
  }, [props.width]);

  return (
    <div className="wrapper-container">
      {!isMobile && <HeaderTopBar />}
      <div className="header-content-wrapper">
        {isMobile ? <MobileHeader /> : <DesktopHeader />}
        <div className="content-wrapper">
          <ErrorBoundary fallback={ErrorFallback}>
            <div className="background-wrapper">{props.children}</div>
          </ErrorBoundary>
          <div style={{ display: "block" }}>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export const Wrapper = withResizeDetector(WrapperComponent);
