import * as Yup from "yup";

export const passwordErrors = {
  minChar: "MIN_CHAR",
  oneNumber: "ONE_NUMBER",
  oneUpper: "ONE_UPPER",
  oneLower: "ONE_LOWE",
  oneSpecial: "ONE_SPECIAL",
};

export const passwordValidator = Yup.string()
  .min(8, passwordErrors.minChar)
  .matches(/\d/, passwordErrors.oneNumber)
  .matches(/[A-Z]/, passwordErrors.oneUpper)
  .matches(/[a-z]/, passwordErrors.oneLower)
  .matches(/\W|_/, passwordErrors.oneSpecial)
  .required();

export const findErrorByPath = (errors, path) => {
  if (errors[path]) {
    return errors[path];
  }
  return errors.find && errors.find((e) => e.path === path);
};

export const findErrorByMessage = (errors, message) => {
  return errors.find && errors.find((e) => e.message === message);
};

export const validateYupSchema = (validationSchema, values) => {
  try {
    validationSchema.validateSync(values, {
      abortEarly: false,
    });
  } catch (e) {
    const errors = [];
    e.inner.forEach((e) => {
      errors.push({
        path: e.path,
        message: e.message,
      });
    });
    return errors;
  }
};
