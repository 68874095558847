import { useState } from "react";
import { Typography } from "@material-ui/core";
import { getDepartments } from "../../../../api/departments";
import useAsyncEffect from "use-async-effect";
import classNames from "classnames";
import qs from "qs";

import { PATHNAMES } from "../../../../constants/pathnames";
import { ProgressBarLoggedOut } from "../../ProgressBarLoggedOut/ProgressBarLoggedOut";
import { authenticationStore } from "../../../../stores/authentication-store";

import "./Departments.scss";
import { appointmentStore } from "../../../../stores/appointment-store";
import { useHistoryWithPathBasedReload } from "../../../App/LinkWithPathBasedReload";

const Departments = () => {
  const [departments, setDepartments] = useState([]);
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const history = useHistoryWithPathBasedReload();
  const departmentsClassName = classNames("departments__container", {
    "scheduling-desktop-container": authenticationStore.userId,
  });
  const departmentsTitleClassName = classNames("departments__title", {
    "departments__title--white": !authenticationStore.userId,
  });

  useAsyncEffect(async () => {
    const { data } = await getDepartments();
    const filterOutSono = data.flatMap((d) =>
      /\bsono(?:graphy)?\b/i.test(d.department_name) ? [] : d
    );

    setDepartments(filterOutSono);
  }, []);

  return (
    <div className={departmentsClassName}>
      <h2 className={departmentsTitleClassName}>Select a clinic</h2>
      {!authenticationStore.userId && (
        <div className="progress-bar-logged">
          <ProgressBarLoggedOut step={1} />
        </div>
      )}
      <div className="departments__cards">
        {departments.map(
          ({ display_name, department_name, emr_departmentid }) => (
            <div
              className="departments__cardContainer"
              key={emr_departmentid}
              onClick={() => {
                appointmentStore.setDepartmentId(emr_departmentid);

                history.push(
                  `${PATHNAMES.LOCATION}?appointment_type_id=${
                    query.appointment_type_id
                  }&book_auth=${!!query.book_auth}`
                );
              }}
            >
              <Typography className="departments__cardTitle departments__cardTitle--main">
                {department_name}
              </Typography>
              <Typography className="departments__cardTitle">
                {display_name}
              </Typography>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Departments;
