import * as yup from "yup";
import {
  AMEX_CODE,
  defaultErrorText,
  FT_TO_INCHES_VALUE,
  INCH_TO_CENTIMETER_VALUE,
  LBS_TO_GR_VALUE,
} from "../constants/validation";

export const formatPhoneText = (number) => {
  let value = number.toString().trim().replace(/\D/g, "");
  const lengthValue = value.length;

  if (lengthValue > 3 && lengthValue <= 6) {
    value = value.slice(0, 3) + "-" + value.slice(3);
  } else if (lengthValue > 6) {
    value =
      value.slice(0, 3) + "-" + value.slice(3, 6) + "-" + value.slice(6, 10);
  }

  return value;
};

export const formatCardNameHolder = (name) => {
  let value = name.toString().replace(/[^a-z A-Z]+/g, "");

  return value;
};

export const formatCreditCardExpirationDate = (date) => {
  let value = date
    .toString()
    .trim()
    .replace(/[^0-9]+/g, "");
  const lengthValue = value.length;

  if (lengthValue > 2) {
    value = value.slice(0, 2) + "/" + value.slice(2, 4);
  }

  return value;
};

export const formatCreditCardNumber = (number) => {
  let value = number
    .toString()
    .trim()
    .replace(/[^0-9]+/g, "");
  const lengthValue = value.length;

  if (lengthValue > 4 && lengthValue <= 8) {
    value = value.slice(0, 4) + " " + value.slice(4);
  } else if (lengthValue > 8 && lengthValue <= 12) {
    value =
      value.slice(0, 4) + " " + value.slice(4, 8) + " " + value.slice(8, 12);
  } else if (lengthValue > 12) {
    value =
      value.slice(0, 4) +
      " " +
      value.slice(4, 8) +
      " " +
      value.slice(8, 12) +
      " " +
      value.slice(12, 16);
  }

  return value;
};

export const formatCVV = (cvv, cardNumber) => {
  let value = cvv.toString().trim();
  const isAmexCreditCard = cardNumber.slice(0, 2) === AMEX_CODE;
  const lengthValue = value.length;
  const maximumDigits = isAmexCreditCard ? 4 : 3;

  if (lengthValue > maximumDigits) {
    value = value.slice(0, maximumDigits);
  }

  return value;
};

export const formatZipText = (zip) => {
  let value = zip.toString().trim();
  if (value.length > 5) {
    value = value.slice(0, 4);
  }

  return value;
};

export const zipValidatorInfo = {
  name: "empty-or-5-characters-check",
  message: "Zip must be exactly 5 characters",
  fn: (zip = "") => zip.length === 5,
};

export const phoneNumberValidatorInfo = {
  name: "empty-or-10-characters-check",
  message: "Phone number must be 10 numbers",
  fn: (cell_phone = "") => {
    const matchedNumber = cell_phone.match(
      /^\(?([2-9][0-9]{2})\)?[-. ]?([2-9](?!11)[0-9]{2})[-. ]?([0-9]{4})$/
    );
    const phoneNumberLength = cell_phone.replaceAll("-", "").length;

    return phoneNumberLength === 0 || matchedNumber;
  },
};

export const generateValidationField = (
  type = "string",
  isRequired = false,
  testValidation,
  validationSchema
) => {
  const requiredValue = isRequired ? "required" : "optional";
  const requiredMessage = isRequired ? defaultErrorText : "";

  if (type === "integer") {
    type = "number";
  }

  if (type === "object") {
    return yup.object().shape(validationSchema);
  }

  if (testValidation) {
    const { name, message, fn } = testValidation;

    return yup[type]()[requiredValue](requiredMessage).test(name, message, fn);
  }

  return yup[type]()[requiredValue](requiredMessage);
};

const round = (num) => Math.round(num);

export const convertPoundsToGrams = (lbs) =>
  round(parseInt(lbs) * LBS_TO_GR_VALUE);

export const convertFtToInches = (ft) =>
  round(parseInt(ft) * FT_TO_INCHES_VALUE);

export const convertInchesToCentimeters = (inch) =>
  round(parseInt(inch) * INCH_TO_CENTIMETER_VALUE);

export const convertGramsToPounds = (grs) =>
  round(parseInt(grs) / LBS_TO_GR_VALUE);

export const convertCentimeterstoFtAndInches = (cms) => {
  const cmToInches = parseInt(cms) / INCH_TO_CENTIMETER_VALUE;
  const feet = Math.floor(cmToInches / FT_TO_INCHES_VALUE);
  const inches = round(cmToInches - FT_TO_INCHES_VALUE * feet);

  return {
    inches,
    feet,
  };
};
