import { Box, Button, Grid, useMediaQuery } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FormikProvider, useFormik } from "formik";
import { ReactNode, useEffect, useState } from "react";
import * as yup from "yup";
import SplashScreen from "./QuestionTypes/SplashScreen";
import SurveyQuestion, { SurveyQuestionProps } from "./SurveyQuesiton";
import { theme } from "../../materialDesignShared";
import { Alert } from "@material-ui/lab";

export interface SurveyModalProps<T> {
  title: string;
  handleClose: () => void;
  handleSubmit: (answers: any) => Promise<T>;
  questions: SurveyQuestionProps<T>[];
}

export type SurveyResPayload<T> = T | "LOADING" | "ERROR" | null;

const SurveyModal = <T extends any>({
  title,
  handleClose,
  handleSubmit,
  questions,
}: SurveyModalProps<T>) => {
  const [surveyRes, setSurveyRes] = useState<SurveyResPayload<T>>(null);
  const [page, setPage] = useState(0);
  const [displayTitle, setTitle] = useState(title);
  const formikInitialValues: Record<string, any> = {};
  questions.forEach((q, i) => {
    const formikKey = `question_${i}`;
    if (q.type === "splash_screen") formikInitialValues[formikKey] = true;
    else formikInitialValues[formikKey] = null;
  });

  // create validation schema
  const formikValidationSchema: Record<string, yup.Schema> = {};

  questions.forEach((q, i) => {
    const questionKey = `question_${i}`;
    switch (q.type) {
      case "select_one": {
        formikValidationSchema[questionKey] = yup
          .string()
          .oneOf(q.options.map((v) => v.answer_value));
        break;
      }
      case "splash_screen": {
        formikValidationSchema[questionKey] = yup.boolean();
        break;
      }
      default: {
        throw new Error(
          `unknown type! please create validation schema for type ${q.type}`
        );
      }
    }
  });

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: yup.object(formikValidationSchema),
    onSubmit: async (answers) => {
      const output: Record<string, any> = {};
      // filter out splash pages
      questions.forEach((q, index) => {
        const questionKey = `question_${index}`;
        if (q.type !== "splash_screen")
          // map outputKey values to answer values
          output[q.outputKey || questionKey] = answers[questionKey];
      });

      setSurveyRes("LOADING");
      try {
        const res = await handleSubmit(output);
        setSurveyRes(res);
      } catch {
        setSurveyRes("ERROR");
      }
    },
  });

  // create question components
  const currentQuestionName = `question_${page}`;
  const currentQuestion = questions[page];
  let displayQuestion: JSX.Element;

  useEffect(() => {
    if (currentQuestion.overrideTitle) {
      setTitle(currentQuestion.overrideTitle);
    } else {
      setTitle(title);
    }
  }, [currentQuestion]);

  const constantProps: {
    name: string;
    value: any;
    surveyRes: any;
  } = {
    name: currentQuestionName,
    value: formik.values[currentQuestionName],
    surveyRes,
  };

  switch (currentQuestion.type) {
    case "select_one": {
      displayQuestion = (
        <SurveyQuestion
          type={currentQuestion.type}
          question_text={currentQuestion.question_text}
          options={currentQuestion.options}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          {...constantProps}
        />
      );
      break;
    }
    case "splash_screen": {
      displayQuestion = (
        <SplashScreen
          type={currentQuestion.type}
          component={currentQuestion.component}
          onChange={formik.handleChange}
          {...constantProps}
        />
      );
      break;
    }
    default: {
      displayQuestion = (
        <Box>Unknown question type {currentQuestion.type}!</Box>
      );
      break;
    }
  }

  useEffect(() => {
    if (formik.values[currentQuestionName] !== null)
      formik.setFieldTouched(currentQuestionName, true);
  }, [formik.values]);

  const allowNext =
    currentQuestion.type === "splash_screen" ||
    !!(
      formik.errors[currentQuestionName] === undefined &&
      formik.touched[currentQuestionName]
    );

  const isLastQuestion = page + 1 >= questions.length;
  // create an array of questions that contain values for submitForm so we can make sure there's only one instance
  // and we can know where it is, so we can adj the form controls
  const submitsFormArray = questions.map((q) => !!q.submitsForm);
  const formSubmitsBeforeLastQuestion = submitsFormArray.indexOf(true) > -1;
  const formSubmitsAtIndex = formSubmitsBeforeLastQuestion
    ? submitsFormArray.indexOf(true)
    : submitsFormArray.length - 1;
  const multipleSubmitsFormValues =
    submitsFormArray.filter((v) => v).length > 1;
  if (multipleSubmitsFormValues)
    // theoretically, we could have multiple places where the form is submitted, but this simplifies the logic a lot
    throw new TypeError(
      "multiple questions contain true for submitsForm! only one is permitted"
    );
  const submitsForm: boolean =
    !!currentQuestion?.submitsForm ||
    (isLastQuestion && formSubmitsBeforeLastQuestion === false);

  const paginationAction = () => {
    // if the form submits before the last page OR
    // it is on the last page and the form doesn't submit before the last page, submit the form
    if (
      (formSubmitsBeforeLastQuestion &&
        !!currentQuestion.submitsForm === true) ||
      (isLastQuestion && !formSubmitsBeforeLastQuestion)
    )
      formik.submitForm();
    if (isLastQuestion === false) setPage(page + 1);
    if (isLastQuestion) handleClose();
  };
  const buttonText = currentQuestion.customButtonText ?? "Next";
  const buttonComponent = currentQuestion.customButtonComponent ? (
    currentQuestion.customButtonComponent(paginationAction, allowNext)
  ) : (
    <Button
      disabled={!allowNext}
      variant="outlined"
      style={{
        color: !allowNext ? "#FFFFFF80" : "#FFFFFF",
        borderColor: !allowNext ? "#FFFFFF80" : "#FFFFFF",
        padding: ".2rem 1.8rem",
        fontSize: "1.1rem",
      }}
      onClick={paginationAction}
    >
      {submitsForm ? "Submit" : buttonText}
    </Button>
  );

  const isSmallViewport = useMediaQuery(theme.breakpoints.down("sm"));

  const outputKeyValues = questions.flatMap((q) => {
    if (q.type !== "splash_screen") return q.outputKey;
    else return [];
  });
  const outputKeyDuplicates = outputKeyValues.flatMap((outputKey, i) => {
    if (outputKeyValues.indexOf(outputKey) !== i) return outputKey;
    else return [];
  });
  if (outputKeyDuplicates.length > 0)
    return (
      <Box
        width={"100vw"}
        height={"100vh"}
        position={"absolute"}
        top={0}
        left={0}
        zIndex={2000}
        bgcolor={"rgba(0,0,0,.5)"}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <Alert severity="error">
            Question schema contains duplicate values for <code>outputKey</code>
            ! Duplicate keys: <code>{JSON.stringify(outputKeyDuplicates)}</code>
          </Alert>
        </Grid>
      </Box>
    );

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      position={"absolute"}
      top={0}
      left={0}
      zIndex={2000}
      bgcolor={"rgba(0,0,0,0.5)"}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={isSmallViewport ? "flex-start" : "center"}
        height={"100%"}
        width={"100%"}
      >
        <Box
          maxWidth={isSmallViewport ? "auto" : "40rem"}
          minWidth={isSmallViewport ? "100vw" : "40rem"}
          minHeight={isSmallViewport ? "100vh" : "auto"}
          style={{
            backgroundImage:
              "linear-gradient(143deg, #eedbc3 12%, #da9c86 98%)",
          }}
        >
          <Grid container style={{ marginTop: ".4rem" }}>
            <Grid item xs={1}></Grid>
            <Grid
              item
              xs={10}
              justifyContent="center"
              style={{ display: "flex" }}
            >
              <Box
                fontWeight={"bold"}
                paddingTop={".5rem"}
                textAlign={"center"}
              >
                {displayTitle}
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box style={{ float: "right", paddingRight: "0.4rem" }}>
                <Button variant="text" onClick={handleClose}>
                  <Close />
                </Button>
              </Box>
            </Grid>
          </Grid>
          <Box padding={"1.5rem"}>
            <FormikProvider value={formik}>
              {displayQuestion}
              <Grid
                container
                alignItems="center"
                direction="column"
                style={{ marginTop: "1rem" }}
              >
                <Grid container justifyContent="center">
                  {/* progress breacrumbs */}
                  {questions.map((q, i) => (
                    <Box
                      style={{
                        minWidth: 0,
                        width: ".6rem",
                        height: ".6rem",
                        borderRadius: "50%",
                        background: i - 1 < page ? "#ba624a" : "#f3dbd2",
                        padding: 0,
                        margin: ".5rem",
                        marginBottom: "1rem",
                      }}
                    />
                  ))}
                </Grid>
                <Box data-testid={"modal-pagination-button-parent"}>
                  {buttonComponent}
                </Box>
                {page > 0 && page <= formSubmitsAtIndex && (
                  <Button
                    onClick={() => setPage(page - 1)}
                    variant="text"
                    style={{
                      marginTop: "0.5rem",
                      textDecoration: "underline",
                    }}
                  >
                    Go back
                  </Button>
                )}
              </Grid>
            </FormikProvider>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SurveyModal;
