import { DASHBOARD_TRANSLATIONS_ES } from "./componentsTranslations/dashboard";
import { CARE_PLAN_TRANSLATIONS_ES } from "./componentsTranslations/carePlan";
import { JOURNEY_TRANSLATIONS_ES } from "./componentsTranslations/journey";
import { APPOINTMENTS_TRANSLATIONS_ES } from "./componentsTranslations/appointments";

export const TRANSLATIONS_ES = {
  ...DASHBOARD_TRANSLATIONS_ES,
  ...CARE_PLAN_TRANSLATIONS_ES,
  ...JOURNEY_TRANSLATIONS_ES,
  ...APPOINTMENTS_TRANSLATIONS_ES,
};
