import chiyo from "./brandLogos/chiyo.png";
import coterie from "./brandLogos/coterie.png";
import fullyFunded from "./brandLogos/fully-funded.png";
import glowbar from "./brandLogos/glowbar.png";
import hatch from "./brandLogos/hatch.png";
import ipsa from "./brandLogos/ipsa.webp";
import origin from "./brandLogos/origin.png";
import summerHealth from "./brandLogos/summer-health.png";
import lotus from "./brandLogos/the-lotus-method.png";
import motherhoodCenter from "./brandLogos/the-motherhood-center.png";

export const LotusMethodLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://thelotusmethod.com/">
    <img
      className="workshops-brand-logo"
      src={lotus}
      alt={"The Lotus Method"}
    />
  </a>
);

export const MotherhoodCenterLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://themotherhoodcenter.com/">
    <img
      className="workshops-brand-logo"
      src={motherhoodCenter}
      alt={"The Motherhood Center of New York"}
      style={{ filter: "none" }}
    />
  </a>
);
export const FullyFundedLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://www.wearefullyfunded.com/">
    <img
      className="workshops-brand-logo"
      src={fullyFunded}
      alt={"Fully Funded"}
    />
  </a>
);
export const OriginLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://www.theoriginway.com/">
    <img className="workshops-brand-logo" src={origin} alt={"Origin"} />
  </a>
);
export const ChiyoLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://wearechiyo.com/">
    <img className="workshops-brand-logo" src={chiyo} alt={"Chiyo"} />
  </a>
);
export const SummerHealthLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://www.summerhealth.com/">
    <img
      className="workshops-brand-logo"
      src={summerHealth}
      alt={"Summer Health"}
    />
  </a>
);
export const IpsaLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://eatipsa.com/">
    <img className="workshops-brand-logo" src={ipsa} alt={"Ipsa Provisions"} />
  </a>
);
export const HatchLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://www.hatchcollection.com/">
    <img className="workshops-brand-logo" src={hatch} alt={"Hatch"} />
  </a>
);
export const CoterieLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://www.coterie.com/">
    <img
      className="workshops-brand-logo"
      src={coterie}
      alt={"Coterie"}
      style={{ filter: "none" }}
    />
  </a>
);
export const GlowbarLogo = () => (
  <a target="_blank" rel="noreferrer" href="https://www.glowbar.com/">
    <img className="workshops-brand-logo" src={glowbar} alt={"Glowbar"} />
  </a>
);
