import { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import RemoveIcon from "@material-ui/icons/Remove";
import { Container, Snackbar } from "@material-ui/core";
import {
  Button,
  AppBar,
  Toolbar,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TextField,
} from "@material-ui/core";
import Expand from "react-expand-animated";
import { get, uniqueId } from "lodash";
import { Check } from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import MuiAlert from "@material-ui/lab/Alert";

import * as commonMaterial from "../../../materialDesignShared";
import { journeyStore } from "../../../stores/journey-store";
import {
  maxTabletScreenWidth,
  savedNoteLength,
} from "../../../services/constants";
import { appointmentStore } from "../../../stores/appointment-store";
import { updateNote } from "../../../api/journey";
import { authenticationStore } from "../../../stores/authentication-store";
import { findNoteInAppointmentsArray } from "../../../services/journey-helpers";
import { YYYY_MM_DD_ALTERNATIVE } from "../../../constants/dateFormat";
import { cancellationSameDayMsg } from "../../../constants/toastMessages";
import { getIsVirtualAppointment } from "../../../helpers/appointmentCardsHelpers";
import { PATHNAMES } from "../../../constants/pathnames";
import { LINKS } from "../../../constants/relatedLinks";

import "./AppointmentCard.scss";
import { getHighlightTextSearchMarkup } from "../../../helpers/highlighterHelpers";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";

const hasNumber = (myString) => {
  return /\d/.test(myString);
};

const retNumber = (myString) => {
  let num = myString.replace(/[^0-9]/g, "");
  return parseInt(num, 10);
};

export const AppointmentCardDetails = ({
  title,
  description,
  begin_date_range,
  appointment_type_id,
  card_key,
  can_book_from_portal,
  appointment_type_description,
  virtual,
  bullets,
  enable_book_now,
  add_note_enabled,
  appointment,
  handleClose,
  onUpdateNote,
  updateNotes,
  event,
  group_class,
  searchText,
}) => {
  const [isExpandedBullet, setIsExpandedBullet] = useState(false);
  const [rowInTable, setRowInTable] = useState(false);
  const [openAddNote, setOpenAddNote] = useState();
  const [addNoteValue, setAddNoteValue] = useState();
  const [savedNoteMessage, setSaveNoteMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const history = useHistoryWithPathBasedReload();
  const isVirtualAppointment = getIsVirtualAppointment(appointment, virtual);
  const appointmentOnSameThanCurrentDay = moment(
    moment(appointment?.start_time).format(YYYY_MM_DD_ALTERNATIVE)
  ).isSame(moment().format(YYYY_MM_DD_ALTERNATIVE));
  const isNotEventOrGroup = !event && !group_class;

  const selectedTitle = hasNumber(title)
    ? `${
        title.toLowerCase().includes("postpartum") ? "POSTPARTUM" : ""
      } WEEK ${retNumber(title)} APPOINTMENT`
    : title;

  const highlightedTitleText = getHighlightTextSearchMarkup(
    selectedTitle,
    searchText
  );

  useEffect(() => {
    if (
      findNoteInAppointmentsArray(appointment?.id) &&
      !journeyStore.publicView
    ) {
      setAddNoteValue(findNoteInAppointmentsArray(appointment?.id));
      setOpenAddNote(true);
    }
  }, [findNoteInAppointmentsArray(appointment?.id)]);

  const saveNoteFromJourney = async (appointmentId) => {
    try {
      setLoading(true);
      const { data } = await updateNote(
        authenticationStore.userId,
        appointmentId,
        addNoteValue
      );
      if (get(data, "success")) {
        setLoading(false);
        setSaveNoteMessage(true);
        if (updateNotes) {
          await Promise.all([
            appointmentStore.refreshAppointments(),
            journeyStore.refreshJourney(),
          ]);
        } else {
          await appointmentStore.refreshAppointments();
        }
        if (onUpdateNote) {
          onUpdateNote();
        }
        setTimeout(() => {
          setLoading(false);
          setSaveNoteMessage(false);
        }, savedNoteLength);
      }
    } catch (e) {
      console.log(e);
      if (get(e, "response.data.errors")) {
        setLoading(false);
        setSaveNoteMessage(false);
        setResponseError(e.response.data.errors[0]);
      }
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResponseError(null);
  };

  return (
    <Container maxWidth="sm" className="appointment-container">
      <AppBar>
        <Toolbar>
          {window.innerWidth < maxTabletScreenWidth && (
            <ArrowBackIosIcon
              edge="start"
              color="inherit"
              onClick={() =>
                handleClose
                  ? handleClose()
                  : (journeyStore.appointmentCardToShow = undefined)
              }
              aria-label="close"
            >
              <CloseIcon />
            </ArrowBackIosIcon>
          )}
          <div className="dialog-title">APPOINTMENT</div>
        </Toolbar>
      </AppBar>
      <div className="dialog-body">
        <div className="appointment-title">
          <img
            className="icon"
            src="/icons/calendar@0,5x.svg"
            alt="calendar-icon"
          />
          {ReactHtmlParser(highlightedTitleText)}
        </div>

        {isVirtualAppointment && can_book_from_portal && isNotEventOrGroup && (
          <div>
            <div className="virtual-label">Virtual</div>
            {appointment && appointment.virtual_link && (
              <div className="video-link">
                <a href={appointment.virtual_link} target="_blank">
                  Join video appointment{" "}
                  <img
                    className="icon"
                    src="/icons/Arrow-Right-Main@0,5x.svg"
                    alt="arrow-right-icon"
                  />
                </a>
              </div>
            )}
          </div>
        )}

        {event && (
          <div style={{ marginBottom: "10px" }}>
            <span className="event-label">Event</span>
          </div>
        )}

        {group_class && (
          <div style={{ marginBottom: "10px" }}>
            <span className="group-class-label">Group Class</span>
          </div>
        )}

        {!isVirtualAppointment && can_book_from_portal && isNotEventOrGroup && (
          <div style={{ marginBottom: "10px" }}>
            <div className="office-visit-label">Office Visit</div>
          </div>
        )}

        {appointment && (
          <div className="appointment-card-details-time">
            {moment(appointment.start_time).format("ddd")},{" "}
            {moment(appointment.start_time).format("MMM")}{" "}
            {moment(appointment.start_time).format("DD")},{" "}
            {moment(appointment.start_time).format("YYYY")}
            <br />
            {moment.utc(appointment.start_time).format("hh:mm A")} ET
          </div>
        )}
        <div className="dialog-description">
          {description.map((section) => {
            if (get(section, "paragraph")) {
              const higlightedText = getHighlightTextSearchMarkup(
                section.paragraph,
                searchText
              );

              return <p key={uniqueId()}>{ReactHtmlParser(higlightedText)}</p>;
            }
            if (get(section, "list")) {
              const higlightedText = getHighlightTextSearchMarkup(
                section.list.title,
                searchText
              );
              return (
                <div className="appointment-list">
                  <p className="appointment-list-title">
                    {ReactHtmlParser(higlightedText)}
                  </p>
                  <ul>
                    {section.list.items.map((item) => {
                      const higlightedText = getHighlightTextSearchMarkup(
                        item,
                        searchText
                      );
                      return (
                        <li key={uniqueId()}>
                          {ReactHtmlParser(higlightedText)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            }
          })}
        </div>
        {bullets && (
          <Table
            className="appointment-details-table"
            aria-label="simple table"
          >
            <TableBody>
              {bullets?.map((bullet, index) => {
                return (
                  <TableRow
                    onClick={() => {
                      if (rowInTable === index) {
                        setIsExpandedBullet(!isExpandedBullet);
                      } else {
                        setIsExpandedBullet(true);
                      }
                      setRowInTable(index);
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="table-title">{bullet.name}</div>
                      <Expand open={rowInTable === index && isExpandedBullet}>
                        <div className="table-subtitle">
                          {ReactHtmlParser(bullet.description)}
                        </div>
                      </Expand>
                    </TableCell>
                    <TableCell className="table-sign" align="right">
                      {rowInTable === index && isExpandedBullet ? (
                        <RemoveIcon />
                      ) : (
                        <AddIcon />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        )}
        {openAddNote &&
          moment(
            moment(appointment?.start_time).format(YYYY_MM_DD_ALTERNATIVE)
          ).isSameOrAfter(moment(moment().format(YYYY_MM_DD_ALTERNATIVE))) && (
            <div className="dialog-notes">
              <div className="comment-question-section">Comments/Questions</div>
              <TextField
                placeholder="Anything we need to know in advance?"
                multiline
                rows={2}
                rowsMax={8}
                value={addNoteValue}
                onChange={(e) => setAddNoteValue(e.target.value)}
              >
                `{">"}`
              </TextField>
              {!savedNoteMessage && (
                <div
                  className="save-button"
                  onClick={() => saveNoteFromJourney(appointment.id)}
                >
                  Save
                  <span style={{ display: !loading && "none" }}>
                    <ClipLoader color={"#ba624a"} loading={loading} size={10} />
                  </span>
                </div>
              )}
              {savedNoteMessage && (
                <div className="saved">
                  Saved <Check />
                </div>
              )}
            </div>
          )}
        <div className="appointment-buttons">
          {appointment && !event && !group_class && (
            <commonMaterial.ReversedCustomButton
              variant="outlined"
              size="large"
              color="primary"
              type="submit"
              style={{ padding: "5px 30px", fontSize: "14px" }}
              onClick={() => {
                appointmentStore.setAppointment({
                  appointment_type_id: appointment_type_id,
                  appointment_card_key: card_key,
                  add_note_enabled: add_note_enabled,
                  card_title: title,
                  appointment_id: appointment.id,
                  start_time: appointment.start_time,
                  appointment_type_description:
                    appointment.appointment_type_description,
                  virtual_link: appointment.virtual_link,
                  begin_date_range: moment(begin_date_range).format(
                    YYYY_MM_DD_ALTERNATIVE
                  ),
                  card_is_virtual: virtual,
                  reschedule: true,
                });

                history.push(PATHNAMES.VIEW_APPOINTMENT);
              }}
            >
              RESCHEDULE
            </commonMaterial.ReversedCustomButton>
          )}

          {(enable_book_now && !appointment && can_book_from_portal) ||
            ((event || (group_class && !journeyStore.publicView)) && (
              <commonMaterial.CustomButton
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{ padding: "5px 30px", fontSize: "12px" }}
                onClick={(e) => {
                  if (event) {
                    e.stopPropagation();
                    window.location.href = LINKS.events;
                  } else if (group_class) {
                    history.push(PATHNAMES.OFFICE_HOURS);
                  } else {
                    appointmentStore.setAppointment({
                      appointment_type_id: appointment_type_id,
                      appointment_card_key: card_key,
                      add_note_enabled: add_note_enabled,
                      card_title: title,
                      appointment_type_description:
                        appointment_type_description,
                      begin_date_range:
                        begin_date_range &&
                        moment(begin_date_range).isAfter(
                          moment().add(1, "days")
                        )
                          ? moment(begin_date_range).format(
                              YYYY_MM_DD_ALTERNATIVE
                            )
                          : moment(moment().add(1, "days")).format(
                              YYYY_MM_DD_ALTERNATIVE
                            ),
                      card_is_virtual: virtual,
                    });

                    history.push(PATHNAMES.SCHEDULING);
                  }
                }}
              >
                BOOK NOW
              </commonMaterial.CustomButton>
            ))}

          {add_note_enabled && !openAddNote && appointment && (
            <span className="right-button">
              <Button
                variant="contained"
                disableElevation
                startIcon={<AddIcon className="add-icon" />}
                onClick={() => setOpenAddNote(!openAddNote)}
                style={{
                  fontSize: "11.5px",
                  backgroundColor: "white",
                  color: "#ba624a",
                  textTransform: "capitalize",
                }}
              >
                Add note
              </Button>
            </span>
          )}
        </div>

        {appointment && appointmentOnSameThanCurrentDay && (
          <div className="dialog-notes">
            <TextField
              placeholder="Anything we need to know in advance?"
              multiline
              rows={2}
              rowsMax={8}
              disable
              value={cancellationSameDayMsg}
            />
          </div>
        )}
      </div>

      <Snackbar
        open={responseError}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {responseError}
        </Alert>
      </Snackbar>
    </Container>
  );
};
