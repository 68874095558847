import { Box, Button, useMediaQuery } from "@material-ui/core";
import { useIntercom } from "react-use-intercom";
import { CustomButton, theme } from "../../materialDesignShared";
import { ChatBubble } from "@material-ui/icons";

const IntercomButton = () => {
  const { show } = useIntercom();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Box margin={`0 ${isSmall ? 0.5 : 1}rem`}>
      <CustomButton onClick={() => show()}>
        <ChatBubble
          style={{
            marginRight: "0.3rem",
            fontSize: isSmall ? ".6rem" : "1.1rem",
          }}
        />
        <span
          style={{
            fontSize: isSmall ? "0.6rem" : "inherit",
            whiteSpace: "nowrap",
          }}
        >
          Message Care Team
        </span>
      </CustomButton>
    </Box>
  );
};

export default IntercomButton;
