import _get from "lodash/get";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import MonitoringIconActive from "../../assets/svg/monitoring-active.svg";
import MonitoringIcon from "../../assets/svg/monitoring.svg";

import { getDashboard } from "../../api/dashboard";
import { getPatient, resetUnseenResults } from "../../api/results";
import { PATHNAMES } from "../../constants/pathnames";
import { getShowOfficeHours } from "../../helpers/timeHelpers";
import { logout } from "../../services/logoutHelper";
import { authenticationStore } from "../../stores/authentication-store";
import { getIsActiveMonitoringRequest } from "../../api/monitoring";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";
import "./DesktopHeader.scss";
import Circles from "../../assets/svg/circles.svg";
import {
  LinkWithPathBasedReload,
  useHistoryWithPathBasedReload,
} from "../App/LinkWithPathBasedReload";

export const DesktopHeader = observer(() => {
  const [collapsed, setCollapsed] = useState(true);
  const [unseenResults, setUnseenResults] = useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [monitoringActive, setMonitoringActive] = useState(false);
  const { oulaCircles } = useFeatureFlag("oula-circles");
  const [patientData, setPatientData] = useState(null);

  const location = useLocation();
  const history = useHistoryWithPathBasedReload();
  const showOfficeHoursIcon = getShowOfficeHours(dashboardData);

  useAsyncEffect(async () => {
    const { data } = await getPatient(authenticationStore.userId);
    const { data: dataDasboard } = await getDashboard(
      authenticationStore.userId
    );
    const fetchedDashboardData = _get(dataDasboard, "dashboard");

    if (_get(data, "patient")) {
      setUnseenResults(data.patient.has_unseen_labs);
    }

    if (fetchedDashboardData) {
      setDashboardData(fetchedDashboardData);
    }
  }, []);

  useAsyncEffect(async (isMounted) => {
    const { active } = await getIsActiveMonitoringRequest();
    if (isMounted()) setMonitoringActive(active);
  }, []);

  useAsyncEffect(async (isMounted) => {
    const newPatientData = await getPatient(authenticationStore.userId);
    if (isMounted) setPatientData(newPatientData);
  }, []);
  const isPregnant = patientData?.data.patient.is_pregnant;
  const isPostpartum = patientData?.data.patient.is_postpartum;

  const officeHoursEnable = isPregnant || isPostpartum;

  return (
    <>
      <ProSidebar
        collapsed={collapsed}
        onMouseEnter={() => setCollapsed(false)}
        onMouseLeave={() => setCollapsed(true)}
      >
        <Menu iconShape="square">
          <MenuItem
            className={location.pathname === PATHNAMES.DASHBOARD && "active"}
            icon={
              <LinkWithPathBasedReload to={PATHNAMES.DASHBOARD}>
                {location.pathname === PATHNAMES.DASHBOARD ? (
                  <img
                    src="/icons/Dashboard-Main@0,5x.svg"
                    alt="dashboard-icon"
                  />
                ) : (
                  <img src="/icons/Dashboard@0,5x.svg" alt="dashboard-icon" />
                )}
              </LinkWithPathBasedReload>
            }
          >
            <LinkWithPathBasedReload to={PATHNAMES.DASHBOARD}>
              Dashboard
            </LinkWithPathBasedReload>
          </MenuItem>

          {isPregnant ? (
            <MenuItem
              className={location.pathname === PATHNAMES.JOURNEY && "active"}
              icon={
                <LinkWithPathBasedReload to={PATHNAMES.JOURNEY}>
                  {location.pathname === PATHNAMES.JOURNEY ? (
                    <img src="/icons/Flag-Main@0,5x.svg" alt="flag-icon" />
                  ) : (
                    <img src="/icons/Flag@0,5x.svg" alt="flag-icon" />
                  )}
                </LinkWithPathBasedReload>
              }
            >
              <LinkWithPathBasedReload to={PATHNAMES.JOURNEY}>
                My journey
              </LinkWithPathBasedReload>
            </MenuItem>
          ) : (
            <div />
          )}
          <MenuItem
            className={location.pathname === PATHNAMES.SCHEDULING && "active"}
            icon={
              <LinkWithPathBasedReload to={PATHNAMES.SCHEDULING}>
                {location.pathname === PATHNAMES.SCHEDULING ? (
                  <img src="/icons/calendar@0,5x.svg" alt="calendar-icon" />
                ) : (
                  <img
                    src="/icons/calendar_black@0,5x.svg"
                    alt="calendar-icon"
                  />
                )}
              </LinkWithPathBasedReload>
            }
          >
            <LinkWithPathBasedReload to={PATHNAMES.SCHEDULING}>
              Scheduling
            </LinkWithPathBasedReload>
          </MenuItem>

          <MenuItem
            className={location.pathname === PATHNAMES.RESULTS && "active"}
            icon={
              <a
                onClick={async () => {
                  await resetUnseenResults(authenticationStore.userId);
                  history.replace(PATHNAMES.RESULTS);
                }}
              >
                {location.pathname === PATHNAMES.RESULTS ? (
                  <div className="results-nav collapsed">
                    <img
                      src="/icons/Graph-Results-Main@0,5x.svg"
                      alt="flag-icon"
                    />
                    {collapsed && unseenResults && (
                      <div className="new-result-notification">
                        <div className="dot-inside" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="results-nav collapsed">
                    <img src="/icons/Graph-Results@0,5x.svg" alt="flag-icon" />
                    {collapsed && unseenResults && (
                      <div className="new-result-notification">
                        <div className="dot-inside" />
                      </div>
                    )}
                  </div>
                )}
              </a>
            }
          >
            <a
              onClick={async () => {
                await resetUnseenResults(authenticationStore.userId);
                history.replace(PATHNAMES.RESULTS);
              }}
            >
              {
                <div className="results-nav">
                  <span>Results </span>
                  {unseenResults && (
                    <div className="new-result-notification">
                      <div className="dot-inside" />
                    </div>
                  )}
                </div>
              }
            </a>
          </MenuItem>

          <>
            {officeHoursEnable && (
              <MenuItem
                className={
                  location.pathname === PATHNAMES.OFFICE_HOURS && "active"
                }
                icon={
                  <LinkWithPathBasedReload to={PATHNAMES.OFFICE_HOURS}>
                    <i
                      className={`fa-solid fa-people-line office-hours-icon ${
                        location.pathname === PATHNAMES.OFFICE_HOURS
                          ? "office-hours-icon--active"
                          : ""
                      }`}
                    />
                  </LinkWithPathBasedReload>
                }
              >
                <LinkWithPathBasedReload to={PATHNAMES.OFFICE_HOURS}>
                  Office hours
                </LinkWithPathBasedReload>
              </MenuItem>
            )}
          </>
          {monitoringActive ? (
            <MenuItem
              className={location.pathname === PATHNAMES.MONITORING && "active"}
              icon={
                <LinkWithPathBasedReload to={PATHNAMES.MONITORING}>
                  <img
                    src={
                      location.pathname === PATHNAMES.MONITORING
                        ? MonitoringIconActive
                        : MonitoringIcon
                    }
                  />
                </LinkWithPathBasedReload>
              }
            >
              <LinkWithPathBasedReload to={PATHNAMES.MONITORING}>
                Monitoring
              </LinkWithPathBasedReload>
            </MenuItem>
          ) : (
            <div />
          )}
          <MenuItem
            className={location.pathname === PATHNAMES.PERKS && "active"}
            icon={
              <LinkWithPathBasedReload
                to={PATHNAMES.PERKS}
                className="results-nav collapsed"
              >
                <i
                  className="fa-solid fa-gift"
                  style={{ fontSize: "1.1rem", marginLeft: "0.2rem" }}
                />
              </LinkWithPathBasedReload>
            }
          >
            <LinkWithPathBasedReload
              to={"/perks"}
              style={{ marginLeft: ".15rem" }}
            >
              Perks
            </LinkWithPathBasedReload>
          </MenuItem>
          {oulaCircles ? (
            <MenuItem
              icon={
                <a
                  href="https://oulahealth.com/circles/"
                  target="_blank"
                  className="results-nav collapsed"
                >
                  <div
                    className="new-result-notification"
                    style={{
                      paddingBottom: ".3rem",
                      paddingTop: ".15rem",
                      marginBottom: ".3rem",
                      marginLeft: ".3rem",
                    }}
                  >
                    NEW
                  </div>

                  <img src={Circles} width={28} height={28} />
                </a>
              }
            >
              <a href="https://oulahealth.com/circles/" target="_blank">
                Circles
              </a>
            </MenuItem>
          ) : (
            <div />
          )}
        </Menu>

        <Menu iconShape="square" className="logout-sidebar">
          <MenuItem
            icon={
              <a onClick={async () => await logout()}>
                <img src="/icons/Log_out@0,5x.svg" alt="logout-icon" />
              </a>
            }
          >
            <a onClick={async () => await logout()}>Log out</a>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </>
  );
});
