import _map from "lodash/map";
import "./Scale.scss";

const Scale = ({ values, formatedLabel, layoutId, setFieldValue, options }) => {
  const getMaximumValueSelected = (options = []) => {
    for (let i = options.length - 1; i >= 0; i--) {
      const currentAnswer = options[i];

      if (currentAnswer?.id === values) {
        return i;
      }
    }

    return -1;
  };

  const maximumValueSelected = getMaximumValueSelected(options);

  return (
    <div className="scale__container">
      <span className="scale__label">{formatedLabel}</span>

      <div className="scale">
        {options.length &&
          _map(options, (answer, idx) => {
            const { name, id } = answer;

            const classNameSelected =
              idx <= maximumValueSelected ? "scale__selected" : "";
            const dotSelected =
              idx === maximumValueSelected ? "scale__dot--selected" : "";
            const classNamesDot = `scale__dot ${classNameSelected} ${dotSelected}`;
            const classNamesBar = `scale__bar ${classNameSelected}`;

            const onSelectSingleOption = (clickedOptionId) => {
              setFieldValue(layoutId, clickedOptionId);
            };

            return (
              <div className="scale__optionsContainer" key={id}>
                {idx > 0 && <div className={classNamesBar} />}

                <div
                  className={classNamesDot}
                  onClick={() => onSelectSingleOption(id)}
                />

                <span className="scale__description">{name}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Scale;
