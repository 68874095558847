import { journeyStore } from "../stores/journey-store";
import { maxTabletScreenWidth } from "./constants";
import { appointmentStore } from "../stores/appointment-store";
import moment from "moment";
import { authenticationStore } from "../stores/authentication-store";
import { YYYY_MM_DD } from "../constants/dateFormat";

export const findAppointmentCardInJourney = (journey, appointment) => {
  let findAppointment;
  let findIndex = 0;
  for (let i = 0; i < journey?.journey_sections?.length || 0; i++) {
    const journeySection = journey?.journey_sections[i];
    findAppointment = journeySection?.ordered_cards.find((card) => {
      if (card.card_type === "AppointmentCard") {
        return card.card_key === appointment.card_key;
      }
    });
    findIndex = journeySection?.ordered_cards.findIndex((card) => {
      if (card.card_type === "AppointmentCard") {
        return card.card_key === appointment.card_key;
      }
    });
    if (findAppointment) {
      return { ...findAppointment, trimester: i, findIndex: findIndex + 1 };
    }
  }
};

export const findDecisionInJourney = (journey, decision) => {
  let findDecision;
  let findIndex = 0;
  for (let i = 0; i < journey?.journey_sections?.length || 0; i++) {
    const journeySection = journey?.journey_sections[i];
    findDecision = journeySection?.ordered_cards.find((card) => {
      if (card.card_type === "DecisionCard") {
        return card.id == decision.id || card.id == decision.decision_card_id;
      }
    });
    findIndex = journeySection?.ordered_cards.findIndex((card) => {
      if (card.card_type === "DecisionCard") {
        return card.id == decision.id || card.id == decision.decision_card_id;
      }
    });
    if (findDecision) {
      return { ...findDecision, trimester: i, findIndex: findIndex + 1 };
    }
  }
};

export const findEducationInJourney = (journey, education) => {
  let findEducation;
  let findIndex = 0;
  for (let i = 0; i < journey?.journey_sections?.length || 0; i++) {
    const journeySection = journey?.journey_sections[i];
    findEducation = journeySection?.ordered_cards.find((card) => {
      if (card.card_type === "EducationCard") {
        return card.id == education.id;
      }
    });
    findIndex = journeySection?.ordered_cards.findIndex((card) => {
      if (card.card_type === "EducationCard") {
        return card.id == education.id;
      }
    });
    if (findEducation) {
      return { ...findEducation, trimester: i, findIndex: findIndex + 1 };
    }
  }
};

export const scrollToJourneyCard = (cardsContainerRef, card, trimester) => {
  let heightCalculate = 0;
  const scrollTrimester = card?.trimester || trimester;
  const loopEnd = card ? scrollTrimester * 2 + 2 : scrollTrimester * 2 - 1;
  for (let i = 0; i < loopEnd; i++) {
    if (card && scrollTrimester * 2 + 1 === i) {
      let findIndex = 0;
      if (card.card_type === "AppointmentCard") {
        const appointment = findAppointmentCardInJourney(
          journeyStore.journey,
          card
        );
        findIndex = appointment.findIndex;
      }
      if (card.bookmark_card_type === "DecisionCard") {
        const decision = findDecisionInJourney(journeyStore.journey, card);
        findIndex = decision.findIndex;
      }
      if (card.bookmark_card_type === "EducationCard") {
        const education = findEducationInJourney(journeyStore.journey, card);
        findIndex = education.findIndex;
      }
      for (let j = 0; j < findIndex - 1; j++) {
        heightCalculate +=
          cardsContainerRef?.current.children[i].children[j].clientHeight + 15;
      }
      break;
    }
    let margin;
    if (window.innerWidth < maxTabletScreenWidth) {
      if (scrollTrimester < 3) {
        margin = 20;
      } else if (scrollTrimester === 3) {
        margin = 18;
      } else {
        margin = 17;
      }
    } else {
      if (scrollTrimester < 3) {
        margin = 33;
      } else if (scrollTrimester === 3) {
        margin = 28;
      } else {
        margin = 25;
      }
    }

    if (scrollTrimester) {
      heightCalculate +=
        cardsContainerRef?.current?.children[i]?.clientHeight + margin;
    }
  }
  const cardsContainer = document.querySelector("#journey-cards-container");
  cardsContainer.scrollTo({ top: heightCalculate, behavior: "smooth" });
};

export const findNoteInAppointmentsArray = (journeyAppointmentId) => {
  const findAppointment = appointmentStore
    .getScheduledAppointments()
    ?.find((appointment) => appointment.id === journeyAppointmentId);
  return findAppointment?.notes[findAppointment?.notes.length - 1]?.note;
};

export const weekInJourney = (appointmentStartDate) => {
  const calendarToPregnancyWeeks =
    authenticationStore.getCalendarToPregnancyWeeks();
  for (let key in calendarToPregnancyWeeks) {
    if (
      moment(moment(appointmentStartDate).format(YYYY_MM_DD)).isSame(
        moment(key)
      )
    ) {
      return calendarToPregnancyWeeks[key];
    }
    if (
      moment(moment(appointmentStartDate).format(YYYY_MM_DD)).isBefore(
        moment(key)
      )
    ) {
      return calendarToPregnancyWeeks[key] - 1;
    }
  }
  return -1;
};
