import { makeObservable, observable, computed } from "mobx";
import { getSettingsFormOptions } from "../api/helper";

class FormOptionsStore {
  formOptions = undefined;
  loading = false;

  constructor() {
    makeObservable(this, {
      formOptions: observable,
      loading: observable,
    });

    try {
      this.refreshFormOptions();
    } catch (e) {}
  }

  refreshFormOptions = async () => {
    if (this.loading || this.formOptions) {
      return;
    }
    try {
      this.loading = true;
      this.formOptions = await getSettingsFormOptions();
    } catch (e) {
      this.formOptions = undefined;
    } finally {
      this.loading = false;
    }
  };

  @computed
  get formSettingsInfo() {
    if (this.formOptions === undefined) {
      this.refreshFormOptions();
      return;
    }
    return this.formOptions;
  }
}

export const formOptionsStore = new FormOptionsStore();
