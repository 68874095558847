export const getJWTToken = () => {
  return localStorage.getItem("jwt") || sessionStorage.getItem("jwt");
};

export const removeToken = () => {
  localStorage.removeItem("jwt");
  sessionStorage.removeItem("jwt");
};

export const setJWTToken = (token, rememberToken = true) => {
  if (rememberToken) {
    localStorage.setItem("jwt", token);
  } else {
    sessionStorage.setItem("jwt", token);
  }
};
