import { Grid } from "@material-ui/core";
import {
  ChiyoLogo,
  FullyFundedLogo,
  LotusMethodLogo,
  MotherhoodCenterLogo,
  OriginLogo,
  SummerHealthLogo,
} from "./LogoCollection";

const WorkshopsTab = () => {
  return (
    <Grid
      container
      justifyContent={"space-around"}
      direction={"row"}
      style={{ flexGrow: 1 }}
      className={"workshops-parent"}
    >
      <iframe
        src="https://bookwhen.com/oulaperks/iframe"
        seamless={true}
        className={"workshops-embed"}
        title="Workshops"
      ></iframe>
      <div className={"workshops-brand-container"}>
        <Grid container justifyContent={"center"} style={{ margin: "1rem" }}>
          <h3 className={"workshops-brand-tagline"}>
            IN PARTNERSHIP WITH THESE BRANDS WE LOVE!
          </h3>
          <LotusMethodLogo />
          <MotherhoodCenterLogo />
          <FullyFundedLogo />
          <OriginLogo />
          <ChiyoLogo />
          <SummerHealthLogo />
        </Grid>
      </div>
    </Grid>
  );
};

export default WorkshopsTab;
