import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { observer } from "mobx-react-lite";
import { ThemeProvider } from "@material-ui/core/styles";
import _get from "lodash/get";
import useAsyncEffect from "use-async-effect";

import "./MobileHeader.scss";
import { authenticationStore } from "../../stores/authentication-store";
import * as commonMaterial from "../../materialDesignShared";
import { logout } from "../../services/logoutHelper";
import { getDashboard } from "../../api/dashboard";
import { PATHNAMES } from "../../constants/pathnames";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";
import IntercomButton from "../Intercom/IntercomButton";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

export const HeaderTopBar = observer(() => {
  const [visibility, setVisibility] = useState(false);
  const [dashboardData, setDashboardData] = useState(false);
  const user = _get(authenticationStore, "userInfo", {});
  const { is_pregnant, current_week_of_pregnancy, is_postpartum } = user;
  const history = useHistoryWithPathBasedReload();
  const { intercomEverywhere } = useFeatureFlag("intercom-everywhere");
  const enable_message_midwife = _get(dashboardData, "enable_message_midwife");

  useAsyncEffect(async () => {
    const dashboardData = await getDashboard(authenticationStore.userId);
    const fetchedDashboardData = _get(dashboardData, "data.dashboard");

    setDashboardData(fetchedDashboardData);
  }, []);

  return (
    <div style={{ backgroundColor: "white" }} className="headerDivider">
      <ThemeProvider theme={commonMaterial.theme}>
        <div>
          <div className="headerPosition">
            <div className="logoPositionHeader">
              <div
                className="logoHeader"
                onClick={() => history.push(PATHNAMES.DASHBOARD)}
              />
            </div>

            <div
              onClick={() => history.push(PATHNAMES.MY_PROFILE)}
              className={`currentWeekOfPregnancy ${
                !is_pregnant || !current_week_of_pregnancy || is_postpartum
                  ? "currentWeekOfPregnancy--hidden"
                  : ""
              }`}
            >
              {is_pregnant &&
                current_week_of_pregnancy > 0 &&
                `Week ${current_week_of_pregnancy}`}
            </div>

            <div className="bellPersonIcon">
              {(enable_message_midwife || intercomEverywhere) && (
                <IntercomButton />
              )}

              <span className="profileIconContainer">
                <OutsideClickHandler
                  onOutsideClick={() => setVisibility(false)}
                >
                  {visibility && (
                    <div className="profileSelection">
                      <ul>
                        <li>
                          <a href={PATHNAMES.MY_PROFILE}>MY PROFILE</a>
                        </li>
                        <li>
                          <a onClick={async () => await logout()}>LOGOUT</a>
                        </li>
                      </ul>
                    </div>
                  )}
                  <img
                    src="/icons/User@0,5x.svg"
                    alt="user-icon"
                    onClick={() => setVisibility(!visibility)}
                    className="userIcon"
                  />
                </OutsideClickHandler>
              </span>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </div>
  );
});
