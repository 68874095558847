import { Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { ChangeEvent } from "react";
import SelectMultiple from "./QuestionTypes/SelectMultiple";
import SelectOne from "./QuestionTypes/SelectOne";
import SplashScreen from "./QuestionTypes/SplashScreen";
import "./SurveyQuestion.scss";

interface QuestionComponent {
  onChange: (e: ChangeEvent) => void;
  value: string | null;
  name: string;
}

interface MultipleChoiceOption {
  display_name: string;
  answer_value: string;
}

// only allow either text or a custom button component, never both
type ButtonMeta =
  | {
      customButtonText?: string;
      customButtonComponent?: never;
    }
  | {
      customButtonText?: never;
      customButtonComponent?: (
        // will always go to the next page, may also submit the form as well
        performPaginationAction: () => void,
        // the custom button doesn't necessarily have to attend to whether the user has touched the question,
        // eg if the button is paired w/ a splash screen
        allowNext: boolean
      ) => JSX.Element;
    };

type FormMeta = {
  submitsForm?: true;
  overrideTitle?: string;
  outputKey?: string;
} & ButtonMeta;

export type SurveyQuestionProps<T> =
  | ({
      type: "select_one";
      question_text: string;
      options: MultipleChoiceOption[];
    } & FormMeta)
  | ({
      type: "select_multiple";
      question_text: string;
      display_mode: "pill" | "checkbox";
      options: MultipleChoiceOption[];
    } & FormMeta)
  | ({
      type: "text_short";
      question_text: string;
    } & FormMeta)
  // don't require a key for splash_screen since it isn't included in the output
  | ({ type: "splash_screen"; component: (res: T) => JSX.Element } & FormMeta);

export type SurveyQuestionComponent<T> = SurveyQuestionProps<T> &
  QuestionComponent & { surveyRes: T };

const SurveyQuestion = <T extends any>(props: SurveyQuestionComponent<T>) => {
  const { type } = props;

  let questionBody;

  switch (type) {
    case "select_one": {
      questionBody = <SelectOne {...props} />;
      break;
    }
    case "select_multiple": {
      questionBody = <SelectMultiple {...props} />;
      break;
    }
    case "splash_screen": {
      questionBody = <SplashScreen {...props} />;
      break;
    }
    default: {
      questionBody = (
        <Alert severity="error">
          Unknown question type <b>{type}</b>!
        </Alert>
      );
    }
  }
  return (
    <Grid justifyContent="center" style={{ textAlign: "center" }}>
      {"question_text" in props && (
        <Typography
          style={{
            marginBottom: "0.5rem",
            fontSize: "1.15rem",
          }}
        >
          {`${props.question_text}`}
        </Typography>
      )}
      {questionBody}
    </Grid>
  );
};

export default SurveyQuestion;
