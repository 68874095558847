import { Box, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormikContext } from "formik";
import {
  CheckinResponse,
  IndividualCheckinResponse,
} from "../../../../api/mentalHealth";
import { CircleLoader, ClipLoader, PuffLoader } from "react-spinners";
import { useEffect, useState } from "react";

const MentalHealthScore = ({
  res,
  examType,
}: {
  res: CheckinResponse | null | "LOADING" | "ERROR";
  examType: IndividualCheckinResponse["nameOfCheckinMoment"];
}) => {
  const [epdsCommentaryElem, setEpdsCommentaryElem] = useState(
    <Grid container justifyContent="center">
      <ClipLoader color={"#ba624a"} />
    </Grid>
  );

  useEffect(() => {
    const examKeyMap: Record<
      IndividualCheckinResponse["nameOfCheckinMoment"],
      keyof CheckinResponse
    > = {
      INITIAL: "initialCheckin",
      THIRD_TRIMESTER: "thirdTrimesterCheckin",
      POSTPARTUM: "postpartumCheckin",
    };

    if (res === "LOADING" || res === null) return;
    else if (res === "ERROR") {
      setEpdsCommentaryElem(
        <Alert severity="error">
          There was an issue fetching your check-in results!
        </Alert>
      );
      return;
    }

    const epdsResult: IndividualCheckinResponse | null =
      res[examKeyMap[examType]];

    if (epdsResult === null)
      throw new Error("exam result data not found in response object!");

    switch (epdsResult.score) {
      case "NEGATIVE": {
        setEpdsCommentaryElem(
          <Typography variant="body1">
            We will ask you these questions again at multiple points throughout
            your care with us. Do not hesitate to reach out for additional
            support at any time.
          </Typography>
        );
        break;
      }
      case "MILD": {
        setEpdsCommentaryElem(
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ gap: ".5rem" }}
          >
            <Typography variant="body1">
              Anxiety, depression, and other mood disorders affect up to ⅓ of
              pregnant and postpartum people. Depression can present very
              differently in pregnancy and may be associated more with anxiety
              symptoms.
            </Typography>
            <Typography variant="body1">
              Oula&apos;s list of recommended mental health resources can be
              found{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1Re3cZc1k3h1yxOqPaAPOIJrMxXhPFmABHgiABSznJV4/edit?usp=sharing"
                target="__blank"
              >
                here
              </a>
              . Please call us at <a href="tel:718-586-4944">(718) 586-4944</a>{" "}
              if you would like to schedule a Mental Health Consult with one of
              our providers.
            </Typography>
          </Grid>
        );
        break;
      }
      case "MODERATE": {
        setEpdsCommentaryElem(
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ gap: ".5rem" }}
          >
            {" "}
            <Typography variant="body1">
              Anxiety, depression, and other mood disorders affect up to ⅓ of
              pregnant and postpartum people. Depression can present very
              differently in pregnancy and may be associated more with anxiety
              symptoms.
            </Typography>
            <Typography variant="body1">
              Oula&apos;s list of recommended mental health resources can be
              found{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1Re3cZc1k3h1yxOqPaAPOIJrMxXhPFmABHgiABSznJV4/edit?usp=sharing"
                target="__blank"
              >
                here
              </a>
              . Please call us at <a href="tel:718-586-4944">(718) 586-4944</a>{" "}
              if you would like to schedule a Mental Health Consult with one of
              our providers.
            </Typography>
          </Grid>
        );
        break;
      }
      case "SEVERE": {
        setEpdsCommentaryElem(
          <Grid
            container
            direction="column"
            alignItems="center"
            style={{ gap: ".5rem" }}
          >
            <Typography variant="body1">
              Anxiety, depression, and other mood disorders affect up to ⅓ of
              pregnant and postpartum people. Depression can present very
              differently in pregnancy and may be associated more with anxiety
              symptoms.
            </Typography>
            <Typography variant="body1">
              Oula&apos;s list of recommended mental health resources can be
              found{" "}
              <a
                href="https://docs.google.com/spreadsheets/d/1Re3cZc1k3h1yxOqPaAPOIJrMxXhPFmABHgiABSznJV4/edit?usp=sharing"
                target="__blank"
              >
                here
              </a>
              . Please call us at <a href="tel:718-586-4944">(718) 586-4944</a>{" "}
              if you would like to schedule a Mental Health Consult with one of
              our providers.
            </Typography>
          </Grid>
        );
      }
    }
  }, [res]);

  return (
    <Grid
      container
      style={{ textAlign: "center", gap: "1rem" }}
      direction="column"
      alignItems="center"
    >
      <Typography style={{ fontWeight: "bold" }}>
        Thanks for taking the time to check in!
      </Typography>
      <Box className="modal-splash-block">
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          {epdsCommentaryElem}
        </Typography>
      </Box>
    </Grid>
  );
};

export default MentalHealthScore;
