import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { CircularProgress, FormControl, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { Check, ExpandMore } from "@material-ui/icons";
import useAsyncEffect from "use-async-effect";
import { observer } from "mobx-react-lite";

import * as commonMaterial from "../../../materialDesignShared";
import { CustomButton } from "../../../materialDesignShared";
import { authenticationStore } from "../../../stores/authentication-store";
import { itemsKeyValue } from "../../../services/formSettings";
import { getProfile, updateProfile } from "../../../api/profile";
import { formOptionsStore } from "../../../stores/form-options-store";
import {
  findErrorByPath,
  validateYupSchema,
} from "../../../services/errorHandlers";
import {
  formatPhoneText,
  formatZipText,
} from "../../../helpers/validationHelpers";

const ProfileContact = observer(({ setUnsaved }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [savedFields, setSavedFields] = useState([]);
  const [settings, setSettings] = useState({});
  // 0: no activity
  // 1: loading
  // 2: success
  // 3: error
  const [saveState, setSaveState] = useState(0);

  useEffect(() => {
    if (savedFields.length > 0) setUnsaved(true);
    else setUnsaved(false);
  }, [savedFields]);

  useAsyncEffect(async () => {
    const { data } = await getProfile(authenticationStore.userId);
    setCurrentUser({
      email: data.patient.email || "",
      cell_phone: data.patient.cell_phone || "",
      address1: data.patient.address1 || "",
      address2: data.patient.address2 || "",
      city: data.patient.city || "",
      state: data.patient.state || "",
      zip: data.patient.zip || "",
    });

    setSettings(formOptionsStore.formSettingsInfo);
  }, []);

  const saveData = async (values) => {
    try {
      const newData = {
        email: currentUser.email,
        cell_phone: values.cell_phone.replaceAll("-", "") || null,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zip || null,
      };
      await updateProfile(authenticationStore.userId, newData);
      setCurrentUser(newData);
      setSavedFields([]);
      setSaveState(2);
      // reset after 2 seconds
      setTimeout(() => setSaveState(0), 2000);
    } catch (err) {
      setSaveState(3);
      console.error(err);
      setTimeout(() => setSaveState(0), 2000);
    }
  };

  // const handleFocus = async (values) => {
  //   try {
  //     if (JSON.stringify(values) !== JSON.stringify(currentUser)) {
  //       // setCurrentUser({ ...values });
  //       if (
  //         (values.zip.toString().length === 5 ||
  //           values.zip.toString() === "") &&
  //         (values.cell_phone.toString().replaceAll("-", "").length === 10 ||
  //           values.cell_phone.toString().replaceAll("-", "") === "")
  //       ) {
  //         const diffFields = diff(values, currentUser);
  //         diffFields?.map((d) => {
  //           if (d.kind === "E") {
  //             setSavedFields([...savedFields, d.path[0]]);
  //           }
  //         });
  //       }
  //     }
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const validationSchema = Yup.object().shape({
    zip: Yup.string().test(
      "empty-or-5-characters-check",
      "Zip must be exactly 5 characters",
      (zip) => !zip || zip.length === 5
    ),
    cell_phone: Yup.string().test(
      "empty-or-10-characters-check",
      "Cell phone must be exactly 10 characters",
      (cell_phone) =>
        !cell_phone.replaceAll("-", "") ||
        cell_phone.replaceAll("-", "").length === 10
    ),
  });

  const handleDiff = (e, target) => {
    const val = e.target.value;
    const oldVal = currentUser[target];
    const changed = val !== oldVal;
    const changedIndex = savedFields.indexOf(target);
    if (changed && changedIndex === -1) {
      setSavedFields([...savedFields, target]);
    } else if (changed === false && changedIndex !== -1) {
      const newSavedFields = savedFields.filter((field) => field !== target);
      setSavedFields(newSavedFields);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: currentUser.email || "",
        address1: currentUser.address1 || "",
        address2: currentUser.address2 || "",
        cell_phone: currentUser.cell_phone || "",
        city: currentUser.city || "",
        state: currentUser.state || "",
        zip: currentUser.zip || "",
      }}
      validate={(values) => validateYupSchema(validationSchema, values)}
      onSubmit={saveData}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors = [],
        handleBlur,
        setFieldTouched,
        submitForm,
      }) => {
        return (
          <div className="form-wrapper">
            <form className="formStyle" onSubmit={submitForm}>
              <commonMaterial.CustomTextField
                disabled
                margin="normal"
                fullWidth
                onBlur={handleBlur}
                value={values.email}
                id="email"
                type="email"
                label="EMAIL"
                onChange={handleChange}
                onInput={() => setFieldTouched("email", true, true)}
              />
              <FormControl fullWidth>
                <commonMaterial.CustomTextField
                  id="cell_phone"
                  error={!!findErrorByPath(errors, "cell_phone")}
                  onBlur={handleBlur}
                  value={formatPhoneText(values.cell_phone)}
                  color="primary"
                  margin="normal"
                  className={
                    savedFields.indexOf("cell_phone") > -1
                      ? "profile-field-changed"
                      : ""
                  }
                  onChange={(e) => {
                    handleChange(e);
                    handleDiff(e, "cell_phone");
                  }}
                  label="PHONE NUMBER"
                  type="tel"
                  pattern="^[\d ()+-]+$"
                  onInput={() => setFieldTouched("cell_phone", true, true)}
                  onKeyPress={(e) => {
                    const keyCode = e.keyCode || e.which;
                    const keyValue = String.fromCharCode(keyCode);
                    if (!/^[\d ()+-]+$/.test(keyValue)) e.preventDefault();
                  }}
                />
              </FormControl>
              <FormControl fullWidth>
                <commonMaterial.CustomTextField
                  id="address1"
                  onBlur={handleBlur}
                  value={values.address1}
                  color="primary"
                  margin="normal"
                  onChange={(e) => {
                    handleChange(e);
                    handleDiff(e, "address1");
                  }}
                  className={
                    savedFields.indexOf("address1") > -1
                      ? "profile-field-changed"
                      : ""
                  }
                  label="ADDRESS LINE 1"
                  onInput={() => setFieldTouched("address1", true, true)}
                />
              </FormControl>
              <FormControl fullWidth>
                <commonMaterial.CustomTextField
                  id="address2"
                  onBlur={handleBlur}
                  value={values.address2}
                  color="primary"
                  margin="normal"
                  className={
                    savedFields.indexOf("address2") > -1
                      ? "profile-field-changed"
                      : ""
                  }
                  onChange={(e) => {
                    handleChange(e);
                    handleDiff(e, "address2");
                  }}
                  label="ADDRESS LINE 2"
                  onInput={() => setFieldTouched("address2", true, true)}
                />
              </FormControl>
              <div className="selectionRow">
                <div className="selectionRowElement" style={{ flex: 8 }}>
                  <commonMaterial.CustomTextField
                    id="city"
                    onBlur={handleBlur}
                    value={values.city}
                    color="primary"
                    margin="normal"
                    className={
                      savedFields.indexOf("city") > -1
                        ? "profile-field-changed"
                        : ""
                    }
                    onChange={(e) => {
                      handleChange(e);
                      handleDiff(e, "city");
                    }}
                    label="CITY"
                    onInput={() => setFieldTouched("city", true, true)}
                  />
                </div>
                <div className="selectionRowElement" style={{ flex: 4 }}>
                  <commonMaterial.CustomTextField
                    id="zip"
                    onBlur={handleBlur}
                    value={formatZipText(values.zip)}
                    type="number"
                    color="primary"
                    margin="normal"
                    className={
                      savedFields.indexOf("zip") > -1
                        ? "profile-field-changed"
                        : ""
                    }
                    onKeyDown={(evt) => evt.key === "e" && evt.preventDefault()}
                    onChange={(e) => {
                      handleChange(e);
                      handleDiff(e, "zip");
                    }}
                    label="ZIP CODE"
                  />
                </div>
              </div>
              <div className="selectionRow" style={{ marginTop: "-20px" }}>
                <div className="selectionRowElement" style={{ flex: 0.5 }}>
                  <FormControl name="pronoun">
                    <InputLabel id="state">STATE</InputLabel>
                    <Select
                      labelId="state"
                      label="state"
                      id="state"
                      name="state"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      value={values.state}
                      onChange={(e) => {
                        handleChange(e);
                        handleDiff(e, "state");
                      }}
                      IconComponent={ExpandMore}
                      onInput={() => setFieldTouched("state", true, true)}
                      className={
                        savedFields.indexOf("state") > -1
                          ? "profile-field-changed"
                          : ""
                      }
                    >
                      {itemsKeyValue(settings?.data, "state")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <CustomButton
                fullWidth={true}
                className={`profile-save-button ${
                  saveState === 3 && "profile-save-error"
                }`}
                disabled={savedFields.length <= 0}
                onClick={() => {
                  handleSubmit();
                  setSaveState(1);
                }}
                style={{ marginBottom: "1rem" }}
              >
                {saveState === 0 && "Save"}
                {saveState === 1 && (
                  <CircularProgress color={"#FFF"} size={24} />
                )}
                {saveState === 2 && <Check size={24} />}
                {saveState === 3 && "Error saving!"}
              </CustomButton>
            </form>
          </div>
        );
      }}
    </Formik>
  );
});

export default ProfileContact;
