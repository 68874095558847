import { Box } from "@material-ui/core";
import { ReactElement, useState } from "react";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";
import birthSmarter from "./brandLogos/birth_smarter.png";
import byheart from "./brandLogos/byheart.png";
import chiyo from "./brandLogos/chiyo.png";
import coterie from "./brandLogos/coterie.png";
import glowbar from "./brandLogos/glowbar.png";
import hatch from "./brandLogos/hatch.png";
import healthyBaby from "./brandLogos/healthy-baby.png";
import ipsa from "./brandLogos/ipsa.webp";
import origin from "./brandLogos/origin.png";
import oula from "./brandLogos/oula.png";
import prenatalYogaCenter from "./brandLogos/prenatal-yoga-center.png";
import revolution from "./brandLogos/revolution-motherhood.png";
import seedMother from "./brandLogos/seed-mother.png";
import summerHealth from "./brandLogos/summer-health.png";
import lotus from "./brandLogos/the-lotus-method.png";
import motherhoodCenter from "./brandLogos/the-motherhood-center.png";
import unionSquarePlay from "./brandLogos/union_square_play.png";
import pom from "./brandLogos/pom.png";

interface PerkProps {
  logoUrl: string;
  logoAltText: string;
  link: string;
  tagline: string;
  code: string;
  endTag: ReactElement;
  // for some reason, some logos doesn't play nice w/ the brightness filter we apply to the logos
  noFilter?: boolean;
}

const Perk = ({
  logoUrl,
  logoAltText,
  tagline,
  code,
  endTag,
  link,
  noFilter,
}: PerkProps) => {
  // 0: start state, 1: successfully copied, 2: error copying
  const [copyState, setCopyState] = useState(0);

  const copyCode = (code: string): void => {
    const type = "text/plain";
    const blob = new Blob([code], { type });
    const data = [new ClipboardItem({ [type]: blob })];

    navigator.clipboard
      .write(data)
      .then(
        () => {
          setCopyState(1);
        },
        () => {
          setCopyState(2);
        }
      )
      .finally(() => {
        setTimeout(() => setCopyState(0), 3000);
      });
  };

  return (
    <div className={"perk-parent"}>
      <div className={"perk-body"}>
        <a href={link} target={"_blank"} rel="noreferrer">
          <div className={"perk-body-upper"}>
            <img
              src={logoUrl}
              alt={logoAltText}
              style={{
                ...{ height: "2.2rem" },
                ...(noFilter && { filter: "none" }),
              }}
            />
          </div>
        </a>
        <div className={"perk-body-lower"}>
          {tagline}
          <div>
            <div className={"perk-code-title"}>Code</div>
            <button
              onClick={() => copyCode(code)}
              className={`perk-code-button ${
                copyState > 0 && "perk-code-button-pressed"
              }`}
            >
              {copyState === 0 && code}
              {copyState === 1 && "Copied!"}
              {copyState === 2 && "Error writing to clipboard!"}
            </button>
          </div>
        </div>
      </div>
      <div className={"perk-end"}>{endTag}</div>
    </div>
  );
};

const perks: PerkProps[] = [
  {
    logoUrl: pom,
    logoAltText: "Pom Health",
    link: "https://www.pomhealth.co/",
    tagline: "Bringing the joys of food into your health ",
    code: "OULA",
    endTag: (
      <div>
        <span className="perk-end-bold">FREE</span>2 months of membership
      </div>
    ),
  },
  {
    logoUrl: prenatalYogaCenter,
    logoAltText: "Prenatal Yoga Center",
    link: "https://prenatalyogacenter.com/",
    tagline: "Prepare your body & mind for an easier birth & recovery",
    code: "OulaPerks",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>15% off </span>
        an in-person or virtual class
      </div>
    ),
  },
  {
    logoUrl: seedMother,
    logoAltText: "Seed Mother",
    link: "https://www.seed-mother.com/",
    tagline: "Matrescence classes for the transition to motherhood",
    code: "Oula20",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>20% off </span>
        all workshops
      </div>
    ),
  },
  {
    logoUrl: revolution,
    logoAltText: "Revolution Motherhood",
    link: "https://ondemand.revolutionmotherhood.com/checkout/subscribe/purchase?code=oulamama&plan=monthly",
    tagline: "Life elevating fitness and women's health ",
    code: "OULAMAMA",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>1 month free</span>
        membership to the RM App
      </div>
    ),
  },
  {
    logoUrl: healthyBaby,
    logoAltText: "Healthybaby",
    link: "https://healthybaby.com/",
    tagline: "Babycare for a better tomorrow",
    code: "OULALOVE",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>15% off</span>
        first time purchase of product or subscription
      </div>
    ),
  },
  {
    logoUrl: unionSquarePlay,
    logoAltText: "Union Square Play",
    link: "https://unionsquareplay.com/",
    tagline: "A joyful play space making parenting easier",
    code: "WelcomeOula",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>1st class</span>
        free at any location
      </div>
    ),
  },
  {
    logoUrl: lotus,
    logoAltText: "The Lotus Method",
    link: "https://thelotusmethod.com/",
    tagline: "Fitness designed for pregnancy & postpartum",
    code: "Mention Oula",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>1st class</span>free
      </div>
    ),
  },
  {
    logoUrl: birthSmarter,
    logoAltText: "Birthsmarter",
    link: "https://birthsmarter.com/",
    tagline: "Non-judgmental childbirth education and support",
    code: "OULAPP24",
    // noFilter: true,
    endTag: (
      <div>
        <span className={"perk-end-bold"}>FREE</span>
        virtual or in-person postpartum class
      </div>
    ),
  },
  {
    logoUrl: hatch,
    logoAltText: "Hatch",
    link: "https://www.hatchcollection.com/",
    tagline: "Maternity clothing for every stage of pregnancy.",
    code: "OULA20",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>20% off</span>all orders
      </div>
    ),
  },
  {
    logoUrl: byheart,
    logoAltText: "ByHeart",
    link: "https://byheart.com/",
    tagline: "A next generation infant formula company",
    code: "BHxOula2024",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>50% off</span>
        your first purchase of 2 or more cans
      </div>
    ),
    noFilter: true,
  },
  {
    logoUrl: glowbar,
    logoAltText: "Glowbar",
    link: "https://www.glowbar.com/",
    tagline: "Custom 30-minute facials by licensed estheticians",
    code: "OULAGLOW",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>$10 off</span>
        your first treatment at any Glowbar location
      </div>
    ),
    noFilter: true,
  },
  {
    logoUrl: summerHealth,
    logoAltText: "Summer Health",
    link: "https://www.summerhealth.com/",
    tagline: "24/7 pediatricians in your pocket.",
    code: "OULA",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>1st</span>month free
      </div>
    ),
  },
  {
    logoUrl: ipsa,
    logoAltText: "Ipsa",
    link: "https://eatipsa.com/",
    tagline: "Artisanal frozen food, delivered.",
    code: "OULA25",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>$25 off</span>your first order of $95+
      </div>
    ),
  },
  {
    logoUrl: motherhoodCenter,
    logoAltText: "Motherhood Center",
    link: "https://themotherhoodcenter.com/",
    tagline: "Perinatal mental health center",
    code: "OULATMC",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>20% off</span>support groups
      </div>
    ),
    noFilter: true,
  },
  {
    logoUrl: coterie,
    logoAltText: "Coterie",
    link: "https://www.coterie.com/",
    tagline: "High-performing, sustainable diapers and wipes",
    code: "OULA20",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>20% off</span>your first month (for
        first-time subscribers only)
      </div>
    ),
    noFilter: true,
  },
  {
    logoUrl: chiyo,
    logoAltText: "Chiyo",
    link: "https://wearechiyo.com/",
    tagline: "Eastern food therapy meets nutritional science",
    code: "OULAFRIENDS",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>$50 off</span>any nutrition program
      </div>
    ),
  },
  {
    logoUrl: origin,
    logoAltText: "Origin",
    link: "https://www.theoriginway.com/",
    tagline: "Pelvic floor & whole body physical therapy",
    code: "30OULA",
    endTag: (
      <div>
        <span className={"perk-end-bold"}>$30 off</span>a virtual evaluation
        with Origin
      </div>
    ),
  },
];

const PerksTab = () => {
  const { oulaCircles } = useFeatureFlag("oula-circles");
  const oulaPerk: PerkProps = {
    logoUrl: oula,
    logoAltText: "Oula Health",
    link: "https://oulahealth.com/circles/",
    tagline: "Delivering a support system of other postpartum moms",
    code: "CIRCLES50",
    endTag: (
      <Box>
        <span className="perk-end-bold">$50 off</span> Oula&apos;s Postpartum
        Circles
      </Box>
    ),
  };
  let perksWithCirclesFeatureFlagged = perks;
  if (oulaCircles)
    perksWithCirclesFeatureFlagged = [perks[0], oulaPerk, ...perks.slice(1)];

  return (
    <div className={"perks-list"}>
      {perksWithCirclesFeatureFlagged.map((p) => Perk(p))}
    </div>
  );
};

export default PerksTab;
