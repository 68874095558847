import { retryRequest } from "../services/requestHelpers";
import { authenticationStore } from "../stores/authentication-store";

export const getCookie = () => {
  return retryRequest({
    method: "get",
    url: "/api/ping",
    withCredentials: "true",
  });
};

export const getClientId = () => {
  return retryRequest({
    method: "get",
    url: "/api/v1/google_settings.json",
    withCredentials: "true",
  });
};

export const logout = () => {
  return retryRequest(
    {
      method: "delete",
      url: "/api/logout.json",
      withCredentials: "true",
    },
    true
  );
};

export const refreshToken = async () => {
  const refreshToken = authenticationStore.getRefreshToken();

  const {
    data: { jwt, refresh_token },
  } = await retryRequest({
    method: "post",
    url: "/api/refresh_token.json",
    data: {
      api_user: {
        id: authenticationStore.userId,
        refresh_token: refreshToken,
      },
    },
  });
  authenticationStore.setJWTToken(jwt);
  authenticationStore.setRefreshToken(refresh_token);
};

export const register = (
  email,
  password,
  externalAppointmentId,
  appointmentTypeId
) => {
  return retryRequest({
    method: "post",
    url: "/api/v1/register.json",
    data: {
      api_user: {
        email,
        password,
        external_appointment_id: externalAppointmentId,
        appointment_type_id: appointmentTypeId,
      },
    },
  });
};

export const login = (email, password) => {
  return retryRequest({
    method: "post",
    url: "/api/login.json",
    data: { api_user: { email, password } },
  });
};

export const googleLogin = (
  idToken,
  externalAppointmentId,
  appointmentTypeId
) => {
  return retryRequest({
    method: "post",
    url: "/api/google_oauth.json",
    data: {
      auth: {
        id_token: idToken,
        external_appointment_id: externalAppointmentId,
        appointment_type_id: appointmentTypeId,
      },
    },
  });
};

export const forgotPassword = (email) => {
  return retryRequest({
    method: "post",
    url: "/api/v1/password.json",
    data: {
      api_user: {
        email: email,
      },
      commit: "Resend instructions",
    },
  });
};

export const resendConfirmation = (email) => {
  return retryRequest({
    method: "post",
    url: "/api/v1/confirmation.json",
    data: {
      api_user: {
        email: email,
      },
      commit: "Resend instructions",
    },
  });
};

export const resetPassword = (password, verifyPassword, passwordToken) => {
  return retryRequest({
    method: "put",
    url: "/api/v1/password.json",
    data: {
      password: password,
      password_confirmation: verifyPassword,
      reset_password_token: passwordToken,
    },
  });
};

export const getAuthUser = () => {
  return retryRequest(
    {
      method: "get",
      url: "/api/v1/auth/me",
    },
    true,
    false,
    true
  );
};
