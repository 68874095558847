import { CheckinResponse } from "../../../../api/mentalHealth";
import { SurveyQuestionProps } from "../../../ModalSurvey/SurveyQuesiton";

const epds3Questions: SurveyQuestionProps<CheckinResponse>[] = [
  {
    type: "select_one",
    outputKey: "epds3_1",
    question_text: "I have blamed myself unnecessarily when things went wrong",
    options: [
      { display_name: "Yes, most of the time", answer_value: "3" },
      { display_name: "Yes, some of the time", answer_value: "2" },
      { display_name: "Not very often", answer_value: "1" },
      { display_name: "No, never", answer_value: "0" },
    ],
  },
  {
    type: "select_one",
    outputKey: "epds3_2",
    question_text: "I have been anxious or worried for no good reason",
    options: [
      { display_name: "Yes, very often", answer_value: "3" },
      { display_name: "Yes, sometimes", answer_value: "2" },
      { display_name: "Hardly ever", answer_value: "1" },
      { display_name: "No, not at all", answer_value: "0" },
    ],
  },
  {
    type: "select_one",
    outputKey: "epds3_3",
    question_text: "I have felt scared or panicky for no good reason",
    submitsForm: true,
    options: [
      { display_name: "Yes, quite a lot", answer_value: "3" },
      { display_name: "Yes, sometimes", answer_value: "2" },
      { display_name: "No, not much", answer_value: "1" },
      { display_name: "No, not at all", answer_value: "0" },
    ],
  },
];

export default epds3Questions;
