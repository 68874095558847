import "./Logo.scss";

export const Logo = (props) => {
  return (
    <div>
      <div className="logo" />
      <div className="myPregnancy" align="center">
        MY PREGNANCY
      </div>
    </div>
  );
};
