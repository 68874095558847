import { useEffect, useState } from "react";
import * as commonMaterial from "../../materialDesignShared";
import { Container, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import "react-toggle/style.css";

import "./Profile.scss";
import ProfilePersonal from "./ProfilePersonal";
import ProfileContact from "./ProfileContact";
import ProfilePharmacy from "./ProfilePharmacy";
import ProfileBilling from "./ProfileBilling";
import Alert from "@material-ui/lab/Alert";

export const Profile = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  // if the user has made changes, prevent them from changing tabs until they save
  const [unsaved, setUnsaved] = useState(false);
  const [showUnsavedAlert, setShowUnsavedAlert] = useState(false);

  const tabNames = ["Personal", "Contact", "Pharmacy", "Billing"];

  useEffect(() => {
    if (unsaved === false) setShowUnsavedAlert(false);
  }, [unsaved, showUnsavedAlert]);
  const handleTabChange = (index) => {
    if (unsaved) {
      if (showUnsavedAlert === false) setShowUnsavedAlert(true);
    } else {
      setActiveTabIndex(index);
      setShowUnsavedAlert(false);
    }
  };

  return (
    <div className="profile-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Container className="form-container">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <div className="profile">
              <div className="myProfile">My profile</div>

              <Grid
                container
                spacing={0}
                className="boxTabContainer profileToggleContainer"
                style={{ marginBottom: "1rem" }}
              >
                {tabNames.map((tabName, index) => (
                  <Grid item xs={3}>
                    <div
                      onClick={() => handleTabChange(index)}
                      className={
                        activeTabIndex === index
                          ? "profileActiveBoxTab"
                          : "profileBoxTab"
                      }
                    >
                      {tabName}
                    </div>
                  </Grid>
                ))}
              </Grid>

              {showUnsavedAlert ? (
                <Alert severity={"error"} style={{ marginBottom: "1rem" }}>
                  You have unsaved changes!
                </Alert>
              ) : null}

              {activeTabIndex === 0 && (
                <ProfilePersonal setUnsaved={setUnsaved} />
              )}
              {activeTabIndex === 1 && (
                <ProfileContact setUnsaved={setUnsaved} />
              )}
              {activeTabIndex === 2 && (
                <ProfilePharmacy setUnsaved={setUnsaved} />
              )}
              {activeTabIndex === 3 && (
                <ProfileBilling setUnsaved={setUnsaved} />
              )}
            </div>
          </Grid>
        </Container>
        <div className="profile-image-container" />
      </ThemeProvider>
    </div>
  );
};
