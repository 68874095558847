import qs from "qs";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";

import { Grid } from "@material-ui/core";
import { ErrorBoundary } from "@sentry/react";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { PATHNAMES } from "../../constants/pathnames";
import { useAuth } from "../../providers/Auth";
import {
  FeatureFlagProvider,
  useFeatureFlag,
} from "../../providers/LaunchDarklyProvider";
import { authenticationStore } from "../../stores/authentication-store";
import ErrorFallback from "../App/ErrorFallback";
import { IdleHandler } from "../App/IdleHandler";
import { Contact } from "../Contact/Contact";
import { Dashboard } from "../Dashboard/Dashboard";
import Feedback from "../Feedback";
import { ForgotPassword } from "../ForgotPassword/ForgotPassword";
import { Journey } from "../Journey/Journey";
import Loading from "../LoadingLayout/Loading";
import LoadingLayout from "../LoadingLayout/LoadingLayout";
import { LogIn } from "../LogIn/LogIn";
import { SignInWithGoogleProvider } from "../LogIn/SignInWithGoogleProvider";
import Monitoring from "../Monitoring/Monitoring";
import OfficeHours from "../OfficeHours";
import OfficeHoursDetails from "../OfficeHours/OfficeHoursDetails";
import Perks from "../Perks";
import { Profile } from "../Profile/Profile";
import { ResendConfirmation } from "../ResendConfirmation/ResendConfirmation";
import { ResetPassword } from "../ResetPassword/ResetPassword";
import { Results } from "../Results/Results";
import Book from "../Scheduling/Book";
import { Booked } from "../Scheduling/Location/Booked";
import { Confirmation } from "../Scheduling/Location/Confirmation";
import Departments from "../Scheduling/Location/Departments";
import IntakeForm from "../Scheduling/Location/IntakeForm";
import { Location } from "../Scheduling/Location/Location";
import { RescheduleAppointment } from "../Scheduling/Location/RescheduleAppointment";
import { Scheduling } from "../Scheduling/Scheduling";
import { SchedulingPublic } from "../Scheduling/SchedulingPublic";
import SignUpWrapper from "../SignUpWrapper";
import { TellUsABitMore } from "../TellUsABitMore/TellUsABitMore";
import UnknownRoute from "../UnknownRoute/UnknownRoute";
import { Wrapper } from "../Wrapper/Wrapper";
import { WrapperPublic } from "../Wrapper/WrapperPublic";

const PrivateRoute = ({
  excludeWrapper,
  component: Component,
  exact,
  strict,
  path,
  allowedUserTypes,
  ...rest
}) => {
  const { user, isAuthenticated } = useAuth();
  const minimumProfileCompleted =
    user?.patient_attributes?.minimum_profile_completed;
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return (
    <Route
      exact={exact}
      strict={strict}
      path={path}
      render={(props) => {
        if (window.location.pathname === PATHNAMES.BOOK_NOW) {
          authenticationStore.removeRedirectScheduling();
          authenticationStore.removeRedirectNextAppointment();
          authenticationStore.removeRedirectDashboardChat();
        }
        if (
          isAuthenticated &&
          minimumProfileCompleted &&
          authenticationStore.getRedirectScheduling() === "true"
        ) {
          authenticationStore.removeRedirectScheduling();
          return (
            <Redirect
              to={{
                pathname: PATHNAMES.SCHEDULING,
                state: { from: props.location },
              }}
            />
          );
        }
        if (
          isAuthenticated &&
          minimumProfileCompleted &&
          authenticationStore.getRedirectNextAppointment() === "true"
        ) {
          authenticationStore.removeRedirectNextAppointment();
          return (
            <Redirect
              to={{
                pathname: PATHNAMES.NEXT_APPOINTMENT,
                state: { from: props.location },
              }}
            />
          );
        }
        if (
          isAuthenticated &&
          minimumProfileCompleted &&
          authenticationStore.getRedirectDashboardChat() === "true"
        ) {
          authenticationStore.removeRedirectDashboardChat();
          return (
            <Redirect
              to={{
                pathname: `${PATHNAMES.DASHBOARD}/?chat`,
                state: { from: props.location },
              }}
            />
          );
        }
        if (
          isAuthenticated &&
          minimumProfileCompleted &&
          window.location.pathname === "/"
        ) {
          return (
            <Redirect
              to={{
                pathname: PATHNAMES.DASHBOARD,
                state: { from: props.location },
              }}
            />
          );
        }
        if (isAuthenticated && minimumProfileCompleted) {
          return excludeWrapper ? (
            <Component {...props} {...rest} />
          ) : (
            <Wrapper>
              <Component {...props} {...rest} />
            </Wrapper>
          );
        }
        if (
          isAuthenticated &&
          !minimumProfileCompleted &&
          window.location.pathname === PATHNAMES.TELL_US_A_BIT
        ) {
          return excludeWrapper ? (
            <Component {...props} {...rest} />
          ) : (
            <Wrapper>
              <Component {...props} {...rest} />
            </Wrapper>
          );
        }
        if (
          isAuthenticated &&
          !minimumProfileCompleted &&
          window.location.pathname !== PATHNAMES.TELL_US_A_BIT
        ) {
          return (
            <Redirect
              to={{
                pathname: PATHNAMES.TELL_US_A_BIT,
                state: { from: props.location },
              }}
            />
          );
        }
        if (!isAuthenticated) {
          if (window.location.pathname === PATHNAMES.SCHEDULING) {
            authenticationStore.setRedirectToScheduling(true);
          }
          if (window.location.pathname === PATHNAMES.NEXT_APPOINTMENT) {
            authenticationStore.setRedirectToNextAppointment(true);
          }
          if (
            window.location.pathname === PATHNAMES.DASHBOARD &&
            query.chat === ""
          ) {
            authenticationStore.setRedirectToDashboardChat(true);
          }
          return (
            <Redirect
              to={{
                pathname: authenticationStore.redirectLogout
                  ? authenticationStore.redirectLogout
                  : PATHNAMES.LOGIN,
                state: { from: props.location },
                search: `redirect_auth=${props.location.pathname}${props.location.search}`,
              }}
            />
          );
        }
      }}
    />
  );
};

export const Navigator = observer(() => {
  const queryParam = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  return (
    <Router>
      <ErrorBoundary
        fallback={(p) => (
          <Grid container style={{ width: "100vw", height: "100vh" }}>
            <ErrorFallback {...p} />
          </Grid>
        )}
      >
        <LoadingLayout>
          <IdleHandler />
          <Switch>
            <PrivateRoute
              path={PATHNAMES.MONITORING}
              component={() => <Monitoring />}
            />
            <PrivateRoute path={PATHNAMES.PERKS} component={() => <Perks />} />
            <PrivateRoute
              excludeWrapper
              path={PATHNAMES.TELL_US_A_BIT}
              component={() => <TellUsABitMore />}
            />
            <PrivateRoute
              path={PATHNAMES.MY_PROFILE}
              component={() => <Profile />}
            />
            <PrivateRoute
              path={PATHNAMES.JOURNEY}
              component={() => <Journey />}
            />
            <Route
              path={PATHNAMES.JOURNEY_DEMO}
              component={() => (
                <WrapperPublic fullBackground>
                  <Journey publicView />
                </WrapperPublic>
              )}
            />
            <PrivateRoute
              path={PATHNAMES.DASHBOARD}
              component={() => <Dashboard />}
            />
            <PrivateRoute
              path={PATHNAMES.SCHEDULING}
              component={() => <Scheduling />}
            ></PrivateRoute>
            <PrivateRoute
              path={PATHNAMES.SCHEDULING_BOOK}
              component={() => <Book />}
            />
            <PrivateRoute
              path={PATHNAMES.RESULTS}
              component={() => <Results />}
            />
            <PrivateRoute
              path={`${PATHNAMES.OFFICE_HOURS}/:type`}
              component={() => <OfficeHoursDetails />}
            />
            <PrivateRoute
              path={PATHNAMES.OFFICE_HOURS}
              component={() => <OfficeHours />}
            />
            <Route
              path={PATHNAMES.BOOK_NOW}
              component={() => (
                <WrapperPublic>
                  <SchedulingPublic />
                </WrapperPublic>
              )}
            />
            <Route
              path={PATHNAMES.LOCATION}
              component={() =>
                authenticationStore.userId &&
                authenticationStore.getTaggedAppointment() === null ? (
                  <Wrapper>
                    <Location />
                  </Wrapper>
                ) : (
                  <WrapperPublic>
                    <Location />
                  </WrapperPublic>
                )
              }
            />
            <Route
              path={PATHNAMES.DEPARTMENTS}
              component={() =>
                authenticationStore.userId &&
                authenticationStore.getTaggedAppointment() === null ? (
                  <Wrapper>
                    <Departments />
                  </Wrapper>
                ) : (
                  <WrapperPublic>
                    <Departments />
                  </WrapperPublic>
                )
              }
            />
            <PrivateRoute
              path={PATHNAMES.VIEW_APPOINTMENT}
              component={() => <RescheduleAppointment />}
            />
            <PrivateRoute
              path={PATHNAMES.NEXT_APPOINTMENT}
              component={() => <RescheduleAppointment nextAppointment />}
            />
            <PrivateRoute
              excludeWrapper
              path={PATHNAMES.CONFIRM}
              component={() =>
                authenticationStore.getTaggedAppointment() === null ? (
                  <Wrapper>
                    <Confirmation />
                  </Wrapper>
                ) : (
                  <WrapperPublic>
                    <Confirmation />
                  </WrapperPublic>
                )
              }
            />
            <PrivateRoute
              excludeWrapper
              path={PATHNAMES.BOOKED}
              component={() =>
                authenticationStore.getTaggedAppointment() === null ? (
                  <Wrapper>
                    <Booked />
                  </Wrapper>
                ) : (
                  <WrapperPublic>
                    <Booked />
                  </WrapperPublic>
                )
              }
            />
            <PrivateRoute
              excludeWrapper
              path={PATHNAMES.CONTACT}
              component={() => <Contact />}
            />
            <Route
              exact
              path={PATHNAMES.SIGN_UP}
              component={() => <SignUpWrapper />}
            />
            <Route
              exact
              path={PATHNAMES.LOGIN}
              component={() =>
                !authenticationStore.userId &&
                (queryParam.book_now === "" ||
                  authenticationStore.getTaggedAppointment() === "true") ? (
                  <WrapperPublic>
                    <SignInWithGoogleProvider>
                      <LogIn />
                    </SignInWithGoogleProvider>
                  </WrapperPublic>
                ) : (
                  <SignInWithGoogleProvider>
                    <LogIn />
                  </SignInWithGoogleProvider>
                )
              }
            />
            <Route exact path={PATHNAMES.FORGOT_PASSWORD}>
              <ForgotPassword />
            </Route>
            <Route exact path={PATHNAMES.RESEND_CONFIRMATION}>
              <ResendConfirmation />
            </Route>
            <Route exact path={PATHNAMES.RESET_PASSWORD}>
              <ResetPassword />
            </Route>
            <Route path={PATHNAMES.FEEDBACK} component={() => <Feedback />} />
            <PrivateRoute
              excludeWrapper
              path={PATHNAMES.INTAKE}
              component={() =>
                authenticationStore.getTaggedAppointment() === null ? (
                  <Wrapper>
                    <IntakeForm />
                  </Wrapper>
                ) : (
                  <WrapperPublic>
                    <IntakeForm />
                  </WrapperPublic>
                )
              }
            />
            {/* for testing err reporting */}
            {window.location.host !== "my.oulahealth.com" && (
              <Route path="/error-test">
                {() => {
                  throw new Error();
                }}
              </Route>
            )}
            <Route path="*">
              <UnknownRoute />
            </Route>
          </Switch>
        </LoadingLayout>
      </ErrorBoundary>
    </Router>
  );
});

export const LDRouter = () => {
  const ldUuidStorageKey = "ld-device-identifier";
  const [uuidExists, setUuidExists] = useState(false);
  const [uuidProp, setUuidProp] = useState({});

  useEffect(() => {
    const ldUuid = localStorage.getItem(ldUuidStorageKey);
    if (!ldUuid) {
      localStorage.setItem(ldUuidStorageKey, v4());
      setUuidExists(true);
    } else {
      setUuidExists(true);
    }
    if (uuidExists) {
      setUuidProp({ user: ldUuid });
    }
  }, [uuidExists]);

  return (
    <FeatureFlagProvider
      apiKey={import.meta.env.VITE_LAUNCHDARKLY_CLIENT_KEY}
      loadingFallback={<Loading />}
      {...uuidProp}
    >
      <Navigator />
    </FeatureFlagProvider>
  );
};
