import _map from "lodash/map";
import {
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  Chip,
} from "@material-ui/core";

import "./MultipleSelectDropdown.scss";

const MultipleSelectDropdown = ({
  fieldWithError,
  formatedLabel,
  errors,
  layoutId,
  values,
  type,
  handleBlur,
  handleChange,
  options,
  questionType,
}) => {
  const extraProps = {};

  if (questionType === "multiple_select_pills") {
    extraProps.renderValue = (selected) => (
      <>
        {selected.map((value) => (
          <Chip
            key={value}
            label={options[value - 1]?.name}
            size="small"
            className="multipleSelectDropdown__pills"
          />
        ))}
      </>
    );
  }

  return (
    <div className="multipleSelectDropdown__container">
      <div className="multipleSelectDropdown__mainInfo">
        <span className="multipleSelectDropdown__description">
          {formatedLabel}
        </span>

        {fieldWithError && (
          <FormHelperText className="intakeForm__errorText">
            {errors}
          </FormHelperText>
        )}
      </div>

      <InputLabel className="multipleSelectDropdown__label">
        SELECT ALL THAT APPLY
      </InputLabel>
      <Select
        error={fieldWithError}
        fullWidth
        value={values}
        id={layoutId}
        type={type}
        key={layoutId}
        multiple
        onChange={handleChange}
        onBlur={handleBlur}
        {...extraProps}
      >
        {_map(options, ({ name, id, ethnicity_id }) => (
          <MenuItem key={`${name}-${id}`} value={ethnicity_id || id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default MultipleSelectDropdown;
