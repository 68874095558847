import { Grid, Typography } from "@material-ui/core";
import communityImage from "../../../../assets/image/mentalHealth/Oula_Community1_CMYK__Terracotta.png";
import { useAuth } from "../../../../providers/Auth";

const SecondCheckInSplash = () => {
  const auth = useAuth() as undefined | { user: { first_name: string } };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      style={{ gap: "1rem", textAlign: "center" }}
    >
      <Typography
        align="center"
        style={{
          fontSize: "2.6rem",
          fontFamily: "RecklessNeueTRIAL, sans-serif",
        }}
      >
        {auth?.user?.first_name},
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          We're glad you're taking the time to check in.
        </Typography>
      </Typography>
      <Typography variant="body1">
        Please complete this brief screening for anxiety and depression prior to
        your 28 week appointment. Select the answer that comes closest to how
        you have felt in the past 7 days for the following questions.
      </Typography>
      <Typography
        variant="body1"
        style={{ fontWeight: "bold", marginBottom: "0.2rem" }}
      >
        Let's get started.
      </Typography>
      <img
        src={communityImage}
        width={300}
        height={147.07}
        style={{ maxWidth: "100%", height: "auto" }}
        alt="Two people at arms length from each other holding hands. They are both seated, one on the floor and one on a small stool."
      />
    </Grid>
  );
};

export default SecondCheckInSplash;
