import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { DescriptionOutlined } from "@material-ui/icons";
import { captureException } from "@sentry/react";
import { ReactNode, useState } from "react";
import useAsyncEffect from "use-async-effect";
import {
  CheckinResponse,
  getMentalHealthScreeningEligibility,
} from "../../../api/mentalHealth";
import { getIsActiveMonitoringRequest } from "../../../api/monitoring";
import { ProfileSchema } from "../../../api/profile";
import { getPatient } from "../../../api/results";
import lightbulb from "../../../assets/svg/lightbulb.svg";
import { theme } from "../../../materialDesignShared";
import { useFeatureFlag } from "../../../providers/LaunchDarklyProvider";
import { authenticationStore } from "../../../stores/authentication-store";
import SurveyModal, { SurveyModalProps } from "../../ModalSurvey/SurveyModal";
import FirstCheckIn from "./MentalHealth/firstCheckIn";
import SecondCheckIn from "./MentalHealth/secondCheckIn";
import thirdCheckIn from "./MentalHealth/thirdCheckIn";
import "./OutstandingTasks.scss";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";

interface OutstandingTask {
  icon: ReactNode;
  text: string | ReactNode;
  buttonAction: () => void;
  buttonText: string;
}

const OutstandingTasks = ({
  // we can pass in mock tasks for storybook
  outstandingTasksMockValues,
}: {
  outstandingTasksMockValues?: OutstandingTask[];
}) => {
  const [outstandingTasks, setOutstandingTasks] = useState(
    outstandingTasksMockValues || []
  );
  const [modalMountPoint, setModalMountPoint] = useState<null | JSX.Element>(
    null
  );
  // incrementing number that we just use to trigger our hook
  // value is irrelevant
  const [triggerOutstandingTasksRefresh, setTriggerOutstandingTasksRefresh] =
    useState(0);
  const isSmallViewport = useMediaQuery(theme.breakpoints.down(1023));
  const { newMentalHealth } = useFeatureFlag("new-mental-health");
  const { signUpToCirclesPrompt } = useFeatureFlag("sign-up-to-circles-prompt");
  const history = useHistoryWithPathBasedReload();

  const checkDisplayCirclesTask = async (): Promise<null | OutstandingTask> => {
    const patientRes = await getPatient(authenticationStore.userId);
    if (patientRes && "data" in patientRes) {
      const parsedRes = ProfileSchema.safeParse(patientRes.data);
      if (parsedRes.success) {
        const { patient } = parsedRes.data;
        const open = patient.ob_episode?.status === "OPEN";
        const inThirdTrimester =
          (patient?.current_week_of_pregnancy || 0) >= 27;
        const showCircles = open && inThirdTrimester;

        if (showCircles && signUpToCirclesPrompt)
          return {
            icon: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width={32}
                height={32}
              >
                <path
                  fillRule="evenodd"
                  fill="#BA624A"
                  d="M7.113 16.887a8.5 8.5 0 1 1 9.775-9.775 8.5 8.5 0 1 1-9.775 9.775Zm2.055.087a6.5 6.5 0 1 0 7.806-7.806 8.502 8.502 0 0 1-7.806 7.806Zm-2.142-2.142a8.502 8.502 0 0 1 7.806-7.806 6.5 6.5 0 1 0-7.806 7.806Zm1.995.147a6.501 6.501 0 0 0 5.958-5.958 6.501 6.501 0 0 0-5.958 5.958Z"
                />
              </svg>
            ),
            text: (
              <Box>
                <span style={{ color: "rgb(186, 98, 74)", fontWeight: "bold" }}>
                  NEW!{" "}
                </span>
                Join a Postpartum Circle with other new moms
              </Box>
            ),
            buttonText: "Learn More",
            buttonAction: () => {
              window.open("https://oulahealth.com/circles/", "_blank");
            },
          };
      } else {
        const msg = `unable to parse patient data!`;
        console.error(msg);
        captureException(msg);
      }
    }
    return null;
  };

  const getOutstandingMentalHealthCheckIn =
    async (): Promise<null | OutstandingTask> => {
      const outstandingCheckins = await getMentalHealthScreeningEligibility();
      let checkinPayload: Omit<
        SurveyModalProps<CheckinResponse>,
        "handleClose"
      >;

      if (outstandingCheckins.initialCheckin.eligible)
        checkinPayload = FirstCheckIn;
      else if (outstandingCheckins.thirdTrimesterCheckin.eligible)
        checkinPayload = SecondCheckIn;
      else if (outstandingCheckins.postpartumCheckin.eligible)
        checkinPayload = thirdCheckIn;
      else return null;

      return {
        icon: <DescriptionOutlined style={{ color: "#BA624A" }} />,
        text: "Your care team has recommended a mental health check-in",
        buttonText: "Start Check-in",
        buttonAction: () => {
          setModalMountPoint(
            <SurveyModal
              {...checkinPayload}
              handleClose={() => {
                setModalMountPoint(null);
                setTriggerOutstandingTasksRefresh(
                  triggerOutstandingTasksRefresh + 1
                );
              }}
            />
          );
        },
      };
    };

  useAsyncEffect(
    async (isMounted) => {
      let newOutstandingTasks: OutstandingTask[] = [];

      // circles
      const circlesRes = await checkDisplayCirclesTask();
      console.log(circlesRes);
      if (circlesRes && isMounted())
        newOutstandingTasks = [...newOutstandingTasks, circlesRes];

      // mental health
      try {
        const res = await getOutstandingMentalHealthCheckIn();
        if (res && isMounted() && newMentalHealth)
          newOutstandingTasks = [...newOutstandingTasks, res];
      } catch (e) {
        const msg =
          "failed to check if the patient is eligible for mental health check ins!";
        console.error(msg);
        captureException(msg);
      }

      // blood pressure
      try {
        const { active } = await getIsActiveMonitoringRequest();
        const payload = {
          icon: <img src={lightbulb} />,
          text: "Your care team has recommended regular blood pressure monitoring",
          buttonAction: () => {
            history.push("/monitoring");
          },
          buttonText: "Log a reading",
        };

        if (active && isMounted()) {
          newOutstandingTasks = [...newOutstandingTasks, payload];
        }
      } catch (e) {
        const msg =
          "failed to check if there were any active monitoring requests. this may be because the patient does not have an athena ID, in which case a silent failure is fine.";
        console.error(msg);
        captureException(msg);
      }
      setOutstandingTasks(newOutstandingTasks);
    },
    [triggerOutstandingTasksRefresh]
  );

  if (outstandingTasks.length <= 0) return null;

  return (
    <Box
      style={{
        backgroundColor: "rgb(239, 220, 194)",
        padding: ".8rem 1.2rem",
        flexGrow: 0,
        borderRadius: "3px",
        maxWidth: isSmallViewport ? "100%" : "40%",
      }}
    >
      {modalMountPoint}
      <Typography
        style={{
          fontWeight: "bold",
          marginLeft: "2.5rem",
        }}
      >
        OUTSTANDING TASKS
      </Typography>
      <Grid container direction="column" style={{}}>
        {outstandingTasks.length <= 0 && (
          <Box marginLeft={"2.5rem"} fontStyle={"italic"}>
            No outstanding tasks
          </Box>
        )}
        {outstandingTasks.map(({ icon, text, buttonAction, buttonText }) => (
          <Grid
            className="outstanding-task-item"
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ flexWrap: "nowrap", gap: ".5rem" }}
          >
            <Grid
              container
              style={{ flexWrap: "nowrap", alignItems: "center" }}
            >
              <Box
                style={{
                  marginRight: "1rem",
                }}
              >
                {icon}
              </Box>
              <Typography variant="body2" style={{ display: "inline" }}>
                {text}
              </Typography>
            </Grid>
            <Button
              onClick={buttonAction}
              variant="outlined"
              style={{
                color: "#BA624A",
                borderColor: "#BA624A",
                fontWeight: "bold",
                fontSize: ".8rem",
                whiteSpace: "nowrap",
                minWidth: "10rem",
              }}
            >
              <Box padding={"0 .4rem"}>{buttonText}</Box>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default OutstandingTasks;
