import "./CounterSelect.scss";

const CounterSelect = ({ values, formatedLabel, layoutId, setFieldValue }) => {
  const value = values || 0;
  const handleClickButton = (value, type) => {
    let newValue;

    if (type === "decrease") {
      if (value === 0) return;
      else {
        newValue = value - 1;
      }
    } else if (type === "increase") {
      newValue = value + 1;
    }

    setFieldValue(layoutId, newValue);
  };

  return (
    <div className="counter__container">
      <span className="counter__question">{formatedLabel}</span>

      <div className="counter__controls">
        <button
          type="button"
          onClick={() => handleClickButton(value, "decrease")}
          className="counter__button"
        >
          <img src="/icons/MinusPeach.svg" />
        </button>

        <span className="counter__value">{value}</span>

        <button
          type="button"
          onClick={() => handleClickButton(value, "increase")}
          className="counter__button"
        >
          <img src="/icons/AddPeach.svg" />
        </button>
      </div>
    </div>
  );
};

export default CounterSelect;
