import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  Input,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import logo from "../../assets/logo/Logo/Oula_logo_primary color_icon.png";
import { CustomButton, theme } from "../../materialDesignShared";
import { FormEvent, useState } from "react";
import { captureUserFeedback } from "@sentry/react";
import { useAuth } from "../../providers/Auth";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";

const ErrorFallback = ({
  eventId,
  resetError,
}: {
  error: Error;
  eventId: string;
  resetError: Function;
}) => {
  const { enhancedErrorFeedback } = useFeatureFlag("enhanced-error-feedback");
  const [feedback, setFeedback] = useState<string>("");
  const [includeContactInfo, setIncludeContactInfo] = useState<boolean>(true);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const auth = useAuth() as
    | undefined
    | { user?: { email: string }; isAuthenticated: boolean };

  let email = "Unauthenticated";
  if (auth?.user && includeContactInfo) email = auth.user.email;
  else if (auth?.user && !includeContactInfo)
    email = "Authenticated, declined to provide";

  const enableSubmit = feedback.length > 5;
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!enableSubmit) return;

    // this doesn't return anything, so it fails silently
    // make sure Sentry is actually set up for your environment
    captureUserFeedback({
      event_id: eventId,
      name: "",
      email: email,
      comments: feedback,
    });
    setSubmitted(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="column"
        style={{
          padding: "4rem",
          backgroundColor: "#faf4ec",
        }}
      >
        <Box />
        <Box textAlign={"center"} maxWidth={"35rem"}>
          <img
            src={logo}
            width={100}
            height={100}
            style={{ marginBottom: "1rem" }}
          />
          <Typography style={{ fontWeight: "bold" }} className="title">
            Oops!
          </Typography>
          {!enhancedErrorFeedback ? (
            <div>
              <Typography>
                There was a problem—our team has been notified.
              </Typography>
            </div>
          ) : (
            <Box>
              {submitted ? (
                <Box marginTop={"0.5rem"}>
                  ✅ Thanks for providing feedback!
                </Box>
              ) : (
                <Box>
                  <Typography>
                    There was a problem—our team has been notified.
                    <br />
                    If you'd like to help, please describe what happened:
                  </Typography>
                  <form onSubmit={handleSubmit}>
                    <Input
                      value={feedback}
                      onChange={(e) => {
                        setFeedback(e.target.value);
                      }}
                      multiline
                      fullWidth
                    ></Input>
                    <FormGroup
                      row
                      style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {auth?.isAuthenticated === false && (
                        <Box marginTop={"1rem"} marginBottom={"0.8rem"}>
                          <FormLabel
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              color: "#183746",
                            }}
                          >
                            If you want us to follow up with you about this
                            issue, please provide an email address:
                            <Input
                              type="text"
                              fullWidth
                              placeholder="Email address"
                              style={{ maxWidth: "18rem", marginTop: "0.5rem" }}
                            ></Input>
                          </FormLabel>
                        </Box>
                      )}
                    </FormGroup>
                    <Box marginTop={".8rem"}>
                      <CustomButton type="submit" disabled={!enableSubmit}>
                        Submit
                      </CustomButton>
                    </Box>
                  </form>
                </Box>
              )}
            </Box>
          )}
          <Box marginTop={"0.5rem"}>
            <Button variant="text" type="button" onClick={() => resetError()}>
              Close
            </Button>
          </Box>
        </Box>
        <Typography style={{ marginTop: ".3rem" }}>
          <code>Event ID: {eventId || ""}</code>
        </Typography>
      </Grid>
    </ThemeProvider>
  );
};

export default ErrorFallback;
