import { useState } from "react";
import { AppBar, Container, Dialog, Toolbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { get, isNil } from "lodash";
import useAsyncEffect from "use-async-effect";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import "./CarePlan.scss";
import { useTranslation } from "react-i18next";
import { getCarePlan } from "../../../api/journey";
import { authenticationStore } from "../../../stores/authentication-store";
import * as commonMaterial from "../../../materialDesignShared";
import { journeyStore } from "../../../stores/journey-store";
import { DecisionCardDetails } from "../DecisionCard/DecisionCardDetails";
import {
  findDecisionInJourney,
  scrollToJourneyCard,
} from "../../../services/journey-helpers";
import { maxMobileScreenWidth } from "../../../services/constants";
import { PATHNAMES } from "../../../constants/pathnames";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";

export const CarePlan = ({ onClose, cardContainerRef, dashboard }) => {
  const [birthPlanDecisions, setBirthPlanDecisions] = useState([]);
  const [carePlanDecisions, setCarePlanDecisions] = useState([]);
  const [isSharePlanEnabled, setIsSharePlanEnabled] = useState(true);
  const [hasPatientPlan, setHasPatientPlan] = useState(null);
  const [showFirstDecision, setShowFirstDecision] = useState(false);
  const [showCarePlan, setShowCarePlan] = useState(true);
  const [openDialog, setOpenDialog] = useState(true);
  const { t } = useTranslation();
  const history = useHistoryWithPathBasedReload();
  const newLine = "%0d%0a";

  useAsyncEffect(async () => {
    const { data } = await getCarePlan(authenticationStore.userId);

    if (get(data, "care_plan")) {
      setBirthPlanDecisions(data.care_plan?.birth_plan_decisions);
      setCarePlanDecisions(data.care_plan?.care_plan_decisions);
      setHasPatientPlan(data.care_plan?.has_patient_plan);
    }
  }, []);

  const handleClose = () => {
    setOpenDialog(false);
    if (onClose) {
      onClose();
    }
  };

  const goToJourney = (findDecision) => {
    history.push(
      `${PATHNAMES.JOURNEY}?decision=${findDecision.decision_card_id}`
    );
  };

  const email = `
${t("share_care_plan")} ${newLine}${newLine}
  ${t("my_care_plan")} ${newLine}
  ${newLine}
  ${carePlanDecisions.map((decision) => {
    return `${decision.title}: ${
      decision.choice ? decision.choice : "-"
    }${newLine}`;
  })}
  ${newLine}
  ${t("my_birth_plan")} ${newLine}
  ${newLine}
  ${birthPlanDecisions.map((decision) => {
    return `${decision.title.replace("&", "%26")}: ${
      decision.choice ? decision.choice : "-"
    }${newLine}`;
  })}
  `;

  return (
    <Container
      maxWidth="sm"
      className={`care-plan-container ${
        dashboard ? "dashboard-care-plan" : ""
      }`}
    >
      {showCarePlan && !isNil(hasPatientPlan) && (
        <>
          {!dashboard && (
            <>
              <AppBar className="care-plan-details-app-bar">
                <Toolbar>
                  <div className="dialog-title">MY CARE PLAN</div>
                  <CloseIcon
                    edge="end"
                    color="inherit"
                    onClick={() => (onClose ? onClose() : "")}
                    aria-label="close"
                  />
                </Toolbar>
              </AppBar>
              <div className="care-plan-section-title">My decisions</div>
            </>
          )}
          {hasPatientPlan && (
            <>
              <div className="care-plan-section">
                <div className="dialog-body">
                  <ul>
                    {carePlanDecisions.map((decision) => {
                      return (
                        <li
                          onClick={(e) => {
                            if (dashboard) {
                              goToJourney(decision);
                            } else {
                              const findDecision = findDecisionInJourney(
                                journeyStore.journey,
                                decision
                              );
                              journeyStore.setDecisionCardToShow(findDecision);
                              journeyStore.resetExpandedCards();
                              journeyStore.expandedTrimester[
                                findDecision.trimester
                              ] = true;
                              setTimeout(() => {
                                scrollToJourneyCard(
                                  cardContainerRef,
                                  findDecision,
                                  findDecision.trimester
                                );
                              }, 1000);
                            }
                          }}
                        >
                          <div className="li-key">{decision.title}:</div>
                          <div className="li-value">
                            {decision.choice ? decision.choice : "-"}
                          </div>
                          <div className="li-arrow">
                            <img
                              src="/icons/Arrow-Right@0,5x.svg"
                              alt="arrow-right-icon"
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="birth-plan-section">
                <div className="care-plan-section-title">My birth plan</div>
                <div className="user-name">
                  {authenticationStore.userInfo.first_name}{" "}
                  {authenticationStore.userInfo.last_name}
                </div>
                <div className="dialog-body">
                  <ul>
                    {birthPlanDecisions.map((decision) => {
                      return (
                        <li
                          onClick={() => {
                            if (dashboard) {
                              goToJourney(decision);
                            } else {
                              const findDecision = findDecisionInJourney(
                                journeyStore.journey,
                                decision
                              );
                              journeyStore.setDecisionCardToShow(findDecision);
                              journeyStore.resetExpandedCards();
                              journeyStore.expandedTrimester[
                                findDecision.trimester
                              ] = true;
                              setTimeout(() => {
                                scrollToJourneyCard(
                                  cardContainerRef,
                                  findDecision,
                                  findDecision.trimester
                                );
                              }, 1000);
                            }
                          }}
                        >
                          <div className="li-key">{decision.title}:</div>
                          <div className="li-value">
                            {decision.choice ? decision.choice : "-"}
                          </div>
                          <div className="li-arrow">
                            <img
                              src="/icons/Arrow-Right@0,5x.svg"
                              alt="arrow-right-icon"
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {isSharePlanEnabled && (
                <div className="share-button">
                  <commonMaterial.ReversedCustomYellowButtonTransparentBackground
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ padding: "4px 28px", fontSize: "14px" }}
                    onClick={() =>
                      (window.location.href = `mailto:?subject=Care%20plan&body=${email
                        .replaceAll(",", "")
                        .replaceAll("  ", " ")}`)
                    }
                  >
                    SHARE CARE PLAN
                  </commonMaterial.ReversedCustomYellowButtonTransparentBackground>
                </div>
              )}
            </>
          )}
          {!hasPatientPlan && (
            <div className="no-care-plan-section">
              <CheckCircleOutlineOutlinedIcon />
              <div className="explanation-summary">
                You will find a short summary
                <br />
                of your decisions here.
                <br />
                Start planning by
                <br />
                <span
                  className="underline"
                  onClick={() => {
                    setShowFirstDecision(true);
                    setShowCarePlan(false);
                    const decision = findDecisionInJourney(
                      journeyStore.journey,
                      journeyStore.firstDecision
                    );
                    journeyStore.resetExpandedCards();
                    journeyStore.expandedTrimester[decision.trimester] = true;
                    setTimeout(() => {
                      scrollToJourneyCard(
                        cardContainerRef,
                        journeyStore.firstDecision,
                        decision.trimester
                      );
                    }, 1000);
                  }}
                >
                  {" "}
                  making your first decision.
                </span>
              </div>
            </div>
          )}
        </>
      )}
      {showFirstDecision && window.innerWidth <= maxMobileScreenWidth && (
        <Dialog
          fullScreen
          open={openDialog}
          onClose={handleClose}
          className="care-plan-dialog"
        >
          <DecisionCardDetails
            selectedDecision={journeyStore.firstDecision}
            updateBookmarks
            handleClose={handleClose}
            onClose={() => (journeyStore.firstDecision = undefined)}
            {...journeyStore.firstDecision}
          />
        </Dialog>
      )}
      {showFirstDecision && window.innerWidth > maxMobileScreenWidth && (
        <DecisionCardDetails
          selectedDecision={journeyStore.firstDecision}
          updateBookmarks
          onClose={() => (journeyStore.firstDecision = undefined)}
          {...journeyStore.firstDecision}
        />
      )}
    </Container>
  );
};
