import { CustomTextField } from "../../../../../../materialDesignShared";

import "./IntegerInput.scss";

const IntegerInput = ({
  value,
  formatedLabel,
  layoutId,
  fieldWithError,
  error,
  handleChange,
  handleBlur,
}) => (
  <div className="integer__container">
    <div className="integer__mainInfo">
      <span className="integer__label">{formatedLabel}</span>

      {fieldWithError && <span className="intakeForm__errorText">{error}</span>}
    </div>

    <CustomTextField
      className="intakeForm__field"
      error={fieldWithError}
      margin="normal"
      fullWidth
      value={value}
      onChange={handleChange}
      id={layoutId}
      type="number"
      key={layoutId}
      onBlur={handleBlur}
      helperText={fieldWithError}
      inputProps={{ min: 0 }}
      onWheel={(e) => e.target.blur()}
    />
  </div>
);

export default IntegerInput;
