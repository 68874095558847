import { useEffect, useRef, useState } from "react";
import {
  AppBar,
  Container,
  Dialog,
  Grid,
  Toolbar,
  Button,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { JourneyCard } from "./JourneyCard/JourneyCard";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ListIcon from "@material-ui/icons/List";
import { ArrowForwardIos } from "@material-ui/icons";
import useAsyncEffect from "use-async-effect";
import LinearProgress from "@material-ui/core/LinearProgress";
import Drawer from "@material-ui/core/Drawer";
import { withResizeDetector } from "react-resize-detector";
import qs from "qs";
import { Helmet } from "react-helmet";
import _get from "lodash/get";
import _find from "lodash/find";
import _reduce from "lodash/reduce";
import _debounce from "lodash/debounce";
import _cloneDeep from "lodash/cloneDeep";
import _every from "lodash/every";
import { toJS } from "mobx";
import classnames from "classnames";

import { authenticationStore } from "../../stores/authentication-store";
import * as commonMaterial from "../../materialDesignShared";
import "./Journey.scss";
import { getJourney } from "../../api/journey";
import { DecisionCard } from "./DecisionCard/DecisionCard";
import { EducationCard } from "./EducationCard/EducationCard";
import { CarePlan } from "./CarePlan/CarePlan";
import { DecisionCardDetails } from "./DecisionCard/DecisionCardDetails";
import {
  maxMobileScreenWidth,
  maxTabletScreenWidth,
} from "../../services/constants";
import { HtmlTooltip } from "../../materialDesignShared";
import { journeyStore } from "../../stores/journey-store";
import { EducationCardDetails } from "./EducationCard/EducationCardDetails";
import { AppointmentCardDetails } from "./AppointmentCard/AppointmentCardDetails";
import {
  findDecisionInJourney,
  scrollToJourneyCard,
} from "../../services/journey-helpers";
import { PATHNAMES } from "../../constants/pathnames";

const JourneyComponent = observer(({ publicView }) => {
  const [openBookmark, setOpenBookmark] = useState(false);
  const [educationCardToShow, setEducationCardToShow] = useState(null);
  const [decisionCardToShow, setDecisionCardToShow] = useState(null);
  const [carePlanToShow, setCarePlanToShow] = useState(null);
  const [notificationToShow, setNotificationToShow] = useState(false);
  const [openBookmarkDrawer, setBookmarkDrawer] = useState(false);
  const [bookmarkMarginTop, setBookmarkMarginTop] = useState(
    "bookmark-margin-top"
  );
  const [filteredSections, setFilteredSections] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [textToShow, setTextToShow] = useState("");
  const journey = journeyStore.journey;
  const trimester = journeyStore.trimester;
  const journeySections = toJS(_get(journey, "journey_sections", []));
  const sectionsToShow = filteredSections.length
    ? filteredSections
    : journeySections;
  const cardsContainerRef = useRef(null);
  const location = useLocation();
  const bookmarks = journeyStore.bookmarks;
  const isBiggerThanTablet = window.innerWidth > maxTabletScreenWidth;
  const noResultsFound =
    searchText &&
    _every(filteredSections, ({ ordered_cards }) => !ordered_cards.length);
  const journeySearchClassNames = classnames("journey__searchContainer", {
    journey__searchContainerWithText: searchText,
  });

  useEffect(() => {
    journeyStore.fetchData(publicView);
  }, [publicView]);

  const filterSectionRules = (text, sections) => {
    const sectionsCopy = _cloneDeep(sections);

    for (let i = 0; i < sectionsCopy.length; i++) {
      const orderedCards = sectionsCopy[i]?.ordered_cards;
      const sectionFiltered = _reduce(
        orderedCards,
        (acc, card) => {
          let cardMatchedText = false;
          const description = card.description;
          const cardTitle = card.title;

          for (let j = 0; j < description.length; j++) {
            const typeOfDescription = description[j];
            const { paragraph, list = {} } = typeOfDescription;
            const { title, items } = list;

            if (
              paragraph?.toLowerCase().includes(text) ||
              title?.toLowerCase().includes(text)
            ) {
              cardMatchedText = true;
              break;
            } else if (items?.length) {
              for (let k = 0; k < items.length; k++) {
                const item = items[k];
                if (item?.toLowerCase().includes(text)) {
                  cardMatchedText = true;
                  break;
                }
              }
            }
          }

          if (cardTitle?.toLowerCase().includes(text)) {
            cardMatchedText = true;
          }

          if (cardMatchedText) {
            acc.push(card);
          }

          return acc;
        },
        []
      );

      sectionsCopy[i].ordered_cards = sectionFiltered;
    }

    return sectionsCopy;
  };

  const debouncedSearch = useRef(
    _debounce((text, sections) => {
      const filteredSectionRules = filterSectionRules(text, sections);

      setFilteredSections(filteredSectionRules);
      setSearchText(text);
      journeyStore.setAppointmentCardToShow();
      journeyStore.setDecisionCardToShow();
      journeyStore.setEducationCardToShow();
    }, 500)
  ).current;

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const changeTextHandler = (e, sections) => {
    const formatedText = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "");

    setTextToShow(formatedText);
    return debouncedSearch(formatedText, sections);
  };

  useAsyncEffect(async () => {
    if (!journey) return;

    window.onscroll = function () {
      window.pageYOffset === 0
        ? setBookmarkMarginTop("bookmark-margin-top")
        : setBookmarkMarginTop("");
    };

    if (publicView) {
      const orderedCards = journey.journey_sections.flatMap(
        (section) => section.ordered_cards
      );
      let selectedCard = orderedCards.find(
        (card) => card.card_key === "week8_visit"
      );
      if (!selectedCard) {
        selectedCard = orderedCards[0];
      }
      const cardType = selectedCard?.card_type;

      if (isBiggerThanTablet) {
        if (cardType === "AppointmentCard") {
          journeyStore.setAppointmentCardToShow(selectedCard);
        } else if (cardType === "DecisionCard") {
          journeyStore.setDecisionCardToShow(selectedCard);
        } else if (cardType === "EducationCard") {
          journeyStore.setEducationCardToShow(selectedCard);
        }
      } else {
        journeyStore.closeAllCards();
      }

      return;
    }

    const { patient_weeks_after_delivery } = journey;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    let decisionCard;
    let helpTrimester = trimester;
    const { is_pregnant, is_postpartum } = authenticationStore.userInfo;

    if (!is_pregnant && !is_postpartum) {
      helpTrimester = 0;
    }

    const orderedCards = _get(
      journey,
      `journey_sections[${helpTrimester}].ordered_cards`,
      []
    );
    let selectedCard = orderedCards[0];

    if (is_postpartum && patient_weeks_after_delivery >= 0) {
      selectedCard = setPostpartumCard(
        orderedCards,
        patient_weeks_after_delivery
      );
    }

    const cardType = selectedCard?.card_type;

    if (query.decision) {
      decisionCard = findDecisionInJourney(journey, {
        decision_card_id: query.decision,
      });
      journeyStore.setDecisionCardToShow(decisionCard);
      journeyStore.expandedTrimester[decisionCard.trimester] = true;
    } else if (isBiggerThanTablet) {
      if (cardType === "AppointmentCard") {
        journeyStore.setAppointmentCardToShow(selectedCard);
      } else if (cardType === "DecisionCard") {
        journeyStore.setDecisionCardToShow(selectedCard);
      } else if (cardType === "EducationCard") {
        journeyStore.setEducationCardToShow(selectedCard);
      }
    }

    if (!query.decision) {
      journeyStore.expandedTrimester[trimester] = true;
    }

    setTimeout(() => {
      if (!cardsContainerRef.current) {
        return;
      }
      scrollToJourneyCard(
        cardsContainerRef,
        decisionCard || selectedCard,
        trimester
      );
    }, 1350);
  }, [journey, trimester]);

  useAsyncEffect(async () => {
    if (!journey) {
      return;
    }
    let newEducationCard;
    for (const journeySection of journey.journey_sections) {
      newEducationCard = journeySection.ordered_cards.find((card) => {
        if (card.card_type === "EducationCard") {
          return (
            card.id === educationCardToShow?.id ||
            card.id === journeyStore.educationCardToShow?.id
          );
        }
      });
      if (newEducationCard) {
        break;
      }
    }
    if (newEducationCard && educationCardToShow) {
      setEducationCardToShow(newEducationCard);
    }
    if (newEducationCard && journeyStore.educationCardToShow) {
      journeyStore.educationCardToShow = newEducationCard;
    }
    let newDecisionCard;
    for (const journeySection of journey.journey_sections) {
      newDecisionCard = journeySection.ordered_cards.find((card) => {
        if (card.card_type === "DecisionCard") {
          return (
            card.id === decisionCardToShow?.id ||
            card.id === journeyStore.decisionCardToShow?.id
          );
        }
      });
      if (newDecisionCard) {
        break;
      }
    }
    if (newDecisionCard && decisionCardToShow) {
      setDecisionCardToShow(newDecisionCard);
    }
    if (newDecisionCard && journeyStore.decisionCardToShow) {
      journeyStore.decisionCardToShow = newDecisionCard;
    }
  }, [journey]);

  const calculateProgress = (showedTrimester) => {
    return journey?.journey_sections[showedTrimester]?.total_points > 0
      ? (journey?.journey_sections[showedTrimester]?.points_completed /
          journey?.journey_sections[showedTrimester]?.total_points) *
          100
      : 0;
  };

  const calculateProgressName = (showedTrimester) => {
    return showedTrimester === journeyStore.trimester ? "active" : "";
  };

  const findBookmarkInJourney = (bookmark) => {
    let findBookmark;
    for (let i = 0; i < journey?.journey_sections?.length || 0; i++) {
      const journeySection = journey?.journey_sections[i];
      if (bookmark.card_type === "DecisionCard") {
        findBookmark = journeySection.ordered_cards.find((card) => {
          if (card.card_type === "DecisionCard") {
            return card.id === bookmark.card_id;
          }
        });
      }
      if (bookmark.card_type === "EducationCard") {
        findBookmark = journeySection.ordered_cards.find((card) => {
          if (card.card_type === "EducationCard") {
            return card.id === bookmark.card_id;
          }
        });
      }
      if (findBookmark) {
        return { ...findBookmark, trimester: i };
      }
    }
  };

  const updateBookmarksDialog = (findBookmark) => {
    if (findBookmark.bookmark_card_type === "DecisionCard") {
      journeyStore.setDecisionCardToShow(findBookmark);
    }
    if (findBookmark.bookmark_card_type === "EducationCard") {
      journeyStore.setEducationCardToShow(findBookmark);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setBookmarkDrawer(open);
  };

  const setPostpartumCard = (cards, weeksAfterDelivery) => {
    let cardType;

    if (weeksAfterDelivery <= 3) cardType = "postpartum_week1";
    else cardType = "postpartum_week4";

    return _find(cards, ({ card_key }) => card_key === cardType);
  };

  return (
    <>
      <Helmet>
        <title>Oula - My Journey</title>
      </Helmet>
      {notificationToShow && (
        <Container className="journey-notification-container">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <div className="journey-notification">
              <div className="journey-notification-content">
                Your next appointment is on 23rd of September 2020
              </div>

              <CloseIcon
                edge="end"
                color="inherit"
                onClick={() => setNotificationToShow(false)}
                aria-label="close"
              />
            </div>
          </Grid>
        </Container>
      )}

      <div
        style={{ backgroundColor: "#faf4ec", paddingBottom: "40px" }}
        className="journey"
      >
        <ThemeProvider theme={commonMaterial.theme}>
          <Container className="journey-container">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              className="my-journey-grid"
            >
              <div className="title">My Journey</div>
              <div>
                {journey?.patient_current_week_of_pregnancy > 0 &&
                  authenticationStore.user?.is_pregnant && (
                    <div className="subtitle">
                      {" "}
                      You are
                      <a href={PATHNAMES.MY_PROFILE}>
                        {" "}
                        <u>
                          {journey?.patient_current_week_of_pregnancy} week
                          {journey?.patient_current_week_of_pregnancy > 1
                            ? "s"
                            : ""}{" "}
                          pregnant <EditOutlinedIcon />{" "}
                        </u>
                      </a>
                    </div>
                  )}
              </div>

              {window.innerWidth < maxMobileScreenWidth && (
                <div className="bookmark" onClick={() => setOpenBookmark(true)}>
                  {bookmarks?.length === 0 ? (
                    <img
                      src="/icons/Bookmark@0,5x.svg"
                      alt="bookmark-icon"
                      className="bookmarkIcon"
                    />
                  ) : (
                    <img
                      src="/icons/Bookmark/Filled@0,5x.svg"
                      alt="bookmark-filled-icon"
                      className="bookmark-filled-icon"
                    />
                  )}
                </div>
              )}

              {bookmarks?.length === 0 &&
                window.innerWidth >= maxMobileScreenWidth && (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div className="tooltip-body">
                          Bookmark items you want to save in your journey and
                          click here for easy access.
                        </div>
                      </React.Fragment>
                    }
                    arrow={true}
                  >
                    <Button className="bookmarks-button-desktop">
                      {bookmarks?.length === 0 ? (
                        <img
                          src="/icons/Bookmark@0,5x.svg"
                          alt="bookmark-icon"
                          className="bookmarkIcon"
                        />
                      ) : (
                        <img
                          src="/icons/Bookmark/Filled@0,5x.svg"
                          alt="bookmark-filled-icon"
                          className="bookmark-filled-icon"
                        />
                      )}
                    </Button>
                  </HtmlTooltip>
                )}

              <div className={journeySearchClassNames}>
                <i className="fas fa-search journey__searchIcon" />
                <input
                  placeholder="Search your journey"
                  value={textToShow}
                  type="search"
                  onChange={(e) => changeTextHandler(e, journeySections)}
                  className="journey__searchInput"
                />
              </div>

              {bookmarks?.length > 0 &&
                window.innerWidth >= maxMobileScreenWidth && (
                  <Button
                    className="bookmarks-button-desktop"
                    onClick={toggleDrawer(true)}
                  >
                    {bookmarks?.length === 0 ? (
                      <img
                        src="/icons/Bookmark@0,5x.svg"
                        alt="bookmark-icon"
                        className="bookmarkIcon"
                      />
                    ) : (
                      <img
                        src="/icons/Bookmark/Filled@0,5x.svg"
                        alt="bookmark-filled-icon"
                        className="bookmark-filled-icon"
                      />
                    )}
                  </Button>
                )}

              {noResultsFound && (
                <div className="journey__noResults">
                  <i className="fas fa-search journey__noResultsIcon" />
                  <span className="journey__noResultsMain">
                    No results found
                  </span>
                  <span className="journey__noResultsSecondary">
                    We didn't find anything that matches this search
                  </span>
                </div>
              )}

              {!searchText ? (
                <div className="completedPointsGraph">
                  {!publicView && (
                    <div className="progressPoints">
                      <LinearProgress
                        variant="determinate"
                        onClick={() => {
                          journeyStore.resetExpandedCards();
                          journeyStore.expandedTrimester[0] = true;
                          setTimeout(() => {
                            scrollToJourneyCard(cardsContainerRef, null, 0);
                          }, 1000);
                        }}
                        value={calculateProgress(0)}
                      />
                      <LinearProgress
                        variant="determinate"
                        onClick={() => {
                          journeyStore.resetExpandedCards();
                          journeyStore.expandedTrimester[1] = true;
                          setTimeout(() => {
                            scrollToJourneyCard(cardsContainerRef, null, 1);
                          }, 1000);
                        }}
                        value={calculateProgress(1)}
                      />
                      <LinearProgress
                        variant="determinate"
                        onClick={() => {
                          journeyStore.resetExpandedCards();
                          journeyStore.expandedTrimester[2] = true;
                          setTimeout(() => {
                            scrollToJourneyCard(cardsContainerRef, null, 2);
                          }, 1000);
                        }}
                        value={calculateProgress(2)}
                      />
                      <LinearProgress
                        variant="determinate"
                        onClick={() => {
                          journeyStore.resetExpandedCards();
                          journeyStore.expandedTrimester[3] = true;
                          setTimeout(() => {
                            scrollToJourneyCard(cardsContainerRef, null, 3);
                          }, 1000);
                        }}
                        value={calculateProgress(3)}
                      />
                      <LinearProgress
                        variant="determinate"
                        onClick={() => {
                          journeyStore.resetExpandedCards();
                          journeyStore.expandedTrimester[4] = true;
                          setTimeout(() => {
                            scrollToJourneyCard(cardsContainerRef, null, 4);
                          }, 1000);
                        }}
                        value={calculateProgress(4)}
                      />
                    </div>
                  )}
                  <div className="progressName">
                    <div
                      className={calculateProgressName(0)}
                      onClick={() => {
                        journeyStore.resetExpandedCards();
                        journeyStore.expandedTrimester[0] = true;
                        setTimeout(() => {
                          scrollToJourneyCard(cardsContainerRef, null, 0);
                        }, 1000);
                      }}
                    >
                      Pre
                    </div>
                    <div
                      className={calculateProgressName(1)}
                      onClick={() => {
                        journeyStore.resetExpandedCards();
                        journeyStore.expandedTrimester[1] = true;
                        setTimeout(() => {
                          scrollToJourneyCard(cardsContainerRef, null, 1);
                        }, 1000);
                      }}
                    >
                      T1
                    </div>
                    <div
                      className={calculateProgressName(2)}
                      onClick={() => {
                        journeyStore.resetExpandedCards();
                        journeyStore.expandedTrimester[2] = true;
                        setTimeout(() => {
                          scrollToJourneyCard(cardsContainerRef, null, 2);
                        }, 1000);
                      }}
                    >
                      T2
                    </div>
                    <div
                      className={calculateProgressName(3)}
                      onClick={() => {
                        journeyStore.resetExpandedCards();
                        journeyStore.expandedTrimester[3] = true;
                        setTimeout(() => {
                          scrollToJourneyCard(cardsContainerRef, null, 3);
                        }, 1000);
                      }}
                    >
                      T3
                    </div>
                    <div
                      className={calculateProgressName(4)}
                      onClick={() => {
                        journeyStore.resetExpandedCards();
                        journeyStore.expandedTrimester[4] = true;
                        setTimeout(() => {
                          scrollToJourneyCard(cardsContainerRef, null, 4);
                        }, 1000);
                      }}
                    >
                      Post
                    </div>
                  </div>
                </div>
              ) : null}
            </Grid>
            <div ref={cardsContainerRef} id="journey-cards-container">
              {sectionsToShow.length
                ? sectionsToShow.map((journeyCard, index) => {
                    const lastTrimester =
                      index + 1 === journey?.journey_sections?.length;

                    return (
                      <JourneyCard
                        title={journeyCard.title}
                        key={index}
                        trimester={index}
                        onEducationUpdate={journeyStore.refreshJourney}
                        onDecisionUpdate={journeyStore.refreshJourney}
                        onNotes={journeyStore.refreshJourney}
                        weeksDescription={journeyCard.weeks_description}
                        description={journeyCard.description}
                        progress={calculateProgress(index)}
                        displayBottomLine={
                          index !== journey?.journey_sections?.length - 1
                        }
                        orderedCards={journeyCard.ordered_cards}
                        lastTrimester={lastTrimester}
                        searchText={searchText}
                      />
                    );
                  })
                : null}
            </div>

            {!publicView && (
              <Container maxWidth="sm">
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  <div
                    className="care-plan-fixed-bottom"
                    onClick={() => (journeyStore.openMyCarePlan = true)}
                  >
                    <ListIcon />
                    <div>MY CARE PLAN</div>
                  </div>
                </Grid>
              </Container>
            )}
          </Container>
          <Container className="dialog-container">
            {!journeyStore.openMyCarePlan && (
              <>
                {journeyStore.decisionCardToShow && (
                  <DecisionCardDetails
                    selectedDecision={journeyStore.decisionCardToShow}
                    updateBookmarks
                    onClose={() =>
                      (journeyStore.decisionCardToShow = undefined)
                    }
                    searchText={searchText}
                    {...journeyStore.decisionCardToShow}
                  />
                )}
                {journeyStore.educationCardToShow && (
                  <EducationCardDetails
                    selectedDecision={journeyStore.educationCardToShow}
                    updateBookmarks
                    onClose={() =>
                      (journeyStore.educationCardToShow = undefined)
                    }
                    searchText={searchText}
                    {...journeyStore.educationCardToShow}
                  />
                )}
                {journeyStore.appointmentCardToShow && (
                  <AppointmentCardDetails
                    onClose={() =>
                      (journeyStore.appointmentCardToShow = undefined)
                    }
                    searchText={searchText}
                    updateNotes
                    {...journeyStore.appointmentCardToShow}
                  />
                )}
              </>
            )}
            {isBiggerThanTablet && journeyStore.openMyCarePlan && (
              <CarePlan
                cardContainerRef={cardsContainerRef}
                onClose={() => (journeyStore.openMyCarePlan = false)}
              />
            )}
          </Container>
        </ThemeProvider>
      </div>

      {openBookmark && (
        <Dialog
          open={openBookmark}
          onClose={() => setOpenBookmark(false)}
          className="fixed-dialog-bottom"
        >
          <Container maxWidth="sm">
            <AppBar>
              <Toolbar>
                <div className="dialog-title-bookmarks">BOOKMARKS</div>

                <CloseIcon
                  edge="end"
                  color="inherit"
                  onClick={() => setOpenBookmark(false)}
                  aria-label="close"
                />
              </Toolbar>
            </AppBar>

            {journey && !publicView && bookmarks?.length > 0 && (
              <div className="dialog-list">
                <ul>
                  {bookmarks.map((bookmark) => {
                    const findBookmark = findBookmarkInJourney(bookmark);
                    return (
                      <li onClick={() => updateBookmarksDialog(findBookmark)}>
                        <div className="dialog-title">
                          {findBookmark?.title}
                        </div>
                        <ArrowForwardIos
                          edge="end"
                          color="inherit"
                          aria-label="link"
                        ></ArrowForwardIos>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {!publicView && bookmarks?.length === 0 && (
              <div className="dialog-body no-bookmarks-description">
                <div className="bookmark-title">
                  <img
                    src="/icons/Bookmark@0,5x.svg"
                    alt="bookmark-icon"
                    className="bookmarkIcon"
                  />
                </div>
                <div className="dialog-description">
                  Your saved bookmarks will go here for future reading or
                  discussion with your care team.
                </div>
              </div>
            )}
          </Container>
        </Dialog>
      )}

      {!isBiggerThanTablet &&
        (journeyStore.educationCardToShow ||
          journeyStore.decisionCardToShow) && (
          <Dialog
            open={true}
            onClose={() => journeyStore.closeAllCards()}
            className="fixed-dialog-bottom"
          >
            {journeyStore.decisionCardToShow && (
              <DecisionCard
                bookmarkedDecision
                updateBookmarks
                onClose={() => (journeyStore.decisionCardToShow = undefined)}
                searchText={searchText}
                {...journeyStore.decisionCardToShow}
              />
            )}
            {journeyStore.educationCardToShow && (
              <EducationCard
                bookmarkedDecision
                updateBookmarks
                searchText={searchText}
                onClose={() => (journeyStore.educationCardToShow = undefined)}
                {...journeyStore.educationCardToShow}
              />
            )}
          </Dialog>
        )}

      <Drawer
        anchor="right"
        open={openBookmarkDrawer}
        onClose={toggleDrawer(false)}
        className={`bookmark-drawer ${bookmarkMarginTop}`}
      >
        {journey && !publicView && bookmarks?.length > 0 && (
          <>
            <div className="dialog-list">
              <ul>
                <div className="dialog-title-container">
                  <div className="dialog-title-bookmarks">BOOKMARKS</div>
                  <CloseIcon
                    edge="end"
                    color="inherit"
                    onClick={() => setBookmarkDrawer(false)}
                    aria-label="close"
                  />
                </div>
                {bookmarks.map((bookmark) => {
                  const findBookmark = findBookmarkInJourney(bookmark);
                  return (
                    <li
                      onClick={() => {
                        updateBookmarksDialog(findBookmark);
                        for (let i = 0; i < 4; i++) {
                          journeyStore.expandedTrimester[i] =
                            findBookmark.trimester === i;
                        }
                        setTimeout(() => {
                          scrollToJourneyCard(
                            cardsContainerRef,
                            findBookmark,
                            findBookmark.trimester
                          );
                        }, 1000);
                      }}
                    >
                      <div className="dialog-title">{findBookmark?.title}</div>
                      <ArrowForwardIos
                        edge="end"
                        color="inherit"
                        aria-label="link"
                      ></ArrowForwardIos>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
      </Drawer>

      <ThemeProvider theme={commonMaterial.theme}>
        {!isBiggerThanTablet && (
          <Dialog
            fullScreen
            open={journeyStore.openMyCarePlan}
            onClose={() => (journeyStore.openMyCarePlan = false)}
            className="care-plan-dialog"
          >
            <CarePlan
              cardContainerRef={cardsContainerRef}
              onClose={() => (journeyStore.openMyCarePlan = false)}
            />
          </Dialog>
        )}
      </ThemeProvider>

      {carePlanToShow && (
        <CarePlan
          cardContainerRef={cardsContainerRef}
          onClose={() => setCarePlanToShow(undefined)}
        />
      )}
    </>
  );
});

export const Journey = withResizeDetector(JourneyComponent);
