import {
  Select,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import _map from "lodash/map";
import classNames from "classnames";

import "./SingleSelectDropdown.scss";

const SingleSelectDropdown = ({
  fieldWithError,
  formatedLabel,
  errors,
  layoutId,
  values,
  type,
  handleBlur,
  handleChange,
  options,
  customClassName,
}) => {
  const baseContainerClassName = "singleSelectDropdown__container";
  const baseDescriptionClassName = "singleSelectDropdown__description";

  const containerClassName = classNames(baseContainerClassName, {
    [`${baseContainerClassName}--${customClassName}`]: customClassName,
  });
  const descriptionClassName = classNames(baseDescriptionClassName, {
    [`${baseDescriptionClassName}--${customClassName}`]: customClassName,
  });

  return (
    <div className={containerClassName}>
      <div className="singleSelectDropdown__mainInfo">
        <span className={descriptionClassName}>{formatedLabel}</span>

        {fieldWithError && (
          <FormHelperText className="intakeForm__errorText">
            {errors}
          </FormHelperText>
        )}

        {customClassName === "ethnicity" && (
          <InputLabel className="singleSelectDropdown__label">
            SELECT ALL THAT APPLY
          </InputLabel>
        )}
      </div>

      <Select
        error={fieldWithError}
        fullWidth
        value={values}
        id={layoutId}
        type={type}
        key={layoutId}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {_map(options, ({ name, id, ethnicity_id }) => (
          <MenuItem key={`${name}-${id}`} value={ethnicity_id || id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default SingleSelectDropdown;
