import { data } from "browserslist";
import { retryRequest } from "../services/requestHelpers";

export const getIntakeFormInfo = (userId) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${userId}/intake_form`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const setIntakeFormAnswers = (userId, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${userId}/intake_form`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { ...data },
    },
    true
  );
};

export const setIntakeFormHistoryAnswers = (userId, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${userId}/intake_form/history_answer`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { ...data },
    },
    true
  );
};

export const setCompletedIntakeForm = (userId) => {
  return retryRequest(
    {
      method: "post",
      url: `/api/v1/patients/${userId}/intake_form/completed`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getPharmaciesInfo = ({ name, city, state }) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/pharmacies/?name=${name}&city=${city}&state=${state}`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getInsurancePackage = ({
  insuranceProvider,
  insuranceMemberId,
}) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/insurance_packages/?insuranceplanname=${insuranceProvider}&memberid=${insuranceMemberId}`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getMedicationsList = ({ searchText }) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/lists/?search_term=${searchText}&emr_type=medication`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getAllergiesList = ({ searchText }) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/lists/?search_term=${searchText}&emr_type=allergy`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const setEmrDetails = (userId, data) => {
  return retryRequest(
    {
      method: "post",
      url: `api/v1/patients/${userId}/emr_details/`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { ...data },
    },
    true
  );
};
