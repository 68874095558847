import { FC, PropsWithChildren, ReactElement } from "react";
import { CacheBuster } from "react-cache-buster/dist/CacheBuster";
import pkg from "../../../package.json";
import { Box, Grid } from "@material-ui/core";
import logo from "../../assets/logo/Logo/Oula_logo_primary color_icon.png";
import Loading from "../LoadingLayout/Loading";

const CacheCheck: FC<PropsWithChildren> = ({ children }) => {
  if (import.meta.env.DEV) {
    console.warn(
      "warning: CacheBuster is disabled because the app is running in a development environment!"
    );
  }
  return (
    <CacheBuster
      currentVersion={pkg.version}
      isEnabled={!import.meta.env.DEV}
      loadingComponent={<Loading />}
    >
      {children}
    </CacheBuster>
  );
};

export default CacheCheck;
