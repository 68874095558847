import { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import * as commonMaterial from "../../materialDesignShared";
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import { Formik } from "formik";
import {
  findErrorByMessage,
  findErrorByPath,
  passwordErrors,
  passwordValidator,
  validateYupSchema,
} from "../../services/errorHandlers";
import * as Yup from "yup";
import qs from "qs";
import useAsyncEffect from "use-async-effect";
import * as authApi from "../../api/auth";
import { useLocation } from "react-router-dom";
import { css } from "emotion";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import styles from "./ResetPassword.scss?inline";
import "./ResetPassword.scss";
import { get } from "lodash";
import { Logo } from "../Logo/Logo";
import { toastLength } from "../../services/constants";
import MuiAlert from "@material-ui/lab/Alert";
import { PATHNAMES } from "../../constants/pathnames";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

const passwordErrorStyle = (isError) => css`
  color: ${isError ? styles.redColor : styles.greenColor};
`;

export const ResetPassword = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [resetPasswordToken, setResetPasswordToken] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState("");
  const history = useHistoryWithPathBasedReload();
  const location = useLocation();
  const validationSchema = Yup.object().shape({
    password: passwordValidator,
  });

  useAsyncEffect(async () => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    setResetPasswordToken(query.reset_password_token);
    await authApi.getCookie();
  }, []);

  const resetPassword = async (values) => {
    try {
      if (values.password === values.verifyPassword) {
        const { data } = await authApi.resetPassword(
          values.password,
          values.verifyPassword,
          resetPasswordToken
        );
        if (get(data, "errors")) {
          setPasswordMessage(data.errors[0]);
        }
        if (get(data, "success")) {
          history.push("login?password_successfully_changed=true");
        }
      } else {
        setPasswordMessage("Passwords are not the same!");
      }
    } catch (e) {
      alert(e);
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setPasswordMessage("");
  };

  return (
    <div className="reset-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Container maxWidth="sm" className="signup-group-gradient">
          <img className="baby-image" src="/images/baby.jpeg" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className="grid-gradient"
          >
            <Logo />
            <h1 className="resetPasswordTitle">Reset password.</h1>
            <Snackbar
              open={!!passwordMessage}
              autoHideDuration={toastLength}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleClose} severity="warning">
                {passwordMessage}
              </Alert>
            </Snackbar>
            <Formik
              initialValues={{
                password: "",
                verifyPassword: "",
              }}
              onSubmit={resetPassword}
              validate={(values) => validateYupSchema(validationSchema, values)}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors = [],
                touched,
                handleBlur,
                setFieldTouched,
              }) => (
                <form className="formStyle" onSubmit={handleSubmit}>
                  <FormControl fullWidth variant="outlined">
                    <commonMaterial.CustomTextField
                      id="password"
                      color="primary"
                      onBlur={handleBlur}
                      error={
                        touched.password && findErrorByPath(errors, "password")
                      }
                      value={values.password}
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      label="PASSWORD"
                      onInput={() => setFieldTouched("password", true, true)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  {touched.password && (
                    <ul className="error-list">
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <li
                            className={passwordErrorStyle(
                              !!findErrorByMessage(
                                errors,
                                passwordErrors.minChar
                              )
                            )}
                          >
                            8 chars
                          </li>
                        </Grid>
                        <Grid item xs={4}>
                          <li
                            className={passwordErrorStyle(
                              !!findErrorByMessage(
                                errors,
                                passwordErrors.oneNumber
                              )
                            )}
                          >
                            1 number
                          </li>
                        </Grid>
                        <Grid item xs={4}>
                          <li
                            className={passwordErrorStyle(
                              !!findErrorByMessage(
                                errors,
                                passwordErrors.oneUpper
                              )
                            )}
                          >
                            1 uppercase
                          </li>
                        </Grid>

                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <li
                              className={passwordErrorStyle(
                                !!findErrorByMessage(
                                  errors,
                                  passwordErrors.oneLower
                                )
                              )}
                            >
                              1 lowercase
                            </li>
                          </Grid>

                          <Grid item xs={4}>
                            <li
                              className={passwordErrorStyle(
                                !!findErrorByMessage(
                                  errors,
                                  passwordErrors.oneSpecial
                                )
                              )}
                            >
                              1 special character
                            </li>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ul>
                  )}
                  {!touched.password && <div className="placeholder" />}
                  <FormControl fullWidth variant="outlined">
                    <commonMaterial.CustomTextField
                      id="verifyPassword"
                      color="primary"
                      onBlur={handleBlur}
                      value={values.verifyPassword}
                      type={showVerifyPassword ? "text" : "password"}
                      onChange={handleChange}
                      label="VERIFY NEW PASSWORD"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowVerifyPassword(!showVerifyPassword)
                              }
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {showVerifyPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <div className="createAccountStyle">
                    <commonMaterial.CustomButton
                      size="large"
                      color="primary"
                      type="submit"
                      variant="contained"
                    >
                      RESET
                    </commonMaterial.CustomButton>
                  </div>
                </form>
              )}
            </Formik>
            <div className="linksSignupLogin">
              <div className="firstLoginGroupLink">
                Don't have an account?{" "}
                <a href={PATHNAMES.SIGN_UP}>
                  <b>Sign up</b>
                </a>
              </div>
              <div className="secondLoginGroupLink">
                <a href={PATHNAMES.LOGIN}>
                  Back to <b>log in</b>
                </a>
              </div>
            </div>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
};
