import { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { get } from "lodash";
import CheckIcon from "@material-ui/icons/Check";
import { withResizeDetector } from "react-resize-detector";
import ReactHtmlParser from "react-html-parser";

import { observer } from "mobx-react-lite";
import { authenticationStore } from "../../../stores/authentication-store";
import "./DecisionCard.scss";
import { updateBookmark } from "../../../api/journey";
import { journeyStore } from "../../../stores/journey-store";
import { DecisionCardDetails } from "./DecisionCardDetails";
import {
  maxTabletScreenWidth,
  maxMobileScreenWidth,
} from "../../../services/constants";
import * as commonMaterial from "../../../materialDesignShared";
import { getHighlightTextSearchMarkup } from "../../../helpers/highlighterHelpers";

/*global rocketcss, $*/
/*eslint no-undef: "error"*/

export const DecisionCard = withResizeDetector(
  observer((props) => {
    const {
      id,
      title,
      target_weeks,
      description,
      patient_bookmark,
      patient_decision,
      bookmarkedDecision,
      onClose,
      updateBookmarks,
      last_card,
      decisionCardToShow,
      searchText,
    } = props;

    const [openDialog, setOpenDialog] = useState(bookmarkedDecision);
    const [successfullySavedBookmark, setSuccessfullySavedBookmark] = useState(
      patient_bookmark?.is_active || false
    );
    const [decisionSelected, setDecisionSelected] = useState(null);
    let firstParagraph = false;
    let firstList = false;

    const highlightedTitleText = getHighlightTextSearchMarkup(
      title,
      searchText
    );

    const setAnimationHandler = () => {
      $(`#decision-${id}`).on("click", function () {
        window.innerWidth >= maxMobileScreenWidth
          ? rocketcss(this, ".bookmarks-button-desktop", "rocketPulseHole")
          : rocketcss(this, ".bookmark", "rocketPulseHole");
        $(".profileIconContainer").addClass("targetPulse");
        $(`#decision-${id}`).css("opacity", "1");
      });
    };
    const removeAnimationHandler = () => {
      $(`#decision-${id}`).off("click");
    };

    useEffect(() => {
      setTimeout(() => {
        !patient_bookmark?.is_active && setAnimationHandler();
      }, 1000);
      setSuccessfullySavedBookmark(patient_bookmark?.is_active);
    }, [patient_bookmark?.is_active]);

    useEffect(() => {
      if (decisionCardToShow?.id === id) {
        setDecisionSelected(decisionCardToShow);
      } else {
        setDecisionSelected(null);
      }
    }, [decisionCardToShow]);

    const handleClickOpen = () => {
      if (window.innerWidth < maxTabletScreenWidth) {
        setOpenDialog(true);
      } else {
        journeyStore.setDecisionCardToShow(props);
      }
    };

    const handleClose = () => {
      setOpenDialog(false);
      if (onClose) {
        onClose();
      }
    };

    const saveBookmark = async (isActive) => {
      try {
        const { data } = await updateBookmark(authenticationStore.userId, {
          card_id: id,
          card_type: "DecisionCard",
          is_active: isActive.toString(),
        });
        if (!isActive) {
          setAnimationHandler();
        } else {
          removeAnimationHandler();
        }
        setSuccessfullySavedBookmark(isActive);
        if (updateBookmarks) {
          await Promise.all([
            journeyStore.refreshBookmarks(),
            journeyStore.refreshJourney(),
          ]);
        }
      } catch (e) {
        if (get(e, "response.status") === 422) {
          console.log(e.response.data.errors[0]);
        }
        setSuccessfullySavedBookmark(false);
      }
    };

    return (
      <>
        <div
          className={`decision-card ${
            decisionSelected?.id === id ? "decision-card-selected" : ""
          }`}
          onClick={() => handleClickOpen()}
        >
          {decisionSelected?.id === id && (
            <img
              className="arrow-pointer"
              src="/icons/Arrow-Left-Material-Filled.png"
              alt="arrow-left-icon"
            />
          )}
          <div className="decision-circle">
            {patient_decision && <CheckIcon className="check" />}
          </div>
          <div className="decision-header-position">
            <div className="label">
              Week
              {target_weeks.length > 1
                ? `s ${Math.min.apply(Math, target_weeks)}-${Math.max.apply(
                    Math,
                    target_weeks
                  )}`
                : ` ${target_weeks[0]}`}
            </div>
          </div>
          <div className="decision-body">
            <div className="decision-title">
              <img
                className="icon"
                src="/icons/Threeway_arrow@0,5x.svg"
                alt="threeway-icon"
              />
              {ReactHtmlParser(highlightedTitleText)}
            </div>
            {description && (
              <div className="decision-description">
                {description.map((section) => {
                  if (get(section, "paragraph") && !firstParagraph) {
                    const higlightedText = getHighlightTextSearchMarkup(
                      section.paragraph,
                      searchText
                    );

                    firstParagraph = true;

                    return (
                      <p className="decision-description-paragraph">
                        <span className="decision-what-is-it">
                          What is it:{" "}
                        </span>
                        {ReactHtmlParser(higlightedText)}
                      </p>
                    );
                  }
                  if (get(section, "list") && !firstList && !firstParagraph) {
                    const higlightedText = getHighlightTextSearchMarkup(
                      section.list.title,
                      searchText
                    );

                    firstList = true;

                    return (
                      <div className="decision-list-column">
                        <div>
                          <span className="decision-what-is-it">
                            What is it:{" "}
                          </span>
                          <p>{ReactHtmlParser(higlightedText)}</p>
                        </div>
                        <ul>
                          {section.list.items.map((item, index) => {
                            if (index < 2) {
                              const higlightedText =
                                getHighlightTextSearchMarkup(item, searchText);

                              return (
                                <li
                                  className="appointment-description-list-item"
                                  key={index}
                                >
                                  {ReactHtmlParser(higlightedText)}
                                </li>
                              );
                            } else if (index === 1) {
                              return "...";
                            }
                          })}
                        </ul>
                      </div>
                    );
                  }
                })}
                <a onClick={handleClickOpen}>expand</a>
              </div>
            )}
            {!journeyStore.publicView && (
              <>
                <div className="make-decision-button">
                  <commonMaterial.ReversedCustomMainButton
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ padding: "5px 30px", fontSize: "14px" }}
                    onClick={() => handleClickOpen()}
                  >
                    MAKE DECISION
                  </commonMaterial.ReversedCustomMainButton>
                </div>
                <div
                  className="card-bookmark journey-position"
                  onClick={(e) => {
                    e.stopPropagation();
                    saveBookmark(!successfullySavedBookmark);
                  }}
                >
                  <div id={`decision-${id}`}>
                    {successfullySavedBookmark ? (
                      <img
                        src="/icons/Bookmark/Filled@0,5x.svg"
                        alt="bookmark-filled-icon"
                        className="bookmark-filled-icon"
                      />
                    ) : (
                      <img
                        src="/icons/Bookmark@0,5x.svg"
                        alt="bookmark-icon"
                        className="bookmarkIcon"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {!last_card && <div className="vertical-line-decision" />}
        </div>

        {openDialog && (
          <Dialog fullScreen open={openDialog} onClose={handleClose}>
            <DecisionCardDetails
              handleClose={handleClose}
              searchText={searchText}
              {...props}
            />
          </Dialog>
        )}
      </>
    );
  })
);
