import { retryRequest } from "../services/requestHelpers";

export const getJourney = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}/journey`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getPublicJourney = () => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/journey`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    false
  );
};

export const updateDecision = (id, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/decisions.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: data,
    },
    true
  );
};

export const getBookmark = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}/bookmarks`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const updateBookmark = (id, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/bookmarks.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { patient_bookmark: data },
    },
    true
  );
};

export const updateEducation = (id, cardId, done) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/education_card/${cardId}`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: {
        patient_education_card: {
          done: done,
        },
      },
    },
    true
  );
};

export const getCarePlan = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}/care_plan`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const updateNote = (id, appointmentId, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/appointment/${appointmentId}/add_note.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { note: data },
    },
    true
  );
};

export const getCalendarToPregnancyWeeks = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}/calendar_to_pregnancy_weeks.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};
