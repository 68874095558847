import { useCallback, useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import qs from "qs";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import { Navigation, Visibility, VisibilityOff } from "@material-ui/icons";
import { get } from "lodash";

import * as authApi from "../../api/auth";
import { setLogEntry } from "../../api/log";
import {
  findErrorByPath,
  validateYupSchema,
} from "../../services/errorHandlers";
import * as commonMaterial from "../../materialDesignShared";
import * as customMaterial from "./LogInMaterialDesign";
import { authenticationStore } from "../../stores/authentication-store";
import { Logo } from "../Logo/Logo";
import { toastLength } from "../../services/constants";
import { appointmentStore } from "../../stores/appointment-store";
import { ProgressBarLoggedOut } from "../Scheduling/ProgressBarLoggedOut/ProgressBarLoggedOut";
import { PATHNAMES } from "../../constants/pathnames";

import "./Login.scss";
import { useAuth } from "../../providers/Auth";
import { GoogleLogin } from "@react-oauth/google";
import { useSignInWithGoogle } from "./SignInWithGoogleProvider";
import { usePageVisibility } from "react-page-visibility";
import { useCacheBuster } from "react-cache-buster";
import jwtDecode from "jwt-decode";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

export const LogIn = observer(() => {
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [emailConfirmedTaggedAppointment, setEmailConfirmedTaggedAppointment] =
    useState(false);
  const [emailNotConfirmed, setEmailNotConfirmed] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [logoutSuccessful, setLogoutSuccessful] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const history = useHistoryWithPathBasedReload();
  const location = useLocation();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });
  const { fetchUser } = useAuth();
  const previousLocation = get(location, "state.from.pathname", "");
  const { initialized: googleInitialized } = useSignInWithGoogle();

  const isVisible = usePageVisibility();
  const { checkCacheStatus } = useCacheBuster();

  useEffect(() => {
    if (isVisible) {
      checkCacheStatus();
    }
  }, [isVisible, checkCacheStatus]);

  useAsyncEffect(async () => {
    if (query["try_again"]) {
      setTryAgain(true);
    }
    if (query["password_successfully_changed"]) {
      setPasswordChanged(true);
    }
    if (query["email_confirmed"] === "false") {
      setEmailNotConfirmed(true);
    }
    if (query["email_confirmed"] === "true") {
      setEmailConfirmed(true);
      if (appointmentStore.getAppointment()) {
        setEmailConfirmedTaggedAppointment(true);
      }
    }
    if (query["logout_successful"] === "true") {
      setLogoutSuccessful(true);
    }

    if (authenticationStore.userId) {
      authenticationStore.getMinimumProfileCompleted() === "true"
        ? history.push(PATHNAMES.DASHBOARD)
        : history.push(PATHNAMES.TELL_US_A_BIT);
    }
  }, []);

  const redirect = (minimumProfileCompleted) => {
    let route = "";

    const search = new URLSearchParams(location.search);
    const redirect_auth = search.get("redirect_auth");

    if (redirect_auth) {
      route = redirect_auth;
    } else if (minimumProfileCompleted) {
      if (previousLocation === PATHNAMES.RESULTS) {
        route = previousLocation;
      } else {
        route = PATHNAMES.DASHBOARD;
      }
    } else {
      route = PATHNAMES.TELL_US_A_BIT;
    }

    if (previousLocation === PATHNAMES.INTAKE) {
      route = previousLocation;
    }

    history.push(route);
  };

  const login = async (values) => {
    setTryAgain(false);
    setPasswordChanged(false);
    setEmailConfirmed(false);
    setEmailConfirmedTaggedAppointment(false);
    setEmailNotConfirmed(false);
    setLogoutSuccessful(false);
    setResponseError(false);

    const {
      resetRedirectLogout,
      setJWTToken,
      setRefreshToken,
      setTaggedAppointment,
      setIsFirstLogin,
      setCarePlanCompleted,
      setFirstName,
      setMinimumProfileCompleted,
      setCalendarToPregnancyWeeks,
    } = authenticationStore;

    resetRedirectLogout();

    try {
      setLoadingLogin(true);
      const { data = {} } = await authApi.login(values.email, values.password);
      const {
        tagged_appointment,
        jwt,
        refresh_token,
        is_first_login,
        care_plan_complete,
        first_name,
        minimum_profile_completed,
        calendar_to_pregnancy_weeks,
        hashed_email,
        hashed_firstname,
        hashed_lastname,
        hashed_state,
      } = data;

      if (tagged_appointment) {
        setJWTToken(jwt, rememberMe);
        setRefreshToken(refresh_token, rememberMe);
        setTaggedAppointment(true);
        setIsFirstLogin(is_first_login);
        setCarePlanCompleted(care_plan_complete);
        setFirstName(first_name);
        setMinimumProfileCompleted(minimum_profile_completed);

        if (minimum_profile_completed) {
          setCalendarToPregnancyWeeks(calendar_to_pregnancy_weeks);
        }

        await fetchUser({ ignoreLoading: true });
        if (tagged_appointment.external_appointment_id === null) {
          appointmentStore.setAppointment({
            ...appointmentStore.getAppointment(),
            slot_taken: true,
          });
          minimum_profile_completed
            ? history.push(
                `${PATHNAMES.LOCATION}?appointment_type_id=${
                  appointmentStore.getAppointment().appointment_type_id
                }&error=slot_taken}`
              )
            : history.push(`${PATHNAMES.TELL_US_A_BIT}?slot_taken=true`);
        } else {
          minimum_profile_completed
            ? history.push(
                `${PATHNAMES.CONFIRM}?appointment_type_id=${
                  appointmentStore.getAppointment().appointment_type_id
                }`
              )
            : history.push(PATHNAMES.TELL_US_A_BIT);
        }
      } else {
        if (jwt) {
          setLoadingLogin(false);
          setJWTToken(jwt, rememberMe);
          setRefreshToken(refresh_token, rememberMe);
          setIsFirstLogin(is_first_login);
          setFirstName(first_name);
          setCarePlanCompleted(care_plan_complete);
          setMinimumProfileCompleted(minimum_profile_completed);
          if (minimum_profile_completed) {
            setCalendarToPregnancyWeeks(calendar_to_pregnancy_weeks);
          }
          await fetchUser({ ignoreLoading: true });
          redirect(minimum_profile_completed);
        }
      }
    } catch (e) {
      if (get(e, "response.status") === 401) {
        if (e.response.data.errors[0].status === "401") {
          setLoadingLogin(false);
          setResponseError(e.response.data.errors[0].title);
        }
      }
    }
  };

  const googleLogin = async (token) => {
    try {
      const { email } = jwtDecode(token);
      setTryAgain(false);
      setPasswordChanged(false);
      setEmailConfirmed(false);
      setEmailConfirmedTaggedAppointment(false);
      setEmailNotConfirmed(false);
      setLogoutSuccessful(false);
      setResponseError(false);

      const { data = {} } = await authApi.googleLogin(token);

      const {
        jwt,
        refresh_token,
        is_first_login,
        first_name,
        care_plan_complete,
        minimum_profile_completed,
        calendar_to_pregnancy_weeks,
        hashed_email,
        hashed_firstname,
        hashed_lastname,
        hashed_state,
      } = data;

      const {
        setJWTToken,
        setRefreshToken,
        setIsFirstLogin,
        setFirstName,
        setCarePlanCompleted,
        setMinimumProfileCompleted,
        setCalendarToPregnancyWeeks,
      } = authenticationStore;

      setJWTToken(jwt, rememberMe);
      setRefreshToken(refresh_token, rememberMe);
      setIsFirstLogin(is_first_login);
      setFirstName(first_name);
      setCarePlanCompleted(care_plan_complete);
      setMinimumProfileCompleted(minimum_profile_completed);

      if (minimum_profile_completed) {
        setCalendarToPregnancyWeeks(calendar_to_pregnancy_weeks);
      }

      setLogEntry({
        component_name: "Login",
        user_email: email,
        log_message: "Google login Success",
      });

      await fetchUser({ ignoreLoading: true });
      redirect(minimum_profile_completed);
    } catch (e) {
      if (get(e, "response.status") === 401) {
        if (e.response.data.errors[0].status === "401") {
          setResponseError(e.response.data.errors[0].title);
        }
      }

      const { email } = jwtDecode(token);
      setLogEntry({
        component_name: "Login",
        user_email: email,
        log_message: `Google login failure: ${e}`,
      });
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setTryAgain(false);
    setPasswordChanged(false);
    setEmailConfirmed(false);
    setEmailConfirmedTaggedAppointment(false);
    setEmailNotConfirmed(false);
    setLogoutSuccessful(false);
    setResponseError(false);
  };

  return (
    <div className="login-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Container maxWidth="sm" className="signup-group-gradient">
          <img className="baby-image" src="/images/baby.jpeg" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className="grid-gradient"
          >
            {!authenticationStore.userId &&
            (query.book_now === "" ||
              authenticationStore.getTaggedAppointment() === "true") ? (
              <>
                <div className="log-in-to-continue">
                  Log in to
                  <br />
                  continue booking
                </div>
                <div className="progress-bar-logged">
                  <ProgressBarLoggedOut step={3} />
                </div>
              </>
            ) : (
              <Logo />
            )}
            <Snackbar
              open={emailNotConfirmed}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                Email confirmation sent. Please check your email and confirm it.
              </Alert>
            </Snackbar>
            <Snackbar
              open={emailConfirmed}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                Email confirmed! Please log in now.
              </Alert>
            </Snackbar>
            <Snackbar
              open={emailConfirmedTaggedAppointment}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                Email confirmed. Please log in to continue booking your
                appointment.
              </Alert>
            </Snackbar>
            <Snackbar
              open={tryAgain}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="warning">
                Please log in again.
              </Alert>
            </Snackbar>
            <Snackbar
              open={passwordChanged}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                Password is successfully changed. Please login.
              </Alert>
            </Snackbar>
            <Snackbar
              open={!!responseError}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="error">
                {responseError}
              </Alert>
            </Snackbar>
            <Snackbar
              open={logoutSuccessful}
              autoHideDuration={toastLength}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={handleSnackbarClose} severity="success">
                You have successfully logged out.
              </Alert>
            </Snackbar>

            {googleInitialized && (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  googleLogin(credentialResponse.credential);
                }}
              />
            )}

            <div className="separator">or</div>
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={login}
              validate={(values) => validateYupSchema(validationSchema, values)}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors = [],
                touched,
                handleBlur,
                setFieldTouched,
              }) => (
                <form className="formStyle" onSubmit={handleSubmit}>
                  <commonMaterial.CustomTextField
                    margin="normal"
                    error={touched.email && !!findErrorByPath(errors, "email")}
                    fullWidth
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    type="email"
                    label="EMAIL"
                    onChange={handleChange}
                    onInput={() => setFieldTouched("email", true, true)}
                  />
                  <FormControl fullWidth variant="outlined">
                    <commonMaterial.CustomTextField
                      id="password"
                      color="primary"
                      onBlur={handleBlur}
                      error={
                        touched.password &&
                        !!findErrorByPath(errors, "password")
                      }
                      value={values.password}
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      label="PASSWORD"
                      onInput={() => setFieldTouched("password", true, true)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  <div>
                    <customMaterial.RememberMeCheckbox
                      checked={rememberMe}
                      handleChange={(e) => setRememberMe(e.target.checked)}
                      label="Remember me"
                    />
                  </div>

                  <div className="createAccountStyle">
                    <commonMaterial.CustomButton
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      disabled={loadingLogin}
                    >
                      LOG IN
                      <span
                        style={{ display: !loadingLogin && "none" }}
                        className="login-loader"
                      >
                        <ClipLoader
                          color={"#ffffff"}
                          loading={loadingLogin}
                          size={13}
                        />
                      </span>
                    </commonMaterial.CustomButton>
                  </div>
                </form>
              )}
            </Formik>
            <div className="linksSignupLogin">
              <div className="firstLoginGroupLink">
                Don't have an account?{" "}
                <a href={PATHNAMES.SIGN_UP}>
                  <b>Sign up</b>
                </a>
              </div>
              <div className="secondLoginGroupLink margin-bottom-10">
                <a href={PATHNAMES.FORGOT_PASSWORD}>
                  <b>Forgot password?</b>
                </a>
              </div>
              <div>
                <a href={PATHNAMES.RESEND_CONFIRMATION}>
                  <b>Resend</b>
                </a>{" "}
                confirmation e-mail
              </div>
            </div>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
});
