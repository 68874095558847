import { useState, useRef, useEffect } from "react";
import _map from "lodash/map";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { getPharmaciesInfo } from "../../../../../../api/intakeForm";

const PreferredPharmacy = ({
  formatedLabel,
  value,
  setFieldValue,
  state,
  city,
  generalTypeForm,
  layoutId,
}) => {
  const [pharmacyText, setPharmacyText] = useState("");
  const [pharmacyOptions, setPharmacyOptions] = useState([{ label: value }]);

  const debounceSearchPharmacy = useRef(
    _debounce(async ({ state, city, search }) => {
      const { data } = await getPharmaciesInfo({ state, city, name: search });

      const clinicalProviders = _get(data, "clinicalproviders", []);
      const formattedProviders = _map(clinicalProviders, (provider) => {
        const { emr_clinical_provider_id, name, phone, address } = provider;

        return {
          label: `${name}, ${address}`,
          id: emr_clinical_provider_id,
          phone,
          address,
        };
      });

      setPharmacyOptions(formattedProviders);
    }, 500)
  );

  useEffect(() => {
    if (generalTypeForm && pharmacyText.length > 2) {
      debounceSearchPharmacy.current({ search: pharmacyText, state, city });
    }
  }, [pharmacyText, state, city]);

  return (
    <AutoComplete
      inputValue={pharmacyText}
      onInputChange={(e, newInputValue) => {
        setPharmacyText(newInputValue);
      }}
      id="pharmacy-dropdown"
      options={pharmacyOptions}
      getOptionLabel={(option) => option.label}
      defaultValue={{ label: value }}
      getOptionSelected={(option) => option.label === value}
      className="intakeForm__fieldDropdown"
      renderInput={(params) => (
        <TextField {...params} value={value} label={formatedLabel} />
      )}
      onChange={(e, value) => {
        const label = _get(value, "label", "");
        const phone = _get(value, "phone", "");
        const address = _get(value, "address", "");
        const id = _get(value, "id", "");

        setFieldValue(layoutId, `${label}, ${address}`);
        setFieldValue("preferred_pharm_number", phone);
        setFieldValue("emr_clinical_provider_id", id);

        if (!value) {
          setPharmacyOptions([]);
        }
      }}
    />
  );
};

export default PreferredPharmacy;
