import { retryRequest } from "../services/requestHelpers";

export const getDepartments = () => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/departments`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};
