import _get from "lodash/get";

import "./Pause.scss";

const Pause = ({ info }) => {
  const title = _get(info, "title", "");
  const description = _get(info, "description", "");

  return (
    <>
      <div className="pause">
        <div className="pause__info">
          <span className="pause__title">{title}</span>

          <p className="pause__description">{description}</p>
        </div>
      </div>
      <img className="pause__image" width={300} src="/images/asupport.png" />
    </>
  );
};

export default Pause;
