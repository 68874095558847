import { retryRequest } from "../services/requestHelpers";

export const getAppointments = () => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/appointment_types.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const getTimeslots = (appointmentTypeId, date, departmentId) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/appointment_types/${appointmentTypeId}/available_slots.json?start_date=${date}&emr_departmentid=${departmentId}`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const bookAppointment = (id, data) => {
  return retryRequest(
    {
      method: "post",
      url: `/api/v1/patients/${id}/appointment/book_appointment.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: data,
    },
    true,
    false,
    true
  );
};

export const cancelAppointment = (id, appointmentId, cancelReason) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/appointment/${appointmentId}/cancel_appointment`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { cancel_reason_key: cancelReason },
    },
    true
  );
};

export const rescheduleAppointment = (id, appointmentId, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}/appointment/${appointmentId}/reschedule_appointment`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { appointment: data },
    },
    true
  );
};
