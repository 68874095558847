import { useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import { Button, Dialog, Snackbar, TextField } from "@material-ui/core";
import moment from "moment";
import { get, uniqueId } from "lodash";
import { Check } from "@material-ui/icons";
import ClipLoader from "react-spinners/ClipLoader";
import MuiAlert from "@material-ui/lab/Alert";
import ReactHtmlParser from "react-html-parser";

import "./AppointmentCard.scss";
import * as commonMaterial from "../../../materialDesignShared";
import { AppointmentCardDetails } from "./AppointmentCardDetails";
import {
  maxTabletScreenWidth,
  toastLength,
  savedNoteLength,
} from "../../../services/constants";
import { journeyStore } from "../../../stores/journey-store";
import { appointmentStore } from "../../../stores/appointment-store";
import { updateNote } from "../../../api/journey";
import { authenticationStore } from "../../../stores/authentication-store";
import { findNoteInAppointmentsArray } from "../../../services/journey-helpers";
import { YYYY_MM_DD_ALTERNATIVE } from "../../../constants/dateFormat";
import { cancellationSameDayMsg } from "../../../constants/toastMessages";
import { getIsVirtualAppointment } from "../../../helpers/appointmentCardsHelpers";
import { PATHNAMES } from "../../../constants/pathnames";
import { LINKS } from "../../../constants/relatedLinks";
import { getHighlightTextSearchMarkup } from "../../../helpers/highlighterHelpers";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";

const hasNumber = (myString) => /\d/.test(myString);

const retNumber = (myString) => {
  let num = myString.replace(/[^0-9]/g, "");
  return parseInt(num, 10);
};

export const AppointmentCard = (props) => {
  const {
    title,
    description,
    appointment_type_id,
    card_key,
    can_book_from_portal,
    begin_date_range,
    appointment_type_description,
    virtual,
    enable_book_now,
    add_note_enabled,
    appointment,
    last_card,
    onNotes,
    appointmentCardToShow,
    event,
    group_class,
    searchText,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [addNoteValue, setAddNoteValue] = useState(null);
  const [savedNoteMessage, setSaveNoteMessage] = useState(null);
  const [loading, setLoading] = useState(null);
  const [responseError, setResponseError] = useState(null);
  const [appointmentSelected, setAppointmentSelected] = useState(null);
  const history = useHistoryWithPathBasedReload();
  const isVirtualAppointment = getIsVirtualAppointment(appointment, virtual);
  const appointmentOnSameThanCurrentDay = moment(
    moment(appointment?.start_time).format(YYYY_MM_DD_ALTERNATIVE)
  ).isSame(moment().format(YYYY_MM_DD_ALTERNATIVE));
  let firstParagraph = false;
  let firstList = false;
  const isNotEventOrGroup = !event && !group_class;
  const selectedTitle = hasNumber(title)
    ? `${
        title.toLowerCase().includes("postpartum") ? "POSTPARTUM" : ""
      } WEEK ${retNumber(title)} APPOINTMENT`
    : title;

  const highlightedTitleText = getHighlightTextSearchMarkup(
    selectedTitle,
    searchText
  );

  useEffect(() => {
    findLastNoteInAppointmentsArray();
  }, [findNoteInAppointmentsArray(appointment?.id)]);

  useEffect(() => {
    if (appointmentCardToShow?.card_key === card_key) {
      setAppointmentSelected(appointmentCardToShow);
    } else {
      setAppointmentSelected(null);
    }
  }, [appointmentCardToShow]);

  const findLastNoteInAppointmentsArray = () => {
    if (
      findNoteInAppointmentsArray(appointment?.id) &&
      !journeyStore.publicView
    ) {
      setAddNoteValue(findNoteInAppointmentsArray(appointment?.id));
      setOpenAddNote(true);
    }
  };

  const handleClickOpen = () => {
    if (window.innerWidth < maxTabletScreenWidth) {
      setOpenDialog(true);
    } else {
      journeyStore.setAppointmentCardToShow(props);
    }
  };

  const saveNoteFromJourney = async (appointmentId) => {
    try {
      setLoading(true);
      const { data } = await updateNote(
        authenticationStore.userId,
        appointmentId,
        addNoteValue
      );
      if (get(data, "success")) {
        await appointmentStore.refreshAppointments();
        if (onNotes) {
          onNotes();
        }
        setLoading(false);
        setSaveNoteMessage(true);
        setTimeout(() => {
          setLoading(false);
          setSaveNoteMessage(false);
        }, savedNoteLength);
      }
    } catch (e) {
      console.log(e);
      if (get(e, "response.data.errors")) {
        setLoading(false);
        setSaveNoteMessage(false);
        setResponseError(e.response.data.errors[0]);
      }
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResponseError(null);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <div
        className={`appointment-card ${
          appointmentSelected?.card_key === card_key
            ? "appointment-card-selected"
            : ""
        }`}
        onClick={() => handleClickOpen()}
      >
        {appointmentSelected?.card_key === card_key && (
          <img
            className="arrow-pointer"
            src="/icons/Arrow-Left-Material-Filled.png"
            alt="arrow-left-icon"
          />
        )}

        <div className="appointment-circle">
          {appointment !== null && <CheckIcon className="check" />}
        </div>
        <div className="appointment-title">
          <img
            className="icon"
            src="/icons/calendar@0,5x.svg"
            alt="calendar-icon"
          />
          {ReactHtmlParser(highlightedTitleText)}
        </div>

        {event && (
          <div style={{ marginBottom: "10px" }}>
            <span className="event-label">Event</span>
          </div>
        )}

        {group_class && (
          <div style={{ marginBottom: "10px" }}>
            <span className="group-class-label">Group Class</span>
          </div>
        )}

        {isVirtualAppointment && can_book_from_portal && isNotEventOrGroup && (
          <div style={{ marginBottom: "10px" }}>
            <div className="virtual-label">Virtual</div>
            {appointment && appointment.virtual_link && (
              <div className="video-link">
                <a href={appointment.virtual_link} target="_blank">
                  Join video appointment{" "}
                  <img
                    className="icon"
                    src="/icons/Arrow-Right-Main@0,5x.svg"
                    alt="arrow-right-icon"
                  />
                </a>
              </div>
            )}
          </div>
        )}

        {!isVirtualAppointment && can_book_from_portal && isNotEventOrGroup && (
          <div style={{ marginBottom: "10px" }}>
            <div className="office-visit-label">Office Visit</div>
          </div>
        )}

        {appointment && (
          <div className="appointment-card-details-time appointment-card-time">
            {moment(appointment.start_time).format("ddd")},{" "}
            {moment(appointment.start_time).format("MMM")}{" "}
            {moment(appointment.start_time).format("DD")},{" "}
            {moment(appointment.start_time).format("YYYY")}
            <br />
            {moment.utc(appointment.start_time).format("hh:mm A")} ET
            <a className="expand expand-link" onClick={handleClickOpen}>
              expand
            </a>
          </div>
        )}

        {description && !appointment && (
          <div className="appointment-description">
            {description.map((section) => {
              if (get(section, "paragraph") && !firstParagraph) {
                const higlightedText = getHighlightTextSearchMarkup(
                  section.paragraph,
                  searchText
                );

                firstParagraph = true;

                return (
                  <p
                    className="appointment-description-paragraph"
                    key={uniqueId()}
                  >
                    {ReactHtmlParser(higlightedText)}
                  </p>
                );
              } else {
                if (get(section, "list") && !firstList && !firstParagraph) {
                  const higlightedText = getHighlightTextSearchMarkup(
                    section.list.title,
                    searchText
                  );

                  firstList = true;
                  return (
                    <div className="appointment-list-column">
                      <div>
                        <p>{ReactHtmlParser(higlightedText)}</p>
                      </div>
                      <ul>
                        {section.list.items.map((item, index) => {
                          if (index < 2) {
                            const higlightedText = getHighlightTextSearchMarkup(
                              item,
                              searchText
                            );

                            return (
                              <li
                                className="appointment-description-list-item"
                                key={index}
                              >
                                {ReactHtmlParser(higlightedText)}
                              </li>
                            );
                          } else if (index === 1) {
                            return "...";
                          }
                        })}
                      </ul>
                    </div>
                  );
                }
              }
            })}
            <a
              onClick={() => {
                handleClickOpen();
              }}
            >
              expand
            </a>
          </div>
        )}

        {openAddNote &&
          moment(
            moment(appointment?.start_time).format(YYYY_MM_DD_ALTERNATIVE)
          ).isSameOrAfter(moment(moment().format(YYYY_MM_DD_ALTERNATIVE))) && (
            <div className="dialog-notes">
              <div className="comment-question-section">Comments/Questions</div>
              <TextField
                placeholder="Anything we need to know in advance?"
                multiline
                rows={2}
                rowsMax={8}
                value={addNoteValue}
                onChange={(e) => setAddNoteValue(e.target.value)}
              >
                `{">"}`
              </TextField>

              {!savedNoteMessage && (
                <div
                  className="save-button"
                  onClick={() => saveNoteFromJourney(appointment.id)}
                >
                  Save
                  <span style={{ display: !loading && "none" }}>
                    <ClipLoader color={"#ba624a"} loading={loading} size={10} />
                  </span>
                </div>
              )}

              {savedNoteMessage && (
                <div className="saved">
                  Saved <Check />
                </div>
              )}
            </div>
          )}

        <div
          className={`appointment-buttons ${
            openAddNote && `appointment-buttons-note`
          }`}
        >
          {appointment && !event && !group_class && (
            <commonMaterial.ReversedCustomButton
              variant="outlined"
              size="large"
              color="primary"
              type="submit"
              style={{ padding: "5px 30px", fontSize: "14px" }}
              onClick={() => {
                appointmentStore.setAppointment({
                  appointment_type_id: appointment_type_id,
                  appointment_card_key: card_key,
                  add_note_enabled: add_note_enabled,
                  card_title: title,
                  appointment_id: appointment.id,
                  start_time: appointment.start_time,
                  appointment_type_description:
                    appointment.appointment_type_description,
                  virtual_link: appointment.virtual_link,
                  begin_date_range: moment(begin_date_range).format(
                    YYYY_MM_DD_ALTERNATIVE
                  ),
                  card_is_virtual: virtual,
                  reschedule: true,
                });

                history.push(PATHNAMES.VIEW_APPOINTMENT);
              }}
            >
              RESCHEDULE
            </commonMaterial.ReversedCustomButton>
          )}

          {(enable_book_now && !appointment && can_book_from_portal) ||
            ((event || (group_class && !journeyStore.publicView)) && (
              <commonMaterial.CustomButton
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                style={{ padding: "5px 30px", fontSize: "14px" }}
                onClick={(e) => {
                  if (event) {
                    e.stopPropagation();
                    window.location.href = LINKS.events;
                  } else if (group_class) {
                    history.push(PATHNAMES.OFFICE_HOURS);
                  } else {
                    appointmentStore.setAppointment({
                      appointment_type_id: appointment_type_id,
                      appointment_card_key: card_key,
                      add_note_enabled: add_note_enabled,
                      card_title: title,
                      appointment_type_description:
                        appointment_type_description,
                      begin_date_range:
                        begin_date_range &&
                        moment(begin_date_range).isAfter(
                          moment().add(1, "days")
                        )
                          ? moment(begin_date_range).format(
                              YYYY_MM_DD_ALTERNATIVE
                            )
                          : moment(moment().add(1, "days")).format(
                              YYYY_MM_DD_ALTERNATIVE
                            ),
                      card_is_virtual: virtual,
                    });

                    history.push(PATHNAMES.SCHEDULING);
                  }
                }}
              >
                BOOK NOW
              </commonMaterial.CustomButton>
            ))}

          {add_note_enabled &&
            !openAddNote &&
            appointment &&
            moment(
              moment(appointment?.start_time).format(YYYY_MM_DD_ALTERNATIVE)
            ).isAfter(moment().format(YYYY_MM_DD_ALTERNATIVE)) && (
              <span className="right-button">
                <Button
                  variant="contained"
                  disableElevation
                  startIcon={<AddIcon className="add-icon" />}
                  onClick={() => setOpenAddNote(!openAddNote)}
                  style={{
                    fontSize: "11.5px",
                    backgroundColor: "white",
                    color: "#ba624a",
                    textTransform: "capitalize",
                  }}
                >
                  Add note
                </Button>
              </span>
            )}
        </div>

        {appointment && appointmentOnSameThanCurrentDay && (
          <div className="dialog-notes">
            <TextField
              placeholder="Anything we need to know in advance?"
              multiline
              rows={2}
              rowsMax={8}
              disabled
              value={cancellationSameDayMsg}
            />
          </div>
        )}
        {!last_card && <div className="vertical-line-appointment" />}
      </div>

      {openDialog && (
        <Dialog fullScreen open={openDialog} onClose={handleClose}>
          <AppointmentCardDetails
            handleClose={handleClose}
            onUpdateNote={() => findLastNoteInAppointmentsArray()}
            searchText={searchText}
            {...props}
          />
        </Dialog>
      )}

      <Snackbar
        open={responseError}
        autoHideDuration={toastLength}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {responseError}
        </Alert>
      </Snackbar>
    </>
  );
};
