import { observer } from "mobx-react-lite";
import {
  AppBar,
  Container,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Toolbar,
  Select,
  Snackbar,
  TextField,
} from "@material-ui/core";
import { useState } from "react";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";
import { ExpandMore } from "@material-ui/icons";
import useAsyncEffect from "use-async-effect";
import { ThemeProvider } from "@material-ui/core/styles";
import { get } from "lodash";
import MuiAlert from "@material-ui/lab/Alert";
import ClipLoader from "react-spinners/ClipLoader";

import { appointmentStore } from "../../../stores/appointment-store";
import { toastLength } from "../../../services/constants";
import "./Location.scss";
import * as commonMaterial from "../../../materialDesignShared";
import { formOptionsStore } from "../../../stores/form-options-store";
import { itemsTwoValue } from "../../../services/formSettings";
import { cancelAppointment } from "../../../api/scheduling";
import { authenticationStore } from "../../../stores/authentication-store";
import { getDashboard } from "../../../api/dashboard";
import { YYYY_MM_DD_ALTERNATIVE } from "../../../constants/dateFormat";
import { cancellationSameDayMsg } from "../../../constants/toastMessages";
import { PATHNAMES } from "../../../constants/pathnames";
import { sonoShortnames } from "../../../constants/sonoShortnames";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";
import { useFeatureFlag } from "../../../providers/LaunchDarklyProvider";

export const RescheduleAppointment = observer(({ nextAppointment }) => {
  const [appointment, setAppointment] = useState(null);
  const [appointmentTypeId, setAppointmentTypeId] = useState(null);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [valueCancellationReason, setValueCancellationReason] = useState("");
  const [cancellationConfirmed, setCancellationConfirmed] = useState(false);
  const [settings, setSettings] = useState({});
  const [errorClass, setErrorClass] = useState(null);
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistoryWithPathBasedReload();
  const userData = authenticationStore?.userInfo;
  const departmentInfo = userData?.department;

  const appointmentOnSameThanCurrentDay =
    appointment?.scheduled &&
    moment(
      moment.utc(appointment?.start_time).format(YYYY_MM_DD_ALTERNATIVE)
    ).isSame(moment(moment().format(YYYY_MM_DD_ALTERNATIVE)));

  useAsyncEffect(async () => {
    if (
      !appointmentStore.getAppointment()?.reschedule &&
      !nextAppointment &&
      authenticationStore.userId
    ) {
      history.goBack();
    }

    if (nextAppointment) {
      const { data } = await getDashboard(authenticationStore.userId);
      if (get(data, "dashboard.patient_next_appointment")) {
        if (data.dashboard.patient_next_appointment.external_appointment_id) {
          appointmentStore.setAppointment({
            ...data.dashboard.patient_next_appointment,
            begin_date_range:
              data.dashboard.patient_next_appointment.start_time,
          });
          setAppointment(data.dashboard.patient_next_appointment);
          setAppointmentTypeId(
            data.dashboard.patient_next_appointment.appointment_type_id
          );
        } else {
          history.push(PATHNAMES.SCHEDULING);
        }
      } else {
        history.push(PATHNAMES.SCHEDULING);
      }
    } else {
      setAppointment(appointmentStore.getAppointment());
      setAppointmentTypeId(
        appointmentStore.getAppointment()?.appointment_type_id
      );
    }
  }, []);

  useAsyncEffect(async () => {
    setSettings(formOptionsStore.formSettingsInfo);
  }, [formOptionsStore.formSettingsInfo]);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setToast("");
  };

  let departmentDisplay = "";
  const appointmentShortname = appointment?.appointment_type_shortname;
  const bkSonoRedirect =
    sonoShortnames.includes(appointmentShortname) &&
    departmentInfo?.display_name === "109 Montague Street";
  if (bkSonoRedirect)
    departmentDisplay = "Downtown Manhattan 220 Spring Street";
  else
    departmentDisplay = `${departmentInfo?.department_name} ${departmentInfo?.display_name}`;

  return (
    <ThemeProvider theme={commonMaterial.theme}>
      <Container
        className={`location-scheduling-container reschedule-container`}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <>
            <div className={`location-scheduling-title`}>
              {cancellationConfirmed && (
                <div className="calendar-icon">
                  <img
                    className="icon"
                    src="/icons/cancellation-main@0,5x.svg"
                    alt="calendar-icon"
                  />
                </div>
              )}
              {!cancellationConfirmed ? (
                <span>
                  {" "}
                  Scheduled <br />
                  appointment
                </span>
              ) : (
                <span>Cancellation confirmed</span>
              )}
            </div>
            <div
              className="location-container confirmation-container"
              style={{ width: "90%", maxWidth: "22rem", padding: ".5rem" }}
            >
              <div className="tag-info">
                <div className="margin-left-15">
                  {appointment?.virtual_link || appointment?.card_is_virtual ? (
                    <div className="virtual-label">Virtual</div>
                  ) : (
                    <div className="office-visit-label">Office Visit</div>
                  )}
                </div>
              </div>
              {appointment?.appointment_type_description && (
                <div className="card-info">
                  {appointment?.appointment_type_description
                    .toLowerCase()
                    .includes("visit")
                    ? appointment?.appointment_type_description
                    : `${appointment?.appointment_type_description} Visit`}
                </div>
              )}
              {!appointment?.virtual_link &&
                !appointment?.card_is_virtual &&
                departmentInfo && (
                  <div className="card-info">{departmentDisplay}</div>
                )}
              <div className="time">
                {moment.utc(appointment?.start_time).format("hh:mm A")} ET
                <br />
                {moment(appointment?.start_time).format("ddd")},{" "}
                {moment(appointment?.start_time).format("MMM")}{" "}
                {moment(appointment?.start_time).format("DD")},{" "}
                {moment(appointment?.start_time).format("YYYY")}
              </div>
              {appointment?.virtual_link && !cancellationConfirmed && (
                <div className="video-code-container">
                  <div className="video-code-title">Virtual link</div>
                  <div className="vide-code-link">
                    <a href={appointment?.virtual_link} target="_blank">
                      Join video appointment{" "}
                      <img
                        className="icon"
                        src="/icons/Arrow-Right-Main@0,5x.svg"
                        alt="arrow-right-icon"
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>

            {appointmentOnSameThanCurrentDay && (
              <div className="dialog-notes">
                <TextField
                  multiline
                  rows={1}
                  rowsMax={2}
                  disabled
                  value={cancellationSameDayMsg}
                ></TextField>
              </div>
            )}

            {!cancellationConfirmed && (
              <div className="two-buttons">
                <div>
                  <commonMaterial.ReversedCustomYellowButton
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ padding: "5px 39px", fontSize: "14px" }}
                    onClick={() => setCancelDialog(true)}
                  >
                    CANCEL
                  </commonMaterial.ReversedCustomYellowButton>
                </div>
                <div>
                  <commonMaterial.ReversedCustomYellowButton
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ padding: "5px 20px", fontSize: "14px" }}
                    onClick={() => {
                      appointmentStore.setAppointment({
                        ...appointmentStore.getAppointment(),
                        begin_date_range: moment().add(1, "days"),
                        start_time: moment().add(1, "days"),
                        reschedule: true,
                      });
                      history.push(
                        `${PATHNAMES.LOCATION}?appointment_type_id=${appointmentTypeId}`
                      );
                    }}
                  >
                    RESCHEDULE
                  </commonMaterial.ReversedCustomYellowButton>
                </div>
              </div>
            )}

            {authenticationStore.getTaggedAppointment() === "true" &&
              cancellationConfirmed && (
                <div className="cancellation-container">
                  <div
                    className={`go-back ${
                      authenticationStore.userId ? "" : "go-back-auth"
                    } `}
                    onClick={() => history.push("/dashboard")}
                  >
                    Go to dashboard
                  </div>
                </div>
              )}

            {authenticationStore.getTaggedAppointment() === null && (
              <div
                className={`go-back ${
                  authenticationStore.userId ? "" : "go-back-auth"
                } `}
                onClick={() => history.goBack()}
              >
                Go back
              </div>
            )}

            <Dialog
              open={cancelDialog}
              onClose={() => setCancelDialog(false)}
              className="fixed-dialog-bottom cancel-appointment"
            >
              <Container maxWidth="sm">
                <AppBar>
                  <Toolbar>
                    <div className="cancel-appointment-title">
                      Are you sure you <br />
                      want to cancel <br />
                      your appointment?
                    </div>
                    <CloseIcon
                      edge="end"
                      color="inherit"
                      onClick={() => setCancelDialog(false)}
                      aria-label="close"
                    />
                  </Toolbar>
                </AppBar>
                <div className={`cancellation-body ${errorClass && "error"}`}>
                  <FormControl>
                    <Select
                      labelId="select_cancellation_reason"
                      id="select_cancellation_reason"
                      displayEmpty
                      required
                      value={valueCancellationReason}
                      onChange={(e) =>
                        setValueCancellationReason(e.target.value)
                      }
                      IconComponent={ExpandMore}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem disabled value="">
                        Select reason
                      </MenuItem>
                      {itemsTwoValue(settings?.data, "cancel_reasons")}
                    </Select>
                  </FormControl>
                </div>
                <div className="yes-no-buttons">
                  <commonMaterial.ReversedCustomYellowButtonBackgroundWhite
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ padding: "5px 20px", fontSize: "14px" }}
                    onClick={async () => {
                      setLoading(true);
                      if (valueCancellationReason === "") {
                        setErrorClass(true);
                        setLoading(false);
                      } else {
                        setErrorClass(false);
                        try {
                          setLoading(true);
                          const { data } = await cancelAppointment(
                            authenticationStore.userId,
                            appointment.appointment_id || appointment.id,
                            valueCancellationReason
                          );
                          if (get(data, "success")) {
                            setLoading(false);
                            setCancellationConfirmed(true);
                            const dashboardData = await getDashboard(
                              authenticationStore.userId
                            );
                            if (get(dashboardData.data, "dashboard")) {
                              appointmentStore.setScheduledAppointments(
                                dashboardData.data.dashboard.appointments
                              );
                            }
                          }
                        } catch (e) {
                          setLoading(false);
                          setToast("Something went wrong.");
                        }
                        setCancelDialog(false);
                      }
                    }}
                  >
                    YES
                    <span
                      style={{ display: !loading && "none" }}
                      className="login-loader"
                    >
                      <ClipLoader
                        color={"#d69722"}
                        loading={loading}
                        size={13}
                      />
                    </span>
                  </commonMaterial.ReversedCustomYellowButtonBackgroundWhite>
                  <commonMaterial.ReversedCustomYellowButtonBackgroundWhite
                    variant="outlined"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{ padding: "5px 20px", fontSize: "14px" }}
                    onClick={() => setCancelDialog(false)}
                  >
                    NO
                  </commonMaterial.ReversedCustomYellowButtonBackgroundWhite>
                </div>
              </Container>
            </Dialog>
          </>
        </Grid>
      </Container>
      <Snackbar
        open={!!toast}
        autoHideDuration={toastLength}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={"error"}>
          {toast}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
});
