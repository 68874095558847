import { FeatureFlags } from "./LaunchDarkly";
import { z } from "zod";
import { pathKeys } from "../constants/pathnames";

const zodEnum = <T extends unknown>(arr: readonly T[]): [T, ...T[]] =>
  arr as [T, ...T[]];

const featureFlags = FeatureFlags.withSchema({
  "check-ins": z.boolean(),
  "enhanced-error-feedback": z.boolean(),
  "free-text-other-insurance-option": z.boolean(),
  "intercom-everywhere": z.boolean(),
  "new-mental-health": z.boolean(),
  "oula-circles": z.boolean(),
  "miscarriage-book-now-alert": z.boolean(),
  "sign-up-to-circles-prompt": z.boolean(),
  "migrated-pages": z.enum(zodEnum(pathKeys)).array(),
  "upper-west-side-redirect": z.boolean(),
})
  .andFallbackValues({
    "check-ins": false,
    "enhanced-error-feedback": false,
    "free-text-other-insurance-option": false,
    "intercom-everywhere": true,
    "new-mental-health": false,
    "oula-circles": false,
    "miscarriage-book-now-alert": false,
    "sign-up-to-circles-prompt": false,
    "migrated-pages": [],
    "upper-west-side-redirect": true,
  })
  .andLoggers(console.error, console.log);

export const useFeatureFlag = featureFlags.useFeatureFlag;
export const useFeatureFlagEvaluator = featureFlags.useFeatureFlagEvaluator;
export const useUpdateFeatureFlagUser = featureFlags.useUpdateFeatureFlagUser;
export const FeatureFlagProvider = featureFlags.LDProvider;
export const StaticFeatureFlagProvider = featureFlags.StaticProvider;
export type FeatureFlagEvaluator = ReturnType<
  typeof featureFlags.useFeatureFlagEvaluator
>;
