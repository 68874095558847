import {
  Box,
  Button,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useEffect, useState } from "react";
import checkAbnormal, { checkAbnormalValues } from "./checkAbnormal";
import { BpReading } from "./types";
import { theme } from "../../materialDesignShared";

const readingSize = "1.6rem";

const alertColor = "#EC5A5A";

const entryNumber = (
  value: number,
  caption: string,
  abnormal: boolean = false
) => (
  <Box display={"inline"} fontSize={readingSize}>
    <Grid container direction="column" alignItems="flex-start">
      <Grid container style={{ gap: ".4rem" }}>
        <Box
          style={{
            background: abnormal ? alertColor : "none",
            padding: "0.1rem 0.4rem",
            borderRadius: "5px",
            color: abnormal ? "white" : "inherit",
          }}
        >
          {value}
        </Box>
        {abnormal && <span style={{ color: alertColor }}>↑</span>}
      </Grid>
      <Typography
        variant="caption"
        color="textSecondary"
        style={{ padding: "0 0.4rem" }}
      >
        {caption}
      </Typography>
    </Grid>
  </Box>
);

const Entry = ({ reading, patientReadings }: { reading: BpReading; patientReadings: BpReading[] }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { timeOfReading, systolic, diastolic, patientMessaging } = reading;
  const [sysAbnormal, diaAbnormal] = checkAbnormalValues(
    reading.systolic,
    reading.diastolic
  );
  const abnormal = sysAbnormal || diaAbnormal;
  const pastReadings = () => {
    const currentTimeOfReading = reading.timeOfReading;
    const pastReadings: BpReading[] = [];
      patientReadings.forEach((patientReading) => {
        if (new Date(patientReading.timeOfReading) < new Date(currentTimeOfReading)) {
          pastReadings.push(patientReading);
        }
      });
    return pastReadings;
  };

  const readingWithAbnormal = checkAbnormal(
    {
      timeOfReading: reading.timeOfReading,
      timeOfSubmission: reading.timeOfSubmission,
      systolic: reading.systolic,
      diastolic: reading.diastolic,
      patientMessaging: "",
      stageCode: "",
    },
    pastReadings()
  );
  const visibleText = readingWithAbnormal.visibleText;
  const hoverText = readingWithAbnormal.hoverText;

  return (
    <Box
      className={`blood-pressure-entry ${
        abnormal && "blood-pressure-entry-abnormal"
      }`}
    >
      <Box marginLeft={"1rem"} paddingBottom={"1rem"}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          style={{ gap: "1rem" }}
        >
          <Box
            padding={".4rem 0"}
            // i eyeballed these values
            marginLeft={abnormal ? "-2.115rem" : "-1.54rem"}
          >
            <Typography
              variant="caption"
              color="textSecondary"
              style={{
                padding: "0 0.4rem",
                marginLeft: abnormal ? "2.35rem" : "1.4rem",
              }}
            >
              {timeOfReading.toLocaleString("en-us", {
                hour: "numeric",
                minute: "numeric",
                timeZone: "America/New_York",
              })}
            </Typography>
            <Grid container direction="row" alignItems="flex-start">
              {abnormal ? (
                <Box className="blood-pressure-abnormal-badge">!</Box>
              ) : (
                <Box className="blood-pressure-badge"></Box>
              )}
              {entryNumber(systolic, "SYS", sysAbnormal)}
              <span style={{ fontSize: readingSize, padding: "0 0.3rem" }}>
                /
              </span>
              {entryNumber(diastolic, "DIA", diaAbnormal)}
            </Grid>
          </Box>
        </Grid>
        {patientMessaging && (
          <Box
            className={`blood-pressure-description ${
              abnormal && "blood-pressure-abnormal-description"
            }`}
          >
            <ClickAwayListener
              onClickAway={(e) => {
                setTooltipOpen(false);
              }}
            >
              <Tooltip
                title={hoverText}
                open={tooltipOpen}
                disableTouchListener
              >
                <span
                  onMouseEnter={() => setTooltipOpen(true)}
                  onMouseOut={() => setTooltipOpen(false)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".4rem",
                  }}
                >
                  {visibleText}
                  <IconButton
                    onTouchStart={() => {
                      console.log("SCREAM!");
                      setTooltipOpen(!tooltipOpen);
                    }}
                    onMouseEnter={() => setTooltipOpen(true)}
                    style={{
                      fontSize: "0.8rem",
                      background: "gray",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "50%",
                      width: "1.0rem",
                      height: "1.0rem",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    ?
                  </IconButton>
                </span>
              </Tooltip>
            </ClickAwayListener>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const EntryList = ({ readings }: { readings: BpReading[] }) => {
  const [collapse, setCollapse] = useState<Record<string, boolean>>({});
  useEffect(() => {
    const datesContainingReadings: typeof collapse = {};
    readings.forEach(({ timeOfReading }) => {
      const key = timeOfReading.toDateString();
      const isOlderThanThreeDays =
        Date.now() - timeOfReading.getTime() > 2.592e8;
      datesContainingReadings[key] = !isOlderThanThreeDays;
    });
    setCollapse(datesContainingReadings);
  }, []);

  const isSmallViewport = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid
      container
      direction="column"
      style={{ padding: "1rem", maxWidth: "30rem" }}
    >
      <Typography
        variant="h6"
        align="center"
        style={{
          width: "100%",
          display: "block",
        }}
      >
        <b>LOG HISTORY</b>
        <Typography variant="caption" align="center" display="block">
          Times are shown in the local time of your clinic
        </Typography>
      </Typography>
      <Grid container justifyContent="center" alignItems="center">
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          style={{ width: "auto" }}
        >
          {readings.length <= 0 && (
            <Box margin={"1.2rem"}>
              <Typography variant="caption" align="center" display="block">
                There are no recorded readings.
              </Typography>
            </Box>
          )}
          {readings
            .map((reading, i) => {
              const render = collapse[reading.timeOfReading.toDateString()];
              return (
                <Box
                  minWidth={isSmallViewport ? "" : "415px"}
                  maxWidth={"90vw"}
                >
                  {/* inline date display every time we change days */}
                  {(i < readings.length - 1
                    ? readings[i + 1].timeOfReading.getDate()
                    : 99) !== reading.timeOfReading.getDate() ? (
                    <Button
                      className="blood-pressure-collapse-button"
                      variant="text"
                      onClick={() => {
                        const newCollapse = { ...collapse };
                        const key = reading.timeOfReading.toDateString();
                        newCollapse[key] = !collapse[key];
                        setCollapse(newCollapse);
                      }}
                      style={{ color: "black" }}
                      disableRipple
                      disableTouchRipple
                      disabled={
                        // disable hiding if section is the day of
                        Date.now() - reading.timeOfReading.getTime() < 8.64e7
                      }
                    >
                      <Typography
                        variant="body1"
                        style={{ marginRight: ".6rem" }}
                      >
                        {reading.timeOfReading.toLocaleString("en-us", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          timeZone: "America/New_York",
                        })}
                      </Typography>
                      {render ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                    </Button>
                  ) : null}
                  {render && (
                    <Entry
                      reading={reading}
                      key={reading.timeOfReading.getTime()}
                      patientReadings={readings}
                    />
                  )}
                </Box>
              );
            })
            .reverse()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntryList;
