import { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import CheckIcon from "@material-ui/icons/Check";
import { get } from "lodash";
import ReactHtmlParser from "react-html-parser";

import "./EducationCard.scss";
import { updateBookmark, updateEducation } from "../../../api/journey";
import { authenticationStore } from "../../../stores/authentication-store";
import { journeyStore } from "../../../stores/journey-store";
import { EducationCardDetails } from "./EducationCardDetails";
import {
  maxMobileScreenWidth,
  maxTabletScreenWidth,
} from "../../../services/constants";
import { getHighlightTextSearchMarkup } from "../../../helpers/highlighterHelpers";

/*global rocketcss, $*/
/*eslint no-undef: "error"*/

export const EducationCard = observer((props) => {
  const {
    title,
    description,
    patient_done,
    patient_bookmark,
    id,
    bookmarkedDecision,
    updateBookmarks,
    last_card,
    onClose,
    educationCardToShow,
    searchText,
  } = props;

  const [openDialog, setOpenDialog] = useState(bookmarkedDecision);
  const [successfullySavedBookmark, setSuccessfullySavedBookmark] = useState(
    patient_bookmark?.is_active || false
  );
  const [done, setDone] = useState(patient_done);
  const [educationSelected, setEducationSelected] = useState(null);
  let firstParagraph = false;
  let firstList = false;

  const highlightedTitleText = getHighlightTextSearchMarkup(title, searchText);

  const setAnimationHandler = () => {
    $(`#education-${id}`).on("click", function () {
      window.innerWidth >= maxMobileScreenWidth
        ? rocketcss(this, ".bookmarks-button-desktop", "rocketPulseHole")
        : rocketcss(this, ".bookmark", "rocketPulseHole");
      $(".profileIconContainer").addClass("targetPulse");
      $(`#education-${id}`).css("opacity", "1");
    });
  };
  const removeAnimationHandler = () => {
    $(`#education-${id}`).off("click");
  };

  useEffect(() => {
    setTimeout(() => {
      !patient_bookmark?.is_active && setAnimationHandler();
    }, 1000);
    setSuccessfullySavedBookmark(patient_bookmark?.is_active);
  }, [patient_bookmark?.is_active]);

  useEffect(() => {
    setDone(patient_done);
  }, [patient_done]);

  useEffect(() => {
    if (educationCardToShow?.id === id) {
      setEducationSelected(educationCardToShow);
    } else {
      setEducationSelected(null);
    }
  }, [educationCardToShow]);

  const handleClickOpen = () => {
    if (window.innerWidth < maxTabletScreenWidth) {
      setOpenDialog(true);
    } else {
      journeyStore.setEducationCardToShow(props);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
    if (onClose) {
      onClose();
    }
  };

  const saveBookmark = async (isActive) => {
    try {
      const { data } = await updateBookmark(authenticationStore.userId, {
        card_id: id,
        card_type: "EducationCard",
        is_active: isActive.toString(),
      });
      if (!isActive) {
        setAnimationHandler();
      } else {
        removeAnimationHandler();
      }
      setSuccessfullySavedBookmark(isActive);
      if (updateBookmarks) {
        await Promise.all([
          journeyStore.refreshBookmarks(),
          journeyStore.refreshJourney(),
        ]);
      }
    } catch (e) {
      if (get(e, "response.status") === 422) {
        console.log(e.response.data.errors[0]);
      }
      setSuccessfullySavedBookmark(false);
    }
  };

  function BookmarkBorderIcon() {
    return (
      <img
        src="/icons/Bookmark@0,5x.svg"
        alt="bookmark-icon"
        className="bookmarkIcon"
      />
    );
  }

  function BookmarkIcon() {
    return (
      <img
        src="/icons/Bookmark/Filled@0,5x.svg"
        alt="bookmark-filled-icon"
        className="bookmark-filled-icon"
      />
    );
  }

  return (
    <>
      <div
        className={`education-card ${
          educationSelected?.id === id ? "education-card-selected" : ""
        }`}
        onClick={() => handleClickOpen()}
      >
        {educationSelected?.id === id && (
          <img
            className="arrow-pointer"
            src="/icons/Arrow-Left-Material-Filled.png"
            alt="arrow-left-icon"
          />
        )}
        <div className="education-circle">
          {done && <CheckIcon className="check" />}
        </div>
        <div className="education-body">
          <div className="education-title">
            <img className="icon" src="/icons/Book@0,5x.svg" alt="book-icon" />
            {ReactHtmlParser(highlightedTitleText)}
          </div>
          {description && (
            <div className="education-description">
              {description.map((section) => {
                if (get(section, "paragraph") && !firstParagraph) {
                  const higlightedText = getHighlightTextSearchMarkup(
                    section.paragraph,
                    searchText
                  );

                  firstParagraph = true;

                  return (
                    <p className="education-description-paragraph">
                      {ReactHtmlParser(higlightedText)}
                    </p>
                  );
                } else {
                  if (get(section, "list") && !firstList && !firstParagraph) {
                    const higlightedText = getHighlightTextSearchMarkup(
                      section.list.title,
                      searchText
                    );

                    firstList = true;

                    return (
                      <div className="education-list-column">
                        <p>{ReactHtmlParser(higlightedText)}</p>
                      </div>
                    );
                  }
                }
              })}
              <a onClick={handleClickOpen}>expand</a>
            </div>
          )}
          {!journeyStore.publicView && (
            <div className="education-buttons">
              <div
                className="right-button"
                onClick={(e) => {
                  e.stopPropagation();
                  saveBookmark(!successfullySavedBookmark);
                }}
              >
                <div id={`education-${id}`}>
                  {successfullySavedBookmark ? (
                    <BookmarkIcon />
                  ) : (
                    <BookmarkBorderIcon />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
        {!last_card && <div className="vertical-line-education" />}
      </div>

      {openDialog && (
        <Dialog fullScreen open={openDialog} onClose={handleClose}>
          <EducationCardDetails
            handleClose={handleClose}
            searchText={searchText}
            {...props}
          />
        </Dialog>
      )}
    </>
  );
});
