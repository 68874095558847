import { Box, Button, Checkbox, Grid, Typography } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import {
  SurveyQuestionComponent,
  SurveyQuestionProps,
} from "../SurveyQuesiton";
import { CheckinResponse } from "../../../api/mentalHealth";

const SelectMultiple = <T extends any>(props: SurveyQuestionComponent<T>) => {
  if (props.type !== "select_multiple")
    throw new Error("wrong type for select_multiple component!");

  return (
    <Box>
      <Typography
        style={{
          color: "#ba624a",
          fontWeight: "bold",
          marginBottom: "1reM",
        }}
      >
        Select multiple
      </Typography>
      <Grid
        container
        direction={props.display_mode === "checkbox" ? "column" : "row"}
        justifyContent="center"
      >
        {props.options.map(({ display_name, answer_value }, i) => {
          return (
            <Field
              className="survey-radio"
              style={{
                display: props.display_mode === "pill" ? "inline" : "block",
                accentColor: "#000",
                height: "1.1rem",
                width: "1.1rem",
                margin: 0,
              }}
              type="checkbox"
              name={props.name}
              value={answer_value}
              component={(cP: FieldProps) => {
                // copy the array of values, init an empty array if it's null ie untouched
                let newFieldValues: string[] =
                  props.value !== null ? [...props.value] : [];
                // remove the checkbox value from the array
                newFieldValues = [
                  ...newFieldValues.filter((v) => cP.field.value !== v),
                ];
                // if the checkbox is checked, add it back into the array
                if (!cP.field.checked) newFieldValues.push(cP.field.value);

                let display: JSX.Element;
                switch (props.display_mode) {
                  case "checkbox": {
                    display = (
                      <Box
                        style={{
                          flexGrow: 1,
                          margin: ".2rem",
                          padding: ".8rem",
                          display: "flex",
                          alignItems: "center",
                          background:
                            props.value &&
                            props.value.indexOf(answer_value) > -1
                              ? "#da9c86"
                              : "#faf4ec",
                        }}
                      >
                        <Checkbox
                          checked={cP.field.checked}
                          className="modal-checkbox"
                        />
                        <Typography>{display_name}</Typography>
                      </Box>
                    );
                    break;
                  }
                  case "pill": {
                    display = (
                      <Box
                        style={{
                          margin: ".2rem",
                          padding: ".3rem .8rem",
                          display: "inline",
                          borderRadius: "1rem",
                          background:
                            props.value &&
                            props.value.indexOf(answer_value) > -1
                              ? "#da9c86"
                              : "#faf4ec",
                        }}
                      >
                        <Typography>{display_name}</Typography>
                      </Box>
                    );
                    break;
                  }
                  default: {
                    throw new Error(
                      `unknown display_mode ${props.display_mode}`
                    );
                  }
                }

                return (
                  <Button
                    onClick={() =>
                      cP.form.setFieldValue(cP.field.name, newFieldValues)
                    }
                    className="modal-handler-button"
                  >
                    {display}
                  </Button>
                );
              }}
            ></Field>
          );
        })}
      </Grid>
    </Box>
  );
};

export default SelectMultiple;
