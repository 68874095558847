import { createContext, useContext, useState } from "react";
import * as authApi from "../../api/auth";
import { useAsyncEffect } from "use-async-effect";
import { GoogleOAuthProvider } from "@react-oauth/google";

const GoogleContext = createContext(undefined);

export const SignInWithGoogleProvider = ({ children }) => {
  const [clientId, setClientId] = useState();

  useAsyncEffect(async () => {
    try {
      await authApi.getCookie();
      console.log("Getting client id");
      const data = await authApi.getClientId();
      setClientId(data.data.client_id);
    } catch (e) {
      console.error(e);
    }
  });

  if (clientId) {
    return (
      <GoogleContext.Provider value={{ initialized: true }}>
        <GoogleOAuthProvider clientId={clientId}>
          {children}
        </GoogleOAuthProvider>
      </GoogleContext.Provider>
    );
  } else {
    return (
      <GoogleContext.Provider value={{ initialized: false }}>
        {children}
      </GoogleContext.Provider>
    );
  }
};

export const useSignInWithGoogle = () => {
  const context = useContext(GoogleContext);
  if (context === undefined) {
    throw new Error(
      "Tried to call useSignInWithGoogle hook outside of SignInWithGoogleProvider component."
    );
  }
  return context;
};
