import { ThemeProvider } from "@material-ui/core/styles";
import * as commonMaterial from "../../materialDesignShared";
import { CustomButton } from "../../materialDesignShared";
import "./Scheduling.scss";
import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import useAsyncEffect from "use-async-effect";
import { getAppointments } from "../../api/scheduling";
import {
  ScheduleableAppointment,
  ScheduleableAppointmentData,
  ScheduledAppointment,
} from "./SchedulingTypes";
import to from "await-to-js";
import { useEffect, useState } from "react";
import Alert from "@material-ui/lab/Alert";
import { SchedulingCalendar } from "./SchedulingCalendar";
import { getDashboard } from "../../api/dashboard";
import { authenticationStore } from "../../stores/authentication-store";
import CircularProgress from "@material-ui/core/CircularProgress";
import { appointmentStore } from "../../stores/appointment-store";
import { CarouselAppointmentTypes } from "./CarouselAppointmentTypes/CarouselAppointmentTypes";

export const Scheduling = () => {
  // component state
  const [err, setErr]: [null | Error, Function] = useState(null);
  const [apptData, setApptData]: [
    ScheduleableAppointmentData | null,
    Function
  ] = useState(null);
  // we want to store the appt types in the state since the order of Object.keys
  // isn't technically guaranteed to be consistent
  const [availableApptTypes, setAvailableApptTypes]: [string[], Function] =
    useState([]);
  const [apptTypeTab, setApptTypeTab]: [Element[] | null, Function] =
    useState(null);
  const [selectedApptTypeIndex, setSelectedApptTypeIndex]: [
    null | number,
    Function
  ] = useState(null);
  const [selectedApptTypes, setSelectedApptTypes]: [
    ScheduleableAppointmentData[] | null,
    Function
  ] = useState(null);
  useState(false);
  const [dept, setDept] = useState("");

  // helper functions and data
  const humanReadableAppointmentTypes: { [key: string]: string } = {
    pregnancy_care_types: "Pregnancy",
    coaching_types: "Coaching",
    gyn_care_types: "Gynecology",
    preconception_care_types: "Preconception",
  };
  // store selected appointment type in session storage so if user navigates away and comes back
  // it's still the same tab. if they open a new tab, the sessionStorage will be empty,
  // so it'll default to the first tab
  const apptTypeStorageKey = "selected-appointment-type-key";
  // wrap sessionStorage and component state update in one function
  const updateApptTypeStateAndStore = (apptTypeIndex: number) => {
    setSelectedApptTypeIndex(apptTypeIndex);
    // store the appt type as a string since between visits the # of available appt types could change
    sessionStorage.setItem(
      apptTypeStorageKey,
      availableApptTypes[apptTypeIndex]
    );
  };

  // effect hooks
  // only show the calendar if the user has appointments scheduled
  useAsyncEffect(async () => {
    await appointmentStore.refreshAppointments();
    const storedScheduledAppts =
      appointmentStore.getScheduledAppointments() as ScheduledAppointment[];
    const futureAppts = storedScheduledAppts.filter(
      ({ start_time }) => new Date(start_time) > new Date()
    );
  });
  useAsyncEffect(async () => {
    const { userId } = authenticationStore;
    if (userId === undefined) return;
    const dashboardData = await getDashboard(userId);
    const department = dashboardData?.data.dashboard.patient.department || "";
    setDept(department);
  }, []);
  useAsyncEffect(async () => {
    // grab available appointment types on component mount
    const [getApptsErr, apptRes] = await to(getAppointments());
    if (getApptsErr || apptRes === undefined) {
      setErr(getApptsErr);
      console.error(getApptsErr);
      return;
    }
    const resData: { [key: string]: ScheduleableAppointment[] } = apptRes.data;
    // remove appointment types key since it's not relevant to what we need to show
    const { appointment_types, ...relevantData } = resData;

    // sort appts by GA so appts w/ specified earlier GA appear first, and later/unspecified GA appear last
    const sortedData: Record<string, ScheduleableAppointment[]> = {};
    Object.entries(relevantData).map(([category, categoryAppts], i) => {
      // assign a very high number to appts w/ no specified GA so that appts w/ specified GA show up first
      const normalizeApptsGA = categoryAppts.map((a) => ({
        ...a,
        ga_start: a.ga_start ?? 999,
        ga_end: a.ga_end ?? 999,
      }));
      const sortedAppts = normalizeApptsGA.sort(
        (a, b) => a.ga_start - b.ga_start
      );
      sortedData[category] = sortedAppts;
    });

    // filter out appt types that we want to hide conditionally
    // hide coaching appts for patients eligible for gyn care
    if (sortedData?.gyn_care_types.length > 0) sortedData.coaching_types = [];

    // move around data so it's easier to display
    const filteredData: { [key: string]: ScheduleableAppointment[] } = {};
    Object.keys(sortedData).forEach((k: string) =>
      sortedData[k].length > 0 ? (filteredData[k] = sortedData[k]) : null
    );

    // push the relevant data to the component state
    setAvailableApptTypes(Object.keys({ ...filteredData }));
    setApptData({ ...filteredData });
  }, []);

  useEffect(() => {
    if (apptData !== null) {
      setApptTypeTab(
        <div className={"appt-type-selector"}>
          {availableApptTypes.map((k: string, i: number) => (
            <CustomButton
              className={
                i === selectedApptTypeIndex
                  ? "appt-type-selected appt-type"
                  : "appt-type"
              }
              onClick={() => updateApptTypeStateAndStore(i)}
            >
              {humanReadableAppointmentTypes[k] || k}
            </CustomButton>
          ))}
        </div>
      );
      // only pull from storage on first mount
      // component writes to sessionStorage every time it changes
      if (selectedApptTypeIndex === null) {
        const lastSelectedKey: string =
          sessionStorage.getItem(apptTypeStorageKey) || "";
        const findStoredIndex = availableApptTypes.indexOf(lastSelectedKey);
        const newApptTypeIndex = findStoredIndex > -1 ? findStoredIndex : 0;
        updateApptTypeStateAndStore(newApptTypeIndex);
      } else {
        const newSelectedTypes =
          apptData[availableApptTypes[selectedApptTypeIndex]];
        // if we don't null out the value first, the carousel re-renders w/ height 0
        // this is a workaround for that behavior
        setSelectedApptTypes(null);
        window.requestAnimationFrame(() => {
          setSelectedApptTypes(newSelectedTypes);
        });
      }
    }
  }, [apptData, selectedApptTypeIndex, dept]);

  const isSmallViewport = useMediaQuery(
    commonMaterial.theme.breakpoints.down("md")
  );
  // component structure
  return (
    <ThemeProvider theme={commonMaterial.theme}>
      {isSmallViewport === false && (
        <Grid container>
          <Grid md={6}>
            <h1 className="scheduling-title">My Calendar</h1>
          </Grid>
          <Grid md={6}>
            <h1 className="scheduling-title">Book an Appointment</h1>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        alignItems={isSmallViewport ? "center" : "flex-start"}
        style={{
          flexDirection: isSmallViewport ? "column-reverse" : "row",
        }}
      >
        <Grid md={6} xs={12}>
          {isSmallViewport && <h1 className="scheduling-title">My Calendar</h1>}
          {/* 62px top margin aligns calendar w/ the top of the appt cards */}
          <Box marginTop={isSmallViewport ? "0px" : "62px"}>
            <SchedulingCalendar />
          </Box>
        </Grid>

        <Grid md={6} xs={12} container direction="column" alignItems="center">
          {isSmallViewport && (
            <h1 className="scheduling-title hide-md-above">
              Book an Appointment
            </h1>
          )}
          {err ? (
            <Alert severity={"error"}>Error fetching scheduling data!</Alert>
          ) : null}
          <Grid
            container
            justifyContent="center"
            style={{ marginBottom: "1.4rem" }}
          >
            {apptTypeTab}
          </Grid>
          {selectedApptTypes !== null ? (
            <Grid container justifyContent="center">
              <CarouselAppointmentTypes
                appointmentTypes={selectedApptTypes}
                isBookNowPath={false}
                selectedDepartment={dept}
                loadingInfoUser={false}
              />
            </Grid>
          ) : (
            <Grid container justifyContent={"center"}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
