import { useEffect, useMemo, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Check } from "@material-ui/icons";
import AutoComplete from "@material-ui/lab/Autocomplete";
import OutsideClickHandler from "react-outside-click-handler";

import {
  getInsurancePackage,
  getIntakeFormInfo,
  setIntakeFormAnswers,
} from "../../../api/intakeForm";
import { authenticationStore } from "../../../stores/authentication-store";
import * as commonMaterial from "../../../materialDesignShared";
import { CustomButton } from "../../../materialDesignShared";
import { FIELDS_INFO } from "../../../constants/intakeFormSections";
import { observer } from "mobx-react-lite";
import _debounce from "lodash/debounce";
import _get from "lodash/get";
import _find from "lodash/find";
import _map from "lodash/map";
import ProfileConfirmModal from "../ProfileConfirmModal";
import { useFeatureFlag } from "../../../providers/LaunchDarklyProvider";

const ProfileBilling = observer(({ setUnsaved }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [savedFields, setSavedFields] = useState([]);
  const [insuranceGroupText, setInsuranceGroupText] = useState("");
  const [insuranceGroupOptions, setInsuranceGroupOptions] = useState([]);
  const [intakeFormInfo, setIntakeFormInfo] = useState({});
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
    useState("");
  const [insuranceOtherString, setInsuranceOtherString] = useState("");
  const [selectedInsurancePackageId, setSelectedInsurancePackageId] =
    useState("");
  const [selectedInsurancePackage, setSelectedInsurancePackage] = useState([
    { label: "" },
  ]);
  const [hasOtherInsurance, setHasOtherInsurance] = useState("");
  const [insuranceMemberId, setInsuranceMemberId] = useState("");
  // 0: no activity
  // 1: loading
  // 2: success
  // 3: error
  const [saveState, setSaveState] = useState(0);
  const [insuranceLoading, setInsuranceLoading] = useState(true);
  // for insurance, prompt the user if they're sure they want to make the changes
  const [showUnconfirmedChangesModal, setShowUnconfirmedChangesModal] =
    useState(false);
  const { freeTextOtherInsuranceOption } = useFeatureFlag(
    "free-text-other-insurance-option"
  );

  useEffect(() => {
    if (savedFields.length > 0) setUnsaved(true);
    else setUnsaved(false);
  }, [savedFields]);

  const insuranceMemberIdKey = FIELDS_INFO.insurance_member_id.key;
  const insuranceOtherStringKey = FIELDS_INFO.insurance_other_string;
  const insurancePackageIdKey = FIELDS_INFO.emr_insurancepackageid.key;
  const insuranceCarrierKey = FIELDS_INFO.insurance_carrier.key;
  const intakeFormAnswers = _get(
    intakeFormInfo,
    "patient_intake_form_answers",
    ""
  );

  useAsyncEffect(async () => {
    const { data } = await getIntakeFormInfo(authenticationStore.userId);
    const insuranceGroups = _get(
      data,
      "intake_form.insurance_groups_options",
      []
    );
    const patientIntakeFormInfo = _get(data, "intake_form", {});
    const patientIntakeFormAnswers = _get(
      patientIntakeFormInfo,
      "patient_intake_form_answers",
      {}
    );
    const insuranceProvider = _get(
      patientIntakeFormAnswers,
      insuranceCarrierKey,
      ""
    );
    const insuranceMemberId = _get(
      patientIntakeFormAnswers,
      insuranceMemberIdKey,
      ""
    );
    const insuranceOtherStringValue = _get(
      patientIntakeFormAnswers,
      insuranceOtherStringKey,
      ""
    );
    const insurancePackageId = _get(
      patientIntakeFormAnswers,
      insurancePackageIdKey,
      ""
    );
    const selectedInsurance = _find(
      insuranceGroups,
      ({ id }) => id === parseInt(insuranceProvider)
    );

    const insuranceName = selectedInsurance?.name;

    if (insuranceName === undefined || insuranceMemberId === null) {
      setInsuranceLoading(false);
    }

    if (insuranceName) {
      setSelectedInsuranceProvider(insuranceName);

      if (insuranceMemberId) {
        setInsuranceMemberId(insuranceMemberId);

        debounceSearchInsurance.current({
          insuranceProvider: insuranceName,
          insuranceMemberId,
          insurancePackageId,
        });
      }

      if (insuranceName === "Other") {
        setHasOtherInsurance(true);
        setInsuranceOtherString(insuranceOtherStringValue);
      }
    }

    setSelectedInsurancePackageId(insurancePackageId);
    setIntakeFormInfo(patientIntakeFormInfo);
  }, []);

  const debounceSearchInsurance = useRef(
    _debounce(
      async ({ insuranceProvider, insuranceMemberId, insurancePackageId }) => {
        setInsuranceLoading(true);
        const { data } = await getInsurancePackage({
          insuranceProvider,
          insuranceMemberId,
        });
        setInsuranceLoading(false);

        const insuranceProviders = _get(data, "insurances", []);
        const formattedProviders = _map(insuranceProviders, (provider) => {
          const { insuranceplanname, emr_insurancepackageid } = provider;

          return {
            label: insuranceplanname,
            id: emr_insurancepackageid,
          };
        });

        const initialInsurancePackageOpt = _find(
          formattedProviders,
          (opt) => opt.id === insurancePackageId
        );

        setInsuranceGroupOptions(formattedProviders);
        setSelectedInsurancePackage(initialInsurancePackageOpt);
      },
      500
    )
  );

  useEffect(() => {
    if ((insuranceMemberId && selectedInsuranceProvider) || hasOtherInsurance) {
      debounceSearchInsurance.current({
        insuranceProvider: selectedInsuranceProvider,
        insuranceMemberId,
        insurancePackageId: selectedInsurancePackageId,
      });
    }
  }, [
    insuranceMemberId,
    selectedInsuranceProvider,
    selectedInsurancePackageId,
    hasOtherInsurance,
  ]);

  useEffect(() => {
    const memberIdValue = currentUser[insuranceMemberIdKey];

    if (memberIdValue) {
      setInsuranceMemberId(memberIdValue);
    } else {
      setInsuranceMemberId("");
      setInsuranceGroupOptions([]);
      setSelectedInsurancePackage({});
    }
  }, [currentUser[insuranceMemberIdKey]]);

  useEffect(() => {
    const insuranceCarrierValue = currentUser[insuranceCarrierKey];
    const insuranceSelected = _find(
      intakeFormInfo?.insurance_groups_options,
      (option) => option.id === insuranceCarrierValue
    );

    if (!insuranceSelected) {
      setHasOtherInsurance(true);

      return;
    }

    setSelectedInsuranceProvider(insuranceSelected?.name);

    if (insuranceSelected.name === "Other") {
      setHasOtherInsurance(true);
    } else {
      setHasOtherInsurance(false);
    }
  }, [currentUser[insuranceCarrierKey]]);

  const saveData = async (values) => {
    // if saveData has been called and the modal is not shown, stop and show the modal
    if (showUnconfirmedChangesModal === false) {
      setSaveState(0);
      return setShowUnconfirmedChangesModal(true);
    }
    // if saveData has been called and the modal is open, we can presume the user
    // has clicked the confirm button
    if (showUnconfirmedChangesModal === true) {
      setShowUnconfirmedChangesModal(false);
    }
    try {
      await setIntakeFormAnswers(authenticationStore.userId, {
        intake_form_answers: {
          ...intakeFormAnswers,
          ...values,
        },
      });
      setSavedFields([]);
      setSaveState(2);
      // reset after 2 seconds
      setTimeout(() => setSaveState(0), 2000);
    } catch (err) {
      setSaveState(3);
      console.error(err);
      setTimeout(() => setSaveState(0), 2000);
    }
  };

  const handleDiff = (e, target) => {
    const val = e.target.value;
    const oldVal = intakeFormAnswers[target];
    const changed = val !== oldVal;
    const changedIndex = savedFields.indexOf(target);

    if (changed && changedIndex === -1) {
      setSavedFields([...savedFields, target]);
    } else if (changed === false && changedIndex !== -1) {
      const newSavedFields = savedFields.filter((field) => field !== target);
      setSavedFields(newSavedFields);
    }
  };

  const handleFocus = async (values, handleSubmit, user = currentUser) => {
    try {
      if (JSON.stringify(values) !== JSON.stringify(user)) {
        setCurrentUser({ ...values });
        setIntakeFormInfo({
          ...intakeFormInfo,
          patient_intake_form_answers: {
            ...intakeFormAnswers,
            ...values,
          },
        });
        // await handleSubmit();
        // const diffFields = diff(values, user);
        // diffFields.map((d) => {
        //   if (d.kind === "E") {
        //     console.log(d.path, savedFields);
        //     setSavedFields([...savedFields, d.path[0]]);
        //   }
        // });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const isSelfPay =
    currentUser.insurance_group_no === 8 ||
    intakeFormAnswers.insurance_group_no === 8;

  const validationSchema = Yup.object().shape({
    insurance_carrier: Yup.number().required("Insurance carrier is required"),
    insurance_member_id: Yup.string().when("insurance_carrier", {
      is: (v) => v !== 8,
      then: (s) => s.required("Member ID is required"),
    }),
    emr_insurancepackageid: Yup.number().when("insurance_carrier", {
      is: (v) => v !== 8 && v !== 7,
      then: (s) => s.required("Insurance package ID is required"),
    }),
    insurance_other_string: Yup.string().when("insurance_carrier", {
      is: 7,
      then: (s) => s.required("Insurance provider name is required"),
    }),
  });

  const handleOnChangeInsuranceProvider = (e, handleChange, setFieldValue) => {
    handleChange(e);
    setInsuranceMemberId("");
    setFieldValue(insurancePackageIdKey, "");
    setFieldValue(insuranceMemberIdKey, "");

    setCurrentUser({
      ...currentUser,
      [insuranceMemberIdKey]: "",
      [insurancePackageIdKey]: "",
    });
  };
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          // nameOfCardHolder: currentUser.nameOfCardHolder || '',
          // cardNumber: currentUser.cardNumber || '',
          insurance_member_id:
            currentUser.insurance_member_id ||
            intakeFormAnswers.insurance_member_id ||
            "",
          insurance_group_no:
            currentUser.insurance_group_no ||
            intakeFormAnswers.insurance_group_no ||
            "",
          insurance_carrier:
            currentUser.insurance_carrier ||
            intakeFormAnswers.insurance_carrier ||
            "",
          emr_insurancepackageid:
            currentUser.emr_insurancepackageid ||
            intakeFormAnswers.emr_insurancepackageid ||
            "",
          insurance_other_string:
            intakeFormAnswers.insurance_other_string ||
            currentUser.insurance_other_string ||
            "",
        }}
        validationSchema={validationSchema}
        onSubmit={saveData}
        validateOnChange={true}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          setFieldValue = [],
          errors,
          handleBlur,
          submitForm,
        }) => {
          if (Object.values(errors).length > 0 && saveState > 0)
            setSaveState(3);
          return (
            <form className="formStyle" onSubmit={handleSubmit}>
              {showUnconfirmedChangesModal && (
                <ProfileConfirmModal
                  handleHide={() => setShowUnconfirmedChangesModal(false)}
                  handleConfirm={() => handleSubmit()}
                >
                  <h4>
                    Are you sure you want to change your insurance details?
                  </h4>
                </ProfileConfirmModal>
              )}
              <OutsideClickHandler
                onOutsideClick={() => handleFocus(values, submitForm)}
              >
                <h2>INSURANCE</h2>

                <div
                  className="intakeFormFieldLayout__singleSelectDropdownContainer"
                  style={{ marginBottom: "1rem" }}
                >
                  <div className="intakeFormFieldLayout__singleSelectDropdownMainInfo">
                    <span className="dropdown-label">Insurance Provider</span>

                    {errors.insurance_carrier && (
                      <FormHelperText className="intakeForm__errorText">
                        {errors.insurance_carrier}
                      </FormHelperText>
                    )}
                  </div>

                  <Select
                    className={
                      savedFields.indexOf("insurance_carrier") > -1
                        ? "profile-field-changed"
                        : ""
                    }
                    onChange={(e) => {
                      handleOnChangeInsuranceProvider(
                        e,
                        handleChange(insuranceCarrierKey),
                        setFieldValue
                      );
                      handleDiff(e, "insurance_carrier");
                    }}
                    fullWidth
                    value={values.insurance_carrier}
                    id={insuranceCarrierKey}
                    key={insuranceCarrierKey}
                    onBlur={handleBlur(insuranceCarrierKey)}
                  >
                    {intakeFormInfo?.insurance_groups_options?.length &&
                      _map(
                        intakeFormInfo.insurance_groups_options,
                        ({ name, id }) => (
                          <MenuItem key={`${name}-${id}`} value={id}>
                            {name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </div>

                <FormControl fullWidth>
                  <commonMaterial.CustomTextField
                    id={insuranceMemberIdKey}
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(values, handleSubmit)}
                    value={values[insuranceMemberIdKey]}
                    color="primary"
                    margin="normal"
                    onChange={(e) => {
                      handleChange(e);
                      handleDiff(e, "insurance_member_id");
                    }}
                    error={errors.insurance_member_id}
                    className={
                      savedFields.indexOf("insurance_member_id") > -1
                        ? "profile-field-changed"
                        : ""
                    }
                    InputProps={{
                      endAdornment: savedFields === insuranceMemberIdKey && (
                        <InputAdornment position="end">
                          <Check />
                        </InputAdornment>
                      ),
                    }}
                    label={
                      errors.insurance_member_id
                        ? errors.insurance_member_id
                        : "MEMBER ID"
                    }
                  />
                </FormControl>

                {hasOtherInsurance && freeTextOtherInsuranceOption ? (
                  <div className="selectionRow" style={{ display: "block" }}>
                    <div className="selectionRowElement" style={{ flex: 0.5 }}>
                      <commonMaterial.CustomTextField
                        id={insuranceOtherStringKey.key}
                        onBlur={handleBlur}
                        onFocus={() => handleFocus(values, handleSubmit)}
                        value={values.insurance_other_string}
                        color="primary"
                        margin="normal"
                        onChange={(e) => {
                          handleChange(e);
                          handleDiff(e, "insurance_other_string");
                        }}
                        className={
                          savedFields.indexOf("insurance_other_string") > -1
                            ? "profile-field-changed"
                            : ""
                        }
                        InputProps={{
                          endAdornment: savedFields ===
                            insuranceOtherStringKey && (
                            <InputAdornment position="end">
                              <Check />
                            </InputAdornment>
                          ),
                        }}
                        error={errors.insurance_other_string}
                        label={
                          errors.insurance_other_string
                            ? errors.insurance_other_string
                            : "INSURANCE PROVIDER NAME"
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="selectionRow" style={{ display: "block" }}>
                    <div className="selectionRowElement" style={{ flex: 0.5 }}>
                      <FormControl name="pronoun">
                        <AutoComplete
                          inputValue={
                            insuranceLoading ? "Loading..." : insuranceGroupText
                          }
                          onInputChange={(e, newInputValue) => {
                            setInsuranceGroupText(newInputValue);
                          }}
                          id="insurance-group-dropdown-profile"
                          options={insuranceGroupOptions}
                          getOptionLabel={(option) => option.label || ""}
                          getOptionSelected={(option) =>
                            option.id === values.insurancePlan
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={values[insurancePackageIdKey]}
                              label={
                                errors.emr_insurancepackageid
                                  ? errors.emr_insurancepackageid
                                  : "INSURANCE PLAN"
                              }
                              error={errors.emr_insurancepackageid}
                              className={
                                savedFields.indexOf("emr_insurancepackageid") >
                                -1
                                  ? "profile-field-changed intakeFormFieldLayout__autocompleteLabel--insurance"
                                  : "intakeFormFieldLayout__autocompleteLabel--insurance"
                              }
                            />
                          )}
                          onChange={(e, value) => {
                            const id = _get(value, "id", "");

                            handleDiff(e, "emr_insurancepackageid");

                            setFieldValue(insurancePackageIdKey, id);
                            setSelectedInsurancePackageId(id);
                            setSelectedInsurancePackage(value);
                          }}
                          value={selectedInsurancePackage}
                        />
                      </FormControl>
                    </div>
                  </div>
                )}

                <FormControl fullWidth>
                  <commonMaterial.CustomTextField
                    id="insurance_group_no"
                    onBlur={handleBlur}
                    onFocus={() => handleFocus(values, handleSubmit)}
                    value={values.insurance_group_no}
                    color="primary"
                    margin="normal"
                    onChange={(e) => {
                      handleChange(e);
                      handleDiff(e, "insurance_group_no");
                    }}
                    className={
                      savedFields.indexOf("insurance_group_no") > -1
                        ? "profile-field-changed"
                        : ""
                    }
                    InputProps={{
                      endAdornment: savedFields === "insurance_group_no" && (
                        <InputAdornment position="end">
                          <Check />
                        </InputAdornment>
                      ),
                    }}
                    label="GROUP #"
                  />
                </FormControl>

                <CustomButton
                  fullWidth={true}
                  className={`profile-save-button ${
                    saveState === 3 && "profile-save-error"
                  }`}
                  disabled={savedFields.length <= 0}
                  onClick={() => {
                    handleSubmit();
                    setSaveState(1);
                  }}
                  style={{ marginBottom: "1rem" }}
                >
                  {saveState === 0 && "Save"}
                  {saveState === 1 && <CircularProgress size={24} />}
                  {saveState === 2 && <Check size={24} />}
                  {saveState === 3 && "Error"}
                </CustomButton>

                {/* <h2>PAYMENT</h2>

              <commonMaterial.CustomTextField
                disabled
                margin="normal"
                fullWidth
                value={values.nameOfCardHolder}
                id="nameOfCardHolder"
                label="NAME OF CARD HOLDER"
              />

              <commonMaterial.CustomTextField
                fullWidth
                disabled
                id="cardNumber"
                value={values.cardNumber}
                color="primary"
                margin="normal"
                label="CARD NUMBER"
              />

              <div className="profile__section">
                <commonMaterial.CustomTextField
                  disabled
                  id="expirationDate"
                  value={values.expirationDate}
                  color="primary"
                  margin="normal"
                  label="EXPIRATION DATE"
                  style={{ marginRight: '20px' }}
                />

                <commonMaterial.CustomTextField
                  disabled
                  id="cvv"
                  value={values.cvv}
                  color="primary"
                  margin="normal"
                  label="CVV"
                />
              </div> */}
              </OutsideClickHandler>
            </form>
          );
        }}
      </Formik>
    </div>
  );
});

export default ProfileBilling;
