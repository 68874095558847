import { useState, useRef, useEffect } from "react";
import _map from "lodash/map";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import AutoComplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { getInsurancePackage } from "../../../../../../api/intakeForm";

import "./InsurancePackage.scss";

const InsurancePackage = ({
  formatedLabel,
  layoutId,
  value,
  setFieldValue,
  hasOtherInsurance,
  insuranceMemberId,
  insurancePackageId,
  insuranceProviderVal,
  setHasOtherInsurance,
  intakeFormDatabaseInfo,
  selectedInsuranceProvider,
  setSelectedInsuranceProvider,
}) => {
  const [insuranceGroupText, setInsuranceGroupText] = useState("");
  const [insuranceGroupOptions, setInsuranceGroupOptions] = useState([]);
  const [selectedInsurancePackageId, setSelectedInsurancePackageId] =
    useState("");
  const [selectedInsurancePackage, setSelectedInsurancePackage] = useState([
    { label: "" },
  ]);

  const debounceSearchInsurance = useRef(
    _debounce(
      async ({ insuranceProvider, insuranceMemberId, insurancePackageId }) => {
        const { data } = await getInsurancePackage({
          insuranceProvider,
          insuranceMemberId,
        });

        const insuranceProviders = _get(data, "insurances", []);
        const formattedProviders = _map(insuranceProviders, (provider) => {
          const { insuranceplanname, emr_insurancepackageid } = provider;

          return {
            label: insuranceplanname,
            id: emr_insurancepackageid,
          };
        });

        const initialInsurancePackageOpt = _find(
          formattedProviders,
          (opt) => opt.id === insurancePackageId
        );

        setInsuranceGroupOptions(formattedProviders);
        setSelectedInsurancePackage(initialInsurancePackageOpt);
      },
      500
    )
  );

  useEffect(() => {
    const insuranceGroups = _get(
      intakeFormDatabaseInfo,
      "insurance_groups_options",
      []
    );
    const selectedInsurance = _find(
      insuranceGroups,
      ({ id }) => id === parseInt(insuranceProviderVal)
    );
    const insuranceName = selectedInsurance?.name;

    if (insuranceName) {
      setSelectedInsuranceProvider(insuranceName);

      if (insuranceMemberId) {
        debounceSearchInsurance.current({
          insuranceProvider: insuranceName,
          insuranceMemberId,
          insurancePackageId,
        });
      }

      if (insuranceName === "Other") {
        setHasOtherInsurance(true);
      }
    }
  }, [intakeFormDatabaseInfo]);

  useEffect(() => {
    const packageId = selectedInsurancePackageId || insurancePackageId;

    if (insuranceMemberId || hasOtherInsurance) {
      debounceSearchInsurance.current({
        insuranceProvider: selectedInsuranceProvider,
        insuranceMemberId,
        insurancePackageId: packageId,
      });
    }
  }, [
    insuranceMemberId,
    selectedInsuranceProvider,
    selectedInsurancePackageId,
    hasOtherInsurance,
  ]);

  return (
    <AutoComplete
      inputValue={insuranceGroupText}
      onInputChange={(e, newInputValue) => {
        setInsuranceGroupText(newInputValue);
      }}
      id="insurance-group-dropdown"
      options={insuranceGroupOptions}
      getOptionLabel={(option) => option.label}
      getOptionSelected={(option) => option.id === value}
      className="singleSelect__autocomplete--insurance"
      renderInput={(params) => (
        <TextField
          {...params}
          value={value}
          label={formatedLabel}
          margin="normal"
          style={{
            marginBottom: 28,
            marginLeft: "-3px",
            paddingLeft: "3px",
          }}
          className="singleSelect__autocompleteLabel--insurance"
        />
      )}
      onChange={(e, value) => {
        const id = _get(value, "id", "");

        setFieldValue(layoutId, id);
        setSelectedInsurancePackageId(id);
        setSelectedInsurancePackage(value);
      }}
      value={selectedInsurancePackage}
    />
  );
};

export default InsurancePackage;
