import { Box, Container, ThemeProvider } from "@material-ui/core";
import logo from "../../assets/logo/Logo/Oula_logo_primary color.png";
import { CustomButton, theme } from "../../materialDesignShared";
import { useAuth } from "../../providers/Auth";
import "./UnknownRoute.scss";
import person from "./person.png";
import { useEffect } from "react";
import { LinkWithPathBasedReload, useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

// it's called unknown route because you can't start a variable name w/ a number in JS
const UnknownRoute = () => {
  const auth = useAuth();
  // typescript is convinced the auth context will always be undefined
  // @ts-ignore
  const isAuthenticated = auth.isAuthenticated;
  const returnRoute = isAuthenticated ? "/dashboard" : "/login";

  const history = useHistoryWithPathBasedReload();

  useEffect(() => {
    if (window.location.pathname === "/") history.push("/dashboard");
  }, [history]);

  return (
    <ThemeProvider theme={theme}>
      <Box className="unknown-route-wrapper">
        <Box display={"flex"} justifyContent={"flex-start"} width={"100%"}>
          <Box padding={"1rem"}>
            <LinkWithPathBasedReload to={returnRoute}>
              <img src={logo} alt="Oula logo" className="oula-logo" />
            </LinkWithPathBasedReload>
          </Box>
        </Box>
        <Container
          maxWidth="sm"
          style={{
            alignItems: "center",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <img
            src={person}
            alt="Person waiting on a procedure table"
            style={{ maxWidth: "80%" }}
          />
          <h1 className="title">Oops!</h1>
          <Box marginBottom={"1.5rem"}>
            We're sorry we can't find the page you're looking for. Please reach
            out to{" "}
            <a href="mailto:hello@oulahealth.com">hello@oulahealth.com</a> if
            you need anything in the meantime.
          </Box>
          <LinkWithPathBasedReload to={returnRoute}>
            <CustomButton>
              {isAuthenticated ? "Return to Dashboard" : "Log In"}
            </CustomButton>
          </LinkWithPathBasedReload>
        </Container>
        {/* empty box so flexbox will space w/ thirds */}
        <Box height={"15%"} />
      </Box>
    </ThemeProvider>
  );
};

export default UnknownRoute;
