import { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { Check, ExpandMore } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Toggle from "react-toggle";
import moment from "moment";
import * as Yup from "yup";
import { Formik } from "formik";
import { css } from "emotion";
import useAsyncEffect from "use-async-effect";
import { observer } from "mobx-react-lite";
import _get from "lodash/get";

import { getProfile, updateProfile } from "../../../api/profile";
import { authenticationStore } from "../../../stores/authentication-store";
import * as commonMaterial from "../../../materialDesignShared";
import { CustomButton } from "../../../materialDesignShared";
import "./ProfilePersonal.scss";
import { itemsKeyValue, itemsValue } from "../../../services/formSettings";
import { formOptionsStore } from "../../../stores/form-options-store";
import {
  MM_DD_YYYY_ALTERNATIVE,
  YYYY_MM_DD,
} from "../../../constants/dateFormat";
import CircularProgress from "@material-ui/core/CircularProgress";
import ProfileConfirmModal from "../ProfileConfirmModal";
import { captureMessage } from "@sentry/react";

const ProfilePersonal = observer(({ setUnsaved }) => {
  const [currentUser, setCurrentUser] = useState({});
  const [savedFields, setSavedFields] = useState([]);
  const [settings, setSettings] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  // 0: no activity
  // 1: loading
  // 2: success
  // 3: error
  const [saveState, setSaveState] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const initialValues = {
    firstName: currentUser.firstName || "",
    lastName: currentUser.lastName || "",
    assigned_sex_at_birth: currentUser.assigned_sex_at_birth || "",
    pregnancy: currentUser.pregnancy || "",
    pronoun: currentUser.pronoun || "",
    dateOfBirth: currentUser.dateOfBirth,
    dateOfLastPeriod:
      currentUser?.ob_episode?.last_menstrual_period_date || null,
    estimatedDeliveryDate: currentUser?.ob_episode?.est_delivery_date || null,
    department: currentUser?.department || null,
  };

  useEffect(() => {
    if (savedFields.length > 0) setUnsaved(true);
    else setUnsaved(false);
  }, [savedFields]);

  useAsyncEffect(async () => {
    if (saveState === 0 || 3) {
      const { data } = await getProfile(authenticationStore.userId);
      const patient = _get(data, "patient", {});

      setCurrentUser({
        firstName: patient.first_name || "",
        lastName: patient.last_name || "",
        assigned_sex_at_birth: patient.assigned_sex_at_birth || "",
        pregnancy: patient.is_pregnant ? "pregnant" : "notPregnant",
        pronoun: patient.preferred_pronouns || "",
        dateOfBirth: patient.birth_date || new Date(),
        ob_episode: {
          last_menstrual_period_date:
            patient.ob_episode?.last_menstrual_period_date,
          est_delivery_date: patient.ob_episode?.est_delivery_date,
          initial_exam_date: patient.ob_episode?.initial_exam_date,
        },
        department: patient.department || "",
      });

      setIsChecked(!patient.ob_episode?.est_delivery_date);
    }
  }, [saveState]);

  useAsyncEffect(async () => {
    setSettings(formOptionsStore.formSettingsInfo);
  }, [formOptionsStore.formSettingsInfo]);

  const getDataToSave = ({
    pregnancy,
    lastPeriod,
    assigned_sex_at_birth,
    pronoun,
    firstName,
    lastName,
    dateOfBirth,
    dateOfLastPeriod,
    estimatedDeliveryDate,
  } = {}) => {
    const isPregnant = pregnancy === "pregnant";

    let data = {
      is_pregnant: isPregnant,
      last_period: lastPeriod,
      assigned_sex_at_birth,
      preferred_pronouns: pronoun,
      first_name: firstName,
      last_name: lastName,
      birth_date: moment(dateOfBirth).format(YYYY_MM_DD),
    };

    if (isPregnant && (dateOfLastPeriod || estimatedDeliveryDate)) {
      data = {
        ...data,
        is_pregnant: true,
        ob_episode: {
          last_menstrual_period_date: getFormatedDate(dateOfLastPeriod),
          est_delivery_date: getFormatedDate(estimatedDeliveryDate),
        },
      };
    } else {
      data = {
        ...data,
        is_pregnant: false,
      };
    }

    return data;
  };

  const getDataToShow = ({ is_pregnant, ob_episode = {} } = {}) => {
    const { est_delivery_date, last_menstrual_period_date, initial_exam_date } =
      ob_episode;

    if (is_pregnant && (last_menstrual_period_date || est_delivery_date)) {
      return {
        ...currentUser,
        is_pregnant: true,
        ob_episode: {
          last_menstrual_period_date,
          est_delivery_date,
          initial_exam_date,
        },
      };
    } else {
      return {
        ...currentUser,
        is_pregnant: false,
      };
    }
  };

  const saveData = async (values) => {
    const eddChanged =
      savedFields.indexOf("dateOfLastPeriod") > -1 ||
      savedFields.indexOf("estimatedDeliveryDate") > -1 ||
      savedFields.indexOf("pregnancy") > -1;
    // if EDD or pregnancy status has been changed, show a modal confirming users intention
    if (eddChanged && showConfirmModal === false) {
      setSaveState(0);
      setShowConfirmModal(true);
      return;
    }
    // any other value of those booleans will mean that either the user hasn't changed
    // the EDD, or they initiated the change from the confirmation modal, so we can proceed.
    setShowConfirmModal(false);
    setSaveState(1);
    try {
      const dataToSave = getDataToSave(values);
      const { data } = await updateProfile(
        authenticationStore.userId,
        dataToSave
      );
      authenticationStore.setUser(data.patient);
      authenticationStore.setUserData(data.patient);
      setCurrentUser(getDataToShow(data));
      setSavedFields([]);
      setSaveState(2);
      // reset after 2 seconds
      setTimeout(() => setSaveState(0), 2000);
    } catch (err) {
      setSaveState(3);
      console.error(err);
      setTimeout(() => setSaveState(0), 2000);
    }
  };

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      assigned_sex_at_birth: Yup.string().required(),
      pregnancy: Yup.string().oneOf(["notPregnant", "pregnant"]).required(),
      estimatedDeliveryDate: Yup.date()
        .test(
          "only-apply-min-when-entering-EDD",
          "Estimated delivery date must be in the future!",
          (v) => {
            if (isChecked) {
              return true;
            } else {
              return v > new Date();
            }
          }
        )
        .nullable()
        .default(null)
        .when(["dateOfLastPeriod", "pregnancy"], {
          is: (dateOfLastPeriod, pregnancy) => {
            return !isValidDate(dateOfLastPeriod) && pregnancy === "pregnant";
          },
          then: () => Yup.date().required(),
        }),
      dateOfLastPeriod: Yup.date()
        .max(new Date())
        .nullable()
        .default(null)
        .when(["estimatedDeliveryDate", "pregnancy"], {
          is: (estimatedDeliveryDate, pregnancy) => {
            return (
              !isValidDate(estimatedDeliveryDate) && pregnancy === "pregnant"
            );
          },
          then: () => Yup.date().required(),
        }),
    },
    ["estimatedDeliveryDate", "dateOfLastPeriod", "pregnancy"]
  );

  const checkboxStyle = (isChecked) => {
    if (isChecked === undefined) {
      return css`
        color: #c5cdd1;
        font-weight: normal;
        font-family: "WorkSans-SemiBold", sans-serif;
        letter-spacing: 0.2px;
      `;
    } else {
      return css`
        color: ${isChecked ? "#183746" : "#c5cdd1"};
        font-weight: 600;
        font-family: "WorkSans-SemiBold", sans-serif;
        letter-spacing: 0.2px;
      `;
    }
  };

  const getFormatedDate = (date) => {
    return date ? moment(date).format(YYYY_MM_DD) : null;
  };

  const handleDiff = (e, target) => {
    const val = e.target.value;
    const oldVal = currentUser[target];
    const changed = val !== oldVal;
    const changedIndex = savedFields.indexOf(target);
    if (changed && changedIndex === -1) {
      setSavedFields([...savedFields, target]);
    } else if (changed === false && changedIndex !== -1) {
      const newSavedFields = savedFields.filter((field) => field !== target);
      setSavedFields(newSavedFields);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={saveData}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue = [],
        errors,
        touched,
        setFieldTouched,
      }) => {
        return (
          <form className="formStyle" onSubmit={handleSubmit}>
            {showConfirmModal && (
              <ProfileConfirmModal
                handleConfirm={() => saveData(values)}
                handleHide={() => setShowConfirmModal(false)}
              >
                <h4>
                  Are you sure you want to change
                  {savedFields.indexOf("pregnancy") > -1
                    ? " your pregnancy status"
                    : " your estimated delivery date"}
                  ?
                </h4>
              </ProfileConfirmModal>
            )}
            <>
              <commonMaterial.CustomTextField
                error={touched.firstName && errors.firstName}
                margin="normal"
                className={
                  savedFields.indexOf("firstName") > -1
                    ? "profile-field-changed"
                    : ""
                }
                onChange={(e) => {
                  handleChange(e);
                  handleDiff(e, "firstName");
                }}
                value={values.firstName}
                id="firstName"
                label="LEGAL FIRST NAME"
                fullWidth
              />
              <FormControl fullWidth>
                <commonMaterial.CustomTextField
                  id="lastName"
                  error={touched.lastName && errors.lastName}
                  value={values.lastName}
                  color="primary"
                  margin="normal"
                  className={
                    savedFields.indexOf("lastName") > -1
                      ? "profile-field-changed"
                      : ""
                  }
                  onChange={(e) => {
                    handleChange(e);
                    handleDiff(e, "lastName");
                  }}
                  label="LEGAL LAST NAME"
                />
              </FormControl>
              <div className="selectionRow">
                <div className="selectionRowElement" style={{ flex: 1 }}>
                  <FormControl name="pronoun">
                    <InputLabel id="pronoun">PRONOUNS</InputLabel>
                    <Select
                      labelId="pronoun"
                      label="Pronoun (optional)"
                      id="pronoun"
                      name="pronoun"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      value={values.pronoun}
                      onChange={(e) => {
                        handleChange(e);
                        handleDiff(e, "pronoun");
                      }}
                      IconComponent={ExpandMore}
                      className={
                        savedFields.indexOf("pronoun") > -1
                          ? "profile-field-changed"
                          : ""
                      }
                    >
                      {itemsKeyValue(settings?.data, "preferred_pronouns")}
                    </Select>
                  </FormControl>
                </div>
                <div className="selectionRowElement" style={{ flex: 1 }}>
                  <FormControl name="assigned_sex_at_birth">
                    <InputLabel id="assigned_sex_at_birth">
                      ASSIGNED SEX AT BIRTH
                    </InputLabel>
                    <Select
                      labelId="assigned_sex_at_birth"
                      label="ASSIGNED SEX AT BIRTH"
                      id="assigned_sex_at_birth"
                      name="assigned_sex_at_birth"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      error={
                        touched.assigned_sex_at_birth &&
                        errors.assigned_sex_at_birth
                      }
                      value={values.assigned_sex_at_birth}
                      onChange={(e) => {
                        handleDiff(e, "assigned_sex_at_birth");
                        setFieldTouched("assigned_sex_at_birth");
                        handleChange(e);
                      }}
                      IconComponent={ExpandMore}
                      className={
                        savedFields.indexOf("assigned_sex_at_birth") > -1
                          ? "profile-field-changed"
                          : ""
                      }
                    >
                      {itemsValue(settings?.data, "assigned_sex_at_birth")}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="selectionRow">
                <div className="selectionRowElement" style={{ flex: 1 }}>
                  <FormControl>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        onClick={() => {
                          setFieldTouched("dateOfBirth");
                        }}
                        className={
                          savedFields.indexOf("dateOfBirth") > -1
                            ? "profile-field-changed"
                            : ""
                        }
                        variant="inline"
                        format={MM_DD_YYYY_ALTERNATIVE}
                        margin="normal"
                        id="dateOfBirth"
                        views={["month", "date", "year"]}
                        openTo="month"
                        disableFuture
                        value={values.dateOfBirth}
                        onChange={(date) => {
                          // format the date so that it matches what the form spits out, wrap in an object as if it was an onChange event
                          try {
                            handleDiff(
                              {
                                target: {
                                  value: `${date.getFullYear()}/${`${
                                    date.getMonth() + 1
                                  }`.padStart(
                                    2,
                                    "0"
                                  )}/${`${date.getDate()}`.padStart(2, "0")}`,
                                },
                              },
                              "dateOfBirth"
                            );
                          } catch {
                            captureMessage(
                              "attempted to run diff on null date of birth!"
                            );
                          }
                          setFieldValue("dateOfBirth", date);
                        }}
                        onOpen={() => console.log("open")}
                        label="DATE OF BIRTH"
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </div>
                <div className="selectionRowElement" style={{ flex: 1 }}>
                  <FormControl name="pregnancy">
                    <InputLabel id="pregnancy">STATUS</InputLabel>
                    <Select
                      labelId="pregnancy"
                      label="Are you pregnant"
                      id="pregnancy"
                      name="pregnancy"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                      value={values.pregnancy}
                      error={touched.pregnancy && errors.pregnancy}
                      onChange={(e) => {
                        handleDiff(e, "pregnancy");
                        setFieldTouched("pregnancy");
                        handleChange(e);
                      }}
                      IconComponent={ExpandMore}
                      className={
                        savedFields.indexOf("pregnancy") > -1
                          ? "profile-field-changed"
                          : ""
                      }
                    >
                      <MenuItem value={"pregnant"}>Pregnant</MenuItem>
                      <MenuItem value={"notPregnant"}>Not pregnant</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {values.pregnancy === "pregnant" && (
                <div style={{ marginBottom: "1rem" }}>
                  <div className="toggleWrapper">
                    <span
                      className={checkboxStyle(!isChecked)}
                      style={{ marginRight: "13px" }}
                    >
                      EDD
                    </span>
                    {currentUser && (
                      <Toggle
                        icons={false}
                        defaultChecked={
                          !currentUser?.ob_episode?.est_delivery_date
                        }
                        onChange={(e) => setIsChecked(e.target.checked)}
                      />
                    )}
                    <span
                      className={checkboxStyle(isChecked)}
                      style={{ marginLeft: "13px" }}
                    >
                      Last period
                    </span>
                  </div>
                  {!isChecked && (
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disablePast
                          disableToolbar
                          className={
                            savedFields.indexOf("estimatedDeliveryDate") > -1
                              ? "profile-field-changed"
                              : ""
                          }
                          onClick={() => {
                            setFieldTouched("estimatedDeliveryDate");
                          }}
                          variant="inline"
                          format={MM_DD_YYYY_ALTERNATIVE}
                          margin="normal"
                          id="estimatedDeliveryDate"
                          value={values.estimatedDeliveryDate}
                          onChange={(date) => {
                            try {
                              // format the date so that it matches what the form spits out, wrap in an object as if it was an onChange event
                              handleDiff(
                                {
                                  target: {
                                    value: `${date.getFullYear()}/${`${
                                      date.getMonth() + 1
                                    }`.padStart(
                                      2,
                                      "0"
                                    )}/${`${date.getDate()}`.padStart(2, "0")}`,
                                  },
                                },
                                "estimatedDeliveryDate"
                              );
                            } catch {
                              captureMessage(
                                "attempted to run diff on null EDD"
                              );
                            }
                            setFieldValue("estimatedDeliveryDate", date);
                          }}
                          label="ESTIMATED DELIVERY DATE"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          error={errors.estimatedDeliveryDate}
                          autoOk={true}
                          emptyLabel={""}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  )}

                  {isChecked && (
                    <FormControl fullWidth>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          className={
                            savedFields.indexOf("dateOfLastPeriod") > -1
                              ? "profile-field-changed"
                              : ""
                          }
                          onClick={() => {
                            setFieldTouched("dateOfLastPeriod");
                          }}
                          variant="inline"
                          format={MM_DD_YYYY_ALTERNATIVE}
                          margin="normal"
                          id="dateOfLastPeriod"
                          disableFuture
                          value={values.dateOfLastPeriod}
                          onChange={(date) => {
                            // format the date so that it matches what the form spits out, wrap in an object as if it was an onChange event
                            try {
                              handleDiff(
                                {
                                  target: {
                                    value: `${date.getFullYear()}/${`${
                                      date.getMonth() + 1
                                    }`.padStart(
                                      2,
                                      "0"
                                    )}/${`${date.getDate()}`.padStart(2, "0")}`,
                                  },
                                },
                                "dateOfLastPeriod"
                              );
                            } catch {
                              captureMessage(
                                "attempted to run diff on null LMP!"
                              );
                            }
                            setFieldValue("dateOfLastPeriod", date);
                          }}
                          label="DATE OF LAST PERIOD"
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          autoOk={true}
                          error={errors["dateOfLastPeriod"]}
                          emptyLabel={""}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  )}
                </div>
              )}
              {values?.department && (
                <div
                  className="selectionRowElement profilePersonal__clinicOption"
                  style={{ flex: 1 }}
                >
                  <FormControl fullWidth>
                    <commonMaterial.CustomTextField
                      id="oulaClinic"
                      value={values.department.display_name}
                      color="primary"
                      margin="normal"
                      disabled="true"
                      label="OULA CLINIC"
                    />
                  </FormControl>

                  <p className="profilePersonal__clinicText">
                    Interested in transferring to another clinic? Please send us
                    a message to check if there is availability.
                  </p>
                </div>
              )}
              <CustomButton
                fullWidth={true}
                className={`profile-save-button ${
                  saveState === 3 && "profile-save-error"
                }`}
                disabled={savedFields.length <= 0}
                onClick={() => {
                  handleSubmit();
                }}
                style={{ marginBottom: "1rem" }}
              >
                {saveState === 0 && "Save"}
                {saveState === 1 && <CircularProgress size={24} />}
                {saveState === 2 && <Check size={24} />}
                {saveState === 3 && "Error saving!"}
              </CustomButton>
            </>
          </form>
        );
      }}
    </Formik>
  );
});

export default ProfilePersonal;
