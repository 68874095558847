import { useEffect, useState } from "react";
import { authenticationStore } from "../../stores/authentication-store";
import { heartbeat } from "../../api/helper";
import { refreshToken } from "../../api/auth";
import { logout } from "../../services/logoutHelper";
import { useIdleTimer } from "react-idle-timer";
import { useCacheBuster } from "react-cache-buster";

// timeout after 15 minutes
const idleTimeout = 60000 * 15;
const heartbeatInterval = 60000;

export const IdleHandler = () => {
  const { getRemainingTime } = useIdleTimer({
    timeout: idleTimeout,
  });
  const { checkCacheStatus } = useCacheBuster();

  useEffect(() => {
    // initial heartbeat ping
    heartbeat(authenticationStore.userId);

    const heartbeatTimer = setInterval(() => {
      // send heartbeat ping
      heartbeat(authenticationStore.userId);
    }, heartbeatInterval);

    const tokenTimer = setInterval(() => {
      // check whether we need to refresh the JWT
      if (
        // check if user has performed action in a time less than the idle timeout
        getRemainingTime() > 0 &&
        // check if token will expire before the next heartbeat
        authenticationStore.expiresAt * 1000 - Date.now() - heartbeatInterval <=
          0
      ) {
        refreshToken();
      } else if (getRemainingTime() <= 0 && authenticationStore.getJWTToken()) {
        checkCacheStatus();
        logout();
      }
    }, 1000);

    return () => {
      clearInterval(heartbeatTimer);
      clearInterval(tokenTimer);
    };
  }, [getRemainingTime]);

  return <div></div>;
};
