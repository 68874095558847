import { DASHBOARD_TRANSLATIONS_EN } from "./componentsTranslations/dashboard";
import { CARE_PLAN_TRANSLATIONS_EN } from "./componentsTranslations/carePlan";
import { JOURNEY_TRANSLATIONS_EN } from "./componentsTranslations/journey";
import { APPOINTMENTS_TRANSLATIONS_EN } from "./componentsTranslations/appointments";

export const TRANSLATIONS_EN = {
  ...DASHBOARD_TRANSLATIONS_EN,
  ...CARE_PLAN_TRANSLATIONS_EN,
  ...JOURNEY_TRANSLATIONS_EN,
  ...APPOINTMENTS_TRANSLATIONS_EN,
};
