import "./WelcomeBanner.scss";
import { Box, Grid } from "@material-ui/core";
import { PATHNAMES } from "../../../constants/pathnames";
import { appointmentStore } from "../../../stores/appointment-store";
import { ScheduledAppointment } from "../../Scheduling/SchedulingTypes";
import CompleteIntakeButton from "../../CompleteIntakeButton";
import { intakeFormStore } from "../../../stores/intake-form-store";
import { openInNewTab } from "../../../services/redirectionHelper";
import { CustomButton } from "../../../materialDesignShared";
import { Alert } from "@material-ui/lab";
import { useEffect } from "react";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";

type WelcomeBannerState =
  | { type: "BOOK_WHEN_READY"; name: string }
  | { type: "TIME_TO_BOOK"; name: string }
  | { type: "START_CARE_PLAN"; name: string }
  | {
      type: "UPCOMING_OFFICE_APPT";
      name: string;
      nextAppointment: ScheduledAppointment;
    }
  | {
      type: "UPCOMING_VIRTUAL_APPT";
      name: string;
      nextAppointment: ScheduledAppointment;
    };

const WelcomeBanner = ({ state }: { state: WelcomeBannerState }) => {
  const history = useHistoryWithPathBasedReload();

  useEffect(() => {
    intakeFormStore.refreshUserInfo();
  }, [intakeFormStore]);

  const showCompleteIntakeButton =
    "nextAppointment" in state &&
    !intakeFormStore.getIntakeFormData()?.patient_intake_form_answers.completed;

  let target: {
    bannerText: string;
    captionText: string;
    buttonText?: string;
    buttonAction?: () => void;
  };

  const { type, name } = state;

  switch (type) {
    case "BOOK_WHEN_READY": {
      target = {
        bannerText: `WELCOME ${name}`,
        captionText: "Book your next appointment whenever you're ready.",
      };
      break;
    }
    case "TIME_TO_BOOK": {
      target = {
        bannerText: `WELCOME ${name}`,
        captionText: "It's time to book your next appointment.",
        buttonText: "BOOK NOW",
        buttonAction: () => {
          history.push(PATHNAMES.SCHEDULING);
        },
      };
      break;
    }
    case "START_CARE_PLAN": {
      target = {
        bannerText: `WELCOME ${name}`,
        captionText: "Schedule your first appointment to start your care plan.",
        buttonText: "BOOK NOW",
        buttonAction: () => {
          history.push(PATHNAMES.SCHEDULING);
        },
      };
      break;
    }
    case "UPCOMING_OFFICE_APPT": {
      const { nextAppointment } = state;
      const startDate = new Date(nextAppointment.start_time);
      target = {
        bannerText: `WELCOME ${name}`,
        captionText: `Your next appointment is on ${startDate.toLocaleDateString(
          "en-us",
          { timeZone: "UTC" }
        )} at ${startDate.toLocaleTimeString("en-us", {
          hour: "numeric",
          minute: "numeric",
          timeZone: "UTC",
        })}`,
        buttonText: "VIEW APPOINTMENT",
        buttonAction: () => {
          appointmentStore.setAppointment({
            ...nextAppointment,
            begin_date_range: nextAppointment.start_time,
            reschedule: true,
          });
          history.push(PATHNAMES.VIEW_APPOINTMENT);
        },
      };
      break;
    }
    case "UPCOMING_VIRTUAL_APPT": {
      const { nextAppointment } = state;
      const startDate = new Date(nextAppointment.start_time);
      target = {
        bannerText: `WELCOME ${name}`,
        captionText: `Your next appointment is on ${startDate.toLocaleDateString(
          "en-us",
          { timeZone: "UTC" }
        )} at ${startDate.toLocaleTimeString("en-us", {
          hour: "numeric",
          minute: "numeric",
          timeZone: "UTC",
        })}`,
        buttonText: "JOIN APPOINTMENT",
        buttonAction: () => {
          openInNewTab(nextAppointment.virtual_link);
        },
      };
      break;
    }
    default: {
      return <Alert severity="error">missing welcome banner type!</Alert>;
    }
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      style={{
        backgroundColor: "#efdcc2",
        padding: ".8rem 1rem",
        borderRadius: "3px",
      }}
    >
      <Box>
        <Grid container direction="column" style={{ marginBottom: "0.5rem" }}>
          <Box className="welcome-back-name" style={{ display: "inline" }}>
            {target.bannerText}
          </Box>
          <Box>{target.captionText}</Box>
        </Grid>
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        style={{ flexShrink: 1, gap: ".5rem", width: "auto" }}
        className="welcome-banner-button-container"
      >
        {target.buttonText && (
          <CustomButton onClick={target.buttonAction}>
            {target.buttonText}
          </CustomButton>
        )}
        {showCompleteIntakeButton && (
          <CompleteIntakeButton customClassName={""} />
        )}
      </Grid>
    </Grid>
  );
};

export default WelcomeBanner;
