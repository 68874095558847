import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  MM_DD_YYYY,
  MM_DD_YYYY_ALTERNATIVE,
} from "../../../../../../constants/dateFormat";
import moment from "moment";

import "./DateSelect.scss";

const DateSelect = ({
  values,
  formatedLabel,
  layoutId,
  setFieldValue,
  fieldWithError,
  error,
  setFieldTouched,
}) => (
  <div className="date__container">
    <div className="date__mainInfo">
      <span className="date__label">{formatedLabel}</span>

      {fieldWithError && <span className="intakeForm__errorText">{error}</span>}
    </div>

    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableFuture
        disableToolbar
        onClick={() => setFieldTouched(layoutId)}
        variant="inline"
        format={MM_DD_YYYY_ALTERNATIVE}
        margin="normal"
        id={layoutId}
        value={values}
        onChange={(date) => {
          setFieldValue(layoutId, moment(date).format(MM_DD_YYYY));
        }}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        autoOk={true}
        emptyLabel={""}
        placeholder="MM/DD/YYYY"
      />
    </MuiPickersUtilsProvider>
  </div>
);

export default DateSelect;
