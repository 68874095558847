import { Button } from "@material-ui/core";
import {
  CheckinResponse,
  postMentalHealthCheckin,
} from "../../../../api/mentalHealth";
import MentalHealthScore from "../../../ModalSurvey/MentalHealth/splashScreens/MentalHealthScore";
import EPDS10IntroSplash from "../../../ModalSurvey/MentalHealth/splashScreens/ThirdCheckInSplash";
import { SurveyModalProps } from "../../../ModalSurvey/SurveyModal";

const thirdCheckIn: Omit<SurveyModalProps<CheckinResponse>, "handleClose"> = {
  title: "CHECK-IN",
  handleSubmit: async (e: Record<string, string>) => {
    const coerceIntoNumbers: Record<string, number> = {};
    Object.entries(e).map(([k, v]) => {
      coerceIntoNumbers[k] = parseInt(v, 10);
    });
    const res = await postMentalHealthCheckin({
      postpartum_checkin: coerceIntoNumbers,
    });
    return res;
  },
  questions: [
    {
      type: "splash_screen",
      customButtonComponent: (paginationAction: () => void) => (
        <Button
          onClick={paginationAction}
          style={{
            background: "#d69722",
            color: "#FFF",
            fontSize: "1.2rem",
            padding: "0.4rem 2rem",
          }}
        >
          Start Check-in
        </Button>
      ),
      component: () => <EPDS10IntroSplash />,
    },
    {
      type: "select_one",
      outputKey: "epds10_1",
      question_text:
        "I have been able to laugh and see the funny side of things",
      options: [
        { display_name: "As much as I always could", answer_value: "0" },
        { display_name: "Not quite so much now", answer_value: "1" },
        { display_name: "Definitely not so much now", answer_value: "2" },
        { display_name: "Not at all", answer_value: "3" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_2",
      question_text: "I have looked forward with enjoyment to things",
      options: [
        { display_name: "As much as I ever did", answer_value: "0" },
        { display_name: "Rather less than I used to", answer_value: "1" },
        { display_name: "Definitely less than I used to", answer_value: "2" },
        { display_name: "Hardly at all", answer_value: "3" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_3",
      question_text:
        "I have blamed myself unnecessarily when things went wrong",
      options: [
        { display_name: "Yes, most of the time", answer_value: "3" },
        { display_name: "Yes, some of the time", answer_value: "2" },
        { display_name: "Not very often", answer_value: "1" },
        { display_name: "No, never", answer_value: "0" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_4",
      question_text: "I have been anxious or worried for no good reason",
      options: [
        { display_name: "No, not at all", answer_value: "0" },
        { display_name: "Hardly ever", answer_value: "1" },
        { display_name: "Yes, sometimes", answer_value: "2" },
        { display_name: "Yes, very often", answer_value: "3" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_5",
      question_text: "I have felt scared or panicky for no good reason",
      options: [
        { display_name: " Yes, quite a lot", answer_value: "3" },
        { display_name: "Yes, sometimes", answer_value: "2" },
        { display_name: "No, not much", answer_value: "1" },
        { display_name: "No, not at all", answer_value: "0" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_6",
      question_text: "Things have been getting to me",
      options: [
        {
          display_name:
            "Yes, most of the time I haven't been able to cope at all",
          answer_value: "3",
        },
        {
          display_name: "Yes, sometimes I haven't been coping as well as usual",
          answer_value: "2",
        },
        {
          display_name: "No, most of the time I have coped quite well",
          answer_value: "1",
        },
        {
          display_name: "No, I have been coping as well as ever",
          answer_value: "0",
        },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_7",
      question_text:
        "I have been so unhappy that I have had difficulty sleeping",
      options: [
        { display_name: "Yes, most of the time", answer_value: "3" },
        { display_name: "Yes, sometimes", answer_value: "2" },
        { display_name: "Not very often", answer_value: "1" },
        { display_name: "No, not at all", answer_value: "0" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_8",
      question_text: "I have felt sad or miserable",
      options: [
        { display_name: "Yes, most of the time", answer_value: "3" },
        { display_name: "Yes, quite often", answer_value: "2" },
        { display_name: "Not very often", answer_value: "1" },
        { display_name: "No, not at all", answer_value: "0" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_9",
      question_text: "I have been so unhappy that I have been crying",
      options: [
        { display_name: "Yes, most of the time", answer_value: "3" },
        { display_name: "Yes, quite often", answer_value: "2" },
        { display_name: "Only occasionally", answer_value: "1" },
        { display_name: "No, never", answer_value: "0" },
      ],
    },
    {
      type: "select_one",
      outputKey: "epds10_10",
      question_text: "The thought of harming myself has occurred to me",
      submitsForm: true,
      options: [
        { display_name: "Yes, quite often", answer_value: "3" },
        { display_name: "Sometimes", answer_value: "2" },
        { display_name: "Hardly ever", answer_value: "1" },
        { display_name: "Never", answer_value: "0" },
      ],
    },
    {
      type: "splash_screen",
      overrideTitle: "POSTPARTUM CHECK-IN SUMMARY",
      customButtonComponent: (paginationAction: () => void) => (
        <Button
          onClick={paginationAction}
          style={{
            background: "#d69722",
            color: "#FFF",
            fontSize: "1.2rem",
            padding: "0.4rem 2rem",
          }}
        >
          Complete Check-In
        </Button>
      ),
      component: (surveyRes: CheckinResponse) => (
        <MentalHealthScore res={surveyRes} examType="POSTPARTUM" />
      ),
    },
  ],
};
export default thirdCheckIn;
