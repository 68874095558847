import { useEffect, useState } from "react";
import {
  AppBar,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Toolbar,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { get, uniqueId } from "lodash";
import ReactHtmlParser from "react-html-parser";

import { updateBookmark, updateDecision } from "../../../api/journey";
import { authenticationStore } from "../../../stores/authentication-store";
import { journeyStore } from "../../../stores/journey-store";
import {
  maxTabletScreenWidth,
  savedIndicatorLength,
} from "../../../services/constants";
import { Check } from "@material-ui/icons";
import { getHighlightTextSearchMarkup } from "../../../helpers/highlighterHelpers";

export const DecisionCardDetails = ({
  id,
  patient_decision,
  patient_bookmark,
  title,
  handleClose,
  target_weeks,
  description,
  ask_yourself,
  resources,
  decision_options,
  searchText,
}) => {
  const [savedBookmark, setSavedBookmark] = useState(
    patient_bookmark?.is_active || false
  );
  const [selectedDecision, setSelectedDecision] = useState(
    patient_decision?.decision_option_id
      ? patient_decision?.decision_option_id
      : 0
  );
  const [addedToCarePlan, setAddedToCarePlan] = useState(false);
  if (searchText === undefined) searchText = "";
  let firstParagraph = false;
  let firstList = false;

  const highlightedTitleText = getHighlightTextSearchMarkup(title, searchText);

  useEffect(() => {
    setSavedBookmark(patient_bookmark?.is_active);
    setAddedToCarePlan(false);
  }, [patient_bookmark?.is_active]);

  useEffect(() => {
    setSelectedDecision(patient_decision?.decision_option_id);
  }, [patient_decision?.decision_option_id]);

  const saveBookmark = async (isActive) => {
    await updateBookmark(authenticationStore.userId, {
      card_id: id,
      card_type: "DecisionCard",
      is_active: isActive.toString(),
    });
    setSavedBookmark(isActive);
    await Promise.all([
      journeyStore.refreshBookmarks(),
      journeyStore.refreshJourney(),
    ]);
  };

  const handleChange = async (event) => {
    setSelectedDecision(parseInt(event.target.value, 10));
    const { data } = await updateDecision(authenticationStore.userId, {
      patient_decision: {
        decision_card_id: id,
        decision_option_id: parseInt(event.target.value, 10),
      },
    });
    setAddedToCarePlan(true);
    authenticationStore.setCarePlanCompleted(
      data.patient_decision.care_plan_complete
    );
    setTimeout(() => {
      setAddedToCarePlan(false);
    }, savedIndicatorLength);
    await journeyStore.refreshJourney();
  };

  return (
    <Container maxWidth="sm" className="decision-container">
      <AppBar>
        <Toolbar>
          {window.innerWidth < maxTabletScreenWidth && (
            <ArrowBackIosIcon
              edge="start"
              color="inherit"
              onClick={() =>
                handleClose
                  ? handleClose()
                  : (journeyStore.decisionCardToShow = undefined)
              }
              aria-label="close"
            ></ArrowBackIosIcon>
          )}
          <div className="dialog-title">DECISION</div>
        </Toolbar>
      </AppBar>
      <div className="dialog-week">
        <span className="decision-week">
          Week
          {target_weeks.length > 1
            ? `s ${Math.min.apply(Math, target_weeks)}-${Math.max.apply(
                Math,
                target_weeks
              )}`
            : ` ${target_weeks[0]}`}
        </span>
      </div>
      <div className="dialog-body">
        <div className="decision-title">
          <img
            className="icon"
            src="/icons/Threeway_arrow@0,5x.svg"
            alt="threeway-icon"
          />
          {ReactHtmlParser(highlightedTitleText)}
        </div>
        <div className="dialog-description">
          {description.map((section) => {
            if (get(section, "paragraph")) {
              const higlightedText = getHighlightTextSearchMarkup(
                section.paragraph,
                searchText
              );
              const paragraph = (
                <p>
                  {!firstParagraph && !firstList && (
                    <span className="decision-what-is-it">What is it: </span>
                  )}
                  {ReactHtmlParser(higlightedText)}
                </p>
              );

              firstParagraph = true;

              return paragraph;
            }
            if (get(section, "list")) {
              const higlightedText = getHighlightTextSearchMarkup(
                section.list.title,
                searchText
              );

              const paragraph = (
                <p>
                  {!firstParagraph && !firstList && (
                    <span className="decision-what-is-it">What is it: </span>
                  )}
                  {ReactHtmlParser(higlightedText)}
                </p>
              );
              firstList = true;
              return (
                <>
                  {paragraph}
                  <ul>
                    {section.list.items.map((item) => {
                      const higlightedText = getHighlightTextSearchMarkup(
                        item,
                        searchText
                      );

                      return (
                        <li key={uniqueId()}>
                          {ReactHtmlParser(higlightedText)}
                        </li>
                      );
                    })}
                  </ul>
                </>
              );
            }
          })}
        </div>
        <div className="decision-questions">
          <div className="bold">Questions to ask yourself:</div>
          {ask_yourself}
        </div>
        {resources.length > 0 && (
          <div className="decision-resources">
            <b>Resources:</b>{" "}
            {resources.map((resource, index) => {
              let comma = index < resources.length - 1 ? ",  " : "";
              return (
                <a href={resource.url} target="_blank">
                  {resource.name}
                  {comma}
                </a>
              );
            })}
          </div>
        )}
        {!journeyStore.publicView && decision_options && (
          <>
            <div className="radio-label">
              <div>Add to care plan:</div>
              {addedToCarePlan && (
                <div>
                  Saved to care plan <Check />
                </div>
              )}
            </div>
            <FormControl component="fieldset" className="add-to-care-plan-form">
              <RadioGroup
                aria-label="add-to-care-plan-dialog"
                name="addToCarePlan"
                value={selectedDecision}
                onChange={handleChange}
                className={
                  decision_options.length > 2
                    ? "add-to-care-plan-dialog-vertical"
                    : "add-to-care-plan-dialog"
                }
              >
                {decision_options.map((decision) => (
                  <FormControlLabel
                    value={decision.id}
                    control={<Radio />}
                    label={decision.description}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </>
        )}
        {!journeyStore.publicView && (
          <div
            className="card-bookmark dialog-position"
            onClick={() => saveBookmark(!savedBookmark)}
          >
            {savedBookmark ? (
              <img
                src="/icons/Bookmark/Filled@0,5x.svg"
                alt="bookmark-filled-icon"
                className="bookmark-filled-icon"
              />
            ) : (
              <img
                src="/icons/Bookmark@0,5x.svg"
                alt="bookmark-icon"
                className="bookmarkIcon"
              />
            )}
          </div>
        )}
      </div>
    </Container>
  );
};
