export const sonoShortnames = [
  "DAV",
  "CAS",
  "NTS",
  "UUS",
  "EAS",
  "GWTH",
  "BPP",
  "BPGW",
  "FUA",
  "UCL",
  "USG",
];
