import { withResizeDetector } from "react-resize-detector";
import "./Wrapper.scss";
import { Footer } from "../Footer/Footer";
import { openInSameTab } from "../../services/redirectionHelper";
import classNames from "classnames";

const WrapperComponent = ({ fullBackground, children }) => {
  return (
    <div
      className={classNames("wrapper-container public", {
        "full-background": fullBackground,
      })}
    >
      <div
        className="logoHeader public"
        onClick={() => openInSameTab("https://oulahealth.com/")}
      />
      <div className="header-content-wrapper">
        <div className="content-wrapper public">
          {children}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export const WrapperPublic = withResizeDetector(WrapperComponent);
