import { defaultErrorText } from "../../../../../../constants/validation";
import { CustomTextField } from "../../../../../../materialDesignShared";
import "./Vitals.scss";

const Vitals = ({ label, errors, values, vitalsKey, setFieldValue }) => {
  return (
    <div className="vitals__container">
      <div className="vitals__mainInfo">
        <span className="vitals__label">{label}</span>
      </div>

      <div style={{ margin: "0 35px 0 20px" }}>
        <CustomTextField
          className="vitals__field"
          error={errors?.weight}
          margin="normal"
          fullWidth
          value={values.weight}
          onChange={(e) =>
            setFieldValue(vitalsKey, {
              ...values,
              weight: e.target.value,
            })
          }
          id={"weight"}
          type="number"
          key={vitalsKey + "weight"}
          helperText={errors?.weight && defaultErrorText}
          label="WEIGHT (LBS)"
          onBlur={(e) =>
            setFieldValue(vitalsKey, {
              ...values,
              weight: e.target.value,
            })
          }
          onWheel={(e) => e.target.blur()}
        />

        <div style={{ display: "flex" }}>
          <CustomTextField
            className="vitals__field"
            error={errors?.heightFt}
            margin="normal"
            fullWidth
            value={values.heightFt}
            onChange={(e) =>
              setFieldValue(vitalsKey, {
                ...values,
                heightFt: e.target.value,
              })
            }
            id={"heightFt"}
            type="number"
            key={vitalsKey + "heightFt"}
            helperText={errors?.heightFt && defaultErrorText}
            label="HEIGHT (FT)"
            onBlur={(e) =>
              setFieldValue(vitalsKey, {
                ...values,
                heightFt: e.target.value,
              })
            }
            onWheel={(e) => e.target.blur()}
          />

          <CustomTextField
            className="vitals__field"
            error={errors?.heightIn}
            margin="normal"
            fullWidth
            value={values.heightIn}
            onChange={(e) =>
              setFieldValue(vitalsKey, {
                ...values,
                heightIn: e.target.value,
              })
            }
            id={"heightIn"}
            type="number"
            key={vitalsKey + "heightIn"}
            helperText={errors?.heightIn && defaultErrorText}
            label="HEIGHT (IN)"
            onBlur={(e) =>
              setFieldValue(vitalsKey, {
                ...values,
                heightIn: e.target.value,
              })
            }
            onWheel={(e) => e.target.blur()}
          />
        </div>
      </div>
    </div>
  );
};

export default Vitals;
