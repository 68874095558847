import {
  Box,
  CircularProgress,
  Grid,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import {
  getIsActiveMonitoringRequest,
  getReadings,
} from "../../api/monitoring";
import { CustomButton, theme } from "../../materialDesignShared";
import BPModal from "./BPModal";
import EntryList from "./EntryList";
import "./monitoring.scss";
import { BpReading } from "./types";
import colors from "../../colors.module.scss";

const Monitoring = () => {
  const [monitoringActive, setMonitoringActive] = useState<boolean>(true);
  const [monitoringEnd, setMonitoringEnd] = useState<Date | null>(null);
  const [monitoringStart, setMonitoringStart] = useState(new Date());
  const [showEntryModal, setShowEntryModal] = useState<boolean>(false);
  // when we have other kinds of readings, we can set the type here so when the modal opens it's right
  const [readings, setReadings] = useState<BpReading[]>([]);
  // 0: uninitialized, 1: loading, 2: success, 3: error
  const [loadReadingState, setLoadReadingState] = useState<0 | 1 | 2 | 3>(0);
  const [error, setError] = useState<string | null>(null);
  const mounted = useRef(false);

  useAsyncEffect(async (isMounted) => {
    const res = await getIsActiveMonitoringRequest();
    if (isMounted()) setMonitoringActive(res.active);
    if (res.active) {
      const cutoff = res.endDate ? new Date(res.endDate) : null;
      setMonitoringEnd(cutoff);
      setMonitoringStart(res.startDate);
    }
  }, []);

  const fetchReadings = useCallback(async () => {
    try {
      setLoadReadingState(1);
      const fetchedReadings = await getReadings();
      if (mounted.current) {
        setReadings(fetchedReadings);
        setLoadReadingState(2);
      }
    } catch (e) {
      if (mounted.current) {
        setLoadReadingState(3);
        setError(
          "There was a problem fetching your blood pressure readings! Please refresh and try again."
        );
      }
      Sentry.captureException(e);
    }
  }, [mounted]);

  useEffect(() => {
    mounted.current = true;
    fetchReadings();
    return () => {
      mounted.current = false;
    };
  }, [fetchReadings, mounted]);

  if (monitoringActive === false) {
    return (
      <ThemeProvider theme={theme}>
        <Grid
          container
          style={{ flexGrow: 1 }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          There are no active monitoring requests from your care team.
        </Grid>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        direction="column"
        alignItems="center"
        style={{ flexGrow: 1 }}
      >
        {showEntryModal && (
          <BPModal
            handleCancel={() => {
              setShowEntryModal(false);
              fetchReadings();
            }}
            pastReadings={readings}
            cutoffEnd={monitoringEnd}
            cutoffStart={monitoringStart}
          />
        )}
        <Grid
          container
          style={{
            justifyContent: "center",
            maxWidth: "46rem",
            padding: "2rem",
          }}
        >
          <Typography className="title">Monitoring</Typography>
          <Typography align="center" style={{ color: "#76878F" }}>
            Please log your readings here twice a day in real time to get
            the most accurate clinical guidance.{" "}
            <a
              href="https://drive.google.com/file/d/1v6900Glry_aS9fQFM2xMk2_EL1X0673h/view"
              target="__blank"
              style={{
                color: colors.titleColor,
              }}
            >
              See instructions
            </a>
            . Call us immediately at (718) 586-4944 if you are having any symptoms of preeclampsia,
            such as headaches, visual changes, nausea, or swelling. We're available 24/7.
          </Typography>
          <CustomButton
            fullWidth={true}
            style={{ maxWidth: "20rem", marginTop: "1rem" }}
            onClick={() => setShowEntryModal(true)}
          >
            + LOG READING
          </CustomButton>
        </Grid>
        <Grid
          container
          style={{
            background: "#FFF",
            width: "100%",
            minHeight: "100%",
            flexGrow: 1,
          }}
          justifyContent="center"
        >
          {loadReadingState === 1 && (
            <CircularProgress style={{ margin: "2rem" }} />
          )}
          {loadReadingState === 2 && <EntryList readings={readings} />}
          {loadReadingState === 3 && (
            <Box padding={"2rem"}>
              <Alert severity="error">{error}</Alert>
              <Typography
                variant="caption"
                align="center"
                display="block"
                style={{ marginTop: "0.5rem" }}
              >
                Our team has been alerted of this error.
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Monitoring;
