import OutsideClickHandler from "react-outside-click-handler";
import { CustomButton } from "../../materialDesignShared";
import { ReactElement } from "react";
import { Close } from "@material-ui/icons";
import { Grid, IconButton } from "@material-ui/core";

const ProfileConfirmModal = ({
  handleConfirm,
  handleHide,
  children,
}: {
  handleConfirm: Function;
  handleHide: Function;
  children: ReactElement;
}) => {
  return (
    <div className={"profile-confirm-modal-wrapper"}>
      <OutsideClickHandler onOutsideClick={() => handleHide()}>
        <div className={"profile-confirm-modal-card"}>
          <Grid container justifyContent={"flex-end"}>
            <IconButton onClick={() => handleHide()}>
              <Close />
            </IconButton>
          </Grid>
          <div className={"profile-confirm-modal-content"}>
            <div>{children}</div>
            <CustomButton onClick={(e) => handleConfirm(e)}>
              Confirm
            </CustomButton>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default ProfileConfirmModal;
