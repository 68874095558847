import { ThemeProvider } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import { Copyright } from "@material-ui/icons";

import * as commonMaterial from "../../materialDesignShared";
import { PATHNAMES } from "../../constants/pathnames";

import "./Footer.scss";

export const Footer = () => (
  <div className="footer">
    <ThemeProvider theme={commonMaterial.theme}>
      <Container className="footer__Container" maxWidth={false}>
        <div className="logoPosition">
          <div className="logoFooter" />
        </div>

        <div className="divider" />

        <div className="footer-mobile">
          <Grid
            container
            spacing={0}
            className="social-container"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={3}>
              <div className="linksBox">
                <a href={PATHNAMES.CONTACT}>Contact</a>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="linksBox">
                <a href="https://oulahealth.com/terms-of-use/" target="_blank">
                  Terms of Use
                </a>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="linksBox">
                <a href="https://oulahealth.com/privacy/" target="_blank">
                  Privacy Policy
                </a>
              </div>
            </Grid>
          </Grid>

          <Container>
            <Grid
              container
              spacing={0}
              className="socialContainer"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={2}>
                <a href="https://www.facebook.com/oulahealth/" target="_blank">
                  <div className="facebook-icon">
                    <img src="/icons/Facebook@0,5x.svg" alt="facebook-icon" />
                  </div>
                </a>
              </Grid>
              <Grid item xs={2}>
                <a href="https://www.instagram.com/oulahealth/" target="_blank">
                  <div>
                    <img src="/icons/Instagram@0,5x.svg" alt="instagram-icon" />
                  </div>
                </a>
              </Grid>
              <Grid item xs={2}>
                <a href="https://www.twitter.com/oulahealth" target="_blank">
                  <div className="twitter-icon">
                    <img src="/icons/Twitter@0,5x.svg" alt="twitter-icon" />
                  </div>
                </a>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="copyright"
            >
              Copyright&nbsp; <Copyright style={{ fontSize: 10 }} />
              &nbsp; Oula Health, Inc
            </Grid>
          </Container>
        </div>

        <div className="footer-desktop" id="footer-desktop">
          <Grid container alignItems="center" className="copyright-desktop">
            Copyright&nbsp; <Copyright style={{ fontSize: 10 }} />
            &nbsp; Oula Health, Inc
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="links-desktop"
          >
            <div>
              <a href={PATHNAMES.CONTACT}>Contact</a>
            </div>
            <div>
              <a href="https://oulahealth.com/terms-of-use/" target="_blank">
                Terms of Use
              </a>
            </div>
            <div>
              <a href="https://oulahealth.com/privacy/" target="_blank">
                Privacy policy
              </a>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            className="social-desktop"
          >
            <a href="https://www.facebook.com/oulahealth/" target="_blank">
              <div className="facebook-icon">
                <img src="/icons/Facebook@0,5x.svg" alt="facebook-icon" />
              </div>
            </a>
            <a href="https://www.instagram.com/oulahealth/" target="_blank">
              <div>
                <img src="/icons/Instagram@0,5x.svg" alt="instagram-icon" />
              </div>
            </a>
            <a href="https://www.twitter.com/oulahealth" target="_blank">
              <div className="twitter-icon">
                <img src="/icons/Twitter@0,5x.svg" alt="twitter-icon" />
              </div>
            </a>
          </Grid>
        </div>
      </Container>
    </ThemeProvider>
  </div>
);
