import { computed, makeObservable, observable } from "mobx";
import { getBookmark, getJourney, getPublicJourney } from "../api/journey";
import { authenticationStore } from "./authentication-store";

class JourneyStore {
  journey = undefined;
  trimester = undefined;
  expandedTrimester = [false, false, false, false, false];
  bookmarks = undefined;
  decisionCardToShow = undefined;
  appointmentCardToShow = undefined;
  educationCardToShow = undefined;
  openMyCarePlan = false;
  firstDecision = undefined;
  loadingJourney = false;
  loadingBookmarks = false;
  totalPoints = false;
  completedPoints = false;
  publicView = false;

  constructor() {
    makeObservable(this, {
      journey: observable,
      expandedTrimester: observable,
      trimester: observable,
      bookmarks: observable,
      decisionCardToShow: observable,
      educationCardToShow: observable,
      appointmentCardToShow: observable,
      firstDecision: observable,
      openMyCarePlan: observable,
      loadingJourney: observable,
      loadingBookmarks: observable,
      totalPoints: observable,
      completedPoints: observable,
      publicView: observable,
    });
  }

  setDecisionCardToShow(decisionCardToShow) {
    this.decisionCardToShow = decisionCardToShow;
    this.educationCardToShow = undefined;
    this.appointmentCardToShow = undefined;
    this.openMyCarePlan = false;
  }

  setEducationCardToShow(educationCardToShow) {
    this.decisionCardToShow = undefined;
    this.appointmentCardToShow = undefined;
    this.educationCardToShow = educationCardToShow;
    this.openMyCarePlan = false;
  }

  setAppointmentCardToShow(appointmentCardToShow) {
    this.appointmentCardToShow = appointmentCardToShow;
    this.decisionCardToShow = undefined;
    this.educationCardToShow = undefined;
    this.openMyCarePlan = false;
  }

  getObservableEnableBookNow = () => {
    return this.enableBookNow;
  };

  closeAllCards() {
    this.appointmentCardToShow = undefined;
    this.decisionCardToShow = undefined;
    this.educationCardToShow = undefined;
    this.openMyCarePlan = false;
  }

  @computed
  get getJourney() {
    if (this.journey === undefined) {
      this.refreshJourney();
      return;
    }
    return this.journey;
  }

  trimesterCalculator = (week) => {
    if (1 <= week && week <= 12) {
      return 1;
    } else if (13 <= week && week <= 26) {
      return 2;
    } else if (week >= 27) {
      return 3;
    } else {
      return 0;
    }
  };

  fetchData(publicView) {
    this.publicView = publicView;
    this.refreshJourney();
    if (!publicView) {
      this.refreshBookmarks();
    }
  }

  refreshJourney = async () => {
    if (this.loadingJourney) {
      return;
    }
    try {
      this.loadingJourney = true;

      let response;
      if (this.publicView) {
        response = await getPublicJourney();
      } else {
        response = await getJourney(authenticationStore.userId);
      }

      const { data } = response;
      this.trimester = this.trimesterCalculator(
        data.journey?.patient_current_week_of_pregnancy
      );
      this.journey = data.journey;

      let totalPointsVar = 0,
        completedPointsVar = 0;
      data.journey?.journey_sections?.map((section) => {
        totalPointsVar += section.total_points;
        this.totalPoints = totalPointsVar;

        completedPointsVar += section.points_completed;
        this.completedPoints = completedPointsVar;
      });

      for (const section of data.journey?.journey_sections) {
        this.firstDecision = section.ordered_cards.find((card) => {
          if (card.card_type === "DecisionCard") {
            return card;
          }
        });
        if (this.firstDecision) {
          break;
        }
      }

      return data.journey;
    } catch (e) {
      this.journey = undefined;
    } finally {
      this.loadingJourney = false;
    }
  };

  @computed
  get getBookmarks() {
    if (this.bookmarks === undefined) {
      this.refreshBookmarks();
      return;
    }
    return this.bookmarks;
  }

  refreshBookmarks = async () => {
    if (this.loadingBookmarks) {
      return;
    }
    try {
      this.loading = true;
      const patientBookmarks = await getBookmark(authenticationStore.userId);
      this.bookmarks = patientBookmarks.data.patient_bookmarks;
    } catch (e) {
      this.journey = undefined;
    } finally {
      this.loading = false;
    }
  };

  resetExpandedCards = () => {
    this.expandedTrimester = [false, false, false, false, false];
  };
}

export const journeyStore = new JourneyStore();
