import { computed, makeObservable, observable } from "mobx";
import { removeLocalStorageExistingItem } from "../services/logoutHelper";
import { getDashboard } from "../api/dashboard";
import { authenticationStore } from "./authentication-store";
import { get } from "lodash";

class AppointmentStore {
  appointment = undefined;
  appointmentTypes = undefined;

  constructor() {
    makeObservable(this, {
      appointment: observable,
      appointmentTypes: observable,
    });
  }

  setAppointment(appointment) {
    localStorage.removeItem("appointment");
    localStorage.setItem("appointment", JSON.stringify(appointment));
  }

  getAppointment() {
    let value = localStorage.getItem("appointment");
    return value && JSON.parse(value);
  }

  getPregnancyCareTypes() {
    let value = localStorage.getItem("pregnancyCareTypes");
    return value && JSON.parse(value);
  }

  setAppointmentTypes(types) {
    localStorage.removeItem("appointmentTypes");
    localStorage.setItem("appointmentTypes", JSON.stringify(types));
  }

  getAppointmentTypes() {
    let value = localStorage.getItem("appointmentTypes");
    return value && JSON.parse(value);
  }

  setDepartmentId(id) {
    localStorage.removeItem("departmentId");
    localStorage.setItem("departmentId", JSON.stringify(id));
  }

  getDepartmentId() {
    let value = localStorage.getItem("departmentId");
    return value && JSON.parse(value);
  }

  setScheduledAppointments(appointmentsArray) {
    localStorage.setItem(
      "appointmentsArray",
      JSON.stringify(appointmentsArray)
    );
  }

  getScheduledAppointments() {
    let value = localStorage.getItem("appointmentsArray");
    return value && JSON.parse(value);
  }

  @computed
  async refreshAppointments() {
    const dashboardData = await getDashboard(authenticationStore.userId);
    const appointments = get(dashboardData.data, "dashboard.appointments");

    if (get(dashboardData.data, "dashboard")) {
      this.setScheduledAppointments(appointments);
    }

    return appointments;
  }

  removeTaggedAppointment() {
    removeLocalStorageExistingItem("taggedAppointment");
  }
}

export const appointmentStore = new AppointmentStore();
