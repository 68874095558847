const escapeRegExpSearchString = (text) =>
  text.replace(/[-[\]{}()*+?.,\\^$|#\\s]/g, "\\$&");

const regXSimpleMarkup = /(<[^>]+>)([^<]+)(<\/[^>]+>)/g;

const createTextSearchMarkup = (fragment, search) => {
  if (typeof search === "undefined") {
    return null;
  }
  search = escapeRegExpSearchString(search);
  search = new RegExp(`(${search})`, "gi");

  return fragment.replace(search, "<mark class='highlightedText'>$1</mark>");
};

const concatTextSearchMarkup = (collector, fragment) => {
  const regXTag = /^<[^>]+>$/;

  if (!regXTag.test(fragment)) {
    fragment = createTextSearchMarkup(fragment, collector.search);
  }

  collector.markup = [collector.markup, fragment].join("");

  return collector;
};

export const getHighlightTextSearchMarkup = (markup, search) =>
  markup.split(regXSimpleMarkup).reduce(concatTextSearchMarkup, {
    search,
    markup: "",
  }).markup;
