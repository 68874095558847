import { useAuth } from "../../providers/Auth";
import Loading from "./Loading";
import "./LoadingLayout.scss";

const LoadingLayout = ({ children }) => {
  const { loading: loadingAuth } = useAuth();

  if (loadingAuth) {
    return <Loading />;
  }

  return children;
};

export default LoadingLayout;
