import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Modal from "@material-ui/core/Modal";
import useAsyncEffect from "use-async-effect";
import _get from "lodash/get";
import _map from "lodash/map";
import _every from "lodash/every";
import _isFunction from "lodash/isFunction";
import _find from "lodash/find";
import _cloneDeep from "lodash/cloneDeep";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import ProgressBar from "../../../ProgressBar";
import { getIntakeFormInfo } from "../../../../api/intakeForm";
import { getProfile } from "../../../../api/profile";
import { authenticationStore } from "../../../../stores/authentication-store";
import { INFO_SECTIONS } from "../../../../constants/intakeFormSections";
import { completeSectionsInfo } from "../../../../helpers/intakeFormHelpers";
import IntakeFormSectionGenerator from "./IntakeFormSectionGenerator";
import { toastLength } from "../../../../services/constants";
import { PATHNAMES } from "../../../../constants/pathnames";

import "./IntakeForm.scss";
import { useFeatureFlag } from "../../../../providers/LaunchDarklyProvider";
import { otherInsuranceMsg } from "../../../../constants/toastMessages";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../../../materialDesignShared";
import { useHistoryWithPathBasedReload } from "../../../App/LinkWithPathBasedReload";

let settings = {
  speed: 500,
  slidesToShow: 1,
  infinite: false,
  slidesToScroll: 1,
  arrows: false,
  swipeToSlide: true,
  dots: false,
  draggable: false,
  swipe: false,
  adaptiveHeight: true,
};

const IntakeForm = ({ openModal = true }) => {
  const [intakeFormInfo, setIntakeFormInfo] = useState([]);
  const [intakeFormAnswers, setIntakeFormAnswers] = useState({});
  const [profileData, setProfileData] = useState({});
  const [sectionIndex, setQuestionIndex] = useState(0);
  const [customButton, setCustomButton] = useState(
    intakeFormInfo[0]?.customButton
  );
  const [intakeFormDatabaseInfo, setIntakeFormDatabaseInfo] = useState({});
  const [toastMessage, setToastMessage] = useState("");
  const [hasOtherInsurance, setHasOtherInsurance] = useState();
  const [selfPay, setSelfPay] = useState(false);

  const history = useHistoryWithPathBasedReload();
  const customSlider = useRef();
  const formHeaderTitle = useRef(null);
  const containerSliderRef = useRef();
  const formContainerRef = useRef();
  const intakeFormInfoLength = intakeFormInfo.length;
  const buttonNextName = customButton?.name || "NEXT";
  const buttonNextCustomClassName = customButton?.className;
  const setMinHeight = customButton?.setMinHeight;
  const sliderContainerClassNames = classNames("intakeForm__slider", {
    "intakeForm__slider--custom": customButton,
    [`intakeForm__slider--${buttonNextCustomClassName}`]:
      buttonNextCustomClassName,
  });
  const isCompleted = intakeFormAnswers.completed;
  const intakeFormCompletedSection = _find(
    intakeFormInfo,
    (section) => section.generalType === "complete"
  );

  const { freeTextOtherInsuranceOption } = useFeatureFlag(
    "free-text-other-insurance-option"
  );

  const filteredInfoSections = freeTextOtherInsuranceOption
    ? INFO_SECTIONS
    : INFO_SECTIONS.map((s) => {
        if (s.key === "second_section")
          return {
            ...s,
            groups: s.groups.filter(
              (f) => f?.field?.key !== "insurance_other_string"
            ),
          };
        return s;
      });

  useEffect(() => {
    if (hasOtherInsurance && sectionIndex === 1)
      setToastMessage(otherInsuranceMsg);
  }, [hasOtherInsurance]);

  settings = {
    ...settings,
    className: sliderContainerClassNames,
  };

  const createIntakeFormContent = async (
    infoSections,
    dbIntakeFormInfo,
    answers,
    dbProfileData
  ) => {
    // filter out any empty sections eg non pregnant patients do not have to answer high risk pregnancy screening q's
    const emptyKeys = Object.entries(dbIntakeFormInfo).flatMap(
      ([key, value]) => {
        if (Array.isArray(value) === false) return [];
        if (value.length === 0) return key;
        else return [];
      }
    );

    const emptySectionsRemoved = infoSections.flatMap((s) => {
      if ("databaseKey" in s === false) return s;
      else if (emptyKeys.indexOf(s.databaseKey.name) > -1) return [];
      else return s;
    });

    const completeInfo = completeSectionsInfo(
      dbIntakeFormInfo,
      emptySectionsRemoved,
      answers,
      dbProfileData
    );

    setIntakeFormInfo(completeInfo);
  };

  useAsyncEffect(async () => {
    const { data } = await getIntakeFormInfo(authenticationStore.userId);
    const {
      data: { patient: profileData },
    } = await getProfile(authenticationStore.userId);
    const intakeForm = _get(data, "intake_form", {});
    const answers = _get(intakeForm, "patient_intake_form_answers", {});

    setIntakeFormDatabaseInfo(intakeForm);
    setProfileData(profileData);
    setIntakeFormAnswers(answers);

    createIntakeFormContent(
      filteredInfoSections,
      intakeForm,
      answers,
      profileData
    );
  }, []);

  useAsyncEffect(() => {
    let sectionsCopy;

    if (typeof hasOtherInsurance !== "boolean") return;

    if (selfPay) {
      sectionsCopy = _cloneDeep(filteredInfoSections);
      if (sectionsCopy?.length) {
        sectionsCopy.forEach(({ groups }, sI) => {
          if (groups?.length) {
            groups.forEach(({ field }, gI) => {
              if (field) {
                sectionsCopy[sI].groups[gI].field.required = false;
              }
            });
          }
        });
      }
    } else if (hasOtherInsurance) {
      sectionsCopy = _cloneDeep(filteredInfoSections);

      for (let i = 0; i < sectionsCopy.length; i++) {
        const groups = sectionsCopy[i]?.groups;

        if (!groups?.length) continue;

        for (let j = 0; j < groups.length; j++) {
          const fieldKey = groups[j]?.field?.key;
          const emrInsurance = fieldKey === "emr_insurancepackageid";
          const insuranceOtherString = fieldKey === "insurance_other_string";

          if (emrInsurance) {
            sectionsCopy[i].groups[j].field.required = false;
          }
          if (insuranceOtherString && freeTextOtherInsuranceOption) {
            sectionsCopy[i].groups[j].field.required = true;
          }
          if (
            !freeTextOtherInsuranceOption &&
            fieldKey === "insurance_member_id"
          ) {
            sectionsCopy[i].groups[j].field.required = false;
          }
        }
      }
    } else {
      sectionsCopy = filteredInfoSections;
    }

    createIntakeFormContent(
      sectionsCopy,
      intakeFormDatabaseInfo,
      intakeFormAnswers,
      profileData
    );
  }, [hasOtherInsurance, selfPay]);

  useEffect(() => {
    if (isCompleted) {
      setQuestionIndex(intakeFormInfoLength - 1);
      setCustomButton(intakeFormCompletedSection?.customButton);
    }
  }, [isCompleted, intakeFormCompletedSection]);

  useEffect(() => {
    if (containerSliderRef.current && formContainerRef.current) {
      if (setMinHeight) {
        containerSliderRef.current.children[0].children[0].style.minHeight = `${formContainerRef.current.parentElement.clientHeight}px`;
      } else {
        containerSliderRef.current.children[0].children[0].style.minHeight =
          null;
      }
    }
  }, [setMinHeight]);

  const goToDashboard = () => history.push(PATHNAMES.DASHBOARD);

  const getFieldException = (currentKeyValue, hasOtherInsurance) => {
    if (
      (currentKeyValue === "emr_insurancepackageid" ||
        currentKeyValue === "insurance_member_id") &&
      hasOtherInsurance
    ) {
      return true;
    }
  };

  const getExistOnlyInsuranceErrorsOrZero = (errors = []) => {
    const errorsLength = errors.length;
    const allAreInsuranceErrors = errors.every((error) => {
      return (
        error === "insurance_member_id" || error === "emr_insurancepackageid"
      );
    });

    if (allAreInsuranceErrors || errorsLength === 0) {
      return true;
    }
  };

  const verifyAllRequiredValuesFilled = (groups, values, hasOtherInsurance) => {
    return _every(groups, (group) => {
      const fields = _get(group, "fields");
      const inline_fields = _get(group, "inline_fields");
      const groupFields = fields || inline_fields;
      let groupFieldsValidation = true;
      let fieldValidation = true;

      if (groupFields) {
        groupFieldsValidation = _every(groupFields, (group) => {
          const isRequired = _get(group, "required", false);
          const key = _get(group, "key", "");
          const value = values[key];
          const isFieldException = getFieldException(key, hasOtherInsurance);
          const isValueEmpty =
            !value || (Array.isArray(value) && !value.length);

          return (
            !isRequired || (isRequired && !isValueEmpty) || isFieldException
          );
        });
      } else {
        const isRequired = _get(group, "field.required", false);
        const key = _get(group, "field.key", "");
        const value = values[key];
        const isFieldException = getFieldException(key, hasOtherInsurance);
        const isValueEmpty = !value || (Array.isArray(value) && !value.length);

        fieldValidation =
          !isRequired || (isRequired && !isValueEmpty) || isFieldException;
      }

      return groupFieldsValidation && fieldValidation;
    });
  };

  const onClickNext = (
    lengthQuestions,
    submitFn,
    existErrors,
    groups,
    values,
    isCompletedType,
    hasOtherInsurance
  ) => {
    const hasAllRequiredFields = verifyAllRequiredValuesFilled(
      groups,
      values,
      hasOtherInsurance
    );

    if (_isFunction(submitFn)) {
      submitFn();
    }

    if (isCompletedType) {
      goToDashboard();
      return;
    }

    if (
      sectionIndex < lengthQuestions &&
      !existErrors &&
      hasAllRequiredFields
    ) {
      setQuestionIndex(sectionIndex + 1);
      setCustomButton(intakeFormInfo[sectionIndex + 1]?.customButton);
      customSlider.current.slickNext();

      if (formHeaderTitle.current) {
        formHeaderTitle.current.scrollTop = 0;
      }
    }
  };

  const onClickPrev = (submitFn) => {
    if (_isFunction(submitFn)) {
      submitFn();
    }

    setQuestionIndex(sectionIndex - 1);
    setCustomButton(intakeFormInfo[sectionIndex - 1]?.customButton);
    customSlider.current.slickPrev();

    if (formHeaderTitle.current) {
      formHeaderTitle.current.scrollTop = 0;
    }
  };

  const handleCloseModal = () => {
    goToDashboard();
  };

  const handleCloseToast = (e, reason) => {
    if (reason === "clickaway") return;

    setToastMessage("");
  };

  const ButtonNext = ({
    submitFn,
    errors,
    groups,
    values,
    isCompletedType,
    hasOtherInsurance,
  }) => {
    const formattedErrors = Object.keys(errors);
    const existOnlyInsuranceError =
      getExistOnlyInsuranceErrorsOrZero(formattedErrors);

    let existErrors;
    if (selfPay) existErrors = false;
    else existErrors = formattedErrors.length;

    const buttonClassNames = classNames("intakeForm__nextButton", {
      "intakeForm__nextButton--custom":
        customButton && !buttonNextCustomClassName,
      "intakeForm__nextButton--disabled": existErrors,
      [`intakeForm__nextButton--${buttonNextCustomClassName}`]:
        buttonNextCustomClassName,
    });

    return (
      <button
        onClick={() =>
          onClickNext(
            intakeFormInfoLength,
            submitFn,
            existErrors,
            groups,
            values,
            isCompletedType,
            hasOtherInsurance
          )
        }
        className={buttonClassNames}
        type="submit"
        disabled={existErrors}
      >
        {buttonNextName}
      </button>
    );
  };

  const ButtonGoBack = ({ submitFn }) => {
    const buttonClassNames = classNames("intakeForm__goBackButton", {
      "intakeForm__goBackButton--custom":
        customButton && !buttonNextCustomClassName,
      [`intakeForm__goBackButton--${buttonNextCustomClassName}`]:
        buttonNextCustomClassName,
    });

    return (
      <button
        onClick={() => onClickPrev(submitFn)}
        className={buttonClassNames}
        type="submit"
      >
        <span>Go back</span>
      </button>
    );
  };

  let baseLayout = (
    <div className="intakeForm__container" ref={formHeaderTitle}>
      <span className="intakeForm__title">INTAKE FORM</span>

      <ProgressBar
        progress={sectionIndex + 1}
        lengthElements={intakeFormInfoLength}
      />

      {isCompleted ? (
        <div style={{ position: "relative" }}>
          <IntakeFormSectionGenerator
            sectionInfo={intakeFormCompletedSection}
            key={intakeFormCompletedSection?.key}
            ButtonNext={ButtonNext}
            ButtonGoBack={ButtonGoBack}
            sectionIndex={intakeFormInfoLength - 1}
            profileData={profileData}
            intakeFormAnswers={intakeFormAnswers}
            setTemporalIntakeFormAnswers={setIntakeFormAnswers}
            intakeFormInfoLength={intakeFormInfoLength}
            hasOtherInsurance={hasOtherInsurance}
            setHasOtherInsurance={setHasOtherInsurance}
            setSelfPay={setSelfPay}
          />
        </div>
      ) : (
        <div ref={containerSliderRef}>
          <Slider {...settings} ref={customSlider}>
            {_map(intakeFormInfo, (section) => (
              <IntakeFormSectionGenerator
                sectionInfo={section}
                key={section.key}
                ButtonNext={ButtonNext}
                ButtonGoBack={ButtonGoBack}
                sectionIndex={sectionIndex}
                profileData={profileData}
                intakeFormAnswers={intakeFormAnswers}
                setTemporalIntakeFormAnswers={setIntakeFormAnswers}
                intakeFormInfoLength={intakeFormInfoLength}
                intakeFormDatabaseInfo={intakeFormDatabaseInfo}
                setToastMessage={setToastMessage}
                hasOtherInsurance={hasOtherInsurance}
                setHasOtherInsurance={setHasOtherInsurance}
                setSelfPay={setSelfPay}
                formContainerRef={formContainerRef}
              />
            ))}
          </Slider>
        </div>
      )}
    </div>
  );

  const isTinyViewport = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <>
      <Snackbar
        open={!!toastMessage}
        // onClose={handleCloseToast}
        className="intakeForm__toast"
        autoHideDuration={toastLength}
        style={{
          position: "absolute",
          left: 0,
          top: isTinyViewport ? 0 : null,
          width: "100vw",
          display: "flex",
          transform: "none",
        }}
      >
        <Alert
          onClose={handleCloseToast}
          className="intakeForm__toastAlert"
          style={{ maxWidth: "350px", margin: "1rem" }}
        >
          {toastMessage}
        </Alert>
      </Snackbar>

      <Modal
        open={openModal}
        aria-labelledby="intake-form-title"
        aria-describedby="intake-form-description"
      >
        <div className="intakeForm__generalContainer">
          <CloseIcon
            className="intakeForm__closeButton"
            onClick={handleCloseModal}
          />
          {baseLayout}
        </div>
      </Modal>
    </>
  );
};

export default IntakeForm;
