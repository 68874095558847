export const AUTHENTICATION_STORE_NAMES = {
  JWT: "jwt",
  REFRESH_TOKEN: "refreshToken",
  FIRST_LOGIN: "firstLogin",
  FIRST_NAME: "firstName",
  CARE_PLAN_COMPLETED: "carePlanCompleted",
  USER_DATA: "userData",
  TAGGED_APPOINTMENT: "taggedAppointment",
  MINIMUM_PROFILE_COMPLETED: "minimumProfileCompleted",
  CALENDAR_TO_PREGNANCY_WEEKS: "calendarToPregnancyWeeks",
  REDIRECT_SCHEDULING: "redirectScheduling",
  REDIRECT_NEXT_APPOINTMENT: "redirectNextAppointment",
  REDIRECT_DASHBOARD_CHAT: "redirectDashboardChat",
  REMEMBER_ME: "rememberMe",
  BOOK_NOW_FLOW: "bookNowFlow",
};
