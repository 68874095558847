import { initialize, LDClient, LDContext } from "launchdarkly-js-client-sdk";
import { z } from "zod";
import {
  Context,
  createContext,
  FC,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type FeatureFlagsSchema = Readonly<Record<string, z.ZodTypeAny>>;
type FlagsType<S extends FeatureFlagsSchema> = {
  readonly [K in keyof S]: z.infer<S[K]>;
};
type FlagType<S extends FeatureFlagsSchema, K extends keyof S> = z.infer<S[K]>;

type CamelCaseFromKebab<S extends any> = S extends string
  ? S extends `${infer P1}-${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCaseFromKebab<P3>}`
    : Lowercase<S>
  : never;

const toCamelCaseFromKebab = <T extends string>(
  s: T
): CamelCaseFromKebab<T> => {
  return s.replace(/-./g, (x) => x[1].toUpperCase()) as CamelCaseFromKebab<T>;
};

export type UseFeatureFlagReturnVal<
  S extends FeatureFlagsSchema,
  KEY extends keyof S
> = Pick<
  { [K in keyof S as CamelCaseFromKebab<K>]: FlagType<S, K> },
  CamelCaseFromKebab<KEY>
> & {
  getValue: () => S[KEY];
};

interface FeatureFlagsClient<S extends FeatureFlagsSchema> {
  listen: <K extends keyof S>(
    flag: keyof S & string,
    callback: (changes: FlagType<S, K>) => void
  ) => () => void;
  evaluateFlag: <K extends keyof S>(flag: keyof S & string) => FlagType<S, K>;
  setUser: (user: string | null) => void;
}

class LDFeatureFlagsClient<S extends FeatureFlagsSchema>
  implements FeatureFlagsClient<S>
{
  private constructor(
    private readonly client: LDClient,
    private readonly schema: S,
    private readonly fallbackValues: FlagsType<S>
  ) {}

  static async initialize<S extends FeatureFlagsSchema>(
    user: string | null,
    launchDarklyKey: string,
    FeatureFlagsSchema: S,
    fallbackValues: FlagsType<S>
  ) {
    const context: LDContext = user ? { key: user } : { anonymous: true };
    const client = initialize(launchDarklyKey, context, {
      sendEventsOnlyForVariation: true,
      streaming: true,
      logger: {
        debug: () => undefined,
        info: () => undefined,
        warn: () => undefined,
        error: (message: string) =>
          console.error(new Error("LaunchDarkly error"), message),
      },
    });
    await client.waitForInitialization();
    return new LDFeatureFlagsClient(client, FeatureFlagsSchema, fallbackValues);
  }

  listen<K extends keyof S>(
    flag: keyof S & string,
    callback: (changes: FlagType<S, K>) => void
  ) {
    const listenerKey = `change:${flag}`;
    const handleChanges = (update: unknown) => {
      const changeSchema = z.object({
        value: this.schema[flag],
      });
      const { value } = changeSchema.parse({ value: update });
      console.info(`Received new value for '${flag}' feature flag: ${value}`);
      callback(value);
    };
    this.client.on(listenerKey, handleChanges);
    return () => this.client.off(listenerKey, handleChanges);
  }

  evaluateFlag<K extends keyof S>(flag: keyof S & string): FlagType<S, K> {
    const variation = this.client.variation(flag);
    if (variation === null) {
      return this.fallbackValues[flag];
    }
    return this.schema[flag].parse(variation);
  }

  setUser(user: string | null) {
    this.client.identify(user === null ? { anonymous: true } : { key: user });
  }
}

class StaticClient<S extends FeatureFlagsSchema>
  implements FeatureFlagsClient<S>
{
  constructor(private readonly values: FlagsType<S>) {}

  evaluateFlag<K extends keyof S>(flag: K): FlagType<S, K> {
    return this.values[flag];
  }

  listen() {
    return () => {};
  }

  setUser(): void {}
}

export class FeatureFlags<S extends FeatureFlagsSchema> {
  private readonly context: Context<FeatureFlagsClient<S> | null>;
  private readonly errorLogger: (...data: any[]) => void;
  private readonly infoLogger: (...data: any[]) => void;
  private readonly schema: S;
  private readonly _fallbackValues: FlagsType<S>;

  constructor(
    errorLogger: (...data: any[]) => void,
    infoLogger: (...data: any[]) => void,
    schema: S,
    fallbackValues: FlagsType<S>
  ) {
    this.context = createContext<FeatureFlagsClient<S> | null>(null);
    this.errorLogger = errorLogger;
    this.infoLogger = infoLogger;
    this._fallbackValues = fallbackValues;
    this.schema = schema;
  }

  static readonly withSchema = <S extends FeatureFlagsSchema>(schema: S) => ({
    andFallbackValues: (defaults: FlagsType<S>) => ({
      andLoggers: (
        errorLogger: (...data: any[]) => void,
        infoLogger: (...data: any[]) => void
      ) => new FeatureFlags(errorLogger, infoLogger, schema, defaults),
    }),
  });

  readonly StaticProvider = ({
    children,
    loadingFallback,
  }: PropsWithChildren<{ loadingFallback?: ReactNode }>) => {
    return (
      <this.Provider
        getClient={async () => {
          return new StaticClient(this._fallbackValues);
        }}
        loadingFallback={loadingFallback}
      >
        {children}
      </this.Provider>
    );
  };

  readonly LDProvider: FC<
    PropsWithChildren<{
      user: string | null;
      apiKey: string;
      loadingFallback?: ReactNode;
    }>
  > = ({ user, children, apiKey, loadingFallback }) => {
    return (
      <this.Provider
        getClient={() =>
          LDFeatureFlagsClient.initialize(
            user,
            apiKey,
            this.schema,
            this._fallbackValues
          )
        }
        loadingFallback={loadingFallback}
      >
        {children}
      </this.Provider>
    );
  };

  readonly useFeatureFlag = <K extends keyof S & string>(
    key: K
  ): UseFeatureFlagReturnVal<S, K> => {
    const client = useContext(this.context);
    const [flagValue, setFlagValue] = useState<FlagType<S, K>>(
      client?.evaluateFlag(key) ?? this._fallbackValues[key]
    );

    if (client === null) {
      throw new Error("must be inside feature flags provider");
    }

    useEffect(() => {
      return client.listen(key, setFlagValue);
    }, [key, client]);

    const camelCaseKey = toCamelCaseFromKebab(key);

    return {
      [camelCaseKey]: flagValue,
      getValue: () => client.evaluateFlag(key),
    } as UseFeatureFlagReturnVal<S, K>;
  };

  readonly useFeatureFlagEvaluator = () => {
    const client = useContext(this.context);

    if (client === null) {
      throw new Error("must be inside feature flags provider");
    }
    return <K extends keyof S & string>(flag: K & string) =>
      client.evaluateFlag(flag) as FlagType<S, K>;
  };

  readonly useUpdateFeatureFlagUser = () => {
    const client = useContext(this.context);

    if (client === null) {
      throw new Error("must be inside feature flags provider");
    }

    return (user: string | null) => client.setUser(user);
  };

  readonly fallbackValues = () => {
    return Object.fromEntries(
      Object.entries(this._fallbackValues).map(([key, value]) => [
        toCamelCaseFromKebab(key),
        value,
      ])
    ) as {
      [K in keyof S as CamelCaseFromKebab<K>]: FlagType<S, K>;
    };
  };

  private Provider: FC<
    PropsWithChildren<{
      loadingFallback?: ReactNode;
      getClient: () => Promise<FeatureFlagsClient<S>>;
    }>
  > = ({ children, loadingFallback, getClient }) => {
    const [client, setClient] = useState<
      FeatureFlagsClient<S> | "unset" | "loading" | "error"
    >("unset");

    useEffect(() => {
      let mounted = true;
      setClient("loading");
      getClient()
        .then((client) => {
          if (mounted) setClient(client);
        })
        .catch((e) => {
          if (mounted) {
            this.errorLogger(e, "Failed to load feature flags");
            setClient("error");
          }
        });

      return () => {
        mounted = false;
      };
    }, [getClient]);

    if (
      (client === "loading" || client === "unset") &&
      loadingFallback !== undefined
    ) {
      return <>{loadingFallback}</>;
    }

    return (
      <this.context.Provider
        value={
          client === "error" || client === "loading" || client === "unset"
            ? new StaticClient(this._fallbackValues)
            : client
        }
      >
        {children}
      </this.context.Provider>
    );
  };
}
