import { get } from "lodash";
import * as authApi from "../api/auth";
import { PATHNAMES } from "../constants/pathnames";
import { authenticationStore } from "../stores/authentication-store";

export const logout = async () => {
  try {
    const { data } = await authApi.logout();
    await authenticationStore.removeToken();
    window.location.href = PATHNAMES.LOGIN;
  } catch (e) {
    if (get(e, "response.status") === 500) {
      window.location.href = PATHNAMES.LOGIN;
    }
  }
};

export function removeLocalStorageExistingItem(key) {
  if (localStorage.getItem(key) === null) return false;
  localStorage.removeItem(key);
  return true;
}

export function removeSessionStorageExistingItem(key) {
  if (sessionStorage.getItem(key) === null) return false;
  sessionStorage.removeItem(key);
  return true;
}
