import {
  Container,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { get } from "lodash";
import moment from "moment";
import { useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { PuffLoader } from "react-spinners";
import useAsyncEffect from "use-async-effect";

import { getDashboard } from "../../api/dashboard";
import { getLabResultImage, getLabResults, revealSex } from "../../api/results";
import { YYYY_MM_DD_ALTERNATIVE } from "../../constants/dateFormat";
import * as commonMaterial from "../../materialDesignShared";
import { authenticationStore } from "../../stores/authentication-store";
import "./Results.scss";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import IntercomButton from "../Intercom/IntercomButton";

const usersAllowedToSeeMessageButton = [
  "3060", // Althea Test
];

export const Results = () => {
  const [showScrollBlurBefore, setShowScrollBlurBefore] = useState(false);
  const [showScrollBlurAfter, setShowScrollBlurAfter] = useState(true);
  const [patientLabResults, setPatientLabResults] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [openRevealSexModal, setOpenRevealSexModal] = useState(false);
  const [openDocumentPage, setOpenDocumentPage] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [imagesUrl, setImagesUrl] = useState([]);
  const [recentDate, setRecentDate] = useState(null);
  const [loadingWatchResults, setLoadingWatchResults] = useState(false);
  const [datesScrollPosition, setDatesScrollPosition] = useState(0);
  const datesRef = useRef(null);

  const abnormalMessagingFlag = dashboardData?.enable_abnormal_messaging;

  useAsyncEffect(async () => {
    const { data } = await getLabResults(authenticationStore.userId);

    const { data: dashboardResult } = await getDashboard(
      authenticationStore.userId
    );

    const unsortedPatientLabResults = get(data, "patient_lab_results");
    const resultsMap = {};
    const sortedResults = Object.entries(unsortedPatientLabResults).sort(
      ([a], [b]) => moment(b) - moment(a)
    );
    sortedResults.forEach(([k, v]) => (resultsMap[k] = v));

    const fetchedDashboardData = get(dashboardResult, "dashboard");

    setDashboardData(fetchedDashboardData);

    if (resultsMap) {
      setPatientLabResults(resultsMap);

      const recentDateKey = sortedResults[0][0];

      setRecentDate(recentDateKey);
    }
  }, []);

  const handleScroll = (e) => {
    setDatesScrollPosition(e.target.scrollLeft);
    e.target.scrollLeft > 0
      ? setShowScrollBlurBefore(true)
      : setShowScrollBlurBefore(false);
    e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth
      ? setShowScrollBlurAfter(false)
      : setShowScrollBlurAfter(true);
  };

  const triggerScroll = (direction) => {
    datesRef.current.scrollTo({
      left:
        direction === "right"
          ? datesScrollPosition + 100
          : datesScrollPosition - 100,
      behavior: "smooth",
    });
  };

  return (
    <>
      <ThemeProvider theme={commonMaterial.theme}>
        <div className="results-container">
          <Container maxWidth="sm">
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <div className="title">Results</div>
              {Object.entries(patientLabResults).length === 0 && (
                <div className="no-results">
                  <div className="no-results-text">
                    <p>You don't have any results yet.</p>
                    <p>
                      Once your care team has
                      <br />
                      released them, we will send
                      <br />
                      you an email and they will
                      <br />
                      appear here.
                    </p>
                    <p>
                      This can take up to 5 days
                      <br />
                      after your test.
                    </p>
                  </div>
                  {usersAllowedToSeeMessageButton.includes(
                    authenticationStore.userId
                  ) && <IntercomButton />}
                </div>
              )}

              {Object.entries(patientLabResults).length > 0 && (
                <>
                  <div className="results-dates-horizontal">
                    <IconButton
                      className="results-dates-arrow "
                      onClick={() => triggerScroll("left")}
                    >
                      <ArrowLeft />
                    </IconButton>
                    <div
                      className="results-dates-container"
                      onScroll={handleScroll}
                      ref={datesRef}
                    >
                      {showScrollBlurBefore && (
                        <div className="results-dates-container-before" />
                      )}
                      <div className="center-dates">
                        {Object.entries(patientLabResults).map((labResult) => {
                          return (
                            <div
                              className={`date ${
                                recentDate &&
                                moment(
                                  recentDate,
                                  YYYY_MM_DD_ALTERNATIVE
                                ).isSame(
                                  labResult[0],
                                  YYYY_MM_DD_ALTERNATIVE
                                ) &&
                                "selected"
                              }`}
                              onClick={() => setRecentDate(labResult[0])}
                            >
                              {moment(labResult[0]).format("MM/DD/YY")}
                            </div>
                          );
                        })}
                      </div>
                      {showScrollBlurAfter && (
                        <div className="results-dates-container-after" />
                      )}
                    </div>
                    <IconButton
                      className="results-dates-arrow"
                      onClick={() => triggerScroll("right")}
                    >
                      <ArrowRight />
                    </IconButton>
                  </div>
                  <Table className="results-table" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ textAlign: "left" }}>
                          Diagnostics
                        </TableCell>
                        <TableCell className="background-beige">
                          My Result
                        </TableCell>
                        <TableCell style={{ textAlign: "right" }}>
                          Normal Range
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentDate &&
                        Object.entries(patientLabResults[recentDate]).map(
                          (entry) => (
                            <>
                              {entry[1].length > 0 && (
                                <>
                                  <TableRow className="results-category">
                                    <TableCell>{entry[0]}</TableCell>
                                    <TableCell className="background-beige" />
                                    <TableCell />
                                  </TableRow>
                                  {entry[1].map((result) => {
                                    const abnormalResult =
                                      result.abnormal_flag &&
                                      result.abnormal_flag !== "normal";

                                    const abnormalResultMessage =
                                      result?.abnormal_messaging;

                                    const sexRevealTitle = "SEX REVEAL";

                                    const handleCloseModal = () =>
                                      setOpenRevealSexModal(false);

                                    const getValueResult = (
                                      resultName,
                                      resultImages,
                                      labResultId
                                    ) => {
                                      if (resultImages) {
                                        const watchResults = async () => {
                                          const images = [];
                                          setLoadingWatchResults(true);

                                          const promises = resultImages.map(
                                            async ({ pageid }) => {
                                              const response =
                                                await getLabResultImage(
                                                  authenticationStore.userId,
                                                  labResultId,
                                                  pageid
                                                );

                                              images.push(response.data);
                                            }
                                          );

                                          await Promise.all(promises);

                                          setImagesUrl(images);

                                          if (images.length > 1) {
                                            setShowDocument(true);
                                            setOpenRevealSexModal(false);
                                            setOpenDocumentPage(true);
                                          } else {
                                            let documentPage = new Image();
                                            documentPage.src = images[0];
                                            documentPage.style.width = "100%";

                                            let newWindow = window.open("");
                                            newWindow?.document.write(
                                              documentPage.outerHTML
                                            );
                                          }

                                          setLoadingWatchResults(false);
                                        };

                                        if (resultName === "NIPT") {
                                          return (
                                            <>
                                              <div
                                                className={`${
                                                  abnormalResult
                                                    ? "abnormal-results--background"
                                                    : ""
                                                }`}
                                              >
                                                <span
                                                  className="results-value--revealSex"
                                                  onClick={() =>
                                                    setOpenRevealSexModal(true)
                                                  }
                                                >
                                                  VIEW PDF
                                                </span>
                                              </div>
                                              <Modal
                                                open={openRevealSexModal}
                                                onClose={() =>
                                                  setOpenRevealSexModal(false)
                                                }
                                              >
                                                <div className="results__revealSexModal">
                                                  <span className="results__revealSexModalTitle">
                                                    {sexRevealTitle}
                                                  </span>
                                                  <CloseIcon
                                                    className="results__revealSexModalClose"
                                                    onClick={handleCloseModal}
                                                  />
                                                  <img
                                                    src="/icons/icon-gender.svg"
                                                    alt="check"
                                                    className="results__revealSexModalIconGender"
                                                  />

                                                  <div className="results__revealSexModalInfo">
                                                    <span className="results__revealSexModalInfoTitle">
                                                      IMPORTANT NOTICE:
                                                    </span>
                                                    <span className="results__revealSexModalAbnormalFlagDescription">
                                                      {abnormalResult ? (
                                                        <span>
                                                          Your results are{" "}
                                                          <b>inconclusive</b> or
                                                          indicate an{" "}
                                                          <b>increased risk</b>.
                                                          Our team has reached
                                                          out to discuss
                                                          recommended next
                                                          steps.
                                                        </span>
                                                      ) : (
                                                        <span>
                                                          Your results are
                                                          considered{" "}
                                                          <b>
                                                            low risk and normal
                                                          </b>
                                                          .
                                                        </span>
                                                      )}
                                                    </span>
                                                    <span className="results__revealSexModalInfoDescription">
                                                      Viewing this PDF will
                                                      reveal the sex of your
                                                      baby. Are you sure you
                                                      want to view?
                                                    </span>
                                                  </div>

                                                  {loadingWatchResults ? (
                                                    <div className="results__revealSexModalShowResultLoader">
                                                      <PuffLoader
                                                        size="20"
                                                        color="#bc6e4d"
                                                      />
                                                    </div>
                                                  ) : (
                                                    <button
                                                      className="results__revealSexModalShowResult"
                                                      onClick={() =>
                                                        watchResults()
                                                      }
                                                    >
                                                      {" "}
                                                      REVEAL SEX
                                                    </button>
                                                  )}

                                                  <span
                                                    className="results__revealSexModalHideResult"
                                                    onClick={async () => {
                                                      await revealSex(
                                                        authenticationStore.userId,
                                                        false
                                                      );

                                                      handleCloseModal();
                                                    }}
                                                  >
                                                    No, I don't want to reveal
                                                    the sex
                                                  </span>
                                                </div>
                                              </Modal>
                                            </>
                                          );
                                        } else {
                                          return (
                                            <div
                                              className={`${
                                                abnormalResult
                                                  ? "abnormal-results--background"
                                                  : ""
                                              }`}
                                            >
                                              <span
                                                className="results-value--revealSex"
                                                onClick={watchResults}
                                              >
                                                VIEW PDF
                                              </span>
                                            </div>
                                          );
                                        }
                                      }

                                      return (
                                        <div
                                          className={`${
                                            abnormalResult
                                              ? "abnormal-results--background"
                                              : ""
                                          }`}
                                        >
                                          <span className="results-value">
                                            {result.value}
                                          </span>
                                          <span className="results-unit">
                                            {" "}
                                            {result.units}
                                          </span>
                                        </div>
                                      );
                                    };

                                    return (
                                      <TableRow className="results-result">
                                        <TableCell className="diagnostics-name">
                                          {result.analyte_name}
                                        </TableCell>
                                        <TableCell
                                          className={`my-results background-beige ${
                                            abnormalResult
                                              ? "abnormal-results"
                                              : ""
                                          }`}
                                        >
                                          <>
                                            <Modal
                                              open={openDocumentPage}
                                              onClose={() => {
                                                setOpenDocumentPage(false);
                                                setImagesUrl([]);
                                              }}
                                            >
                                              {imagesUrl.length &&
                                                showDocument && (
                                                  <div className="results__pagePreview">
                                                    <span className="results__pagePreviewTitle">
                                                      Results
                                                    </span>
                                                    <CloseIcon
                                                      className="results__revealSexModalClose"
                                                      onClick={() => {
                                                        setOpenDocumentPage(
                                                          false
                                                        );
                                                        setImagesUrl([]);
                                                      }}
                                                    />
                                                    <div className="results_pagePreviewImagesContainer">
                                                      {imagesUrl.map(
                                                        (image, index) => {
                                                          return (
                                                            <div
                                                              className="results__pagePreviewContainer"
                                                              onClick={() => {
                                                                let documentPage =
                                                                  new Image();
                                                                documentPage.src =
                                                                  image;
                                                                documentPage.style.width =
                                                                  "100%";

                                                                let newWindow =
                                                                  window.open(
                                                                    ""
                                                                  );
                                                                newWindow?.document.write(
                                                                  documentPage.outerHTML
                                                                );
                                                              }}
                                                            >
                                                              <img
                                                                src={image}
                                                                className="results__revealDocumentPage"
                                                              />
                                                              <span>
                                                                Page {index + 1}
                                                              </span>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </div>
                                                  </div>
                                                )}
                                            </Modal>
                                            {getValueResult(
                                              result.analyte_name,
                                              result.images,
                                              result.lab_result_id
                                            )}
                                          </>
                                        </TableCell>
                                        <TableCell className="normal-range">
                                          <span className="normal-range-value">
                                            {result.reference_range
                                              ?.replace("-", " - ")
                                              .replace("<", "< ")
                                              .replace(">", "> ")}
                                          </span>
                                          <span className="normal-range-unit">
                                            {" "}
                                            {result.units}
                                          </span>
                                          {abnormalResult &&
                                          abnormalMessagingFlag &&
                                          abnormalResultMessage ? (
                                            <div className="abnormal-messaging">
                                              {ReactHtmlParser(
                                                result.abnormal_messaging
                                              )}
                                            </div>
                                          ) : null}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )
                        )}
                    </TableBody>
                  </Table>
                </>
              )}
            </Grid>
          </Container>
        </div>
      </ThemeProvider>
    </>
  );
};
