import { get } from "lodash";
import { MenuItem } from "@material-ui/core";

export const itemsKeyValue = (array, key) => {
  if (get(array, key)) {
    const items = array[key].map((item) => (
      <MenuItem value={item[0]}>{item[1]}</MenuItem>
    ));

    return items;
  }
};

export const itemsValue = (array, key) => {
  if (get(array, key)) {
    const items = array[key].map((item) => (
      <MenuItem value={item}>{item}</MenuItem>
    ));
    return items;
  }
};

export const itemsTwoValue = (array, key) => {
  if (get(array, key)) {
    const items = array[key].map((item) => (
      <MenuItem value={item[0]}>{item[1]}</MenuItem>
    ));
    return items;
  }
};
