import { useState, useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { getAuthUser } from "../api/auth";

const AuthContext = React.createContext(undefined);

const AuthProvider = ({ children, mockUser }) => {
  const [user, setUser] = useState({});
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const { update } = useIntercom();

  useEffect(() => {
    if (mockUser !== undefined) {
      setUser(mockUser);
    } else {
      fetchUser();
    }
  }, []);

  useEffect(() => {
    if (Object.values(user).length > 0) {
      const intercomProps = {
        name: `${user.first_name ? user.first_name + " " : ""}${
          user.last_name ?? ""
        }`,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        userHash: user.intercom_hmac,
        phone_number: user.patient_attributes?.cell_phone,
        athena_id: user.patient_attributes?.athena_health_id,
        preferred_pronouns: user.patient_attributes?.preferred_pronouns,
        clinic_location: user.patient_attributes?.department?.display_address,
        expected_delivery_date:
          user.patient_attributes?.ob_episode?.est_delivery_date,
        intake_form_completed: user.patient_attributes?.completed_intake_form,
        screening_status: user.patient_attributes?.screening_status,
        current_week_of_pregnancy:
          user.patient_attributes?.current_week_of_pregnancy,
        actual_delivery_date:
          user.patient_attributes?.ob_episode?.actual_delivery_date,
      };
      update(intercomProps);
    }
  }, [user]);

  const fetchUser = async ({ ignoreLoading } = {}) => {
    try {
      if (!ignoreLoading) {
        setLoading(true);
      }

      const { data } = await getAuthUser();

      setIsAuthenticated(data?.user?.id ? true : false);
      setUser(data?.user ?? {});
    } catch (error) {
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, loading, fetchUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export default AuthProvider;
