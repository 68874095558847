import { retryRequest } from "../services/requestHelpers";

export const setLogEntry = (data) => {
  return retryRequest({
    method: "post",
    url: "/api/v1/frontend_log.json",
    data: {
      log_entry: {
        app_name: "PORTAL",
        ...data,
      },
    },
  });
};
