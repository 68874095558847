import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Slider from "react-slick";
import Button from "@material-ui/core/Button";

import "./CarouselEventsAndClasses.scss";

export const CarouselEventsAndClasses = ({ prop }) => {
  const CustomPrevArrow = ({ onClick }) => (
    <div className="prev-arrow" onClick={onClick}>
      <NavigateBeforeIcon />
    </div>
  );

  const CustomNextArrow = ({ onClick }) => (
    <div className="next-arrow" onClick={onClick}>
      <NavigateNextIcon />
    </div>
  );

  const settings = {
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="carousel-slider-events-classes">
      <Slider {...settings}>
        <div>
          <div className="carousel-item-events-classes">
            <div className="events-image">
              <img
                src="https://oulahealth.com/wp-content/uploads/2020/10/Priya.jpg	"
                width="100%"
                height="176px"
              />
              <Button>RSVP</Button>
            </div>
            <div className="events-body">
              <div className="events-title">First Trimester 101</div>
              <div className="events-date">12.16.2020</div>
              <div className="events-address">
                1403-1385 Atlantic Ave
                <br />
                Brooklyn, NY 11216
              </div>
              <div className="events-host">Hosted by CM, Shawna King</div>
              <div className="events-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
              <div className="read-more-link">
                <a href="">READ MORE</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-item-events-classes">
            <div className="events-image">
              <img
                src="https://oulahealth.com/wp-content/uploads/2020/10/Priya.jpg	"
                width="100%"
                height="176px"
              />
              <Button>RSVP</Button>
            </div>
            <div className="events-body">
              <div className="events-title">First Trimester 101</div>
              <div className="events-date">12.16.2020</div>
              <div className="events-address">
                1403-1385 Atlantic Ave
                <br />
                Brooklyn, NY 11216
              </div>
              <div className="events-host">Hosted by CM, Shawna King</div>
              <div className="events-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
              <div className="read-more-link">
                <a href="">READ MORE</a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="carousel-item-events-classes">
            <div className="events-image">
              <img
                src="https://oulahealth.com/wp-content/uploads/2020/10/Priya.jpg	"
                width="100%"
                height="176px"
              />
              <Button>RSVP</Button>
            </div>
            <div className="events-body">
              <div className="events-title">First Trimester 101</div>
              <div className="events-date">12.16.2020</div>
              <div className="events-address">
                1403-1385 Atlantic Ave
                <br />
                Brooklyn, NY 11216
              </div>
              <div className="events-host">Hosted by CM, Shawna King</div>
              <div className="events-description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </div>
              <div className="read-more-link">
                <a href="">READ MORE</a>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  );
};
