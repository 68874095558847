import { IntercomProvider } from "react-use-intercom";
import "@fortawesome/fontawesome-free/css/all.css";
import { StylesProvider } from "@material-ui/core/styles";
import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import CacheCheck from "./components/App/CacheCheck";
import { LDRouter } from "./components/Navigator/Navigator";
import "./index.scss";
import AuthProvider from "./providers/Auth";
import * as serviceWorker from "./serviceWorker";
import mixpanel from "mixpanel-browser";

Sentry.init({
  dsn: "https://39ea26bfc2b946a7bbb9fe3f9f8e3c78@o1410684.ingest.sentry.io/6748180",
  environment: import.meta.env.VITE_ENV,
  sampleRate: 1,
  tracesSampleRate: 0.2,
  integrations: [
    Sentry.browserTracingIntegration({
      tracingOrigins: ["api.oulahealth.com", "api.staging.oulahealth.com"],
    }),
  ],
  beforeSend: (e) => {
    if (window.location.host !== "my.oulahealth.com") {
      if (e?.exception) {
        console.error("Exception:", e.exception);
      } else console.error(e);
    }
    return e;
  },
});

mixpanel.init(import.meta.env.VITE_MIXPANEL_ID);

ReactDOM.render(
  <React.StrictMode>
    <IntercomProvider
      appId={import.meta.env.VITE_INTERCOM_ID}
      autoBoot
      autoBootProps={{
        hideDefaultLauncher: true,
      }}
    >
      <AuthProvider>
        <StylesProvider injectFirst>
          <CacheCheck>
            <LDRouter />
          </CacheCheck>
        </StylesProvider>
      </AuthProvider>
    </IntercomProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
