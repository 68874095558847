import { createTheme, withStyles } from "@material-ui/core/styles";
import "./constants.scss";
import { Button, TextField, Tooltip } from "@material-ui/core";
import "./index.scss";
import colors from "./colors.module.scss";

export const theme = createTheme({
  typography: {
    fontFamily: ["WorkSansCustom", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: colors.mainColor,
    },
    secondary: {
      main: colors.titleColor,
    },
    button: {
      main: colors.buttonColor,
    },
    yellowButton: {
      main: "#d69722",
      background: "#faf4ec",
    },
  },
  zIndex: {
    modal: 8000,
    snackbar: 8100,
    tooltip: 8200,
  },
});

export const CustomTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: colors.titleColor,
    },
    "& label": {
      color: colors.titleColor,
      fontSize: colors.fs_14,
    },
    "& .MuiFormLabel": {
      color: colors.titleColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: colors.mainColor,
      },
      "&:hover fieldset": {
        borderColor: colors.titleColor,
      },
      "& input:invalid + fieldset": {
        borderColor: colors.redColor,
      },
    },
  },
})(TextField);

export const CustomButton = withStyles((theme) => {
  return {
    root: {
      color: "white",
      backgroundColor: theme.palette.yellowButton.main,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: theme.palette.yellowButton.main,
        boxShadow: "none",
      },
      fontWeight: "bold",
      borderRadius: "3px",
    },
  };
})(Button);

export const CustomButtonDisabled = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#c58c04c2",
    "&:hover": {
      backgroundColor: "#c58c04c2",
      boxShadow: "none",
    },
    fontWeight: "bold",
    borderRadius: "3px",
    boxShadow: "none",
    cursor: "initial",
  },
}))(Button);

export const ReversedCustomButton = withStyles((theme) => ({
  root: {
    color: theme.palette.button.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      border: `solid 1px ${theme.palette.button.main}`,
    },
    border: `solid 1px ${theme.palette.button.main}`,
    fontWeight: "bold",
    borderRadius: "3px",
  },
}))(Button);

export const ReversedCustomMainButton = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white",
      border: `solid 1px ${theme.palette.secondary.main}`,
    },
    border: `solid 1px ${theme.palette.secondary.main}`,
    fontWeight: "bold",
    borderRadius: "3px",
  },
}))(Button);

export const ReversedCustomYellowButton = withStyles((theme) => ({
  root: {
    color: theme.palette.yellowButton.main,
    backgroundColor: theme.palette.yellowButton.background,
    "&:hover": {
      backgroundColor: theme.palette.yellowButton.background,
      border: `solid 1px ${theme.palette.yellowButton.main}`,
    },
    border: `solid 1px ${theme.palette.yellowButton.main}`,
    fontWeight: "bold",
    borderRadius: "3px",
  },
}))(Button);

export const ReversedCustomYellowButtonTransparentBackground = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.yellowButton.main,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        border: `solid 1px ${theme.palette.yellowButton.main}`,
      },
      border: `solid 1px ${theme.palette.yellowButton.main}`,
      fontWeight: "bold",
      borderRadius: "3px",
      letterSpacing: "0.2px",
    },
  })
)(Button);

export const ReversedCustomYellowButtonBackgroundWhite = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.yellowButton.main,
      backgroundColor: "#ffffff",
      "&:hover": {
        backgroundColor: "#ffffff",
        border: `solid 1px ${theme.palette.yellowButton.main}`,
      },
      border: `solid 1px ${theme.palette.yellowButton.main}`,
      fontWeight: "bold",
      borderRadius: "3px",
    },
  })
)(Button);

export const CustomYellowButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: theme.palette.yellowButton.main,
    "&:hover": {
      backgroundColor: theme.palette.yellowButton.main,
      boxShadow: "none",
    },
    fontWeight: "600",
    borderRadius: "3px",
    boxShadow: "none",
    fontSize: "14px",
    letterSpacing: "0.2px",
  },
}))(Button);

export const ReversedCustomYellowButtonBackgroundTransparent = withStyles(
  (theme) => ({
    root: {
      color: theme.palette.yellowButton.main,
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        boxShadow: "none",
      },
      fontWeight: "600",
      borderRadius: "3px",
      boxShadow: "none",
      fontSize: "14px",
      letterSpacing: "0.2px",
      border: `1px solid ${theme.palette.yellowButton.main}`,
    },
  })
)(Button);

export const HtmlTooltip = withStyles((theme) => ({
  arrow: {
    color: "#1a3745",
  },
  tooltip: {
    backgroundColor: "#1a3745",
    color: "#ffffff",
    maxWidth: 205,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "0 2px 22px 0 rgba(239, 220, 194, 0.4)",
    padding: "15px",
    margin: "10px",
  },
}))(Tooltip);
