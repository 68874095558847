import { Link, useHistory } from "react-router-dom";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";
import { PathnameKey, PATHNAMES } from "../../constants/pathnames";
import { History } from "history";
import { AnchorHTMLAttributes } from "react";

const isMigrated = (location: string, migratedPages: PathnameKey[]) => {
  const pathPart = location.split("?")[0];
  return migratedPages
    .map((page) => PATHNAMES[page] as string)
    .some((path) => pathPart.includes(path));
};

export const LinkWithPathBasedReload = ({
  to,
  ...props
}: { to: string } & AnchorHTMLAttributes<HTMLAnchorElement>) => {
  const { migratedPages } = useFeatureFlag("migrated-pages");

  if (isMigrated(to, migratedPages)) {
    return <a {...props} href={to} />;
  } else {
    return <Link to={to} {...props} />;
  }
};

export const useHistoryWithPathBasedReload = () => {
  const { migratedPages } = useFeatureFlag("migrated-pages");
  const history = useHistory();

  const push = (location: string, state?: History.LocationState) => {
    if (isMigrated(location, migratedPages)) {
      window.location.href = location;
    } else {
      history.push(location);
    }
  };

  const replace = (location: string) => {
    if (isMigrated(location, migratedPages)) {
      window.location.href = location;
    } else {
      history.replace(location);
    }
  };

  return { ...history, push, replace };
};
