import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Input,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { captureException } from "@sentry/react";
import { Formik, FormikErrors } from "formik";
import { ChangeEvent, useState } from "react";
import * as Yup from "yup";
import { postReading } from "../../api/monitoring";
import colors from "../../colors.module.scss";
import { CustomButton, theme } from "../../materialDesignShared";
import checkAbnormal from "./checkAbnormal";
import "./monitoring.scss";
import { BpReading, BpReadingRaw } from "./types";
import { authenticationStore } from "../../stores/authentication-store";
import { getPatient } from "../../api/results";

const getPregnancyStage = async() => {
  const patientData = await getPatient(authenticationStore.userId);
  const is_pregnant = patientData?.data.patient.is_pregnant;
  const is_postpartum = patientData?.data.patient.is_postpartum;
  if (is_pregnant) {
    return "ANTEPARTUM";
  } else if (is_postpartum) {
    return "POSTPARTUM";
  } else {
    throw new Error("Patient pregnancy stage not found");
  }
}

const ModalNumericField = ({
  name,
  error,
  handleChange,
  value,
}: {
  name: string;
  error: undefined | FormikErrors<string>;
  handleChange: (e: ChangeEvent<any>) => void;
  value: number;
}) => (
  <Grid
    container
    justifyContent="space-between"
    alignItems="center"
    style={{ marginBottom: "1rem" }}
  >
    <Typography variant="h6" style={{ fontWeight: "bold" }}>
      {name}
    </Typography>
    <Input
      error={!!error}
      value={value !== 0 && value}
      placeholder="-"
      name={name}
      onChange={handleChange}
      inputProps={{
        style: { textAlign: "center" },
        type: "number",
      }}
      style={{
        fontSize: "2rem",
        flexShrink: 1,
        width: "8rem",
        background: `${colors.terracotta}1E`,
      }}
    />
    {error && (
      <Typography
        color="error"
        align="right"
        style={{ width: "100%", fontSize: "0.8rem" }}
      >
        {`${error}`}
      </Typography>
    )}
  </Grid>
);

const missingReadingError =
  "Please enter both systolic and diastolic readings.";
const tooHighError = "This value is too high.";
const tooLowError = "This value is too low.";
const readingIsInPastMessage =
  "It looks like the reading you've entered is in the past. If you haven't already, please take another reading today and enter it here so we can provide accurate clinical guidance and call us at (718) 586-4944 if you are having any symptoms of preeclampsia, such as headaches, nausea, or swelling.";

const BPModal = ({
  handleCancel,
  pastReadings,
  cutoffEnd,
  cutoffStart,
}: {
  handleCancel: () => void;
  pastReadings: BpReading[];
  cutoffEnd: Date | null;
  cutoffStart: Date;
}) => {
  const isSmallViewport = useMediaQuery(theme.breakpoints.down(768));
  // 0: init, 1: loading, 2: success, 3: error
  const [submitState, setSubmitState] = useState<0 | 1 | 2 | 3>(0);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid
        container
        justifyContent={isSmallViewport ? "flex-start" : "center"}
        alignItems={isSmallViewport ? "flex-start" : "center"}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          background: "rgba(0,0,0,0.3)",
          zIndex: 5000,
          top: 0,
          left: 0,
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent={isSmallViewport ? "center" : "flex-start"}
          style={{
            background: "#FFF",
            maxWidth: isSmallViewport ? "100vw" : "44rem",
            height: isSmallViewport ? "100vh" : "auto",
            gap: "1rem",
          }}
        >
          <Grid
            container
            alignItems="center"
            style={{
              paddingBottom: ".6rem",
              paddingTop: "0.6rem",
              maxWidth: "95vw",
            }}
          >
            <Grid xs={1} />
            <Grid xs={10} style={{ textAlign: "center" }}>
              LOG RESULTS
            </Grid>
            <Grid xs={1}>
              <Button onClick={handleCancel}>
                <Close fontSize="small" />
              </Button>
            </Grid>
          </Grid>
          <Box
            padding={isSmallViewport ? "0rem 1rem" : "1rem 10rem"}
            paddingBottom={!isSmallViewport && "2rem"}
          >
            <Typography
              align="center"
              variant="h6"
              style={{ fontWeight: "bold", marginBottom: "1rem" }}
            >
              BLOOD PRESSURE READING
            </Typography>
            <Formik
              initialValues={{
                SYS: 0,
                DIA: 0,
                timestamp: new Date(),
              }}
              validationSchema={Yup.object().shape({
                SYS: Yup.number()
                  .min(1, tooLowError)
                  .max(400, tooHighError)
                  .required(missingReadingError),
                DIA: Yup.number()
                  .min(1, tooLowError)
                  .max(400, tooHighError)
                  .required(missingReadingError),
                timestamp: Yup.date()
                  .required()
                  .test("is-not-in-future", "Date is in the future!", (v) => {
                    const isInFuture = v.getTime() > Date.now();
                    return !isInFuture;
                  })
                  .test(
                    "is-not-after-window-ends",
                    cutoffEnd
                      ? `Date is after monitoring window ends on ${cutoffEnd.toLocaleDateString()}`
                      : "There is no end date",
                    (v) => {
                      if (!cutoffEnd) {
                        return true;
                      }
                      const isAfterWindowEnds = v > cutoffEnd;
                      return !isAfterWindowEnds;
                    }
                  )
                  .test(
                    "is-not-before-window-starts",
                    `Date is before monitoring window begins on ${new Date(
                      // shift 8 hours forward from GMT to EST
                      cutoffStart.getTime() + 2.88e7
                    ).toLocaleDateString()}`,
                    (v) => {
                      console.log(cutoffStart.getTime());
                      const isAfterWindowStarts =
                        v.getTime() < cutoffStart.getTime() + 2.88e7;
                      return !isAfterWindowStarts;
                    }
                  ),
              })}
              onSubmit={async ({ SYS, DIA, timestamp }) => {
                setSubmitState(1);
                try {
                  const abnormal = checkAbnormal(
                    {
                      timeOfReading: timestamp,
                      timeOfSubmission: new Date(),
                      systolic: SYS,
                      diastolic: DIA,
                      patientMessaging: "",
                      stageCode: "",
                    },
                    pastReadings
                  );

                  // if reading is older than 24 hours
                  const readingIsInPast =
                    Date.now() - timestamp.getTime() > 8.64e7;

                  const payload: BpReadingRaw = {
                    systolic: SYS,
                    diastolic: DIA,
                    timeOfReading: timestamp.getTime() / 1000,
                    timeOfSubmission: Date.now() / 1000,
                    patientMessaging: readingIsInPast
                      ? readingIsInPastMessage
                      : abnormal.patientMessaging,
                    stageCode: await getPregnancyStage(),
                  };
                  await postReading(payload);
                  setSubmitState(2);
                  handleCancel();
                } catch (e) {
                  captureException(e);
                  setSubmitState(3);
                }
              }}
            >
              {({ values, handleChange, errors, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Box>
                    <Typography
                      variant="h6"
                      style={{
                        color: `${colors.titleColor}`,
                        marginBottom: "0.8rem",
                      }}
                    >
                      Readings
                    </Typography>
                    <ModalNumericField
                      value={values.SYS}
                      error={errors.SYS}
                      name={"SYS"}
                      handleChange={handleChange}
                    />
                    <ModalNumericField
                      value={values.DIA}
                      error={errors.DIA}
                      name={"DIA"}
                      handleChange={handleChange}
                    />
                  </Box>
                  <Grid
                    container
                    justifyContent="space-between"
                    direction="row"
                    style={{
                      marginTop: "2.8rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      align="left"
                      style={{
                        color: `${colors.titleColor}`,
                        display: "inline",
                      }}
                    >
                      Time
                    </Typography>
                    <DateTimePicker
                      className="chromatic-ignore"
                      error={!!errors.timestamp}
                      value={values.timestamp}
                      name="timestamp"
                      style={{
                        width: "14rem",
                      }}
                      format="MMMM d, yyyy hh:mm a"
                      onChange={(e) => {
                        handleChange({
                          target: { name: "timestamp", value: e },
                        });
                      }}
                    ></DateTimePicker>
                    {errors.timestamp && (
                      <Typography
                        color="error"
                        align="right"
                        style={{ width: "100%", fontSize: "0.8rem" }}
                      >
                        {`${errors.timestamp}`}
                      </Typography>
                    )}
                  </Grid>
                  {submitState === 3 && (
                    <Alert severity="error" style={{ marginTop: "0.5rem" }}>
                      Oops! Looks like something went wrong and we couldn't save
                      your reading. Please try again and give us a call if you
                      have any concerns.
                    </Alert>
                  )}
                  <Box marginTop={"3rem"} width={"100%"}>
                    <CustomButton
                      fullWidth={true}
                      type="submit"
                      disabled={submitState === 1 || submitState === 2}
                    >
                      {(submitState === 0 || submitState === 3) && "Submit"}
                      {submitState === 1 && (
                        <CircularProgress size={"1.5rem"} />
                      )}
                      {submitState === 2 && "Success!"}
                    </CustomButton>
                  </Box>
                </form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
export default BPModal;
