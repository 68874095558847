import { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CheckIcon from "@material-ui/icons/Check";
import Expand from "react-expand-animated";
import { observer } from "mobx-react-lite";

import "./JourneyCard.scss";
import { AppointmentCard } from "../AppointmentCard/AppointmentCard";
import { DecisionCard } from "../DecisionCard/DecisionCard";
import { EducationCard } from "../EducationCard/EducationCard";
import { journeyStore } from "../../../stores/journey-store";

export const JourneyCard = observer(
  ({
    trimester,
    onEducationUpdate,
    onDecisionUpdate,
    weeksDescription,
    progress,
    title,
    description,
    orderedCards,
    lastTrimester,
    onNotes,
    searchText,
  }) => {
    const isExpandedCards = journeyStore.expandedTrimester[trimester];

    const setIsExpandedCards = (value) => {
      journeyStore.expandedTrimester[trimester] = value;
    };

    useEffect(() => {
      if (searchText) {
        journeyStore.expandedTrimester.fill(true);
      } else {
        journeyStore.expandedTrimester.fill(false);
      }
    }, [searchText]);

    return (
      <>
        {!searchText ? (
          <div
            className="journey-card"
            style={{
              height: isExpandedCards ? "60px" : "",
              paddingBottom: isExpandedCards ? "0" : "",
            }}
            onClick={() => setIsExpandedCards(!isExpandedCards)}
          >
            <div className="journey-circle">
              {progress < 100 ? (
                <div>
                  <CircularProgress
                    className="circle"
                    variant="static"
                    size={30}
                    value={100}
                    style={{ color: "white" }}
                  />
                  <CircularProgress
                    className="circle"
                    variant="static"
                    size={30}
                    value={progress}
                  />
                </div>
              ) : (
                <CheckIcon className="check" />
              )}
            </div>
            <div className="journey-card-header">
              <div className="white-label">{weeksDescription}</div>
            </div>
            <div className="journey-title">{title}</div>
            <div className="journey-description">
              <div
                className="journey-paragraph"
                style={{ display: isExpandedCards ? "none" : "block" }}
              >
                {description}
              </div>
              <div className="journey-expand">
                {isExpandedCards ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
            {(!lastTrimester || isExpandedCards) && (
              <div className="journey-line" />
            )}
          </div>
        ) : null}
        <Expand
          className={!isExpandedCards ? "closed-expand" : "opened-expand"}
          open={isExpandedCards}
        >
          {orderedCards?.map((orderedCard, index) => {
            const lastCard = index + 1 === orderedCards.length && lastTrimester;
            if (orderedCard.card_type === "AppointmentCard") {
              return (
                <>
                  <AppointmentCard
                    last_card={lastCard}
                    onNotes={onNotes}
                    appointmentCardToShow={journeyStore.appointmentCardToShow}
                    searchText={searchText}
                    {...orderedCard}
                  />
                </>
              );
            }
            if (orderedCard.card_type === "DecisionCard") {
              return (
                <>
                  <DecisionCard
                    last_card={lastCard}
                    updateBookmarks
                    onDecisionUpdate={onDecisionUpdate}
                    decisionCardToShow={journeyStore.decisionCardToShow}
                    searchText={searchText}
                    {...orderedCard}
                  />
                </>
              );
            }
            if (orderedCard.card_type === "EducationCard") {
              return (
                <>
                  <EducationCard
                    last_card={lastCard}
                    updateBookmarks
                    onEducationUpdate={onEducationUpdate}
                    educationCardToShow={journeyStore.educationCardToShow}
                    searchText={searchText}
                    {...orderedCard}
                  />
                </>
              );
            }
          })}
        </Expand>
      </>
    );
  }
);
