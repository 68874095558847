import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { DROPDOWN_NAMES } from "../../IntakeFormFieldLayout/IntakeFormFieldLayout";
import {
  getMedicationsList,
  getAllergiesList,
} from "../../../../../../api/intakeForm";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Chip } from "@material-ui/core";
import _map from "lodash/map";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import "./SingleSelect.scss";

const SingleSelect = ({
  handleOnChange,
  setFieldValue,
  databaseKeyName,
  label,
  formatedLabel,
  fieldWithError,
  errors,
  options,
  noteField,
  note,
  values,
  layoutId,
  intakeFormDatabaseInfo,
}) => {
  const [noteAnswer, setNoteAnswer] = useState(note);
  const [medicationText, setMedicationText] = useState("");
  const [allergyText, setAllergyText] = useState("");
  const [selectedMedications, setSelectedMedications] = useState([]);
  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [medicationsGroupOptions, setMedicationsGroupOptions] = useState([]);
  const [allergyGroupOptions, setAllergyGroupOptions] = useState([]);

  const medicalHistoryKey = "medical_history_questions";

  const debounceSearchMedications = useRef(
    _debounce(async ({ searchText }) => {
      const { data } = await getMedicationsList({ searchText });

      const medications = _get(data, "items", []);
      const formattedMedications = _map(medications, (medicationInfo) => {
        const { medication, medicationid } = medicationInfo;

        return {
          label: medication,
          id: medicationid,
        };
      });

      setMedicationsGroupOptions(formattedMedications);
    }, 500)
  );

  const debounceSearchAllergies = useRef(
    _debounce(async ({ searchText }) => {
      const { data } = await getAllergiesList({ searchText });

      const allergies = _get(data, "items", []);
      const formattedAlergies = _map(allergies, (allergieInfo) => {
        const { allergy, allergyid } = allergieInfo;

        return {
          label: allergy,
          id: allergyid,
        };
      });

      setAllergyGroupOptions(formattedAlergies);
    }, 500)
  );

  useEffect(() => {
    if (medicationText.length >= 2) {
      debounceSearchMedications.current({
        searchText: medicationText,
      });
    }
  }, [medicationText]);

  useEffect(() => {
    if (allergyText.length >= 2) {
      debounceSearchAllergies.current({
        searchText: allergyText,
      });
    }
  }, [allergyText]);

  useEffect(() => {
    const emrDetails = _get(intakeFormDatabaseInfo, "emr_details");

    if (Object.keys(emrDetails).length) {
      const formatEmrInfo = (type) =>
        emrDetails[type]?.map((each) => ({
          label: each.name,
          id: parseInt(each.emr_id),
        }));

      const preSelectedMedications = formatEmrInfo(DROPDOWN_NAMES.medication);
      const preSelectedAllergies = formatEmrInfo(DROPDOWN_NAMES.allergy);

      if (preSelectedMedications?.length) {
        setSelectedMedications(preSelectedMedications);
      }

      if (preSelectedAllergies?.length) {
        setSelectedAllergies(preSelectedAllergies);
      }
    }
  }, [intakeFormDatabaseInfo]);

  const selectDropdownProps = (type) => {
    const METHODS = {
      [DROPDOWN_NAMES.medication]: {
        onInputChange: setMedicationText,
        inputValue: medicationText,
        id: DROPDOWN_NAMES.medication,
        options: medicationsGroupOptions,
        onChange: setSelectedMedications,
        value: selectedMedications,
      },
      [DROPDOWN_NAMES.allergy]: {
        onInputChange: setAllergyText,
        inputValue: allergyText,
        id: DROPDOWN_NAMES.allergy,
        options: allergyGroupOptions,
        onChange: setSelectedAllergies,
        value: selectedAllergies,
      },
    };

    return METHODS[type];
  };

  const labelClassName = classNames("singleSelect__label", {
    "singleSelect__label--noBold":
      databaseKeyName === "high_risk_questions" ||
      databaseKeyName === medicalHistoryKey,
  });

  const medicationsQuestion =
    label.toLowerCase().includes("medications") && DROPDOWN_NAMES.medication;
  const allergiesQuestion =
    label.toLowerCase().includes("allergies") && DROPDOWN_NAMES.allergy;
  const typeOfDropdownToShow = medicationsQuestion || allergiesQuestion;
  const dropdownInfoToShow = selectDropdownProps(typeOfDropdownToShow);
  const affirmativeAnswer = parseInt(values[layoutId]) === 1;
  const containerClassName = classNames("singleSelect__container", {
    "singleSelect__container--vitals": typeOfDropdownToShow,
  });

  console.log({
    handleOnChange,
    setFieldValue,
    databaseKeyName,
    label,
    formatedLabel,
    fieldWithError,
    errors,
    options,
    noteField,
    note,
    values,
    layoutId,
    intakeFormDatabaseInfo,
  });
  return (
    <div className={containerClassName}>
      <div className="singleSelect__mainInfo">
        <span className={labelClassName}>{formatedLabel}</span>

        {fieldWithError && (
          <span className="intakeForm__errorText">{errors}</span>
        )}
      </div>

      {options.length &&
        _map(options, ({ name, id }) => {
          const valueOption = parseInt(values[layoutId]);
          const isSelected = valueOption === id;

          const inputId = layoutId + id;
          const classNamesContainer = classNames("singleSelect__option", {
            "singleSelect__option--selected": isSelected,
          });
          const classNamesCheckmark = classNames(
            "singleSelect__customCheckmark",
            {
              "singleSelect__customCheckmark--selected": isSelected,
            }
          );

          return (
            <label className={classNamesContainer} key={id}>
              {name}
              <input
                type="radio"
                name={layoutId}
                value={id}
                id={inputId}
                onChange={(e) => handleOnChange(e, name)}
              />
              <span className={classNamesCheckmark}></span>
            </label>
          );
        })}

      {noteField && (
        <>
          {dropdownInfoToShow && affirmativeAnswer && (
            <Autocomplete
              limitTags={1}
              multiple
              inputValue={dropdownInfoToShow.inputValue}
              onInputChange={(e, newInputValue) => {
                dropdownInfoToShow.onInputChange(newInputValue);
              }}
              filterOptions={(x) => x}
              id={`${dropdownInfoToShow.id}-group-dropdown`}
              options={dropdownInfoToShow.options}
              getOptionLabel={(option) => option.label}
              getOptionSelected={(option) =>
                dropdownInfoToShow.value.find((each) => each.id === option.id)
              }
              className="singleSelect__autocomplete--medical"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={"SELECT ALL THAT APPLY"}
                  className="singleSelect__autocompleteLabel--medical"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option.label}
                    size="small"
                    {...getTagProps({ index })}
                    className="singleSelect__autocompletePill"
                  />
                ))
              }
              onChange={(e, value) => {
                dropdownInfoToShow.onChange(value);

                setFieldValue(medicalHistoryKey, {
                  ...values[medicalHistoryKey],
                  [layoutId]: {
                    options: value.map((each) => each.id),
                    type: dropdownInfoToShow.id,
                  },
                });
              }}
              value={dropdownInfoToShow.value}
            />
          )}

          {/* don't show textarea for blood transfusion question */}
          {!dropdownInfoToShow &&
            !/(religious)*(blood transfusion)/g.test(label) && (
              <textarea
                onBlur={() => {
                  setFieldValue(medicalHistoryKey, {
                    ...values[medicalHistoryKey],
                    [layoutId]: noteAnswer,
                  });
                }}
                onChange={(e) => setNoteAnswer(e.target.value)}
                className="singleSelect__note"
                placeholder="Please elaborate ..."
                rows={2}
              >
                {noteAnswer}
              </textarea>
            )}
        </>
      )}
    </div>
  );
};

export default SingleSelect;
