import { Box } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import PuffLoader from "react-spinners/PuffLoader";

import OulaLogo from "../../assets/logo/Logo/Oula_logo_white.png";
import * as commonMaterial from "../../materialDesignShared";
import "./LoadingLayout.scss";

const Loading = () => (
  <div className="loading-container">
    <ThemeProvider theme={commonMaterial.theme}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100vw"
        height="100vh"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          height={200}
        >
          <Box marginBottom={2}>
            <img src={OulaLogo} className="logoIcon" alt="Oula" />
          </Box>
          <PuffLoader color="white" loading={true} size="70px" />
        </Box>
      </Box>
    </ThemeProvider>
  </div>
);

export default Loading;
