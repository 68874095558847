import { Box, Button, Grid, Radio, Typography } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import {
  SurveyQuestionComponent,
  SurveyQuestionProps,
} from "../SurveyQuesiton";

const SelectOne = <T extends any>(props: SurveyQuestionComponent<T>) => {
  if (props.type !== "select_one")
    throw new Error("wrong type for select_one component!");

  return (
    <Box>
      <Typography
        style={{
          color: "#ba624a",
          fontWeight: "bold",
          marginBottom: "1rem",
        }}
      >
        Select one
      </Typography>
      <Grid container direction="column">
        {props.options.map(({ display_name, answer_value }, i) => (
          <Field
            className="survey-radio"
            style={{
              accentColor: "#000",
              height: "1.1rem",
              width: "1.1rem",
              margin: 0,
            }}
            type="radio"
            name={props.name}
            value={answer_value}
            component={(cP: FieldProps) => (
              <Button
                data-testid={`modal-selectone-option-${i}`}
                onClick={() =>
                  cP.form.setFieldValue(
                    props.name,
                    props.value === answer_value ? null : answer_value
                  )
                }
              >
                <Box
                  style={{
                    flexGrow: 1,
                    margin: ".2rem",
                    padding: ".15rem",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",
                    background:
                      answer_value === props.value ? "#da9c86" : "#faf4ec",
                  }}
                >
                  <Radio
                    checked={answer_value === props.value}
                    className={
                      answer_value === props.value
                        ? "modal-checkbox-checked"
                        : "modal-checkbox"
                    }
                  />
                  <Typography
                    style={{
                      color: answer_value === props.value ? "#000" : "#ba624a",
                      fontWeight: answer_value === props.value ? "bold" : "500",
                    }}
                  >
                    {display_name}
                  </Typography>
                </Box>
              </Button>
            )}
          ></Field>
        ))}
      </Grid>
    </Box>
  );
};

export default SelectOne;
