import { useRef, useState, useEffect } from "react";
import _map from "lodash/map";
import _get from "lodash/get";
import _set from "lodash/set";
import _isEqual from "lodash/isEqual";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";
import _find from "lodash/find";
import _isFunction from "lodash/isFunction";
import _debounce from "lodash/debounce";
import ReactHtmlParser from "react-html-parser";
import Slider from "react-slick";
import classNames from "classnames";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { CustomTextField } from "../../../../../materialDesignShared";
import Tooltip from "../../../../Tooltip";
import { maxMobileScreenWidth } from "../../../../../services/constants";
import { otherInsuranceMsg } from "../../../../../constants/toastMessages";
import Vitals from "../Layouts/Vitals/Vitals";
import SingleSelect from "../Layouts/SingleSelect";
import MultipleSelectDropdown from "../Layouts/MultipleSelectDropdown";
import SingleSelectDropdown from "../Layouts/SingleSelectDropdown";
import CounterSelect from "../Layouts/CounterSelect";
import Scale from "../Layouts/Scale/Scale";
import DateSelect from "../Layouts/DateSelect";
import { CheckboxWithLabel } from "formik-material-ui";
import { Field } from "formik";
import {
  FormControl,
  FormGroup,
  FormLabel,
  FormHelperText,
  Box,
} from "@material-ui/core";

import "./IntakeFormFieldLayout.scss";
import IntegerInput from "../Layouts/IntegerInput/IntegerInput";
import Pause from "../Layouts/Pause";
import InsurancePackage from "../Layouts/InsurancePackage";
import PreferredPharmacy from "../Layouts/PreferredPharmacy";
import { useFeatureFlag } from "../../../../../providers/LaunchDarklyProvider";

export const DROPDOWN_NAMES = {
  medication: "medication",
  allergy: "allergy",
};

const IntakeFormFieldLayout = ({
  touched,
  errors,
  values,
  generalType,
  groups,
  handleChange,
  setFieldValue,
  setFieldTouched,
  noteField,
  databaseKeyName,
  handleBlur,
  intakeFormDatabaseInfo,
  setToastMessage,
  setHasOtherInsurance,
  setSelfPay,
  hasOtherInsurance,
}) => {
  const [selectedInsuranceProvider, setSelectedInsuranceProvider] =
    useState("");
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);
  const customSliderOptions = useRef();
  const state = values["state"];
  const city = values["city"];
  const insuranceMemberId = values["insurance_member_id"];
  const insurancePackageId = values["emr_insurancepackageid"];
  const insuranceProviderVal = values["insurance_carrier"];
  const generalTypeForm = generalType === "form";
  const { freeTextOtherInsuranceOption } = useFeatureFlag(
    "free-text-other-insurance-option"
  );

  const getFieldWithError = (key, hasOtherInsurance) => {
    const isMemberIdKey = key === "insurance_member_id";

    if (touched[key] && isMemberIdKey && hasOtherInsurance) return false;

    return touched[key] && errors[key];
  };

  const CustomPrevArrow = () => {
    const arrowClassName = classNames(
      "intakeFormFieldLayout__sliderArrow intakeFormFieldLayout__sliderArrow--left",
      {
        "intakeFormFieldLayout__sliderArrow--noteField": noteField,
      }
    );

    return (
      <div
        className={arrowClassName}
        onClick={() => customSliderOptions.current.slickPrev()}
      >
        <NavigateBeforeIcon />
      </div>
    );
  };

  const CustomNextArrow = () => {
    const arrowClassName = classNames(
      "intakeFormFieldLayout__sliderArrow intakeFormFieldLayout__sliderArrow--right",
      {
        "intakeFormFieldLayout__sliderArrow--noteField": noteField,
      }
    );

    return (
      <div
        className={arrowClassName}
        onClick={() => customSliderOptions.current.slickNext()}
      >
        <NavigateNextIcon />
      </div>
    );
  };

  const onBeforeChange = (oldIndex, newIndex) => {
    setCurrentCarouselIndex(newIndex);
  };

  const SLIDER_SETTINGS = {
    speed: 500,
    slidesToShow: 2,
    infinite: false,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    swipeToSlide: true,
    className: "intakeFormFieldLayout__slider",
    dots: true,
    draggable: false,
    swipe: false,
    adaptiveHeight: true,
    beforeChange: onBeforeChange,
  };

  const getSliderSettings = (isMobile, currentCarouselIndex) => {
    let customSettings = {
      ...SLIDER_SETTINGS,
    };

    if (isMobile) {
      customSettings = {
        ...customSettings,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        swipeToSlide: false,
      };
    }

    if (currentCarouselIndex === 0) {
      customSettings = {
        ...customSettings,
        prevArrow: null,
      };
    } else if (
      currentCarouselIndex + customSettings.slidesToShow >
      groups.length - 1
    ) {
      customSettings = {
        ...customSettings,
        nextArrow: null,
      };
    }

    if (noteField) {
      customSettings = {
        ...customSettings,
        className: `${SLIDER_SETTINGS.className} ${SLIDER_SETTINGS.className}--note`,
      };
    }

    return customSettings;
  };

  /**
   * This is a function to add the asterisk , when the field is
   * required. And it adds a color red when exist an error in a
   * required field.
   *
   * @param {boolean} required - param to check if the field is required
   * @param {string} label - label of input or question
   * @param {string} fieldWithError - param that indicates an error
   */
  const getLabel = (required, label, fieldWithError) => {
    let labelGenerated = label;

    if (required) {
      labelGenerated = `${label} <span style="color: ${
        fieldWithError ? "#f12b2b" : "#ba624a"
      }">*</span>`;
    }

    return ReactHtmlParser(labelGenerated);
  };

  const getLayoutFields = (fields) =>
    fields.map(
      (
        {
          label,
          key,
          type,
          inline_fields,
          required,
          questionType,
          options,
          formatFunction,
          inputProps = {},
          customClassName,
        },
        idx
      ) => {
        const fieldWithError = getFieldWithError(key);
        const formattedValue = _isFunction(formatFunction)
          ? formatFunction(values[key])
          : values[key];

        if (inline_fields) {
          return (
            <div key={`${key}-${idx}`} className="intakeForm__rowContainer">
              {getLayoutFields(inline_fields)}
            </div>
          );
        } else {
          if (key === "state") {
            return getLayoutByType({
              field: {
                label,
                key,
                type,
                required,
              },
              questionType,
              options,
              customClassName,
            });
          }

          const formatedLabel = getLabel(required, label, fieldWithError);

          if (!formatedLabel || !formatedLabel.length) return;

          if (key === "preferred_pharm_name") {
            return (
              <PreferredPharmacy
                formatedLabel={formatedLabel}
                value={values[key]}
                setFieldValue={setFieldValue}
                state={state}
                city={city}
                generalTypeForm={generalTypeForm}
                layoutId={key}
              />
            );
          } else {
            return (
              <CustomTextField
                className="intakeForm__field"
                error={Boolean(fieldWithError)}
                margin="normal"
                fullWidth
                value={formattedValue}
                id={key}
                type={type}
                label={formatedLabel}
                key={key}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={fieldWithError}
                inputProps={inputProps}
              />
            );
          }
        }
      }
    );

  const getLayoutByType = ({
    options,
    field,
    questionType,
    infoPause,
    customClassName,
    noteField,
    note = "",
  }) => {
    let layout;

    let { label, key, type, required } = field;

    const fieldWithError = getFieldWithError(key, false);

    const formatedLabel = getLabel(required, label, fieldWithError);

    if (
      key === "insurance_other_string" &&
      !hasOtherInsurance &&
      freeTextOtherInsuranceOption
    ) {
      return null;
    }

    if (
      questionType === "single_select" ||
      questionType === "yes_or_no_question"
    ) {
      const handleOnChange = (e, value) => {
        handleChange(e);
        if (key === "insurance_carrier") {
          setSelectedInsuranceProvider(value);
          setFieldValue("emr_insurancepackageid", "");
          setFieldValue("insurance_member_id", "");

          if (value.match(/(I plan to pay out of pocket|Self.pay)/gim)) {
            setSelfPay(true);
            setHasOtherInsurance(false);
          } else if (value === "Other") {
            setToastMessage(otherInsuranceMsg);
            setHasOtherInsurance(true);
            setSelfPay(false);
          } else {
            setHasOtherInsurance(false);
            setSelfPay(false);
          }
        }
      };

      layout = (
        <SingleSelect
          handleOnChange={handleOnChange}
          setFieldValue={setFieldValue}
          databaseKeyName={databaseKeyName}
          label={label}
          formatedLabel={formatedLabel}
          fieldWithError={fieldWithError}
          errors={errors[key]}
          options={options}
          noteField={noteField}
          note={note}
          values={values}
          layoutId={key}
          intakeFormDatabaseInfo={intakeFormDatabaseInfo}
        />
      );
    } else if (questionType === "field") {
      // duplicate the logic
      const isSelfPay = parseInt(values?.insurance_carrier, 10) === 8;
      const isOther = parseInt(values?.insurance_carrier, 10) === 7;
      setHasOtherInsurance(isOther);
      setSelfPay(isSelfPay);

      layout = (
        <CustomTextField
          className="intakeForm__field"
          error={fieldWithError}
          margin="normal"
          fullWidth
          value={values[key]}
          onChange={handleChange}
          id={key}
          type={type}
          label={formatedLabel}
          key={key}
          onBlur={handleBlur}
          helperText={fieldWithError}
        />
      );
    } else if (
      questionType === "multiple_select_dropdown" ||
      questionType === "multiple_select_pills"
    ) {
      layout = (
        <MultipleSelectDropdown
          formatedLabel={formatedLabel}
          fieldWithError={fieldWithError}
          errors={errors[key]}
          layoutId={key}
          values={values[key]}
          type={type}
          handleChange={handleChange(key)}
          handleBlur={handleBlur(key)}
          options={options}
          questionType={questionType}
        />
      );
    } else if (questionType === "single_select_dropdown") {
      layout = (
        <SingleSelectDropdown
          fieldWithError={fieldWithError}
          formatedLabel={formatedLabel}
          errors={errors[key]}
          layoutId={key}
          values={values[key]}
          type={type}
          handleChange={handleChange(key)}
          handleBlur={handleBlur(key)}
          options={options}
          customClassName={customClassName}
        />
      );
    } else if (questionType === "multiple_select") {
      layout = (
        <>
          <FormControl className="intakeFormFieldLayout__multipleSelectContainer">
            <span className="intakeFormFieldLayout__multipleSelectDescription">
              {formatedLabel}
            </span>

            {fieldWithError && <FormHelperText>{errors[key]}</FormHelperText>}

            <FormLabel className="intakeFormFieldLayout__multipleSelectLabel">
              SELECT ALL THAT APPLY
            </FormLabel>
            <FormGroup>
              {options.map(({ name, id, race_id }) => (
                <Field
                  type="checkbox"
                  component={CheckboxWithLabel}
                  name={key}
                  key={`${key}-${name}-${id}`}
                  value={race_id || name}
                  Label={{
                    label: name,
                    className: "intakeFormFieldLayout__multipleSelectOption",
                  }}
                  className="intakeFormFieldLayout__multipleSelectOptionCheckBox"
                />
              ))}
            </FormGroup>
          </FormControl>
        </>
      );
    } else if (questionType === "counter_select") {
      layout = (
        <CounterSelect
          values={values[key]}
          formatedLabel={formatedLabel}
          layoutId={key}
          setFieldValue={setFieldValue}
        />
      );
    } else if (questionType === "scale") {
      layout = (
        <Scale
          values={values[key]}
          formatedLabel={formatedLabel}
          layoutId={key}
          setFieldValue={setFieldValue}
          options={options}
        />
      );
    } else if (questionType === "date") {
      layout = (
        <DateSelect
          values={values[key]}
          formatedLabel={formatedLabel}
          layoutId={key}
          setFieldValue={setFieldValue}
          fieldWithError={fieldWithError}
          errors={errors[key]}
          setFieldTouched={setFieldTouched}
        />
      );
    } else if (questionType === "integer") {
      layout = (
        <IntegerInput
          value={values[key]}
          formatedLabel={formatedLabel}
          layoutId={key}
          fieldWithError={fieldWithError}
          error={errors[key]}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
      );
    } else if (questionType === "vitals") {
      layout = (
        <Vitals
          label={label}
          errors={fieldWithError}
          values={values[key]}
          vitalsKey={key}
          setFieldValue={setFieldValue}
        />
      );
    } else if (infoPause) {
      layout = <Pause info={infoPause} />;
    } else if (key === "emr_insurancepackageid") {
      if (!hasOtherInsurance || !freeTextOtherInsuranceOption) {
        layout = (
          <InsurancePackage
            formatedLabel={formatedLabel}
            layoutId={key}
            value={values[key]}
            setFieldValue={setFieldValue}
            hasOtherInsurance={hasOtherInsurance}
            insuranceMemberId={insuranceMemberId}
            insurancePackageId={insurancePackageId}
            insuranceProviderVal={insuranceProviderVal}
            setHasOtherInsurance={setHasOtherInsurance}
            intakeFormDatabaseInfo={intakeFormDatabaseInfo}
            selectedInsuranceProvider={selectedInsuranceProvider}
            setSelectedInsuranceProvider={setSelectedInsuranceProvider}
          />
        );
      }
    }

    return layout;
  };

  if (generalTypeForm) {
    return _map(groups, (group, idx) => {
      const fields = _get(group, "fields", []);
      const tag = _get(group, "tag", "");
      const layoutFields = getLayoutFields(fields);

      return (
        <div className="intakeFormFieldLayout__mainFormContainer" key={idx}>
          {tag && <h2 className="intakeForm__label">{tag}</h2>}
          {layoutFields}
        </div>
      );
    });
  } else {
    const layouts = _map(groups, (group) => {
      const field = _get(group, "field", []);
      const options = _get(group, "options", []);
      const questionType = _get(group, "questionType", "");
      const customClassName = _get(group, "customClassName", "");
      const infoPause = _get(group, "info_pause", "");
      const helpInfo = _get(group, "help");
      const note = _get(group, "note", "");
      const layout = getLayoutByType({
        field,
        questionType,
        options,
        infoPause,
        customClassName,
        noteField,
        note,
      });

      return (
        <Box>
          {layout}
          {helpInfo && (
            <Tooltip iconURI="/icons/question-circle.svg" {...helpInfo} />
          )}
        </Box>
      );
    });

    if (generalType === "slider") {
      const isMobile = window.innerWidth <= maxMobileScreenWidth;

      return (
        <Slider
          {...getSliderSettings(isMobile, currentCarouselIndex)}
          ref={customSliderOptions}
        >
          {layouts}
        </Slider>
      );
    } else {
      return layouts;
    }
  }
};

export default IntakeFormFieldLayout;
