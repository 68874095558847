import { useEffect, useRef, useState } from "react";
import useAsyncEffect from "use-async-effect";
import * as Yup from "yup";
import { Formik } from "formik";
import AutoComplete from "@material-ui/lab/Autocomplete";
import _get from "lodash/get";
import _map from "lodash/map";
import _debounce from "lodash/debounce";
import { CircularProgress, TextField } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { getProfile } from "../../../api/profile";
import {
  getPharmaciesInfo,
  setIntakeFormAnswers,
} from "../../../api/intakeForm.js";
import { intakeFormStore } from "../../../stores/intake-form-store";
import { authenticationStore } from "../../../stores/authentication-store";
import * as commonMaterial from "../../../materialDesignShared";
import { CustomButton } from "../../../materialDesignShared";
import { Check } from "@material-ui/icons";

const ProfilePharmacy = ({ setUnsaved }) => {
  const [savedFields, setSavedFields] = useState([]);
  const [pharmacyText, setPharmacyText] = useState("");
  const [pharmacyOptions, setPharmacyOptions] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [disableFields, setDisableFields] = useState(false);
  // 0: no activity
  // 1: loading
  // 2: success
  // 3: error
  const [saveState, setSaveState] = useState(0);

  useEffect(() => {
    if (savedFields.length > 0) setUnsaved(true);
    else setUnsaved(false);
  }, [savedFields]);

  const city = _get(profileData, "city", "");
  const state = _get(profileData, "state", "");
  const validationSchema = Yup.object().shape({});

  const debounceSearchPharmacy = useRef(
    _debounce(async ({ state, city, search }) => {
      const { data } = await getPharmaciesInfo({ state, city, name: search });

      const clinicalProviders = _get(data, "clinicalproviders", []);
      const formattedProviders = _map(clinicalProviders, (provider) => {
        const { emr_clinical_provider_id, name, phone, address } = provider;

        return {
          label: `${name}, ${address}`,
          id: emr_clinical_provider_id,
          phone,
          address,
        };
      });

      setPharmacyOptions(formattedProviders);
    }, 500)
  );

  useAsyncEffect(async () => {
    const {
      data: { patient: profileData },
    } = await getProfile(authenticationStore.userId);
    const city = _get(profileData, "city", "");
    const state = _get(profileData, "state", "");
    const disableFields = !city || !state;

    setProfileData(profileData);
    setDisableFields(disableFields);
  }, []);

  useEffect(() => {
    if (pharmacyText.length > 2 && state && city) {
      debounceSearchPharmacy.current({ search: pharmacyText, state, city });
    }
  }, [pharmacyText, state, city]);

  const intakeFormAnswers = _get(
    intakeFormStore.intakeForm,
    "patient_intake_form_answers",
    {}
  );

  const {
    preferred_pharm_name,
    preferred_pharm_number,
    emr_clinical_provider_id,
  } = intakeFormAnswers;

  const saveData = async ({ preferred_pharm_name, preferred_pharm_number }) => {
    try {
      await setIntakeFormAnswers(authenticationStore.userId, {
        intake_form_answers: {
          ...intakeFormAnswers,
          preferred_pharm_name: preferred_pharm_name,
          preferred_pharm_number: preferred_pharm_number,
        },
      });
      await intakeFormStore.refreshUserInfo();
      setSavedFields([]);
      setSaveState(2);
      // reset after 2 seconds
      setTimeout(() => setSaveState(0), 2000);
    } catch (err) {
      setSaveState(3);
      console.error(err);
      setTimeout(() => setSaveState(0), 2000);
    }
  };
  const handleDiff = (e, target) => {
    const val = e.target.value;
    const oldVal = intakeFormAnswers[target];
    const changed = val !== oldVal;
    const changedIndex = savedFields.indexOf(target);

    if (changed && changedIndex === -1) {
      setSavedFields([...savedFields, target]);
    } else if (changed === false && changedIndex !== -1) {
      const newSavedFields = savedFields.filter((field) => field !== target);
      setSavedFields(newSavedFields);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        preferred_pharm_name: preferred_pharm_name || "",
        preferred_pharm_number: preferred_pharm_number || "",
        emr_clinical_provider_id,
      }}
      validationSchema={validationSchema}
      onSubmit={saveData}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        setFieldValue = [],
        handleBlur,
      }) => {
        const preferred_pharm_nameValue = values["preferred_pharm_name"];
        const wrapperRef = useRef(null);

        return (
          <form ref={wrapperRef} className="formStyle" onSubmit={handleSubmit}>
            <AutoComplete
              inputValue={pharmacyText}
              onInputChange={(e, newInputValue) => {
                setPharmacyText(newInputValue);
                if (preferred_pharm_name !== newInputValue) {
                  setSavedFields(["preferred_pharm_name", ...savedFields]);
                }
              }}
              className={
                savedFields.indexOf("preferred_pharm_name") > -1
                  ? "profile-field-changed intakeForm__fieldDropdown"
                  : "intakeForm__fieldDropdown"
              }
              id="pharmacy-dropdown-profile"
              options={pharmacyOptions}
              getOptionLabel={(option) => option.label}
              defaultValue={{ label: preferred_pharm_nameValue }}
              getOptionSelected={(option) =>
                option.label === preferred_pharm_nameValue
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  value={preferred_pharm_nameValue}
                  label={"NAME OF PHARMACY"}
                />
              )}
              onChange={(e, value) => {
                const label = _get(value, "label", "");
                const phone = _get(value, "phone", "");
                const address = _get(value, "address", "");
                const id = _get(value, "id", "");

                setFieldValue("preferred_pharm_name", `${label}, ${address}`);
                setFieldValue("preferred_pharm_number", phone);
                setFieldValue("emr_clinical_provider_id", id);

                handleDiff(
                  { target: { value: `${label}, ${address}` } },
                  "preferred_pharm_name"
                );
                handleDiff(
                  { target: { value: phone } },
                  "preferred_pharm_number"
                );

                if (!value) {
                  setPharmacyOptions([]);
                }
              }}
              disabled={disableFields}
            />

            <commonMaterial.CustomTextField
              id="preferred_pharm_number"
              onBlur={handleBlur}
              value={values.preferred_pharm_number}
              color="primary"
              margin="normal"
              onChange={(e) => {
                handleChange(e);
                handleDiff(e, "preferred_pharm_number");
              }}
              className={
                savedFields.indexOf("preferred_pharm_number") > -1
                  ? "profile-field-changed"
                  : ""
              }
              label="PHONE NUMBER"
              fullWidth
              style={{
                marginTop: "20px",
              }}
              disabled={disableFields}
            />

            {disableFields && (
              <Alert
                severity="error"
                style={{
                  backgroundColor: "rgb(248 202 197)",
                  marginBottom: "30px",
                }}
              >
                In order to add pharmacy information, please add your city and
                state info on the Contact tab.
              </Alert>
            )}
            <CustomButton
              fullWidth={true}
              className={`profile-save-button ${
                saveState === 3 && "profile-save-error"
              }`}
              disabled={savedFields.length <= 0}
              onClick={() => {
                handleSubmit();
                setSaveState(1);
              }}
              style={{ marginBottom: "1rem" }}
            >
              {saveState === 0 && "Save"}
              {saveState === 1 && <CircularProgress size={24} />}
              {saveState === 2 && <Check size={24} />}
              {saveState === 3 && "Error saving!"}
            </CustomButton>
          </form>
        );
      }}
    </Formik>
  );
};

export default ProfilePharmacy;
