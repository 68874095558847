import { Container, Grid, Snackbar } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import { get } from "lodash";
import { useState } from "react";
import useAsyncEffect from "use-async-effect";
import * as Yup from "yup";
import * as authApi from "../../api/auth";
import { PATHNAMES } from "../../constants/pathnames";
import * as commonMaterial from "../../materialDesignShared";
import { toastLength } from "../../services/constants";
import {
  findErrorByPath,
  validateYupSchema,
} from "../../services/errorHandlers";
import { Logo } from "../Logo/Logo";
import "./ForgotPasword.scss";

export const ForgotPassword = (props) => {
  const [response, setResponse] = useState("");
  const [severityResponse, setSeverityResponse] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const forgotPassword = async (values) => {
    try {
      const { data } = await authApi.forgotPassword(values.email);
      if (get(data, "password_reset_sent")) {
        setSeverityResponse("success");
        setResponse(
          "If you’ve already signed up with this email, you’ll receive a password reset and link email there."
        );
      }
    } catch (e) {
      if (get(e, "response.status") === 422) {
        setSeverityResponse("error");
        if (e.response.data.errors.email[0] === "not found") {
          setResponse(
            "If you’ve already signed up with this email, you’ll receive a password reset and link email there."
          );
        } else {
          setResponse(e.response.data.errors.email[0]);
        }
      }
    }
  };

  useAsyncEffect(async () => {
    await authApi.getCookie();
  }, []);

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResponse("");
  };

  return (
    <div className="forgot-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Container maxWidth="sm" className="signup-group-gradient">
          <img className="baby-image" src="/images/baby.jpeg" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className="grid-gradient"
          >
            <Logo />
            <h1 className="forgotPasswordTitle">Forgot password?</h1>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={forgotPassword}
              validate={(values) => validateYupSchema(validationSchema, values)}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors = [],
                touched,
                handleBlur,
                setFieldTouched,
              }) => (
                <form className="formStyle" onSubmit={handleSubmit}>
                  <commonMaterial.CustomTextField
                    margin="normal"
                    error={touched.email && !!findErrorByPath(errors, "email")}
                    fullWidth
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    type="email"
                    label="EMAIL"
                    onChange={handleChange}
                    onInput={() => setFieldTouched("email", true, true)}
                  />
                  <div className="createAccountStyle">
                    <commonMaterial.CustomButton
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                    >
                      SEND RESET INSTRUCTIONS
                    </commonMaterial.CustomButton>
                  </div>
                </form>
              )}
            </Formik>
            <div className="linksSignupLogin">
              <div className="firstLoginGroupLink">
                Don't have an account?{" "}
                <a href={PATHNAMES.SIGN_UP}>
                  <b>Sign up</b>
                </a>
              </div>
              <div className="secondLoginGroupLink">
                Back to{" "}
                <a href={PATHNAMES.LOGIN}>
                  <b>log in</b>
                </a>
              </div>

              <Snackbar
                open={!!response}
                autoHideDuration={toastLength}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity={severityResponse}
                >
                  {response}
                </Alert>
              </Snackbar>
            </div>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
};
