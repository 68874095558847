import { Box, Grid } from "@material-ui/core";
import { PATHNAMES } from "../../constants/pathnames";
import logo from "../../assets/logo/Logo/Oula_logo_primary color.png";
import { Link } from "react-router-dom";
import { Footer } from "../Footer/Footer";

const Feedback = () => {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignContent="center"
      direction="column"
      wrap="nowrap"
      style={{
        width: "100vw",
        height: "100vh",
        minHeight: "70rem",
        background: "rgb(250, 244, 236)",
      }}
    >
      <Box width={"100vw"}>
        <Box padding="1rem">
          <a href="https://oulahealth.com">
            <img src={logo} alt="Oula logo" width={"100px"} />
          </a>
        </Box>
      </Box>
      <iframe
        src="https://docs.google.com/forms/d/e/1FAIpQLSewuzFvXKYYry-cD-_satcd7IllssISkKc3u6YwA7BIu48iAQ/viewform?embedded=true"
        width="100%"
        height="1155"
        marginHeight={0}
        marginWidth={0}
        frameBorder={0}
        title="Oula Feedback"
      >
        Loading…
      </iframe>
      <Footer />
    </Grid>
  );
};

export default Feedback;
