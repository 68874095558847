import "./ProgressBar.scss";

const ProgressBar = ({ progress = 0, lengthElements = 0 }) => {
  const currentPercentageProgress = (100 / lengthElements) * progress;
  const roundedPercentage =
    currentPercentageProgress > 100 ? 100 : currentPercentageProgress;
  const totalInPercentage = `${roundedPercentage}%`;
  return (
    <div className="progressBar__container">
      <div
        style={{ width: totalInPercentage }}
        className="progressBar__percentage"
      ></div>
    </div>
  );
};

export default ProgressBar;
