import { useState } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import * as commonMaterial from "../../materialDesignShared";
import { Container, Grid, Snackbar } from "@material-ui/core";
import { Formik } from "formik";
import {
  findErrorByPath,
  validateYupSchema,
} from "../../services/errorHandlers";
import qs from "qs";
import MuiAlert from "@material-ui/lab/Alert";
import { get } from "lodash";
import * as Yup from "yup";
import useAsyncEffect from "use-async-effect";
import * as authApi from "../../api/auth";
import { Logo } from "../Logo/Logo";
import { toastLength } from "../../services/constants";
import { PATHNAMES } from "../../constants/pathnames";

export const ResendConfirmation = () => {
  const [response, setResponse] = useState("");
  const [colorResponse, setColorResponse] = useState("");
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  useAsyncEffect(async () => {
    const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    if (query.expired_token) {
      setColorResponse("error");
      setResponse(
        "Your link has expired. Please select “Re-send confirmation email” below for a new link."
      );
    }

    await authApi.getCookie();
  }, []);

  const resendConfirmation = async (values) => {
    try {
      const { data } = await authApi.resendConfirmation(values.email);
      if (get(data, "confirmation_sent")) {
        setColorResponse("success");
        setResponse(
          "If you’ve already signed up with this email, you’ll receive a confirmation email and link email there."
        );
      }
    } catch (e) {
      if (get(e, "response.status") === 422) {
        setColorResponse("error");
        if (e.response.data.errors.email[0] === "not found") {
          setResponse(
            "If you’ve already signed up with this email, you’ll receive a confirmation email and link email there."
          );
        } else {
          setResponse(e.response.data.errors.email[0]);
        }
      }
    }
  };

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setResponse("");
  };

  return (
    <div className="resend-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Container maxWidth="sm" className="signup-group-gradient">
          <img className="baby-image" src="/images/baby.jpeg" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className="grid-gradient"
          >
            <Logo />
            <h1 className="resendConfirmationTitle">
              Resend confirmation instructions.
            </h1>
            <Formik
              initialValues={{
                email: "",
              }}
              onSubmit={resendConfirmation}
              validate={(values) => validateYupSchema(validationSchema, values)}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors = [],
                touched,
                handleBlur,
                setFieldTouched,
              }) => (
                <form className="formStyle" onSubmit={handleSubmit}>
                  <commonMaterial.CustomTextField
                    margin="normal"
                    error={touched.email && !!findErrorByPath(errors, "email")}
                    fullWidth
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    type="email"
                    label="EMAIL"
                    onChange={handleChange}
                    onInput={() => setFieldTouched("email", true, true)}
                  />
                  <Snackbar
                    open={!!response}
                    autoHideDuration={toastLength}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Alert onClose={handleClose} severity={colorResponse}>
                      {response}
                    </Alert>
                  </Snackbar>
                  <div className="createAccountStyle">
                    <commonMaterial.CustomButton
                      variant="contained"
                      size="large"
                      color="primary"
                      type="submit"
                      style={{ padding: "10px 50px" }}
                    >
                      RESEND CONFIRMATION
                    </commonMaterial.CustomButton>
                  </div>
                </form>
              )}
            </Formik>
            <div className="linksSignupLogin">
              <div className="firstLoginGroupLink">
                Don't have an account?{" "}
                <a href={PATHNAMES.SIGN_UP}>
                  <b>Sign up</b>
                </a>
              </div>
              <div className="secondLoginGroupLink">
                Back to{" "}
                <a href={PATHNAMES.LOGIN}>
                  <b>log in</b>
                </a>
              </div>
            </div>
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
};
