import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonFilled from "@material-ui/icons/FiberManualRecord";
import { Grid, Container } from "@material-ui/core";

import "./ProgressBarLoggedOut.scss";

export const ProgressBarLoggedOut = ({ step }) => {
  return (
    <Container maxWidth={"xs"} className="responsive-progress-bar-container">
      <div className="progress-bar-container">
        <div className="item select-appt">
          <div className="icon-container">
            {step === 0 && <RadioButtonCheckedIcon />}
            {step > 0 && <RadioButtonFilled />}
            <div className="step-title">
              Select <br /> appointment
            </div>
          </div>
          <div className="line-container" />
        </div>
        <div className="item select-clinic">
          <div className="icon-container">
            {step < 1 && <RadioButtonUncheckedIcon />}
            {step === 1 && <RadioButtonCheckedIcon />}
            {step > 1 && <RadioButtonFilled />}
            <div className="step-title">
              Select clinic
              <br />
            </div>
          </div>
          <div className="line-container" />
        </div>
        <div className="item select-time">
          <div className="icon-container">
            {step < 2 && <RadioButtonUncheckedIcon />}
            {step === 2 && <RadioButtonCheckedIcon />}
            {step > 2 && <RadioButtonFilled />}
            <div className="step-title">
              Select time
              <br />
            </div>
          </div>
          <div className="line-container" />
        </div>
        <div className="item signup-login">
          <div className="icon-container">
            {step < 3 && <RadioButtonUncheckedIcon />}
            {step === 3 && <RadioButtonCheckedIcon />}
            {step > 3 && <RadioButtonFilled />}
            <div className="step-title">
              Sign up / <br />
              Log in
            </div>
          </div>
          <div className="line-container" />
        </div>
        <div className="item confirm">
          <div className="icon-container">
            {step < 4 && <RadioButtonUncheckedIcon />}
            {step === 4 && <RadioButtonCheckedIcon />}
            <div className="step-title">
              Confirm
              <br />
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
