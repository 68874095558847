export const DASHBOARD_TRANSLATIONS_EN = {
  book_next_appointment: "It's time to book your next appointment.",
  next_appointment: "next appointment",
  is_on: "is on",
  your: "Your",
  my_decisions: "My decisions",
  care_plan_complete: "Your care plan is now complete.",
  decisions: "decisions",
  care_plan: "care plan",
  is_now_complete: "is now complete.",
};
