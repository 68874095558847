import { retryRequest } from "../services/requestHelpers";
import * as z from "zod";

// we are just using this to safely parse the week to surface the oula circles sign up prompt
export const ProfileSchema = z.object({
  patient: z.object({
    current_week_of_pregnancy: z.number().nullable(),
    ob_episode: z
      .object({
        status: z.string(),
      })
      .nullable(),
  }),
});

export const getProfile = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};

export const updateProfile = (id, data) => {
  return retryRequest(
    {
      method: "put",
      url: `/api/v1/patients/${id}.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
      data: { patient: data },
    },
    true
  );
};
