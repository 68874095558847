import { useEffect, useState } from "react";
import * as commonMaterial from "../../materialDesignShared";
import { authenticationStore } from "../../stores/authentication-store";
import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Snackbar,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import * as Yup from "yup";
import Toggle from "react-toggle";
import { ThemeProvider } from "@material-ui/core/styles";
import { ErrorMessage, Formik } from "formik";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import { ExpandMore } from "@material-ui/icons";
import "react-toggle/style.css";
import DateFnsUtils from "@date-io/date-fns";
import { css } from "emotion";
import { observer } from "mobx-react-lite";
import _get from "lodash/get";

import { updateProfile } from "../../api/profile";
import styles from "./TellUsABitMore.scss?inline";
import "./TellUsABitMore.scss";
import useAsyncEffect from "use-async-effect";
import { itemsKeyValue, itemsValue } from "../../services/formSettings";
import { Logo } from "../Logo/Logo";
import { formOptionsStore } from "../../stores/form-options-store";
import * as customMaterial from "../LogIn/LogInMaterialDesign";
import { toastLength } from "../../services/constants";
import MuiAlert from "@material-ui/lab/Alert";
import qs from "qs";
import { appointmentStore } from "../../stores/appointment-store";
import { getCalendarToPregnancyWeeks } from "../../api/journey";
import { MM_DD_YYYY_ALTERNATIVE, YYYY_MM_DD } from "../../constants/dateFormat";
import {
  formatPhoneText,
  phoneNumberValidatorInfo as phoneValidator,
} from "../../helpers/validationHelpers";
import { PATHNAMES } from "../../constants/pathnames";
import { useAuth } from "../../providers/Auth";
import to from "await-to-js";
import mixpanel from "mixpanel-browser";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

const checkboxStyle = (isChecked) => {
  if (isChecked === undefined) {
    return css`
      color: #c5cdd1;
      font-weight: normal;
      font-family: "WorkSans-SemiBold", sans-serif;
      font-size: 14px;
      letter-spacing: 0.2px;
    `;
  } else {
    return css`
      color: ${isChecked ? "#183746" : "#c5cdd1"};
      font-weight: 600;
      font-family: "WorkSans-SemiBold", sans-serif;
      font-size: 14px;
      letter-spacing: 0.2px;
    `;
  }
};

export const TellUsABitMore = observer(() => {
  const { user: authUser, fetchUser } = useAuth();
  const [settings, setSettings] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [acknowledgeHippaNotice, setAcknowledgeHippaNotice] = useState(false);
  const [showConsentToast, setShowConsentToast] = useState(false);
  const [showBirthYearWarningToast, setShowBirthYearWarningToast] =
    useState(false);
  const [birthdayError, setBirthdayError] = useState(false);
  const history = useHistoryWithPathBasedReload();
  const user = authenticationStore.userInfo;
  const minimumProfileCompleted =
    authUser?.patient_attributes?.minimum_profile_completed;
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [err, setErr] = useState(null);

  useEffect(() => {
    // if there's an error eg dupe account, scroll to the top so the user can see
    if (err !== null) window.scrollTo(0, 0);
  }, [err]);
  useAsyncEffect(async () => {
    setSettings(formOptionsStore.formSettingsInfo);
    setIsChecked(true);
    setAgreedToTerms(minimumProfileCompleted);
    setAcknowledgeHippaNotice(minimumProfileCompleted);

    if (minimumProfileCompleted) {
      history.push(PATHNAMES.DASHBOARD);
    }

    setTimeout(() => {
      const scrollContainer = document.querySelector("#scroll-div");
      scrollContainer?.scrollTo({ top: 5, behavior: "smooth" });
    }, 10);
  }, [formOptionsStore.formSettingsInfo]);

  const getFormatedDate = (date) =>
    date ? moment(date).format(YYYY_MM_DD) : null;

  const saveData = async (values) => {
    try {
      const res = await updateProfile(authenticationStore.userId, {
        is_pregnant: values.pregnancy === "pregnant",
        assigned_sex_at_birth: values.sex,
        preferred_pronouns: values.pronoun,
        first_name: values.firstName,
        last_name: values.lastName,
        birth_date: moment(values.dateOfBirth).format(YYYY_MM_DD),
        ob_episode: {
          last_menstrual_period_date: getFormatedDate(values.dateOfLastPeriod),
          est_delivery_date: getFormatedDate(values.estimatedDeliveryDate),
        },
        agreed_to_terms: agreedToTerms,
        acknowledged_hipaa_notice: acknowledgeHippaNotice,
        cell_phone: values.cell_phone,
        tell_us_more: true,
      });
      const { data } = res;
      await fetchUser({ ignoreLoading: true });

      if (_get(data, "patient")) {
        authenticationStore.setUser(data.patient);
        authenticationStore.setUserData(data.patient);
        authenticationStore.setMinimumProfileCompleted(true);

        const calendarToPregnancyWeeks = await getCalendarToPregnancyWeeks(
          authenticationStore.userId
        );
        if (
          _get(
            calendarToPregnancyWeeks,
            "data.patient.calendar_to_pregnancy_weeks"
          )
        ) {
          authenticationStore.setCalendarToPregnancyWeeks(
            calendarToPregnancyWeeks.data.patient.calendar_to_pregnancy_weeks
          );
        }
        if (authenticationStore.getTaggedAppointment() === "true") {
          if (query.slot_taken) {
            appointmentStore.setAppointment({
              ...appointmentStore.getAppointment(),
              slot_taken: true,
            });
            history.push(
              `${PATHNAMES.LOCATION}?appointment_type_id=${
                appointmentStore.getAppointment().appointment_type_id
              }&error=slot_taken`
            );
          } else {
            history.push(
              `${PATHNAMES.CONFIRM}?appointment_type_id=${
                appointmentStore.getAppointment().appointment_type_id
              }`
            );
          }
        } else {
          history.push(`${PATHNAMES.DASHBOARD}?first_login=true`);
        }
      }
    } catch (e) {
      console.error(e);
      try {
        const humanReadableErrorFromBackend = e?.response?.data?.errors[0];
        setErr(humanReadableErrorFromBackend);
      } catch {
        setErr("There was an issue creating your account!");
      }
    }
  };

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  const validationSchema = Yup.object().shape(
    {
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      sex: Yup.string().required(),
      cell_phone: Yup.string()
        .required()
        .test(phoneValidator.name, phoneValidator.message, phoneValidator.fn),
      pregnancy: Yup.string().required(),
      dateOfBirth: Yup.string()
        .required()
        .test("dateOfBirth", "Must be 18+ years old", (value) => {
          if (
            moment().diff(moment(moment(value).format(YYYY_MM_DD)), "years") <
            18
          ) {
            mixpanel.track("min_age_cutoff");
            return false;
          }
          return true;
        })
        .test("dateOfBirth", "Must be under 50 years old", (value) => {
          if (
            moment().diff(moment(moment(value).format(YYYY_MM_DD)), "years") >
            50
          ) {
            mixpanel.track("max_age_cutoff");
            return false;
          }
          return true;
        }),
      estimatedDeliveryDate: Yup.date()
        .min(new Date())
        .nullable()
        .default(null)
        .when(["dateOfLastPeriod", "pregnancy"], {
          is: (dateOfLastPeriod, pregnancy) => {
            return !isValidDate(dateOfLastPeriod) && pregnancy === "pregnant";
          },
          then: () => Yup.date().required(),
        }),
      dateOfLastPeriod: Yup.date()
        .max(new Date())
        .nullable()
        .default(null)
        .when(["estimatedDeliveryDate", "pregnancy"], {
          is: (estimatedDeliveryDate, pregnancy) => {
            return (
              !isValidDate(estimatedDeliveryDate) && pregnancy === "pregnant"
            );
          },
          then: () => Yup.date().required(),
        }),
    },
    ["estimatedDeliveryDate", "dateOfLastPeriod", "pregnancy"]
  );

  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowConsentToast(false);
    setShowBirthYearWarningToast(false);
  };

  const handleConsentOnClick = (e) => {
    if (!agreedToTerms || !acknowledgeHippaNotice) {
      e.preventDefault();
      setShowConsentToast(true);
    } else if (birthdayError) {
      e.preventDefault();
      setShowBirthYearWarningToast(true);
    }
  };

  return (
    <div className="tell-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Grid container direction={"column"}>
          {err !== null && (
            <Grid container justifyContent={"center"}>
              <Alert style={{ marginBottom: "1rem" }} severity={"error"}>
                {err}
              </Alert>
            </Grid>
          )}
          <Container maxWidth="sm" className="signup-group-gradient">
            <img
              className="baby-image"
              alt={"a baby and a mother"}
              src="/images/baby.jpeg"
            />
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              className="grid-gradient"
              id="scroll-div"
            >
              <Logo />
              <h1
                className="tellUsABitMore"
                style={{ marginBottom: styles.marginCredentials }}
              >
                Tell us a bit more about yourself.
              </h1>
              {user && (
                <Formik
                  initialValues={{
                    firstName: user?.first_name || "",
                    lastName: user?.last_name || "",
                    sex: user?.assigned_sex_at_birth || "",
                    pregnancy:
                      user?.is_pregnant !== null
                        ? user?.is_pregnant
                          ? "pregnant"
                          : "notPregnant"
                        : "",
                    pronoun: user?.preferred_pronouns || "",
                    lastPeriod: user?.ob_episode?.last_menstrual_period_date,
                    dateOfBirth: user?.birth_date || null,
                    dateOfLastPeriod:
                      user?.ob_episode?.last_menstrual_period_date || null,
                    estimatedDeliveryDate:
                      user?.ob_episode?.est_delivery_date || null,
                    cell_phone: user?.cell_phone || null,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={saveData}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    setFieldValue = [],
                    errors,
                    touched,
                    handleBlur,
                    setFieldTouched,
                  }) => {
                    return (
                      <form className="formStyle" onSubmit={handleSubmit}>
                        <commonMaterial.CustomTextField
                          error={touched.firstName && errors.firstName}
                          margin="normal"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                          id="firstName"
                          type=""
                          label="LEGAL FIRST NAME"
                        />
                        <FormControl fullWidth>
                          <commonMaterial.CustomTextField
                            id="lastName"
                            error={touched.lastName && errors.lastName}
                            value={values.lastName}
                            onBlur={handleBlur}
                            color="primary"
                            margin="normal"
                            onChange={handleChange}
                            label="LEGAL LAST NAME"
                          />
                        </FormControl>
                        <commonMaterial.CustomTextField
                          error={touched.cell_phone && errors.cell_phone}
                          margin="normal"
                          fullWidth
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={
                            values.cell_phone
                              ? formatPhoneText(values.cell_phone)
                              : null
                          }
                          id="cell_phone"
                          label="PHONE NUMBER"
                          inputProps={{
                            maxLength: 12,
                          }}
                        />
                        <div
                          className="selectionRow"
                          style={{ marginTop: ".6rem", marginBottom: "1rem" }}
                        >
                          <div
                            className="selectionRowElement"
                            style={{ flex: 1 }}
                          >
                            <FormControl name="pronoun">
                              <InputLabel id="pronoun">PRONOUNS</InputLabel>
                              <Select
                                labelId="pronoun"
                                label="Pronoun (optional)"
                                id="pronoun"
                                name="pronoun"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                value={values.pronoun}
                                onChange={handleChange}
                                IconComponent={ExpandMore}
                              >
                                {itemsKeyValue(
                                  settings?.data,
                                  "preferred_pronouns"
                                )}
                              </Select>
                            </FormControl>
                          </div>
                          <div
                            className="selectionRowElement"
                            style={{ flex: 1 }}
                          >
                            <FormControl name="sex">
                              <InputLabel id="sex">
                                SEX ASSIGNED AT BIRTH
                              </InputLabel>
                              <Select
                                labelId="sex"
                                label="Sex"
                                id="sex"
                                name="sex"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                error={touched.sex && errors.sex}
                                value={values.sex}
                                onClick={() => {
                                  setFieldTouched("sex");
                                }}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                IconComponent={ExpandMore}
                              >
                                {itemsValue(
                                  settings?.data,
                                  "assigned_sex_at_birth"
                                )}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div
                          className="selectionRow"
                          style={{ marginBottom: "2rem" }}
                        >
                          <div
                            className="selectionRowElement"
                            style={{ flex: 1 }}
                          >
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  style={{ marginTop: 0, marginBottom: 0 }}
                                  onClick={() => {
                                    setFieldTouched("dateOfBirth");
                                  }}
                                  variant="inline"
                                  format={MM_DD_YYYY_ALTERNATIVE}
                                  views={["month", "date", "year"]}
                                  openTo="month"
                                  margin="normal"
                                  id="dateOfBirth"
                                  value={values.dateOfBirth}
                                  onChange={(date) =>
                                    setFieldValue("dateOfBirth", date)
                                  }
                                  error={
                                    errors.dateOfBirth &&
                                    touched.dateOfBirth &&
                                    values.dateOfBirth
                                  }
                                  label="DATE OF BIRTH"
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  autoOk={true}
                                />
                              </MuiPickersUtilsProvider>
                              {errors.dateOfBirth &&
                                touched.dateOfBirth &&
                                values.dateOfBirth && (
                                  <Typography
                                    color="error"
                                    style={{
                                      fontSize: "0.8rem",
                                    }}
                                  >
                                    {errors.dateOfBirth}
                                  </Typography>
                                )}
                            </FormControl>
                          </div>
                          <div
                            className="selectionRowElement"
                            style={{ flex: 1 }}
                          >
                            <FormControl name="pregnancy">
                              <InputLabel id="pregnancy">STATUS</InputLabel>
                              <Select
                                labelId="pregnancy"
                                label="Are you pregnant"
                                id="pregnancy"
                                name="pregnancy"
                                MenuProps={{
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  getContentAnchorEl: null,
                                }}
                                value={values.pregnancy}
                                error={touched.pregnancy && errors.pregnancy}
                                onChange={(e) => {
                                  setFieldTouched("pregnancy");
                                  handleChange(e);
                                }}
                                IconComponent={ExpandMore}
                              >
                                <MenuItem value={"pregnant"}>Pregnant</MenuItem>
                                <MenuItem value={"notPregnant"}>
                                  Not pregnant
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>

                        {values.pregnancy === "pregnant" && (
                          <div>
                            <div className="toggleWrapper">
                              <span
                                className={checkboxStyle(!isChecked)}
                                style={{ marginRight: "13px" }}
                              >
                                EDD
                              </span>
                              {user && (
                                <Toggle
                                  icons={false}
                                  onChange={(e) =>
                                    setIsChecked(e.target.checked)
                                  }
                                  defaultChecked={true}
                                />
                              )}
                              <span
                                className={checkboxStyle(isChecked)}
                                style={{ marginLeft: "13px" }}
                              >
                                Last period
                              </span>
                            </div>

                            {!isChecked && (
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disablePast
                                    disableToolbar
                                    onClick={() => {
                                      setFieldTouched("estimatedDeliveryDate");
                                    }}
                                    error={
                                      touched.estimatedDeliveryDate &&
                                      errors.estimatedDeliveryDate
                                    }
                                    variant="inline"
                                    format={MM_DD_YYYY_ALTERNATIVE}
                                    margin="normal"
                                    id="estimatedDeliveryDate"
                                    value={values.estimatedDeliveryDate}
                                    onChange={(date) => {
                                      setFieldValue("dateOfLastPeriod", null);
                                      setFieldValue(
                                        "estimatedDeliveryDate",
                                        date
                                      );
                                    }}
                                    label="ESTIMATED DELIVERY DATE"
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    autoOk={true}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            )}

                            {isChecked && (
                              <FormControl fullWidth>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    disableToolbar
                                    onClick={() => {
                                      setFieldTouched("dateOfLastPeriod");
                                    }}
                                    variant="inline"
                                    format={MM_DD_YYYY_ALTERNATIVE}
                                    margin="normal"
                                    id="dateOfLastPeriod"
                                    error={
                                      touched.dateOfLastPeriod &&
                                      errors.dateOfLastPeriod
                                    }
                                    disableFuture
                                    value={values.dateOfLastPeriod}
                                    onChange={(date) => {
                                      setFieldValue(
                                        "estimatedDeliveryDate",
                                        null
                                      );
                                      setFieldValue("dateOfLastPeriod", date);
                                    }}
                                    label="DATE OF LAST PERIOD"
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    autoOk={true}
                                  />
                                </MuiPickersUtilsProvider>
                              </FormControl>
                            )}
                          </div>
                        )}

                        <div
                          className="consent-checkbox margin-bottom-10"
                          style={{ marginTop: "1rem" }}
                        >
                          <customMaterial.RememberMeCheckbox
                            checked={agreedToTerms}
                            handleChange={(e) =>
                              setAgreedToTerms(e.target.checked)
                            }
                            label={
                              <div>
                                I agree to the{" "}
                                <a
                                  target="_blank"
                                  href="https://oulahealth.com/terms-of-use/"
                                >
                                  Terms of Use
                                </a>{" "}
                                and{" "}
                                <a
                                  target="_blank"
                                  href="https://oulahealth.com/privacy/"
                                >
                                  Privacy Policy
                                </a>
                              </div>
                            }
                          />
                        </div>
                        <div className="consent-checkbox">
                          <customMaterial.RememberMeCheckbox
                            checked={acknowledgeHippaNotice}
                            handleChange={(e) =>
                              setAcknowledgeHippaNotice(e.target.checked)
                            }
                            label={
                              <div>
                                I have read and acknowledged the{" "}
                                <a
                                  target="_blank"
                                  href="https://oulahealth.com/hipaa/"
                                >
                                  HIPAA Notice
                                </a>
                              </div>
                            }
                          />
                        </div>

                        <div className="createAccountStyle">
                          {agreedToTerms &&
                          acknowledgeHippaNotice &&
                          Object.values(errors).length === 0 ? (
                            <commonMaterial.CustomButton
                              variant="contained"
                              size="large"
                              color="primary"
                              type="submit"
                            >
                              CREATE ACCOUNT
                            </commonMaterial.CustomButton>
                          ) : (
                            <commonMaterial.CustomButtonDisabled
                              variant="contained"
                              size="large"
                              color="primary"
                              type="submit"
                              onClick={handleConsentOnClick}
                              disabled
                            >
                              CREATE ACCOUNT
                            </commonMaterial.CustomButtonDisabled>
                          )}
                        </div>
                      </form>
                    );
                  }}
                </Formik>
              )}
              <Snackbar
                open={showConsentToast}
                autoHideDuration={toastLength}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert onClose={handleSnackbarClose} severity="error">
                  Please agree and acknowledge the listed policies.{" "}
                </Alert>
              </Snackbar>
              <Snackbar
                open={showBirthYearWarningToast}
                autoHideDuration={toastLength}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
              >
                <Alert onClose={handleSnackbarClose} severity="warning">
                  Please check your birth year.{" "}
                </Alert>
              </Snackbar>
            </Grid>
          </Container>
        </Grid>
      </ThemeProvider>
    </div>
  );
});
