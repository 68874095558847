import { BpReading, BpReadingWithAbnormal } from "./types";

export const checkAbnormalValues = (systolic: number, diastolic: number) => [
  systolic >= 140,
  diastolic >= 90,
];

const checkMildlyElevated = (systolic: number, diastolic: number) =>
  (systolic >= 140 && systolic < 150) || (diastolic >= 90 && diastolic < 100);
const checkModeratelyElevated = (systolic: number, diastolic: number) =>
  (systolic >= 150 && systolic < 160) || (diastolic >= 100 && diastolic < 110);
const checkSeverelyElevated = (systolic: number, diastolic: number) =>
  systolic >= 160 || diastolic >= 110;

const generateAbnormalMessaging = (
  code: BpReadingWithAbnormal["code"],
  visibleText: string,
  hoverText: string,
  patientMessaging: string
) => ({
  code,
  visibleText,
  hoverText,
  patientMessaging,
});

const checkAbnormal = (
  reading: BpReading,
  pastReadings?: BpReading[]
): BpReadingWithAbnormal => {
  const { systolic, diastolic } = reading;

  const isMildlyElevated = checkMildlyElevated(systolic, diastolic);
  const isModeratelyElevated = checkModeratelyElevated(systolic, diastolic);
  const isSeverelyElevated = checkSeverelyElevated(systolic, diastolic);

  let isModeratelyElevatedAgain = false;
  if (isModeratelyElevated && pastReadings) {
    // get readings that preceded this one
    const { timeOfReading } = reading;
    // seven days in milliseconds
    const window = 6.048e8;
    // filter out older than 7 days
    const lastSevenDaysOfReadings = pastReadings.flatMap((pR) => {
      const diff = timeOfReading.getTime() - pR.timeOfReading.getTime();
      if (diff < window) return pR;
      else return [];
    });
    // find if there is another mildly elevated reading
    lastSevenDaysOfReadings.forEach(
      ({ systolic: pastSys, diastolic: pastDia }) => {
        const pastReadingIsMildlyElevated = checkModeratelyElevated(
          pastSys,
          pastDia
        );
        if (pastReadingIsMildlyElevated) isModeratelyElevatedAgain = true;
      }
    );
  }

  const isAbnormal =
    isMildlyElevated || isModeratelyElevated || isSeverelyElevated;

  let abnormalMessaging = generateAbnormalMessaging(
    "normal",
    "Your reading is normal.",
    "Please call us at (718) 586-4944 if you are having any symptoms of preeclampsia, such as headaches, visual changes, nausea, or swelling.",
    "Your reading is normal. Please call us at (718) 586-4944 if you are having any symptoms of preeclampsia, such as headaches, visual changes, nausea, or swelling."
  );

  if (isMildlyElevated)
    abnormalMessaging = generateAbnormalMessaging(
      "mild",
      "Your reading is mildly elevated.",
      "We like to monitor blood pressures in this range, but no intervention is required. Please call us at (718) 586-4944 if you develop symptoms of preeclampsia, such as headaches, visual changes, nausea, or swelling.",
      "Your reading is mildly elevated. We like to monitor blood pressures in this range, but no intervention is required. Please call us at (718) 586-4944 if you develop symptoms of preeclampsia, such as headaches, visual changes, nausea, or swelling."
    );

  if (isModeratelyElevated)
    abnormalMessaging = generateAbnormalMessaging(
      "moderate",
      "Your blood pressure is moderately elevated. Please rest and recheck your blood pressure in one hour.",
      "Please rest and recheck your blood pressure in one hour and log your reading in the portal. Call us right away at (718) 586-4944 if you are having any symptoms of preeclampsia, such as headaches, visual changes, nausea, or swelling.",
      "Your blood pressure is moderately elevated. Please rest and recheck your blood pressure in one hour and log your reading in the portal. Call us right away at (718) 586-4944 if you are having any symptoms of preeclampsia, such as headaches, visual changes, nausea, or swelling."
    );

  if (isModeratelyElevatedAgain)
    abnormalMessaging = generateAbnormalMessaging(
      "moderate",
      "Your blood pressure is moderately elevated again. Please call us right away at (718) 586-4944.",
      "Please call us at (718) 586-4944. Persistent high blood pressure can be dangerous and the Oula clinical team needs to speak to you to discuss next steps. The Oula Clinical team is available 24/7.",
      "Your blood pressure is moderately elevated again. Please call us at (718) 586-4944. Persistent high blood pressure can be dangerous and the Oula clinical team needs to speak to you to discuss next steps. The Oula Clinical team is available 24/7."
    );

  if (isSeverelyElevated)
    abnormalMessaging = generateAbnormalMessaging(
      "severe",
      "Your blood pressure is very elevated. Please call us right away at (718) 586-4944.",
      "Please call us right away at (718) 586-4944 to discuss next steps. Very high blood pressure is dangerous and needs further evaluation. The Oula Clinical team is available 24/7.",
      "Your blood pressure is very elevated. Please call us right away at (718) 586-4944 to discuss next steps. Very high blood pressure is dangerous and needs further evaluation. The Oula Clinical team is available 24/7."
    );

  return {
    ...reading,
    abnormal: isAbnormal,
    ...abnormalMessaging,
  };
};

export default checkAbnormal;
