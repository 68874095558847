import axios from "axios";
import { get, set } from "lodash";
import { getJWTToken, removeToken } from "./tokenHandler";
import { authenticationStore } from "../stores/authentication-store";
import { PATHNAMES } from "../constants/pathnames";

const isDev = import.meta.env.DEV;
const devURL = import.meta.env.VITE_API_URL || "http://localhost:3001";
let BASE_URL;
const host = window.location.host;
if (host.includes("localhost") || isDev) {
  BASE_URL = devURL;
} else if (
  host === "staging.oulahealth.com" ||
  host === "stage.oulahealth.com" ||
  host === "local.oulahealth.com:3000"
) {
  BASE_URL = "https://api.staging.oulahealth.com";
} else if (host === "prod.oulahealth.com") {
  BASE_URL = "https://api.prod.oulahealth.com";
} else if (host === "my.oulahealth.com") {
  BASE_URL = "https://api.oulahealth.com";
}

axios.defaults.baseURL = import.meta.env.VITE_API_URL;

function sleep(ms) {
  return function (x) {
    return new Promise((resolve) => setTimeout(() => resolve(x), ms));
  };
}

export const retryRequest = async (
  requestData,
  useCredentials,
  parseImage,
  throwError
) => {
  const notLoginIncluded =
    !requestData.url.includes("login") &&
    !requestData.url.includes("google_oauth");

  for (let i = 0; i < (isDev || !authenticationStore.userId ? 1 : 3); i++) {
    try {
      if (useCredentials) {
        set(requestData, "headers.Authorization", `Bearer ${getJWTToken()}`);
      }

      if (parseImage) {
        requestData.responseType = "arraybuffer";
      }
      const response = await axios(requestData);
      return response;
    } catch (error) {
      if (throwError) {
        throw error;
      } else if (get(error, "response.status") === 401 && notLoginIncluded) {
        removeToken();
        window.location.href = PATHNAMES.LOGIN;
      } else {
        throw error;
      }
    }
    await sleep(2000)();
  }
};
