import {
  formatPhoneText,
  formatZipText,
  zipValidatorInfo,
  phoneNumberValidatorInfo,
} from "../helpers/validationHelpers";

const phoneValidations = {
  formatFunction: formatPhoneText,
  testValidation: phoneNumberValidatorInfo,
  inputProps: {
    maxLength: 12,
  },
};

export const FIELDS_INFO = {
  cell_phone: {
    label: "PHONE NUMBER",
    key: "cell_phone",
    type: "string",
    required: false,
    fromProfile: true,
    ...phoneValidations,
  },
  address1: {
    label: "ADDRESS LINE 1",
    key: "address1",
    type: "string",
    required: true,
    fromProfile: true,
  },
  address2: {
    label: "ADDRESS LINE 2",
    key: "address2",
    type: "string",
    required: false,
    fromProfile: true,
  },
  city: {
    label: "CITY",
    key: "city",
    type: "string",
    required: true,
    fromProfile: true,
  },
  state: {
    label: "STATE",
    key: "state",
    type: "string",
    required: true,
    inline: true,
    fromProfile: true,
  },
  zip: {
    label: "ZIP CODE",
    key: "zip",
    type: "string",
    required: true,
    inline: true,
    fromProfile: true,
    formatFunction: formatZipText,
    testValidation: zipValidatorInfo,
  },
  preferred_pharm_name: {
    label: "NAME",
    key: "preferred_pharm_name",
    type: "string",
    required: false,
  },
  preferred_pharm_number: {
    label: "PHONE NUMBER",
    key: "preferred_pharm_number",
    type: "string",
    required: false,
  },
  emr_clinical_provider_id: {
    key: "emr_clinical_provider_id",
    type: "string",
  },
  emergency_contact_name: {
    label: "NAME",
    key: "emergency_contact_name",
    type: "string",
    required: true,
  },
  emergency_contact_number: {
    label: "PHONE NUMBER",
    key: "emergency_contact_number",
    type: "string",
    required: true,
    ...phoneValidations,
  },
  insurance_carrier: {
    label: "Insurance Provider",
    key: "insurance_carrier",
    type: "string",
    required: true,
  },
  insurance_member_id: {
    label: "MEMBER ID",
    key: "insurance_member_id",
    type: "string",
    required: true,
  },
  insurance_group_no: {
    label: "GROUP #",
    key: "insurance_group_no",
    type: "string",
    required: false,
  },
  insurance_other_string: {
    label: "INSURANCE PROVIDER NAME",
    key: "insurance_other_string",
    type: "string",
    required: false,
  },
  emr_insurancepackageid: {
    label: "INSURANCE PLAN",
    key: "emr_insurancepackageid",
    type: "string",
    required: true,
  },
  referral_sources: {
    label: "How did you hear about Oula?",
    key: "referral_sources",
    type: "array",
    required: true,
  },
  ethnicity: {
    label: "How would you describe your ethnicity?",
    key: "ethnicity",
    type: "string",
    required: true,
  },
  race: {
    label: "Which of the following best describes you?",
    key: "race",
    type: "array",
    required: false,
  },
  sexual_orientation: {
    label: "Which of these best describes your current sexual orientation?",
    key: "sexual_orientation",
    type: "string",
    required: true,
  },
  gender_identity: {
    label: "Which of these best describes your current gender identity?",
    key: "gender_identity",
    type: "string",
    required: true,
  },
  number_of_pregnancies: {
    label: "How many times have you been pregnant before?",
    key: "number_of_pregnancies",
    type: "number",
    required: false,
  },
  number_of_births: {
    label: "How many times have you given birth before?",
    key: "number_of_births",
    type: "number",
    required: false,
  },
  marital_status: {
    label: "Marital status",
    key: "marital_status",
    type: "string",
    required: true,
  },
};

export const INFO_SECTIONS = [
  {
    key: "first_section",
    groups: [
      {
        fields: [
          FIELDS_INFO.cell_phone,
          FIELDS_INFO.address1,
          FIELDS_INFO.address2,
          FIELDS_INFO.city,
          {
            inline_fields: [
              {
                ...FIELDS_INFO.state,
                questionType: "single_select_dropdown",
                customClassName: "state",
              },
              FIELDS_INFO.zip,
            ],
          },
        ],
        hasFormValue: true,
      },
      {
        tag: "PREFERRED PHARMACY",
        fields: [
          FIELDS_INFO.preferred_pharm_name,
          FIELDS_INFO.preferred_pharm_number,
          FIELDS_INFO.emr_clinical_provider_id,
        ],
        hasFormValue: true,
      },
      {
        tag: "EMERGENCY CONTACT",
        fields: [
          FIELDS_INFO.emergency_contact_name,
          FIELDS_INFO.emergency_contact_number,
        ],
        hasFormValue: true,
      },
    ],
    showName: true,
    description:
      "First, let's complete some necessary parts of your medical record. They allow us to order medications and tests.",
    generalType: "form",
  },
  {
    key: "second_section",
    groups: [
      {
        field: FIELDS_INFO.insurance_carrier,
        questionType: "single_select",
        databaseKey: {
          name: "insurance_groups_options",
          type: "options",
        },
      },
      {
        field: FIELDS_INFO.insurance_member_id,
        questionType: "field",
        answer: null,
        hasFormValue: true,
        help: {
          description:
            "You’ll see plan options once you’ve entered a member ID and select an insurance provider",
          containerClassname: "tooltip--insurance",
          placement: "top",
        },
      },
      {
        field: FIELDS_INFO.emr_insurancepackageid,
        answer: null,
        hasFormValue: true,
      },
      {
        field: FIELDS_INFO.insurance_other_string,
        hasFormValue: true,
        questionType: "field",
        answer: null,
      },
      {
        field: FIELDS_INFO.insurance_group_no,
        questionType: "field",
        answer: null,
        hasFormValue: true,
      },
    ],
  },
  {
    key: "third_section",
    groups: [
      {
        field: FIELDS_INFO.referral_sources,
        questionType: "multiple_select_pills",
        databaseKey: {
          name: "referral_source_options",
          type: "options",
        },
      },
    ],
    customButton: true,
  },
  {
    key: "fourth_section",
    groups: [
      {
        field: FIELDS_INFO.ethnicity,
        questionType: "single_select_dropdown",
        customClassName: "ethnicity",
        databaseKey: {
          name: "ethnicity_options",
          type: "options",
        },
      },
      {
        field: FIELDS_INFO.race,
        questionType: "multiple_select",
        help: {
          question: "Why are we asking these questions?",
          description:
            "For optional genetic testing, we collect information on ethnicity to ensure the right panel of tests are run.",
          placement: "top-start",
        },
        databaseKey: {
          name: "race_options",
          type: "options",
        },
      },
    ],
  },
  {
    key: "fifth_section",
    groups: [
      {
        field: FIELDS_INFO.sexual_orientation,
        questionType: "single_select",
        databaseKey: {
          name: "sexual_orientation_options",
          type: "options",
        },
      },
      {
        field: FIELDS_INFO.gender_identity,
        questionType: "single_select",
        databaseKey: {
          name: "gender_identities_options",
          type: "options",
        },
      },
      {
        field: FIELDS_INFO.marital_status,
        questionType: "single_select",
        question: "Marital status",
        databaseKey: {
          name: "marital_status_options",
          type: "options",
        },
      },
    ],
  },
  {
    key: "sixth_section",
    databaseKey: {
      name: "high_risk_questions",
      type: "questions",
    },
    questionType: "single_select",
    required: true,
  },
  {
    key: "seventh_section",
    databaseKey: {
      name: "medical_history_questions",
      type: "questions",
    },
    generalType: "slider",
    noteField: true,
    description:
      "The following questions will help your care team personalize and focus your visit. Let's get an overview of your medical and gynecological history.",
    customButton: true,
    required: true,
  },
  {
    key: "eighth_section",
    databaseKey: {
      name: "social_history_questions",
      type: "questions",
    },
    generalType: "slider",
    description:
      "The following questions may be uncomfortable, but we want to make sure you have a private opportunity to share if you would like to. We're here to support you!",
    customButton: true,
  },
  {
    key: "nineth_section",
    databaseKey: {
      name: "gyn_history_questions",
      type: "questions",
    },
    customButton: {
      name: "FINISH INTAKE",
      finishIntake: true,
      className: "finish",
      setMinHeight: true,
    },
    required: true,
  },
  {
    key: "tenth_section",
    generalType: "complete",
    title: "Complete",
    paragraphs: [
      "Thank you for submitting your responses!",
      "The Oula team may be in touch for further clarification.",
    ],
    customButton: {
      name: "START YOUR JOURNEY",
      className: "complete",
    },
  },
];
