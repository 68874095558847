import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import _get from "lodash/get";

import { getDashboard } from "../../../api/dashboard";
import { getShowOfficeHours } from "../../../helpers/timeHelpers";
import { authenticationStore } from "../../../stores/authentication-store";
import { OFFICE_HOURS_TYPE } from "../../../constants/officeHours";

import "./OfficeHoursDetails.scss";
import { useHistoryWithPathBasedReload } from "../../App/LinkWithPathBasedReload";

const OfficeHoursDetails = () => {
  const [dashboardData, setDashboardData] = useState(null);

  const history = useHistoryWithPathBasedReload();
  const params = useParams();
  const paramType = params?.type;

  const officeHoursType = OFFICE_HOURS_TYPE[paramType];
  const showOfficeHours = getShowOfficeHours(dashboardData);

  useAsyncEffect(async () => {
    const { data } = await getDashboard(authenticationStore.userId);
    const fetchedDashboardData = _get(data, "dashboard");

    window.scrollTo(0, 0);

    await setDashboardData(fetchedDashboardData);
  }, []);

  useEffect(() => {
    if (!showOfficeHours && dashboardData) {
      history.push("/");
    }
  }, [showOfficeHours]);

  useEffect(() => {
    if (!officeHoursType) {
      history.push("/");
    }
  }, [officeHoursType]);

  return (
    <div class="officeHoursDetails__container">
      <span className="officeHoursDetails__title">
        {officeHoursType?.title}
      </span>

      <div className="officeHoursDetails__iframeContainer">
        <iframe
          src={officeHoursType?.iframeUrl}
          frameborder="0"
          scrolling="yes"
          seamless="seamless"
          className="officeHoursDetails__iframe"
        />
      </div>
    </div>
  );
};

export default OfficeHoursDetails;
