import { useLocation } from "react-router-dom";
import qs from "qs";

import { WrapperPublic } from "../Wrapper/WrapperPublic";
import { SignUp } from "../SignUp/SignUp";
import { authenticationStore } from "../../stores/authentication-store";
import { SignInWithGoogleProvider } from "../LogIn/SignInWithGoogleProvider";

const SignUpWrapper = () => {
  const location = useLocation();
  const queryParam = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <SignInWithGoogleProvider>
      {!authenticationStore.userId && queryParam.appointment === "true" ? (
        <WrapperPublic>
          <SignUp />
        </WrapperPublic>
      ) : (
        <SignUp />
      )}
    </SignInWithGoogleProvider>
  );
};

export default SignUpWrapper;
