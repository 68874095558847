import * as commonMaterial from "../../materialDesignShared";
import classNames from "classnames";
import { PATHNAMES } from "../../constants/pathnames";

import "./CompleteIntakeButton.scss";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

const CompleteIntakeButton = ({ customClassName }) => {
  const history = useHistoryWithPathBasedReload();

  const goToIntakeForm = () => history.push(PATHNAMES.INTAKE);

  const buttonClassNames = classNames({
    [customClassName]: customClassName,
  });

  return (
    <commonMaterial.ReversedCustomYellowButtonBackgroundTransparent
      variant="contained"
      size="large"
      color="primary"
      type="submit"
      onClick={goToIntakeForm}
      className={buttonClassNames}
    >
      COMPLETE INTAKE
    </commonMaterial.ReversedCustomYellowButtonBackgroundTransparent>
  );
};

export default CompleteIntakeButton;
