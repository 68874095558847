export const getIsVirtualAppointment = (appointment, virtual) => {
  if (appointment) {
    if (appointment.virtual_link) {
      return true;
    } else {
      return false;
    }
  }

  if (virtual) {
    return true;
  }

  return false;
};
