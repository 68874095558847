import { RouterHistory } from "@sentry/react/types/reactrouter";

const goBackHelper = (history: RouterHistory) => {
  const params = new URLSearchParams(window.location.search);
  const isBookAuth = params.get("book_auth") === "true";
  if (isBookAuth) history.push("/scheduling");
  else history.goBack();
};

export default goBackHelper;
