import _get from "lodash/get";
import _forEach from "lodash/forEach";
import _map from "lodash/map";
import _isEqual from "lodash/isEqual";
import _find from "lodash/find";
import { useFormik, FormikProvider } from "formik";
import classNames from "classnames";

import { authenticationStore } from "../../../../../stores/authentication-store";
import { updateProfile } from "../../../../../api/profile";
import IntakeFormFieldLayout from "../IntakeFormFieldLayout";
import {
  setIntakeFormAnswers,
  setIntakeFormHistoryAnswers,
  setCompletedIntakeForm,
  setEmrDetails,
} from "../../../../../api/intakeForm.js";
import { delay } from "../../../../../helpers/intakeFormHelpers";
import {
  convertPoundsToGrams,
  convertFtToInches,
  convertInchesToCentimeters,
} from "../../../../../helpers/validationHelpers";

const IntakeFormSectionGenerator = ({
  sectionInfo,
  setButtonName,
  ButtonNext,
  ButtonGoBack,
  sectionIndex,
  intakeFormInfoLength,
  profileData,
  intakeFormAnswers,
  setTemporalIntakeFormAnswers,
  intakeFormDatabaseInfo,
  setToastMessage,
  hasOtherInsurance,
  setHasOtherInsurance,
  setSelfPay,
  formContainerRef,
}) => {
  const showName = _get(sectionInfo, "showName", false);
  const description = _get(sectionInfo, "description", "");
  const dbKeyName = _get(sectionInfo, "databaseKey.name", "");
  const existingFirstName = authenticationStore.getIsFirstName();
  const firstName =
    (existingFirstName && existingFirstName !== "null" && existingFirstName) ||
    authenticationStore.userInfo?.first_name ||
    "";
  const groups = _get(sectionInfo, "groups");
  const generalType = _get(sectionInfo, "generalType", "");
  const noteField = _get(sectionInfo, "noteField", false);
  const initialValues = _get(sectionInfo, "initialValues", "");
  const validationSchema = _get(sectionInfo, "validationSchema", "");
  const finishIntake = _get(sectionInfo, "customButton.finishIntake", false);
  const sectionKey = _get(sectionInfo, "key", "");
  const databaseKeyName = _get(sectionInfo, "databaseKey.name");
  const userId = authenticationStore.userId;
  const showGoBackButton =
    sectionIndex > 0 && sectionIndex < intakeFormInfoLength - 1;
  const isCompletedType = generalType === "complete";
  const formContainerRefValue =
    dbKeyName === "gyn_history_questions" ? formContainerRef : null;

  const onSubmit = async (values) => {
    let profileAnswers = {};
    let copyIntakeFormAnswers = {};
    let timeoutIndex = 0;

    _forEach(groups, async (group) => {
      const fields = _get(group, "fields");
      const field = _get(group, "field");
      const databaseKeyType = _get(group, "databaseKey.type", "");

      if (fields) {
        const fillInfo = (fields) => {
          _forEach(fields, ({ key, fromProfile, inline_fields } = {}) => {
            if (inline_fields) {
              fillInfo(inline_fields);
            } else if (fromProfile) {
              if (key) {
                profileAnswers[key] = values[key];
              }
            } else {
              if (key) {
                copyIntakeFormAnswers[key] = values[key];
              }
            }
          });
        };

        fillInfo(fields);
      } else if (databaseKeyType === "options" || !databaseKeyType) {
        const fieldKey = field?.key;

        if (fieldKey) {
          copyIntakeFormAnswers[fieldKey] = values[fieldKey];
        }
      } else if (databaseKeyType === "questions") {
        const history_question_type = _get(group, "history_question_type", "");
        const history_question_id = _get(group, "history_question_id", "");
        const options = _get(group, "options", []);
        const note = _get(group, "note", "");
        const value = values[field?.key];
        const type = field?.type;
        const optionAnswered = _find(options, (option) => option.id === value);
        const intake_history_answer = {
          history_question_id,
          history_question_type,
          intake_shared_answer_id: optionAnswered?.id,
        };

        // there's probably some other logic somewhere that usually would fill in these values,
        // but i think it broken when i added some more checks to make sure intake forms
        // don't get submitted uncompleted, so i found this works
        // position the block up here so that intakeGynHistory can change values
        try {
          intake_history_answer.answer = `${value}`;
          intake_history_answer.intake_shared_answer_id = value;
        } catch (e) {
          console.error(
            "the workaround to the intake form values not saving encountered an error!",
            e
          );
        }

        if (history_question_type === "IntakeHighRisk") {
          timeoutIndex += 1;
          await delay(300 * timeoutIndex);
        } else if (
          (history_question_type === "IntakeGynHistoryQuestion" &&
            type === "integer") ||
          type === "date"
        ) {
          intake_history_answer.answer = `${value}`;
          intake_history_answer.intake_shared_answer_id = options[0]?.id;
        } else if (history_question_type === "IntakeHistoryQuestion") {
          const extraValue =
            values["medical_history_questions"][field?.key] || note;
          const typeOfExtraValue = extraValue?.type;
          const vitalsKey = "IntakeHistoryQuestion-vitals";

          if (typeOfExtraValue) {
            await setEmrDetails(userId, {
              emr_type: extraValue.type,
              emr_ids: extraValue.options,
            });
          } else if (field?.key === vitalsKey) {
            const { weight, heightFt, heightIn } = values[vitalsKey];

            if (weight) {
              const weightInGrams = convertPoundsToGrams(weight);

              await setEmrDetails(userId, {
                emr_type: "weight",
                value: weightInGrams,
              });
            }

            if (!isNaN(parseInt(heightFt)) && !isNaN(parseInt(heightIn))) {
              const heightInInches = convertFtToInches(heightFt);
              const heightInCentimeters = convertInchesToCentimeters(
                heightInInches + parseInt(heightIn)
              );

              await setEmrDetails(userId, {
                emr_type: "height",
                value: heightInCentimeters,
              });
            }
          } else {
            intake_history_answer.note =
              values["medical_history_questions"][field?.key] || note;
          }
        }

        await setIntakeFormHistoryAnswers(userId, { intake_history_answer });
      }
    });

    profileAnswers = {
      ...profileData,
      ...profileAnswers,
    };

    copyIntakeFormAnswers = {
      ...intakeFormAnswers,
      ...copyIntakeFormAnswers,
      updated_at: intakeFormAnswers?.updated_at,
    };

    if (!_isEqual(profileData, profileAnswers)) {
      await updateProfile(userId, profileAnswers);
    }

    if (!_isEqual(intakeFormAnswers, copyIntakeFormAnswers)) {
      await setIntakeFormAnswers(userId, {
        intake_form_answers: copyIntakeFormAnswers,
      });

      setTemporalIntakeFormAnswers(copyIntakeFormAnswers);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const intakeFormDescriptionClassnames = classNames(
    "intakeForm__description",
    {
      [`intakeForm__description--${generalType}`]: generalType,
    }
  );

  const {
    touched,
    errors,
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    handleBlur,
  } = formik;

  let section;

  if (groups) {
    section = (
      <>
        {showName && <span className="intakeForm__name">{firstName}</span>}
        {description && (
          <span className={intakeFormDescriptionClassnames}>{description}</span>
        )}
        <FormikProvider value={formik}>
          <form
            className="intakeForm"
            key={sectionKey}
            ref={formContainerRefValue}
            autoComplete="off"
          >
            <IntakeFormFieldLayout
              touched={touched}
              errors={errors}
              values={values}
              generalType={generalType}
              noteField={noteField}
              groups={groups}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              setButtonName={setButtonName}
              databaseKeyName={databaseKeyName}
              handleBlur={handleBlur}
              intakeFormDatabaseInfo={intakeFormDatabaseInfo}
              setToastMessage={setToastMessage}
              setHasOtherInsurance={setHasOtherInsurance}
              setSelfPay={setSelfPay}
              hasOtherInsurance={hasOtherInsurance}
            />
          </form>
        </FormikProvider>
      </>
    );
  } else if (isCompletedType) {
    const title = _get(sectionInfo, "title", "");
    const paragraphs = _get(sectionInfo, "paragraphs", []);

    section = (
      <>
        <div className="intakeForm__completeInfo">
          <img
            src="/icons/icon-circle-check-black.svg"
            alt="check"
            className="intakeForm__completeInfoIcon"
          />
          <h1 className="intakeForm__completeInfoTitle">{title}</h1>

          {paragraphs.length &&
            _map(paragraphs, (paragraph) => (
              <p className="intakeForm__completeInfoDescription">{paragraph}</p>
            ))}
        </div>

        <img
          src="/images/about_us.png"
          alt="about us"
          className="intakeForm__completeImage"
        />
      </>
    );
  }

  return (
    <>
      {section}

      <ButtonNext
        submitFn={(e) => {
          handleSubmit(e, "next");

          if (finishIntake) {
            setCompletedIntakeForm(userId);
          }
        }}
        groups={groups}
        errors={errors}
        values={values}
        isCompletedType={isCompletedType}
        hasOtherInsurance={hasOtherInsurance}
        // really make sure that, if this is the last pane of the form,
        // that the user isn't able to submit until they've answered all the questions.
        // if they can submit w/out completing all the questions, they can't get back in :-(
        disabled={!!formik.values && formik.isValid && finishIntake}
      />

      {showGoBackButton && <ButtonGoBack submitFn={handleSubmit} />}
    </>
  );
};

export default IntakeFormSectionGenerator;
