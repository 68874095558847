import * as commonMaterial from "../../materialDesignShared";
import { Container, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

import { Logo } from "../Logo/Logo";
import styles from "../TellUsABitMore/TellUsABitMore.scss?inline";
import "./Contact.scss";

export const Contact = (props) => {
  return (
    <ThemeProvider theme={commonMaterial.theme}>
      <Container maxWidth="sm" className="contact-gradient">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className="grid-gradient"
        >
          <Logo />
          <h1
            className="contact-title"
            style={{ marginBottom: styles.marginCredentials }}
          >
            Contact us
          </h1>

          <div className="contact-body">
            <div className="contact-key">Email</div>
            <div className="contact-value">
              <a href="mailto:hello@oulahealth.com">HELLO@OULAHEALTH.COM</a>
            </div>
            <div className="contact-key">Address</div>
            <div className="contact-value">
              109 MONTAGUE STREET, BROOKLYN NY
            </div>
          </div>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
