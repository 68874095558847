import jwtDecode from "jwt-decode";
import * as z from "zod";

const getToken = () => {
  const token = localStorage.getItem("jwt");
  if (!token) throw new Error("cannot find token—is user authenticated?");
  return token;
};

const isDevelopment = import.meta.env.DEV;
const baseUrl = isDevelopment
  ? "http://localhost:8000"
  : `${import.meta.env.VITE_API_URL}/v2`;

const checkToken = (token: string): void => {
  const tokenData = jwtDecode(token) as { ptid?: string };
  if (
    ("ptid" in tokenData === false || !tokenData.ptid) &&
    import.meta.env.DEV === false
  ) {
    throw new Error("ptid is undefined! does the patient have an athena ID?");
  }
};

const MentalHealthEligibleField = z.object({
  eligible: z.boolean(),
  editable: z.boolean(),
});

const MentalHealthEligibility = z.object({
  initialCheckin: MentalHealthEligibleField,
  thirdTrimesterCheckin: MentalHealthEligibleField,
  postpartumCheckin: MentalHealthEligibleField,
});

const individualCheckinResponseSchema = z.object({
  nameOfCheckinMoment: z.enum(["INITIAL", "THIRD_TRIMESTER", "POSTPARTUM"]),
  gaAtCompletion: z.number(),
  completedBy: z.string(),
  completedDate: z.string(),
  questionnaireData: z.record(z.number()),
  score: z.enum(["NEGATIVE", "MILD", "MODERATE", "SEVERE"]),
});

export type IndividualCheckinResponse = z.infer<
  typeof individualCheckinResponseSchema
>;

const checkinResponseSchema = z.object({
  initialCheckin: z.union([individualCheckinResponseSchema, z.null()]),
  thirdTrimesterCheckin: z.union([individualCheckinResponseSchema, z.null()]),
  postpartumCheckin: z.union([individualCheckinResponseSchema, z.null()]),
});

export type CheckinResponse = z.infer<typeof checkinResponseSchema>;

type EligibilityType = z.infer<typeof MentalHealthEligibility>;

export const getMentalHealthScreeningEligibility =
  async (): Promise<EligibilityType> => {
    const token = getToken();
    checkToken(token);
    const url = `${baseUrl}/mental-health-screening-eligibility`;
    const opts = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const res = await fetch(url, opts);
    if (res.status !== 200)
      throw new Error(`request failed with status ${res.status}`);
    const data = await res.json();
    return MentalHealthEligibility.parse(data);
  };

export const postMentalHealthCheckin = async (
  checkinResults: Record<string, Record<string, number>>
) => {
  const token = getToken();
  checkToken(token);
  const url = `${baseUrl}/mental-health-checkin`;
  const opts = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(checkinResults),
  };
  const res = await fetch(url, opts);

  if (res.status !== 200)
    throw new Error(`request failed with status ${res.status}`);

  const data = await res.json();
  return checkinResponseSchema.parse(data);
};
