import { Checkbox, FormControlLabel } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";

import classnames from "classnames";

export function RememberMeCheckbox(props) {
  const iconClassName = classnames({
    [props.iconClassName]: props.iconClassName,
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={props.checked}
          onChange={props.handleChange}
          disableRipple
          checkedIcon={
            <CheckCircleOutlineIcon
              color="secondary"
              className={iconClassName}
            />
          }
          icon={
            <RadioButtonUncheckedIcon
              color="secondary"
              className={iconClassName}
            />
          }
          inputProps={{ "aria-label": "decorative checkbox" }}
        />
      }
      label={props.label}
    />
  );
}
