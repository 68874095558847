import { Container, Dialog, Grid, useMediaQuery } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { ArrowForwardIos } from "@material-ui/icons";
import classNames from "classnames";
import _get from "lodash/get";
import { observer } from "mobx-react-lite";
import qs from "qs";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ReactResizeDetector, { withResizeDetector } from "react-resize-detector";
import useAsyncEffect from "use-async-effect";

import { getDashboard } from "../../api/dashboard";
import { getJourney } from "../../api/journey";
import { PATHNAMES } from "../../constants/pathnames";
import { getShowOfficeHours } from "../../helpers/timeHelpers";
import * as commonMaterial from "../../materialDesignShared";
import {
  maxMobileScreenWidth,
  maxTabletScreenWidth,
} from "../../services/constants";
import { findDecisionInJourney } from "../../services/journey-helpers";
import { appointmentStore } from "../../stores/appointment-store";
import { authenticationStore } from "../../stores/authentication-store";
import { intakeFormStore } from "../../stores/intake-form-store";
import { CarePlan } from "../Journey/CarePlan/CarePlan";
import { DecisionCard } from "../Journey/DecisionCard/DecisionCard";
import OfficeHours from "../OfficeHours";
import { CarouselEventsAndClasses } from "./CarouselEventsAndClasses/CarouselEventsAndClasses";
import { CarouselTeam } from "./CarouselTeam/CarouselTeam";

import { captureMessage } from "@sentry/react";
import "../../translations/i18n";
import IntercomButton from "../Intercom/IntercomButton";
import "./Dashboard.scss";
import OutstandingTasks from "./OutstandingTasks/OutstandingTasks";
import WelcomeBanner from "./WelcomeBanner/WelcomeBanner";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

const DashboardComponent = observer(({ width }) => {
  const [dashboardData, setDashboardData] = useState({});
  const [journey, setJourney] = useState(null);
  const [decisionCardToShow, setDecisionCardToShow] = useState(null);
  const [carePlanToShow, setCarePlanToShow] = useState(null);
  const [openMyCarePlan, setOpenMyCarePlan] = useState(false);
  const [heightDecision, setHeightDecision] = useState(330);
  const [teamMembers, setTeamMembers] = useState([]);
  const [firstTimeVisit, setFirstTimeVisit] = useState(null);
  const [carePlanCompleted, setCarePlanCompleted] = useState(null);
  const [isIntakeFormCompleted, setIsIntakeFormCompleted] = useState(false);
  const [monitoringActive, setMonitoringActive] = useState(false);
  const history = useHistoryWithPathBasedReload();
  const pathname = _get(history, "location.pathname");
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const { t } = useTranslation();
  const firstName = authenticationStore.userInfo?.first_name;
  const nextAppointment = dashboardData.patient_next_appointment;
  const appointment_type_shortname =
    nextAppointment?.appointment_type_shortname;
  const isPregnancyCareTypeAppointment = appointment_type_shortname === "VFIR";
  const intakeFormCompleted = _get(
    intakeFormStore.intakeForm,
    "patient_intake_form_answers.completed",
    false
  );

  const showNextAppointment = nextAppointment && nextAppointment.start_time;

  const showOfficeHours = getShowOfficeHours(dashboardData);

  const showCompleteIntakeButton =
    showNextAppointment &&
    isPregnancyCareTypeAppointment &&
    !isIntakeFormCompleted;

  const buttonsClassNames = classNames({
    "book-now-button": !showCompleteIntakeButton,
    dashboard__buttons: showCompleteIntakeButton,
  });

  useAsyncEffect(async () => {
    setFirstTimeVisit(authenticationStore.getIsFirstLogin());
    setCarePlanCompleted(
      authenticationStore.getIsCarePlanCompleted() === "true"
    );

    if (query.first_login) {
      setFirstTimeVisit(true);
    }

    appointmentStore.removeTaggedAppointment();

    if (query.chat === "") {
      await showIntercom();
    }

    await updateDashboard();
  }, []);

  useEffect(() => {
    setIsIntakeFormCompleted(intakeFormCompleted);
  }, [intakeFormCompleted]);

  const updateDashboard = async () => {
    const { data } = await getDashboard(authenticationStore.userId);
    const fetchedDashboardData = _get(data, "dashboard");

    if (fetchedDashboardData) {
      const {
        care_team_members,
        appointments,
        enable_message_midwife,
        patient_remote_checkin_statuses,
      } = fetchedDashboardData;

      setDashboardData(fetchedDashboardData);
      setTeamMembers(care_team_members);
      appointmentStore.setScheduledAppointments(appointments);
    }
    await fetchJourney();
  };

  const fetchJourney = async () => {
    const journey = await getJourney(authenticationStore.userId);
    setJourney(journey.data.journey);
  };

  const onResize = () => {
    width > maxTabletScreenWidth &&
      setHeightDecision(
        document.getElementById("care-team-container").clientHeight
      );
  };

  const goToJourney = (findDecision) => {
    history.push(
      `${PATHNAMES.JOURNEY}?decision=${findDecision.decision_card_id}`
    );
  };

  const isLargeViewport = useMediaQuery(
    commonMaterial.theme.breakpoints.up("md")
  );

  const isSmallViewport = useMediaQuery(
    commonMaterial.theme.breakpoints.down("md")
  );

  const welcomeState = {
    name: firstTimeVisit ? `${firstName}` : `BACK ${firstName}`,
  };

  if (showNextAppointment && nextAppointment.virtual_link) {
    welcomeState.type = "UPCOMING_VIRTUAL_APPT";
    welcomeState.nextAppointment = nextAppointment;
  } else if (showNextAppointment && !nextAppointment.virtual_link) {
    welcomeState.type = "UPCOMING_OFFICE_APPT";
    welcomeState.nextAppointment = nextAppointment;
  } else if (
    dashboardData.patient?.is_pregnant &&
    nextAppointment &&
    nextAppointment.appointment_card_key === "week8_visit" &&
    !nextAppointment.external_appointment_id &&
    dashboardData.enable_book_now &&
    !dashboardData.appointments.length
  )
    welcomeState.type = "START_CARE_PLAN";
  else if (
    dashboardData.patient?.is_pregnant &&
    nextAppointment &&
    !nextAppointment.external_appointment_id &&
    dashboardData.enable_book_now &&
    !dashboardData.start_time &&
    dashboardData.appointments.length > 0 &&
    !showNextAppointment
  )
    welcomeState.type = "TIME_TO_BOOK";
  else if (
    !nextAppointment ||
    (!dashboardData.patient?.is_pregnant && !dashboardData.appointments.length)
  )
    welcomeState.type = "BOOK_WHEN_READY";
  else {
    const msg = "unknown dashboard state, defaulting to BOOK_WHEN_READY";
    console.error(msg);
    captureMessage(msg, "error");
    welcomeState.type = "BOOK_WHEN_READY";
  }

  return (
    <div className="dashboard-wrapper">
      <Helmet>
        <title>Oula - Dashboard</title>
      </Helmet>
      <ThemeProvider theme={commonMaterial.theme}>
        <Container id="dashboard-container" className="dashboard__container">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="row"
          >
            <Grid
              container
              direction="row"
              className="dashboard__mainSection"
              wrap="nowrap"
              style={{ gap: "1.5rem", margin: "1.5rem 0" }}
            >
              <WelcomeBanner state={welcomeState} />
              <OutstandingTasks />
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="flex-start"
              direction="row"
            >
              {dashboardData.patient_upcoming_decisions && !showOfficeHours && (
                <div
                  style={{
                    height: carePlanCompleted ? "auto" : heightDecision + "px",
                    maxHeight: carePlanCompleted ? "fit-content" : "initial",
                  }}
                  className="patient-upcoming-decision"
                  id="patient-upcoming-decision"
                >
                  {carePlanCompleted && (
                    <>
                      <div className="patient-upcoming-decision-title care-plan-completed">
                        {t("my_decisions")}
                      </div>
                      <div className="care-plan-completed-msg">
                        {t("care_plan_complete")}
                      </div>
                      <CarePlan dashboard />
                    </>
                  )}

                  {!carePlanCompleted && (
                    <>
                      <div className="patient-upcoming-decision-title">
                        Your upcoming decisions
                      </div>

                      {dashboardData.patient_upcoming_decisions.length > 0 ? (
                        <div className="upcoming-decision-list">
                          <ul>
                            {dashboardData?.patient_upcoming_decisions.map(
                              (decision) => {
                                const findDecision = findDecisionInJourney(
                                  journey,
                                  decision
                                );

                                return (
                                  <li
                                    onClick={() => {
                                      if (
                                        window.innerWidth < maxMobileScreenWidth
                                      ) {
                                        setDecisionCardToShow(findDecision);
                                      } else {
                                        goToJourney(decision);
                                      }
                                    }}
                                    key={decision.decision_card_id}
                                  >
                                    <div className="key">
                                      <div className="upcoming-decision-title">
                                        {decision.title}
                                      </div>
                                      <div className="week">
                                        Week {decision.target_week}
                                      </div>
                                    </div>
                                    <ArrowForwardIos
                                      edge="end"
                                      color="inherit"
                                      aria-label="link"
                                    ></ArrowForwardIos>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      ) : (
                        <>
                          <div className="no-care-plan-message">
                            <img
                              src="/icons/Ok.png"
                              alt="care plan completed"
                              className="check-circle-icon"
                            />
                            <div className="complete-message">
                              Your care plan <br />
                              is now complete!
                            </div>
                            <div className="view-care-plan">
                              <a onClick={() => setOpenMyCarePlan(true)}>
                                VIEW CARE PLAN
                              </a>
                            </div>
                          </div>
                          <Dialog
                            fullScreen
                            open={openMyCarePlan}
                            onClose={() => setOpenMyCarePlan(false)}
                            className="care-plan-dialog"
                          >
                            <CarePlan
                              onClose={() => setOpenMyCarePlan(false)}
                            />
                          </Dialog>
                          {carePlanToShow && (
                            <CarePlan
                              onClose={() => setCarePlanToShow(undefined)}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}

              {showOfficeHours && <OfficeHours isFromDashboard />}

              <ReactResizeDetector handleWidth handleHeight onResize={onResize}>
                <div
                  id="care-team-container"
                  className={`care-team-container ${
                    carePlanCompleted && "care-team-completed"
                  }`}
                >
                  <div className="care-team-children-ver">
                    <div className="care-team-title">Oula care team</div>
                    <div className="carousel-container">
                      <CarouselTeam careTeamMembers={teamMembers} />
                    </div>
                    {dashboardData.enable_message_midwife && (
                      <div className="messenger-button-container">
                        <IntercomButton />
                      </div>
                    )}
                  </div>
                </div>
              </ReactResizeDetector>
            </Grid>

            {decisionCardToShow && (
              <DecisionCard
                onDecisionUpdate={updateDashboard}
                onBookmarkUpdate={fetchJourney}
                bookmarkedDecision={true}
                onClose={() => setDecisionCardToShow(undefined)}
                {...decisionCardToShow}
              />
            )}

            {dashboardData?.events?.length > 0 && (
              <div className="events-classes-container">
                <div className="events-classes-title">Events & classes</div>
                <CarouselEventsAndClasses />
              </div>
            )}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
});

export const Dashboard = withResizeDetector(DashboardComponent);
