import { Box, Container, Grid } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import classnames from "classnames";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import useAsyncEffect from "use-async-effect";
import * as commonMaterial from "../../materialDesignShared";

import { getDashboard } from "../../api/dashboard";
import { getPatient, resetUnseenResults } from "../../api/results";
import { PATHNAMES } from "../../constants/pathnames";
import { getShowOfficeHours } from "../../helpers/timeHelpers";
import { authenticationStore } from "../../stores/authentication-store";
import { HeaderTopBar } from "./HeaderTopBar";
import "./MobileHeader.scss";
import { Assessment } from "@material-ui/icons";
import { useFeatureFlag } from "../../providers/LaunchDarklyProvider";
import { getIsActiveMonitoringRequest } from "../../api/monitoring";
import { LinkWithPathBasedReload } from "../App/LinkWithPathBasedReload";

export const MobileHeader = observer(() => {
  const [showNavItemsBlurBefore, setShowNavItemsBlurBefore] = useState(false);
  const [showNavItemsBlurAfter, setShowNavItemsBlurAfter] = useState(true);
  const [unseenResults, setUnseenResults] = useState(false);
  const navRef = useRef();
  const [monitoringActive, setMonitoringActive] = useState(false);
  const { oulaCircles } = useFeatureFlag("oula-circles");
  const [patientData, setPatientData] = useState(null);
  const [dashboardData, setDashboardData] = useState({});

  useAsyncEffect(async (isMounted) => {
    const { active } = await getIsActiveMonitoringRequest();
    if (isMounted()) setMonitoringActive(active);
  }, []);

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    let slideTimer = setInterval(function () {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  const handleNav = (direction) => {
    if (navRef) {
      if (direction === "left") {
        sideScroll(navRef.current, "left", 10, 400, 10);
      } else {
        if (direction === "right") {
          sideScroll(navRef.current, "right", 10, 400, 10);
        }
      }
    }
  };

  useAsyncEffect(async () => {
    const { data } = await getPatient(authenticationStore.userId);
    if (get(data, "patient")) {
      setUnseenResults(data.patient.has_unseen_labs);
    }
    const { data: dataDasboard } = await getDashboard(
      authenticationStore.userId
    );
    const { dashboard: fetchedDashboardData } = dataDasboard;
    if (fetchedDashboardData) {
      setDashboardData(fetchedDashboardData);
    }
  }, []);

  const handleScroll = (e) => {
    e.target.scrollLeft > 0
      ? setShowNavItemsBlurBefore(true)
      : setShowNavItemsBlurBefore(false);
    e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth
      ? setShowNavItemsBlurAfter(false)
      : setShowNavItemsBlurAfter(true);
  };

  useAsyncEffect(async (isMounted) => {
    const newPatientData = await getPatient(authenticationStore.userId);
    if (isMounted) setPatientData(newPatientData);
  }, []);
  const isPregnant = patientData?.data.patient.is_pregnant;
  const isPostpartum = patientData?.data.patient.is_postpartum;

  const officeHoursEnable = isPregnant || isPostpartum;

  return (
    <div style={{ backgroundColor: "white" }}>
      <ThemeProvider theme={commonMaterial.theme}>
        <Container className="header-top-bar-container">
          <HeaderTopBar />

          <div className="menu-container-mobile">
            <div>
              <button className="arrow-prev" onClick={() => handleNav("left")}>
                <img src="/icons/Arrow-Left@0,5x.svg" alt="arrow-left-icon" />
              </button>
            </div>
            {showNavItemsBlurBefore && <div className="nav-items-before" />}
            <div className="nav-items" onScroll={handleScroll}>
              <div className="scrollable-nav right-bg" ref={navRef}>
                <div className="list-item-container">
                  <MenuItem path={PATHNAMES.DASHBOARD} label="Dashboard" />
                  {isPregnant ? (
                    <MenuItem path={PATHNAMES.JOURNEY} label="My journey" />
                  ) : null}
                  <MenuItem path={PATHNAMES.SCHEDULING} label="Scheduling" />
                  <MenuItem
                    path={PATHNAMES.RESULTS}
                    resetResults
                    label={
                      <div className="results-nav">
                        {unseenResults && (
                          <div className="new-result-notification">
                            <div className="dot-inside" />
                          </div>
                        )}
                        <span> Results</span>
                      </div>
                    }
                  />
                  {officeHoursEnable ? (
                    <MenuItem
                      path={PATHNAMES.OFFICE_HOURS}
                      label="Office Hours"
                    />
                  ) : null}
                  {monitoringActive ? (
                    <MenuItem
                      path={PATHNAMES.MONITORING}
                      label={"MONITORING"}
                      className={
                        location.pathname === PATHNAMES.MONITORING && "active"
                      }
                    ></MenuItem>
                  ) : null}
                  <MenuItem
                    path={PATHNAMES.PERKS}
                    label={<Box className="results-nav">PERKS</Box>}
                  />
                  {oulaCircles && (
                    <Box className="results-nav">
                      <Box
                        sx={{
                          marginTop: ".3rem",
                          marginLeft: "4rem",
                          display: "flex",
                          alignItems: "flex-start",
                          paddingBottom: ".3rem !important",
                        }}
                        className="new-result-notification"
                      >
                        NEW
                      </Box>
                      <MenuItem
                        externalLink="https://oulahealth.com/circles/"
                        label={"CIRCLES"}
                      />
                    </Box>
                  )}
                </div>
              </div>
            </div>
            {showNavItemsBlurAfter && <div className="nav-items-after" />}
            <div>
              <button className="arrow-next" onClick={() => handleNav("right")}>
                <img src="/icons/Arrow-Right@0,5x.svg" alt="arrow-right-icon" />
              </button>
            </div>
          </div>
        </Container>
      </ThemeProvider>
    </div>
  );
});

const MenuItem = ({ path, label, resetResults, externalLink }) => {
  const location = useLocation();
  const ref = useRef();
  const activeLabel =
    (location.pathname === PATHNAMES.SCHEDULING ||
      location.pathname === PATHNAMES.LOCATION ||
      location.pathname === PATHNAMES.VIEW_APPOINTMENT ||
      location.pathname === PATHNAMES.CONFIRM ||
      location.pathname === PATHNAMES.BOOKED) &&
    path === PATHNAMES.SCHEDULING;

  const isSelected = location.pathname === path || activeLabel;

  const classNameActiveLabel = classnames({
    active: location.pathname === path || activeLabel,
  });

  useEffect(() => {
    if (isSelected) {
      // Use a short timeout to ensure elements are rendered/ready to scroll
      setTimeout(() => {
        ref.current?.scrollIntoView({ inline: "center" });
      }, 100);
    }
  }, [isSelected]);

  if (externalLink)
    return (
      <a href={externalLink} target="_blank">
        {label}
      </a>
    );

  return (
    <LinkWithPathBasedReload
      to={path}
      className={classNameActiveLabel}
      onClick={async () => {
        resetResults && (await resetUnseenResults(authenticationStore.userId));
      }}
    >
      {label}
    </LinkWithPathBasedReload>
  );
};
