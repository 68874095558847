import { Box } from "@material-ui/core";
import { SurveyQuestionComponent } from "../SurveyQuesiton";

const SplashScreen = <T extends any>(props: SurveyQuestionComponent<T>) => {
  if (props.type !== "splash_screen")
    throw new Error("wrong type for splash_screen component!");
  return <Box>{props.component(props.surveyRes)}</Box>;
};

export default SplashScreen;
