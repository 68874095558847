import { useState } from "react";
import { css } from "emotion";
import { Formik } from "formik";
import _get from "lodash/get";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import qs from "qs";
import {
  findErrorByMessage,
  findErrorByPath,
  passwordErrors,
  passwordValidator,
  validateYupSchema,
} from "../../services/errorHandlers";
import styles from "./SignUp.scss?inline";
import "./SignUp.scss";
import * as authApi from "../../api/auth";
import * as commonMaterial from "../../materialDesignShared";
import { authenticationStore } from "../../stores/authentication-store";
import { Logo } from "../Logo/Logo";
import { appointmentStore } from "../../stores/appointment-store";
import { formOptionsStore } from "../../stores/form-options-store";
import { ProgressBarLoggedOut } from "../Scheduling/ProgressBarLoggedOut/ProgressBarLoggedOut";
import { PATHNAMES } from "../../constants/pathnames";
import { useAuth } from "../../providers/Auth";
import { useSignInWithGoogle } from "../LogIn/SignInWithGoogleProvider";
import { GoogleLogin } from "@react-oauth/google";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

const passwordErrorStyle = (isError) => css`
  color: ${isError ? styles.redColor : styles.greenColor};
`;

export const SignUp = () => {
  const { user: authUser, fetchUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [response, setResponse] = useState("");
  const [touchedPassword, setTouchedPassword] = useState(false);
  const [showHiddenDiv, setShowHiddenDiv] = useState(true);
  const [loadingSignup, setLoadingSignup] = useState(false);
  const history = useHistoryWithPathBasedReload();
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [registerUserFromAppointment, setRegisterUserFromAppointment] =
    useState(false);
  const { initialized: googleInitialized } = useSignInWithGoogle();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: passwordValidator,
  });

  const register = async (values) => {
    try {
      setLoadingSignup(true);
      const externalAppointmentId =
        appointmentStore.getAppointment()?.timeslot?.external_appointment_id;
      const appointmentTypeId =
        appointmentStore.getAppointment()?.appointment_type_id;
      const { data } = await authApi.register(
        values.email,
        values.password,
        registerUserFromAppointment ? externalAppointmentId : null,
        registerUserFromAppointment ? appointmentTypeId : null
      );

      if (externalAppointmentId && appointmentTypeId) {
        authenticationStore.setTaggedAppointment(true);
      }
      history.push(`${PATHNAMES.LOGIN}?email_confirmed=false`);
    } catch (e) {
      if (_get(e, "response.status") === 422) {
        if (_get(e, "response.data.forward_to_sign_in")) {
          setLoadingSignup(false);
          setResponse(e.response.data.errors[0]);
          history.push(`${PATHNAMES.LOGIN}?try_again=true`);
        } else {
          setLoadingSignup(false);
          setResponse(e.response.data.errors.email[0]);
        }
      }
    }
  };

  const googleLogin = async (token) => {
    const { data = {} } = await authApi.googleLogin(
      token,
      registerUserFromAppointment
        ? appointmentStore.getAppointment()?.timeslot?.external_appointment_id
        : null,
      registerUserFromAppointment
        ? appointmentStore.getAppointment().appointment_type_id
        : null
    );

    const {
      tagged_appointment,
      jwt,
      is_first_login,
      first_name,
      care_plan_complete,
      minimum_profile_completed,
      calendar_to_pregnancy_weeks,
      hashed_email,
      hashed_firstname,
      hashed_lastname,
      hashed_state,
    } = data;

    const {
      setJWTToken,
      setTaggedAppointment,
      setIsFirstLogin,
      setFirstName,
      setCarePlanCompleted,
      setMinimumProfileCompleted,
      setCalendarToPregnancyWeeks,
    } = authenticationStore;

    if (tagged_appointment) {
      setJWTToken(jwt, true);
      setTaggedAppointment(true);
      setIsFirstLogin(is_first_login);
      setFirstName(first_name);
      setCarePlanCompleted(care_plan_complete);
      setMinimumProfileCompleted(minimum_profile_completed);

      if (minimum_profile_completed) {
        setCalendarToPregnancyWeeks(calendar_to_pregnancy_weeks);
      }

      await fetchUser({ ignoreLoading: true });

      if (tagged_appointment.external_appointment_id === null) {
        appointmentStore.setAppointment({
          ...appointmentStore.getAppointment(),
          slot_taken: true,
        });
        minimum_profile_completed
          ? history.push(
              `${PATHNAMES.LOCATION}?appointment_type_id=${
                appointmentStore.getAppointment().appointment_type_id
              }&error=slot_taken`
            )
          : history.push(`${PATHNAMES.TELL_US_A_BIT}?slot_taken=true`);
      } else {
        minimum_profile_completed
          ? history.push(
              `${PATHNAMES.CONFIRM}?appointment_type_id=${
                appointmentStore.getAppointment().appointment_type_id
              }`
            )
          : history.push(`${PATHNAMES.TELL_US_A_BIT}`);
      }
    } else {
      if (_get(data, "jwt")) {
        setLoadingSignup(false);
        setJWTToken(jwt, true);
        setIsFirstLogin(is_first_login);
        setFirstName(first_name);
        setCarePlanCompleted(care_plan_complete);
        setMinimumProfileCompleted(minimum_profile_completed);

        if (minimum_profile_completed) {
          setCalendarToPregnancyWeeks(calendar_to_pregnancy_weeks);
        }

        // hard route change to prevent redirect loop.
        // the tell_us route will auto redirect to dashboard if user has already completed their profile
        // might be something to do w/ the PrivateRoute component
        // this workaround prevents the unwanted behavior for now... (sorry)
        window.location.href = PATHNAMES.TELL_US_A_BIT;

        // original behavior that started reload loop:
        /* minimum_profile_completed
           ? history.push(PATHNAMES.DASHBOARD)
           : history.push(PATHNAMES.TELL_US_A_BIT); */
      }
    }
  };

  return (
    <div className="signup-container">
      <ThemeProvider theme={commonMaterial.theme}>
        <Container
          maxWidth="sm"
          className={`signup-group-gradient ${
            touchedPassword && "expand-container-height"
          }`}
        >
          <img className="baby-image" src="/images/baby.jpeg" />
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            className="grid-gradient"
          >
            {appointmentStore.getAppointment() &&
            !authenticationStore.userId &&
            query.appointment === "true" ? (
              <>
                <div className="account-creation">
                  Create an account to <br />
                  confirm this appointment
                </div>
                <div className="progress-bar-logged">
                  <ProgressBarLoggedOut step={3} />
                </div>
              </>
            ) : (
              <Logo />
            )}
            {googleInitialized && (
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  googleLogin(credentialResponse.credential);
                }}
              />
            )}

            <div className="separator">or</div>

            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validate={(values) => validateYupSchema(validationSchema, values)}
              onSubmit={register}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors = [],
                touched,
                handleBlur,
                setFieldTouched,
              }) => (
                <form className="formStyle" onSubmit={handleSubmit}>
                  <commonMaterial.CustomTextField
                    margin="normal"
                    error={touched.email && !!findErrorByPath(errors, "email")}
                    fullWidth
                    onBlur={handleBlur}
                    value={values.email}
                    id="email"
                    type="email"
                    label="EMAIL"
                    onChange={handleChange}
                    onInput={() => setFieldTouched("email", true, true)}
                  />
                  <FormControl fullWidth variant="outlined">
                    <commonMaterial.CustomTextField
                      id="password"
                      color="primary"
                      onBlur={handleBlur}
                      error={
                        touched.password && findErrorByPath(errors, "password")
                      }
                      value={values.password}
                      type={showPassword ? "text" : "password"}
                      onChange={handleChange}
                      label="PASSWORD"
                      onInput={() => {
                        setTouchedPassword(true);
                        return setFieldTouched("password", true, true);
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(e) => e.preventDefault()}
                              edge="end"
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                  {touched.password && (
                    <ul className="error-list">
                      {setShowHiddenDiv(false)}
                      <Grid container spacing={0}>
                        <Grid item xs={4}>
                          <li
                            className={passwordErrorStyle(
                              !!findErrorByMessage(
                                errors,
                                passwordErrors.minChar
                              )
                            )}
                          >
                            8 chars
                          </li>
                        </Grid>
                        <Grid item xs={4}>
                          <li
                            className={passwordErrorStyle(
                              !!findErrorByMessage(
                                errors,
                                passwordErrors.oneNumber
                              )
                            )}
                          >
                            1 number
                          </li>
                        </Grid>
                        <Grid item xs={4}>
                          <li
                            className={passwordErrorStyle(
                              !!findErrorByMessage(
                                errors,
                                passwordErrors.oneUpper
                              )
                            )}
                          >
                            1 uppercase
                          </li>
                        </Grid>

                        <Grid container spacing={0}>
                          <Grid item xs={4}>
                            <li
                              className={passwordErrorStyle(
                                !!findErrorByMessage(
                                  errors,
                                  passwordErrors.oneLower
                                )
                              )}
                            >
                              1 lowercase
                            </li>
                          </Grid>

                          <Grid item xs={4}>
                            <li
                              className={passwordErrorStyle(
                                !!findErrorByMessage(
                                  errors,
                                  passwordErrors.oneSpecial
                                )
                              )}
                            >
                              1 special character
                            </li>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ul>
                  )}
                  <div>{response}</div>
                  <div className="createAccountStyle">
                    <commonMaterial.CustomButton
                      size="large"
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={loadingSignup}
                    >
                      SIGN UP
                      <span
                        style={{ display: !loadingSignup && "none" }}
                        className="login-loader"
                      >
                        <ClipLoader
                          color={"#ffffff"}
                          loading={loadingSignup}
                          size={13}
                        />
                      </span>
                    </commonMaterial.CustomButton>
                  </div>
                </form>
              )}
            </Formik>
            <div className="linksSignupLogin">
              <div className="firstLoginGroupLink">
                Already have an account?{" "}
                <a href={PATHNAMES.LOGIN}>
                  <b>Log in</b>
                </a>
              </div>
              <div>
                <a href={PATHNAMES.FORGOT_PASSWORD}>
                  <b>Forgot password?</b>
                </a>
              </div>
            </div>
            {showHiddenDiv ? (
              <div style={{ height: "30px", backgroundColor: "white" }} />
            ) : (
              <div style={{ height: "10px", backgroundColor: "white" }} />
            )}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
};
