import { useEffect, useState } from "react";
import { AppBar, Button, Container, Toolbar } from "@material-ui/core";
import { observer } from "mobx-react-lite";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { get, uniqueId } from "lodash";
import ReactHtmlParser from "react-html-parser";

import * as commonMaterial from "../../../materialDesignShared";
import "./EducationCard.scss";
import { updateBookmark, updateEducation } from "../../../api/journey";
import { authenticationStore } from "../../../stores/authentication-store";
import { journeyStore } from "../../../stores/journey-store";
import { maxTabletScreenWidth } from "../../../services/constants";
import { getHighlightTextSearchMarkup } from "../../../helpers/highlighterHelpers";

export const EducationCardDetails = observer(
  ({
    title,
    description,
    handleClose,
    patient_done,
    patient_bookmark,
    id,
    updateBookmarks,
    resources,
    searchText,
  }) => {
    const [savedBookmark, setSavedBookmark] = useState(
      patient_bookmark?.is_active || false
    );
    const [done, setDone] = useState(patient_done);
    useEffect(() => {
      setSavedBookmark(patient_bookmark?.is_active);
    }, [patient_bookmark?.is_active]);

    useEffect(() => {
      setDone(patient_done);
    }, [patient_done]);

    const highlightedTitleText = getHighlightTextSearchMarkup(
      title,
      searchText
    );

    const saveBookmark = async (isActive) => {
      try {
        await updateBookmark(authenticationStore.userId, {
          card_id: id,
          card_type: "EducationCard",
          is_active: isActive.toString(),
        });
        setSavedBookmark(isActive);
        if (updateBookmarks) {
          await Promise.all([
            journeyStore.refreshBookmarks(),
            journeyStore.refreshJourney(),
          ]);
        }
      } catch (e) {
        setSavedBookmark(false);
      }
    };

    const markEducationAsDone = async () => {
      await updateEducation(authenticationStore.userId, id, true);
      setDone(true);
      if (updateBookmarks) {
        await journeyStore.refreshJourney();
      }
    };

    const markEducationAsUnDone = async () => {
      await updateEducation(authenticationStore.userId, id, false);
      setDone(false);
      if (updateBookmarks) {
        await journeyStore.refreshJourney();
      }
    };

    function BookmarkBorderIcon() {
      return (
        <img
          src="/icons/Bookmark@0,5x.svg"
          alt="bookmark-icon"
          className="bookmarkIcon"
        />
      );
    }

    function BookmarkIcon() {
      return (
        <img
          src="/icons/Bookmark/Filled@0,5x.svg"
          alt="bookmark-filled-icon"
          className="bookmark-filled-icon"
        />
      );
    }

    return (
      <Container maxWidth="sm" className="education-container">
        <AppBar>
          <Toolbar>
            {window.innerWidth < maxTabletScreenWidth && (
              <ArrowBackIosIcon
                edge="start"
                color="inherit"
                onClick={() =>
                  handleClose
                    ? handleClose()
                    : (journeyStore.educationCardToShow = undefined)
                }
                aria-label="close"
              ></ArrowBackIosIcon>
            )}
            <div className="dialog-title">LEARN</div>
          </Toolbar>
        </AppBar>
        <div className="dialog-body">
          <div className="education-title">
            <img className="icon" src="/icons/Book@0,5x.svg" alt="book-icon" />
            {ReactHtmlParser(highlightedTitleText)}
          </div>
          <div className="dialog-description">
            {description.map((section) => {
              if (get(section, "paragraph")) {
                const higlightedText = getHighlightTextSearchMarkup(
                  section.paragraph,
                  searchText
                );
                return (
                  <p key={uniqueId()}>{ReactHtmlParser(higlightedText)}</p>
                );
              }
              if (get(section, "list")) {
                const higlightedText = getHighlightTextSearchMarkup(
                  section.list.title,
                  searchText
                );

                return (
                  <div className="education-list">
                    <p className="education-list-title">
                      {ReactHtmlParser(higlightedText)}
                    </p>
                    <ul>
                      {section.list.items.map((item) => {
                        const higlightedText = getHighlightTextSearchMarkup(
                          item,
                          searchText
                        );

                        return (
                          <li key={uniqueId()}>
                            {ReactHtmlParser(higlightedText)}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }
            })}
          </div>
          {resources?.length > 0 && (
            <div className="decision-resources">
              <b>Resources:</b>{" "}
              {resources.map((resource, index) => {
                let comma = index < resources.length - 1 ? ",  " : "";
                return (
                  <a href={resource.url} target="_blank">
                    {resource.name}
                    {comma}
                  </a>
                );
              })}
            </div>
          )}
          <div className="dialog-buttons">
            {!journeyStore.publicView && (
              <>
                <div className="button-mark-done">
                  {done ? (
                    <span onClick={markEducationAsUnDone}>
                      <Button
                        variant="contained"
                        className="mark-as-unread-button"
                        disableElevation
                        startIcon={<CloseIcon className="add-icon" />}
                        style={{
                          backgroundColor: "#ba624a",
                          color: "white",
                          textTransform: "capitalize",
                          whiteSpace: "nowrap",
                          position: "absolute",
                          width: "185px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        MARK AS UNREAD
                      </Button>
                      <Button
                        variant="contained"
                        disableElevation
                        startIcon={<CheckIcon className="add-icon" />}
                        className="read-button"
                        style={{
                          backgroundColor: "#ba624a",
                          color: "white",
                          textTransform: "capitalize",
                          whiteSpace: "nowrap",
                          width: "185px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        READ
                      </Button>
                    </span>
                  ) : (
                    <span onClick={markEducationAsDone}>
                      <commonMaterial.ReversedCustomMainButton
                        variant="outlined"
                        size="large"
                        color="primary"
                        type="submit"
                      >
                        MARK AS READ
                      </commonMaterial.ReversedCustomMainButton>
                    </span>
                  )}
                </div>
                <div
                  className="right-button"
                  onClick={() => saveBookmark(!savedBookmark)}
                >
                  {savedBookmark ? <BookmarkIcon /> : <BookmarkBorderIcon />}
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    );
  }
);
