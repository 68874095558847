import { Button } from "@material-ui/core";
import FirstCheckInSplash from "../../../ModalSurvey/MentalHealth/splashScreens/FirstCheckInSplash";
import MentalHealthScore from "../../../ModalSurvey/MentalHealth/splashScreens/MentalHealthScore";
import {
  CheckinResponse,
  postMentalHealthCheckin,
} from "../../../../api/mentalHealth";
import epds3Questions from "./epds3Questions";
import SecondCheckInSplash from "../../../ModalSurvey/MentalHealth/splashScreens/SecondCheckInSplash";
import { SurveyModalProps } from "../../../ModalSurvey/SurveyModal";

const SecondCheckIn: Omit<SurveyModalProps<CheckinResponse>, "handleClose"> = {
  title: "CHECK-IN",
  handleSubmit: async (e: Record<string, string>) => {
    const coerceIntoNumbers: Record<string, number> = {};
    Object.entries(e).map(([k, v]) => {
      coerceIntoNumbers[k] = parseInt(v, 10);
    });
    const res = await postMentalHealthCheckin({
      third_trimester_checkin: coerceIntoNumbers,
    });
    return res;
  },
  questions: [
    {
      type: "splash_screen",
      customButtonComponent: (paginationAction: () => void) => (
        <Button
          onClick={paginationAction}
          style={{
            background: "#d69722",
            color: "#FFF",
            fontSize: "1.2rem",
            padding: "0.4rem 2rem",
          }}
        >
          Start Check-in
        </Button>
      ),
      component: () => <SecondCheckInSplash />,
    },
    ...epds3Questions,
    {
      type: "splash_screen",
      overrideTitle: "SECOND CHECK-IN SUMMARY",
      customButtonComponent: (paginationAction) => (
        <Button
          onClick={() => paginationAction()}
          style={{
            background: "#262523",
            color: "#FFF",
            fontSize: "1.2rem",
            padding: "0.4rem 2rem",
          }}
        >
          Complete Check-In
        </Button>
      ),
      component: (surveyRes: CheckinResponse) => (
        <MentalHealthScore res={surveyRes} examType="THIRD_TRIMESTER" />
      ),
    },
  ],
};

export default SecondCheckIn;
