import { retryRequest } from "../services/requestHelpers";

export const getSettingsFormOptions = () => {
  return retryRequest({
    method: "get",
    url: "/api/v1/settings/form_options",
  });
};

export const heartbeat = (patientId) => {
  if (!patientId) {
    return;
  }
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${patientId}/heartbeat.json`,
    },
    true
  );
};
