import { Button } from "@material-ui/core";
import FirstCheckInSplash from "../../../ModalSurvey/MentalHealth/splashScreens/FirstCheckInSplash";
import MentalHealthScore from "../../../ModalSurvey/MentalHealth/splashScreens/MentalHealthScore";
import {
  CheckinResponse,
  postMentalHealthCheckin,
} from "../../../../api/mentalHealth";
import epds3Questions from "./epds3Questions";
import { SurveyQuestionProps } from "../../../ModalSurvey/SurveyQuesiton";
import { SurveyModalProps } from "../../../ModalSurvey/SurveyModal";

const FirstCheckIn: Omit<SurveyModalProps<CheckinResponse>, "handleClose"> = {
  title: "CHECK-IN",
  handleSubmit: async (e: Record<string, string>) => {
    const coerceIntoNumbers: Record<string, number> = {};
    Object.entries(e).map(([k, v]) => {
      coerceIntoNumbers[k] = parseInt(v, 10);
    });
    const res = await postMentalHealthCheckin({
      initialCheckin: coerceIntoNumbers,
    });
    return res;
  },
  questions: [
    {
      type: "splash_screen",
      customButtonComponent: (paginationAction) => (
        <Button
          onClick={paginationAction}
          style={{
            background: "#d69722",
            color: "#FFF",
            fontSize: "1.2rem",
            padding: "0.4rem 2rem",
          }}
        >
          Start Check-in
        </Button>
      ),
      component: () => <FirstCheckInSplash />,
    },
    ...epds3Questions,
    {
      type: "splash_screen",
      overrideTitle: "FIRST CHECK-IN SUMMARY",
      customButtonComponent: (paginationAction) => (
        <Button
          onClick={paginationAction}
          style={{
            background: "#d69722",
            color: "#FFF",
            fontSize: "1.2rem",
            padding: "0.4rem 2rem",
          }}
        >
          Complete Check-In
        </Button>
      ),
      component: (surveyRes: CheckinResponse) => {
        return <MentalHealthScore res={surveyRes} examType="INITIAL" />;
      },
    },
  ],
};

export default FirstCheckIn;
