import { retryRequest } from "../services/requestHelpers";

export const getDashboard = (id) => {
  return retryRequest(
    {
      method: "get",
      url: `/api/v1/patients/${id}/dashboard.json`,
      withCredentials: "true",
      headers: { Accept: "application/json" },
      json: true,
    },
    true
  );
};
