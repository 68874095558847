import { useState, useEffect } from "react";
import useAsyncEffect from "use-async-effect";
import _get from "lodash/get";
import { ThemeProvider } from "@material-ui/core/styles";
import classNames from "classnames";

import { getDashboard } from "../../api/dashboard";
import { getShowOfficeHours } from "../../helpers/timeHelpers";
import { authenticationStore } from "../../stores/authentication-store";
import * as commonMaterial from "../../materialDesignShared";
import { OFFICE_HOURS_TYPE } from "../../constants/officeHours";

import "./OfficeHours.scss";
import { useHistoryWithPathBasedReload } from "../App/LinkWithPathBasedReload";

const OfficeHours = ({ isFromDashboard }) => {
  const [dashboardData, setDashboardData] = useState(null);

  const history = useHistoryWithPathBasedReload();

  const showOfficeHours = getShowOfficeHours(dashboardData);

  const officeHoursCards = Object.values(OFFICE_HOURS_TYPE);

  useAsyncEffect(async () => {
    const { data } = await getDashboard(authenticationStore.userId);
    const fetchedDashboardData = _get(data, "dashboard");

    await setDashboardData(fetchedDashboardData);
  }, []);

  useEffect(() => {
    if (!showOfficeHours && dashboardData) {
      history.push("/");
    }
  }, [dashboardData]);

  return (
    <div
      className={classNames("officeHours__container", {
        "officeHours__container--dashboard": isFromDashboard,
      })}
    >
      <ThemeProvider theme={commonMaterial.theme}>
        <h2 className="officeHours__title">Office Hours</h2>

        {!isFromDashboard && (
          <span className="officeHours__description">
            Office hours at Oula are a way for you to access extra support and
            experts, as well as connect with other members of our community.
          </span>
        )}

        <div
          className={`officeHoursCards ${
            isFromDashboard ? "officeHoursCards--dashboardView" : ""
          }`}
        >
          {!isFromDashboard ? (
            officeHoursCards.map((card, idx) => (
              <div
                className="officeHoursCard__container"
                key={card.title + idx}
              >
                <div
                  style={{
                    backgroundImage: `url(${card.image})`,
                  }}
                  className="officeHoursCard__image"
                />

                <div className="officeHoursCard__info">
                  <h3 className="officeHoursCard__infoTitle">{card.title}</h3>

                  <span className="officeHoursCard__infoDescription">
                    {card.description}
                  </span>

                  <commonMaterial.CustomButton
                    variant="contained"
                    size="large"
                    color="primary"
                    type="submit"
                    style={{
                      display: "block",
                      marginTop: "25px",
                    }}
                    onClick={() => history.push(`office-hours/${card.link}`)}
                  >
                    BOOK NOW
                  </commonMaterial.CustomButton>
                </div>
              </div>
            ))
          ) : (
            <>
              {officeHoursCards.map((card, idx) => (
                <div
                  className="officeHoursCard__container"
                  key={card.title + idx}
                  onClick={() => history.push(`office-hours/${card.link}`)}
                >
                  <div
                    style={{
                      backgroundImage: `url(${card.image})`,
                    }}
                    className="officeHoursCard__image"
                  />

                  <div className="officeHoursCard__info">
                    <h3 className="officeHoursCard__infoTitle">{card.title}</h3>

                    <span className="officeHoursCard__infoDescription">
                      {card.description}
                    </span>
                  </div>

                  <button className="officeHoursCard__arrowButton">
                    <img src="/icons/arrow-right-peach.svg" alt="right arrow" />
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      </ThemeProvider>
    </div>
  );
};

export default OfficeHours;
