import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./CarouselTeam.scss";
import { openInNewTab } from "../../../services/redirectionHelper";

export const CarouselTeam = ({ careTeamMembers }) => {
  const arrows = (direction) => (
    <img
      src={`/icons/Arrow-${direction}-White@0,5x.svg`}
      alt={`arrow-${direction}-icon`}
    />
  );

  const CustomPrevArrow = ({ className, onClick }) => (
    <div className={`${className} prev-arrow`} onClick={onClick}>
      {arrows("Left")}
    </div>
  );

  const CustomNextArrow = ({ className, onClick }) => (
    <div className={`${className} next-arrow`} onClick={onClick}>
      {arrows("Right")}
    </div>
  );

  const itemsToShow = careTeamMembers?.length >= 3 ? 3 : 2;

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: itemsToShow,
    slidesToScroll: itemsToShow,
    arrows: true,
    swipeToSlide: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div className="carousel-slider">
      <Slider {...settings}>
        {careTeamMembers?.map((careMember, index) => (
          <div key={index}>
            <div
              className="carousel-item"
              onClick={() =>
                careMember?.bio[0] !== "" && openInNewTab(careMember?.bio[0])
              }
              style={{ cursor: careMember?.bio[0] !== "" && "pointer" }}
            >
              <img src={careMember.image.replace("\\t", "")} width="100%" />
              <div className="care-plan-name">
                {careMember.honorific} {careMember.first_name}{" "}
                {careMember.last_name} {careMember.post_nominal}
              </div>
              <div className="care-plan-title">{careMember.title}</div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
