import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _forEach from "lodash/forEach";
import _map from "lodash/map";
import _find from "lodash/find";
import _reduce from "lodash/reduce";
import _some from "lodash/some";
import _every from "lodash/every";
import * as yup from "yup";
import moment from "moment";

import { formOptionsStore } from "../stores/form-options-store";
import { generateValidationField } from "../helpers/validationHelpers";
import { MM_DD_YYYY } from "../constants/dateFormat";
import { defaultErrorText } from "../constants/validation";
import {
  convertCentimeterstoFtAndInches,
  convertGramsToPounds,
} from "../helpers/validationHelpers";

const INITIAL_VALUES = {
  string: "",
  number: "",
  array: [],
  date: null,
  integer: 0,
  object: {},
};

const TYPES_OF_LAYOUT = {
  IntakeScreeningQuestion: "scale",
  IntakeGynHistoryQuestion: {
    integer: "integer",
    date: "date",
  },
  IntakeHistoryQuestion: {
    vitals: "vitals",
  },
};

const TYPES_OF_VALUE = {
  IntakeScreeningQuestion: "number",
  IntakeGynHistoryQuestion: {
    integer: "integer",
    date: "date",
  },
  IntakeHistoryQuestion: {
    vitals: "object",
  },
};

/**
 * This is a function that get the initial info of the intake Form
 * and add new information coming from the database to later be shown
 * on the form.
 *
 * @param {object} databaseInfo - Info from database
 * @param {array} initialInfo - Initial info
 */

export const completeSectionsInfo = (
  databaseInfo = {},
  initialInfo = [],
  intakeFormAnswers = {},
  profileInfo = {}
) => {
  const { is_pregnant } = profileInfo;
  const copyInitialInfo = _cloneDeep(initialInfo);

  const completeInfo = _forEach(copyInitialInfo, (section) => {
    const groups = _get(section, "groups");
    const databaseKey = _get(section, "databaseKey");
    const generalType = _get(section, "generalType");
    const allInfoComeFromDatabase = !!(!groups && databaseKey);
    let initialValues = {};
    let validationSchemaFields = {};
    let setValidationAndValues = false;

    // A recursive function to fill initialValues and validationSchema
    // properties on different levels of nesting
    const completeFieldsInfo = (info) => {
      _forEach(info, (each) => {
        const key = _get(each, "key", "");
        const type = _get(each, "type", "");
        const isRequired = _get(each, "required", false);
        const label = _get(each, "label", "");
        const inlineFields = _get(each, "inline_fields");
        const isFromProfile = _get(each, "fromProfile", false);
        const testValidation = _get(each, "testValidation");
        const valueFromProfile = profileInfo[key];
        const valueFromAnswers = intakeFormAnswers?.[key];

        const getInitialValue = () => {
          if (isFromProfile && valueFromProfile) {
            if (type === "number") {
              return parseInt(valueFromProfile);
            }

            return valueFromProfile;
          } else if (valueFromAnswers) {
            return valueFromAnswers;
          }

          return INITIAL_VALUES[type];
        };

        if (inlineFields) {
          completeFieldsInfo(inlineFields);
        } else {
          initialValues[key] = getInitialValue();
          validationSchemaFields[key] = generateValidationField(
            type,
            isRequired,
            testValidation
          );
          each.label = label;
          each.answer = isFromProfile ? profileInfo[key] : null;

          if (key === "state") {
            const states = _get(
              formOptionsStore.formSettingsInfo,
              "data.state",
              []
            );

            const formatedStates = _reduce(
              states,
              (acc, state) => {
                const [id, name] = state;

                acc.push({
                  name,
                  id,
                });

                return acc;
              },
              []
            );

            each.options = formatedStates;
          }
        }
      });
    };

    // Is an specific type of section that has an specific requirements
    if (generalType === "form") {
      _forEach(groups, (group) => {
        const fields = _get(group, "fields", []);
        const hasFormValue = _get(group, "hasFormValue", []);

        completeFieldsInfo(fields);

        if (hasFormValue) {
          setValidationAndValues = true;
        }
      });
    }
    // When all the info come from the database is necessary to create each field
    // with the info, the type of question, the options for the question, the initial
    // values and the validation schema
    else if (allInfoComeFromDatabase) {
      const keyName = _get(databaseKey, "name", "");
      const keyType = _get(databaseKey, "type", "");

      const getInitialValue = (
        patientAnswer,
        options,
        type,
        answer,
        defaultVal
      ) => {
        const matchedAnswer = options.find(
          (option) => option.intake_shared_answer_id === patientAnswer
        );

        if (defaultVal) {
          return defaultVal;
        } else if (type === "integer") {
          return answer || 0;
        } else if (type === "date") {
          return answer || moment().format(MM_DD_YYYY);
        } else if (matchedAnswer) {
          return matchedAnswer?.intake_shared_answer_id;
        } else {
          return INITIAL_VALUES[type];
        }
      };

      const getQuestionType = (groupId, options) => {
        let layoutType = TYPES_OF_LAYOUT[groupId];

        if (typeof layoutType === "object") {
          layoutType = layoutType[options[0].name];
        }

        return layoutType || "yes_or_no_question";
      };

      const getValueType = (groupId, options) => {
        let valueType = TYPES_OF_VALUE[groupId];

        if (typeof valueType === "object") {
          valueType = valueType[options[0].name];
        }

        return valueType || "string";
      };

      if (keyName) {
        const matchedInfo = databaseInfo[keyName];
        const vitalsKey = "vitals";
        const height = _get(databaseInfo, "emr_details.height[0].value");
        const weight = _get(databaseInfo, "emr_details.weight[0].value");
        const ftAndInches = convertCentimeterstoFtAndInches(height);
        const pounds = convertGramsToPounds(weight);

        if (matchedInfo && keyType === "questions") {
          const required = _get(section, "required", false);
          section.groups = [];

          if (keyName === "medical_history_questions") {
            initialValues[keyName] = {};

            if (
              _every(
                matchedInfo,
                (each) => each.history_question_id !== vitalsKey
              )
            ) {
              matchedInfo[matchedInfo.length] = {
                answer: null,
                description: [
                  {
                    paragraph: `What is your current height and non-pregnant weight?`,
                  },
                ],
                history_question_type: "IntakeHistoryQuestion",
                history_question_id: vitalsKey,
                options: [{ name: "vitals" }],
                validationSchema: {
                  weight: yup.number(),
                  heightFt: yup.number(),
                  heightIn: yup.number(),
                },
                defaultValues: {
                  weight: pounds || "",
                  heightFt: ftAndInches.feet || "",
                  heightIn: ftAndInches.inches || "",
                },
              };
            }
          }

          _forEach(matchedInfo, (question, idx) => {
            const history_question_type = _get(
              question,
              "history_question_type",
              ""
            );
            const history_question_id = _get(
              question,
              "history_question_id",
              idx
            );
            const patientAnswerId = _get(question, "patient_answer_id", 0);
            const options = _get(question, "options", []);
            const label = _get(question, "description[0].paragraph", "");
            const answer = _get(question, "answer", "");
            const note = _get(question, "note", "");
            const validationSchema = _get(question, "validationSchema");
            const defaultValues = _get(question, "defaultValues");
            const questionType = getQuestionType(
              history_question_type,
              options
            );
            const key = `${history_question_type}-${history_question_id}`;
            const type = getValueType(history_question_type, options);
            const initialValue = getInitialValue(
              patientAnswerId,
              options,
              type,
              answer,
              defaultValues
            );
            setValidationAndValues = true;

            initialValues[key] = initialValue;
            validationSchemaFields[key] = generateValidationField(
              type,
              required,
              null,
              validationSchema
            );

            const completeOptions = _map(
              options,
              ({ name, intake_shared_answer_id }) => ({
                name,
                id: intake_shared_answer_id,
              })
            );

            section.groups.push({
              field: {
                label,
                key,
                type,
                required,
              },
              options: completeOptions,
              questionType,
              answer: null,
              databaseKey,
              history_question_type,
              history_question_id,
              note,
            });
          });
        }
      }

      // When only the options come from the database, just the options, initial values
      // and validation schema are necessary
    } else {
      _forEach(groups, (group) => {
        const databaseKey = _get(group, "databaseKey", {});
        const field = _get(group, "field");
        const questionType = _get(group, "questionType", "");
        const hasFormValue = _get(group, "hasFormValue", "");
        const keyNameDatabase = _get(databaseKey, "name", "");
        const keyTypeDatabase = _get(databaseKey, "type", "");
        const keyNameField = _get(field, "key", "");
        const keyTypeField = _get(field, "type", "");
        const isFieldRequired = _get(field, "required", false);
        const label = _get(field, "label", "");
        setValidationAndValues = false;

        if (field) {
          initialValues[keyNameField] =
            intakeFormAnswers?.[keyNameField] || INITIAL_VALUES[keyTypeField];
          validationSchemaFields[keyNameField] = generateValidationField(
            keyTypeField,
            isFieldRequired
          );
          field.label = label;
        }

        // Initial set of answer
        group.answer = INITIAL_VALUES[questionType] || null;

        // This piece of code should be deleted later: It's needed to add this info
        // to the options of the response of intake form
        if (questionType === "yes_or_no_question") {
          group.options = [
            {
              name: "Yes",
              id: 1,
            },
            {
              name: "No",
              id: 2,
            },
          ];
        }

        if (hasFormValue) {
          setValidationAndValues = true;
        }

        if (keyNameDatabase) {
          const matchedInfo = databaseInfo[keyNameDatabase];
          setValidationAndValues = true;

          if (matchedInfo && keyTypeDatabase === "options") {
            group.options = matchedInfo;
          }
        }
      });
    }

    if (setValidationAndValues) {
      section.initialValues = initialValues;
      section.validationSchema = yup.object().shape(validationSchemaFields);
    }
  });

  return completeInfo;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
